import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "@tanstack/react-query";
import axios from "axios";
import { XMasonry, XBlock } from "react-xmasonry";
import ProductCard5 from "../Products/ProductCard5";
import Skeleton from "../Products/Skeleton";
import { UserAuth } from "../Context";

export default function Recombee() {
  const { user } = UserAuth();
  const { ref: loadMoreRef, inView: loadMoreView } = useInView({
    threshold: 1,
    // delay :5000,
    rootMargin: "-170px",
  });

  // Fetch products using useInfiniteQuery
  const {
    data,
    isLoading,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["products", user?.uid], // Unique key for the query
    queryFn: async ({ pageParam = 1 }) => {
      const response = await axios.get(
        `https://us-central1-lookflock-api.cloudfunctions.net/recombee/${user.uid}`,
        {
          params: {
            page: pageParam,
            limit: 40,
          },
        }
      );

      // Format the products
      const formattedProducts = response.data.map((product) => ({
        id: product.values.productId,
        ...product.values,
      }));
      console.log('formattedProducts',formattedProducts);
      
      return formattedProducts; // Return the formatted products
    },
    getNextPageParam: (lastPage, allPages) => {
      // If the last page has fewer than 25 items, there are no more pages
      return lastPage.length < 40 ? undefined : allPages.length + 1;
    },
    enabled: !!user, // Only enable the query if the user is available
  });

  // Trigger fetching the next page when the "load more" ref is in view
  useEffect(() => {
    if (loadMoreView && hasNextPage && !isFetchingNextPage &&!isLoading) {
      console.log('fetchNextPage',fetchNextPage);
      fetchNextPage();
    }
  }, [loadMoreView]);
console.log('loadMoreView',loadMoreView);


  // Function to calculate the target block width for the masonry layout
  function getTargetBlockWidth() {
    const screenWidth = window.innerWidth;
    return screenWidth < 440 ? 200 : 250;
  }

  const isMobileScreen = window.innerWidth <= 768;

  return (
    <div className="vstack gap-4 px-0">
      <XMasonry
        center={true}
        responsive={true}
        maxColumns={5}
        targetBlockWidth={getTargetBlockWidth()}
      >
        {/* Render products */}
        {data?.pages
          ?.flat() // Flatten the array of pages into a single array of products
          .map((product, index) => (
            <XBlock key={index} width={1}>
              <div className={`me-0 ${index === 0 ? "ms-1" : "ms-2"}`}>
                <ProductCard5 product={product} />
              </div>
            </XBlock>
          ))}

        {/* Render skeletons while loading */}
        {(isLoading || isFetchingNextPage) &&
          Array.from({ length: 25 }).map((_, index) => (
            <XBlock key={`skeleton-${index}`} width={1}>
              <Skeleton />
            </XBlock>
          ))}

        {/* Load more ref */}
        <XBlock>
          <div ref={loadMoreRef}></div>
        </XBlock>
      </XMasonry>

      {/* Show loading spinner */}
      {(isLoading || isFetchingNextPage) && (
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {/* Show error message */}
      {isError && (
        <div className="alert alert-danger" role="alert">
          Failed to fetch products. Please try again later.
        </div>
      )}
    </div>
  );
}