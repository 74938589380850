import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddToList from "../components/AddToList";
import { Card, Spinner } from "react-bootstrap";
import "./style.css";
import AddOrRemoveFavourites from "./AddOrRemoveFavourites";
import BrandDisplay from "../NewsFeed/BrandDisplay";
import formatPrice from "../lib/formatPrice";
import { getBrandDetails } from "../lib/getBrandDetails";
import useFetch from "../hooks/useFetch";
import { CheckCircleFill, PlusCircleFill } from "react-bootstrap-icons";
import { db } from "../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";

const ProductCard3 = ({ product, isStrip, addButtonData }) => {
  const [brandData, setBrandData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInList, setIsInList] = useState(false);
  // const socket = useSocketStore((state) => state.socket);
  // const [productDetails, setProductDetails] = useState(product);

  // const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  const { data: activeBrands } = useFetch(
    `${process.env.REACT_APP_API}/api/activeBrands`
  );
  const fetchBrandData = async () => {
    try {
      // Fetch the brand's display name and logo using the getDisplayName function
      const result = await getBrandDetails(product?.supplier, activeBrands);
      setBrandData(result);
    } catch (error) {
      console.error("Error getting brand data:", error);
    }
  };

  const checkProductInList = async () => {
    const userRef = doc(
      db,
      "users",
      addButtonData.userId,
      "shoppingList",
      addButtonData.selectedShopList,
      "products",
      product?.id
    );
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      setIsInList(true);
    }
  };
  useEffect(() => {
    if (!product || !activeBrands) return;
    fetchBrandData();
  }, [product, activeBrands]);

  useEffect(() => {
    if (!product || !addButtonData) return;
    checkProductInList();
  }, [product, addButtonData]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    product?.imageUrl && (
      <div className="">
        <Card>
          <div style={{ position: "relative", height: "auto" }}>
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#eef0f2", // background for the placeholder
                }}
              >
                {/* Placeholder: Can be an image, a spinner, or any other component */}
                <Spinner
                  animation="border"
                  style={{ color: "grey", width: "20px", height: "20px" }}
                />
              </div>
            )}
            <Link to={`/ProductDetails/${product?.id}`}>
              <Card.Img
                src={product?.imageUrl}
                style={{
                  height: `28vh`,
                  objectFit: "cover",
                  opacity: loading ? 0 : 1, // Hide the image until it's fully loaded
                  transition: "opacity 0.5s ease-in-out",
                }}
                onLoad={handleImageLoad}
                loading="lazy"
              />
            </Link>
          </div>

          {/* Price Tag */}
          <div
            className="position-absolute rounded-pill top-0 start-0 m-2"
            style={{
              fontSize: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
          >
            {product?.newPrice && (
              <p className="m-0 text-white px-2 py-1">
                PKR{" "}
                {product?.newPrice
                  ? formatPrice(product.newPrice)
                  : formatPrice(product.oldPrice)}
              </p>
            )}
          </div>

          {/* Plus Button to add product in the list */}
          {addButtonData && (
            <div
              className="position-absolute rounded-circle top-0 end-0 m-2"
              style={{
                fontSize: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (!isInList) {
                  addButtonData.handleAddProductToList(product?.id, product);
                  setIsInList(true);
                }
              }}
            >
              {isInList ? (
                <CheckCircleFill className="fs-4 text-body" />
              ) : (
                <PlusCircleFill className="fs-4 text-body" />
              )}
            </div>
          )}
          {/* Strip */}
          {(isStrip === undefined || isStrip === true) && (
            <div
              className="d-inline-flex ps-1 pe-2 align-items-center justify-content-center rounded-pill text-light position-absolute bottom-0 end-0"
              style={{
                fontSize: "12px",
                marginBottom: "0.2rem",
                marginRight: "0.2rem",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <span
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <AddToList product={product} componentName={"chat"} />
              </span>
            </div>
          )}
        </Card>

        <div className="p-1 rounded-2">
          <div className="d-flex align-items-center">
            {/* Logo */}
            <div
              className="avatar me-2"
              style={{ width: "35px", height: "35px" }}
            >
              <Link to={"/BrandHome/" + product?.supplier}>
                <img
                  className="avatar-img rounded-2"
                  src={brandData?.logo}
                  alt=""
                />
              </Link>
            </div>
            <div
              className="d-flex flex-column justify-content-start"
              style={{ width: "78%" }}
            >
              <div className="mb-0">
                <BrandDisplay
                  brandData={brandData}
                  post={product}
                  type={"ProductCard"}
                />
              </div>
              <div
                className="fw-bold h6 position-relative"
                style={{
                  marginTop: "2px",
                  fontSize: "12px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {product?.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ProductCard3;
