import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddToList from "../components/AddToList";
import { Card } from "react-bootstrap";
import "./style.css";
import { UserAuth } from "../Context";
import BrandDisplay from "../NewsFeed/BrandDisplay";
import formatPrice from "../lib/formatPrice";
import { getBrandDetails } from "../lib/getBrandDetails";
import useFetch from "../hooks/useFetch";
import usePost from "../hooks/usePost";
import { db } from "../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import AddToChat from "../components/AddToChat";
import AddToCart from "../components/AddToCart";
import AddOrRemoveLike from "./AddOrRemoveLike";
import Slider from "react-slick";

const PrevArrow = ({ style, onClick }) => {
  return (
    <div
      className="slick-prev "
      style={{
        ...style,
        fontSize: "24px",
        color: "grey",
        zIndex: 1,
        left: "2%",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // background: "rgba(var(--bs-primary-rgb), 0.3)",
        background: "rgba(0, 0, 0, 0.6)",
        borderRadius: "0.4rem",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-prev-icon"
        style={{ filter: "brightness(1.4)", width: "15px", height: "15px" }}
      ></span>
    </div>
  );
};

const NextArrow = ({ style, onClick }) => {
  return (
    <div
      style={{
        ...style,
        background: "rgba(0, 0, 0, 0.6)",
        fontSize: "24px",
        color: "grey",
        zIndex: 1,
        right: "2%",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: "20px",
        height: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // background: "rgba(var(--bs-primary-rgb), 0)",
        borderRadius: "0.4rem",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-next-icon"
        style={{ filter: "brightness(1.4)", width: "15px", height: "15px" }}
      ></span>
    </div>
  );
};
const ProductCardBrandsPage = ({ product, isStrip, addButtonData }) => {
  const [likeCount, setLikeCount] = useState(product?.likes);
  const [brandData, setBrandData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isInList, setIsInList] = useState(false);
  const [loginCount, setLoginCount] = useState(0);
  const [showCard, setShowCard] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const settings = {
    speed: 500,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    swipe: true,
    nextArrow: !loading ? <NextArrow /> : null, // Show only when not loading
    prevArrow: !loading ? <PrevArrow /> : null,
  };
  // const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  const { user } = UserAuth();
  const { data: activeBrands } = useFetch(
    `${process.env.REACT_APP_API}/api/activeBrands`
  );
  let url = process.env.REACT_APP_API + `/api/activity/${user?.uid}`;

  // let url = process.env.REACT_APP_API+`/api/logs/${user?.uid}`;
  const { data: postData, post } = usePost(url);

  const fetchBrandData = async () => {
    try {
      // Fetch the brand's display name and logo using the getDisplayName function
      const result = await getBrandDetails(product?.supplier, activeBrands);
      setBrandData(result);
      // console.log("result", result);
    } catch (error) {
      console.error("Error getting brand data:", error);
    }
  };

  const checkProductInList = async () => {
    const userRef = doc(
      db,
      "users",
      addButtonData.userId,
      "shoppingList",
      addButtonData.selectedShopList,
      "products",
      product?.id
    );
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      setIsInList(true);
    }
  };

  useEffect(() => {
    if (!product || !activeBrands) return;
    fetchBrandData();
  }, [product, activeBrands]);

  useEffect(() => {
    if (!product || !addButtonData) return;
    checkProductInList();
  }, [product, addButtonData]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  // const fetchLoginCount = async () => {
  //   const userRef = doc(db, "users", user?.uid);
  //   const userSnap = await getDoc(userRef);
  //   if (userSnap.exists()) {
  //     setLoginCount(userSnap?.data().loginCount || 0);
  //   }
  // };

  // useEffect(() => {
  //   if (!user) return;
  //   fetchLoginCount();
  // }, [user]);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!showCard) return;

  // if (product?.name === "Cambric Shalwar") console.log("product", product);
  return (
    <div className="shadow-sm p-2 rounded-2 bg-mode m-1">
      <div className="d-flex flex-column">
        <div style={{ position: "relative", height: "auto" }}>
          {loading && (
            <div
              className="skeleton"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "20em",
                backgroundColor: "#ffffff", // background for the placeholder
              }}
            ></div>
          )}
          <Link to={`/ProductDetails/${product?.id}`}>
            <Slider {...settings} className="m-0">
              {[product?.imageUrl, ...(product?.secondaryImages || [])]?.map(
                (image, index) => (
                  <Card.Img
                    src={image}
                    className={`rounded-2 ${loading ? "skeleton" : ""}`}
                    style={{
                      // opacity: loading ? 0 : 1, // Hide the image until it's fully loaded
                      transition: "opacity 0.5s ease-in-out",
                      objectFit: "cover",
                      // height: isSmallScreen ? "11rem" : "14rem",
                      height: "auto",
                      display: "inline",
                      // objectPosition: "top",
                    }}
                    onLoad={handleImageLoad}
                    onError={() => setShowCard(false)}
                    loading="lazy"
                  />
                )
              )}
            </Slider>
          </Link>
          <div
            style={{
              position: "absolute",
              backgroundColor: "#59A800",
              color: "white",
              width: "4rem",
              fontSize: "10px",
              alignContent: "center",
              height: "1.5rem",
            }}
            className="rounded-pill text-center top-0 end-0 m-1"
          >
            PKR{" "}
            {product?.newPrice
              ? formatPrice(product.newPrice)
              : formatPrice(product.oldPrice)}
          </div>
        </div>

        {isSmallScreen ? (
          <div className={`d-flex flex-column`}>
            <div className="d-flex align-items-center">
              {/* Logo */}
              <div
                className="avatar me-2"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              >
                <Link to={"/BrandHome/" + product?.supplier}>
                  <img
                    className="avatar-img rounded border"
                    src={brandData?.logoUrl}
                    alt=""
                  />
                </Link>
              </div>
              <div
                className="d-flex flex-column justify-content-start mb-2"
                style={{ width: "78%" }}
              >
                <div className="mb-0">
                  <BrandDisplay brandData={brandData} type={"ProductCard"} />
                </div>
                <div
                  className="h6 text-body position-relative text-truncate mb-0"
                  style={{
                    marginTop: "2px",
                    fontSize: "11px",
                    width: "92%",
                  }}
                >
                  {product?.name}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <AddToChat product={product} componentName={"Discover"} />
              </div>
              <div className={`d-flex`} style={{ gap: "0.4rem" }}>
                <div
                  style={{ width: "1.3rem", height: "1.3rem" }}
                  className="icon-xs text-white rounded-circle border border-secondary"
                >
                  <AddToList product={product} componentName={"strip2"} />
                </div>
                <div
                  style={{ width: "1.3rem", height: "1.3rem" }}
                  className="icon-xs text-white rounded-circle border border-secondary"
                >
                  <AddOrRemoveLike
                    user={user}
                    product={product}
                    setLikeCount={setLikeCount}
                    componentName={"strip2"}
                  />
                </div>
                <div
                  style={{ width: "1.3rem", height: "1.3rem" }}
                  className="icon-xs text-white rounded-circle border border-secondary"
                >
                  <AddToCart
                    user={user}
                    product={product}
                    componentName={"ShopListProdCard"}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={`d-flex flex-column  mt-1`}>
            <div className="d-flex align-items-center">
              {/* Logo */}
              <div
                className="avatar me-2"
                style={{
                  width: "40px",
                  height: "40px",
                }}
              >
                <Link to={"/BrandHome/" + product?.supplier}>
                  <img
                    className="avatar-img rounded border"
                    src={brandData?.logoUrl}
                    alt=""
                  />
                </Link>
              </div>
              <div
                className="d-flex flex-column justify-content-start mb-2"
                style={{ width: "78%" }}
              >
                <div className="mb-0">
                  <BrandDisplay brandData={brandData} type={"ProductCard"} />
                </div>
                <div
                  className="h6 position-relative text-truncate mb-0"
                  style={{
                    marginTop: "2px",
                    fontSize: "13px",
                    width: "100%",
                  }}
                >
                  {product?.name}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <AddToChat product={product} componentName={"Discover"} />
              </div>
              <div className={`d-flex gap-2`}>
                <div className="icon-xs text-white rounded-circle border border-secondary">
                  <AddToList product={product} componentName={"strip2"} />
                </div>
                <div className="icon-xs text-white rounded-circle border border-secondary">
                  <AddOrRemoveLike
                    user={user}
                    product={product}
                    setLikeCount={setLikeCount}
                    componentName={"strip2"}
                  />
                </div>
                <div className="icon-xs text-white rounded-circle border border-secondary">
                  <AddToCart
                    user={user}
                    product={product}
                    componentName={"ShopListProdCard"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCardBrandsPage;
