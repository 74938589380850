import React from 'react';
import { DialogMenuButton } from './DialogMenuButton';

export const ShoppingListAttachmentAction = ({ closeMenu, openModalForAction }) => (
  <DialogMenuButton
    className='str-chat__attachment-selector-actions-menu__button str-chat__attachment-selector-actions-menu__upload-file-button'
    onClick={() => {
      openModalForAction("shoppingList");
      closeMenu();
    }}
    type={"ShoppingList"}
  >
    Shopping List
  </DialogMenuButton>
);


export const ProductsAttachmentAction = ({ closeMenu, openModalForAction }) => (
  <DialogMenuButton
    className="str-chat__attachment-selector-actions-menu__button str-chat__attachment-selector-actions-menu__upload-file-button"
    onClick={() => {
      openModalForAction("products");
      closeMenu();
    }}
    type={"Products"}
  >
    Products
  </DialogMenuButton>
);