import React, { useState } from 'react';
import { Attachment } from 'stream-chat-react';
import ProductCard3 from '../Products/ProductCard3';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";


const CustomGallery = ({ images }) => {
    console.log("CustomGallery", images);

    const updatedImages = Object.values(images).map((image) => ({
        original: image.image_url,
        link: `/#/ProductDetails/${image.product.id}` || "#",
    }));

    const renderItem = (item) => (
        <a href={item.link} target="_blank" rel="noopener noreferrer">
            <img src={item.original} alt="Gallery" style={{ width: "100%", height: "14rem", objectFit: "cover" }} />
        </a>
    );

    const renderLeftNav = (onClick, disabled) => (
        <button
            className="custom-left-nav"
            onClick={onClick}
            disabled={disabled}
            style={{
                zIndex: '12',
                position: "absolute",
                top: "50%",
                left: "-1px",
                transform: "translateY(-50%)",
                filter: "brightness(1)",
                border: "none",
                padding: "5px",
                borderRadius: "50%",
                cursor: "pointer",
                width: "25px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            ❮
        </button>
    );

    const renderRightNav = (onClick, disabled) => (
        <button
            className="custom-right-nav"
            onClick={onClick}
            disabled={disabled}
            style={{
                zIndex: '12',
                position: "absolute",
                top: "50%",
                right: "-1px",
                transform: "translateY(-50%)",
                filter: "brightness(1)",
                border: "none",
                padding: "5px",
                borderRadius: "50%",
                cursor: "pointer",
                width: "25px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            ❯
        </button>
    );

    return (
        <ImageGallery
            items={updatedImages}
            renderItem={renderItem}
            renderLeftNav={renderLeftNav}
            renderRightNav={renderRightNav}
        />
    );
};


function CustomAttachment({ attachments }) {

    const [attachment] = attachments;

    if (!attachments || attachments.length === 0) return null;


    if (attachment?.type === "product") {
        return <ProductCard3 product={attachment?.product} isStrip={true} />;
    }


    return <Attachment attachments={attachments} Gallery={CustomGallery} />;
}

export default CustomAttachment;
