import React from "react";
import {
  SideNavPriceRangeDropDown,
  SideNavGenderDropDown,
  SideNavBrandDropdown,
  SideNavCategoryDropDown,
  SideNavSubCategoryDropDown,
  SideNavSizeDropDown,
  SideNavColorDropDown,
} from "./SideNavSearchDropDown";

export default function SearchPageSideNav() {
  // console.log('Results are',Result);
  return (
    <div className="offcanvas-body d-block bg-mode rounded-2 p-0 searchFilters">
      <style jsx>
        {`
          .searchFilters .dropdown-toggle:after {
            font-size: 0.8rem;
            display: none;
          }
        `}
      </style>
      {/* Card START */}
      <p className="fw-semibold m-0 fs-2 px-4 py-4">Filter &nbsp;</p>
      {/* GENDER */}
      <div className="px-4 py-3">
        <p
          className="fw-semibold mb-0 d-flex w-100 justify-content-between"
          data-bs-toggle="collapse"
          data-bs-target="#collapseGender"
          aria-expanded="false"
          aria-controls="collapseGender"
        >
          <div>Gender &nbsp;</div>
          <div className="dropdown-toggle"></div>
        </p>
        <SideNavGenderDropDown dataId={"collapseGender"} />
      </div>
      <hr className="m-0" />

      {/* BRANDS */}
      <div className="px-4 py-3">
        <p
          className="fw-semibold mb-0 d-flex w-100 justify-content-between"
          data-bs-toggle="collapse"
          data-bs-target="#collapseBrands"
          aria-expanded="false"
          aria-controls="collapseBrands"
        >
          <div>Brand &nbsp;</div>
          <div className="dropdown-toggle"></div>
        </p>
        <SideNavBrandDropdown dataId={"collapseBrands"} />
      </div>
      <hr className="m-0" />

      {/* CATEGORY */}
      <div className="px-4 py-3">
        <p
          className="fw-semibold mb-0 d-flex w-100 justify-content-between"
          data-bs-toggle="collapse"
          data-bs-target="#collapseCategory"
          aria-expanded="false"
          aria-controls="collapseCategory"
        >
          <div>Category &nbsp;</div>
          <div className="dropdown-toggle"></div>
        </p>
        <SideNavCategoryDropDown dataId={"collapseCategory"} />
      </div>
      <hr className="m-0" />

      {/* SUB-CATEGORY */}
      {/* <div className="px-4 py-3">
        <p
          className="fw-semibold mb-0 d-flex w-100 justify-content-between"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSubCategory"
          aria-expanded="false"
          aria-controls="collapseSubCategory"
        >
          <div>Sub-Category &nbsp;</div>
          <div className="dropdown-toggle"></div>
        </p>
        <SideNavSubCategoryDropDown dataId={"collapseSubCategory"} />
      </div>
      <hr className="m-0" /> */}

      {/* PRICE RANGE */}
      <div className="px-4 py-3">
        <p
          className="fw-semibold mb-0 d-flex w-100 justify-content-between"
          data-bs-toggle="collapse"
          data-bs-target="#collapsePriceRange"
          aria-expanded="false"
          aria-controls="collapsePriceRange"
        >
          <div>Price &nbsp;</div>
          <div className="dropdown-toggle"></div>
        </p>
        <SideNavPriceRangeDropDown dataId={"collapsePriceRange"} />
      </div>
      <hr className="m-0" />

      {/* SIZE */}
      <div className="px-4 py-3">
        <p
          className="fw-semibold mb-0 d-flex w-100 justify-content-between"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSize"
          aria-expanded="false"
          aria-controls="collapseSize"
        >
          <div>Size &nbsp;</div>
          <div className="dropdown-toggle"></div>
        </p>
        <SideNavSizeDropDown dataId={"collapseSize"} />
      </div>
      <hr className="m-0" />

      {/* COLORS */}
      <div className="px-4 py-3">
        <p
          className="fw-semibold mb-0 d-flex w-100 justify-content-between"
          data-bs-toggle="collapse"
          data-bs-target="#collapseColor"
          aria-expanded="false"
          aria-controls="collapseColor"
        >
          <div>Color &nbsp;</div>
          <div className="dropdown-toggle"></div>
        </p>
        <SideNavColorDropDown dataId={"collapseColor"} />
      </div>
      <hr className="m-0" />
    </div>
  );
}
