import React from 'react'
import { MessageInput, useChannelActionContext, useChannelStateContext } from 'stream-chat-react';
import { UserMessage } from '../EmailSystem/sendEmails';
import { UserAuth } from '../Context';
import { db } from '../FirebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

function CustomMessageInput(props) {
    const { channel } = useChannelStateContext();
    const { user } = UserAuth();
    const { sendMessage } = useChannelActionContext();

    const customOverrideSubmitHandler = async (message, channelCid,data) => {
      console.log('customOverrideSubmitHandler message',message);
      const response = sendMessage(message,data);
      console.log('customOverrideSubmitHandler',response);
      console.log('customOverrideSubmitHandler data',data);
      if (message.text.includes("/#/ProductDetails/")) {
        let text
        // Extract the product code
        text = message.text.split("/#/ProductDetails/");
        // test case checking for: if no code is written is added after ProductDetails/
        if (text[1] !== "") {
          console.log("FOUNDD", text);
          //If some extra text is used after link then do not fetch again and again for product
          if (text[1].split(" ").length === 1) {
            const productRef = doc(db, "products", text[1].split(" ")[0]);
            const productSnap = await getDoc(productRef);
            if (productSnap.exists()) {
              console.log("FOUNDD", productSnap.data());
              const product = productSnap.data();
              const attachments = [
                {
                  image:product?.imageUrl,
                  product,
                  type: 'product',
                },
              ];
              await channel.sendMessage({
                productId:product?.id,
                product:product,
                attachments
              });
            }
          }
        }
      }
      const otherMembers = Object.values(channel.state.members)
      .filter(member => member.user.id !== user.uid)
      .map(member => member.user);

      const offlineUsers = otherMembers.filter(member => !member.online);
      if (offlineUsers.length > 0) {
        const emailPromises = offlineUsers.map(receiver =>
          UserMessage(
            user.displayName || user.email, // Sender name
            receiver.name || receiver.id,     // Receiver name
            receiver.email                    // Receiver email
          )
            .then(() => {
              console.log(`Email sent successfully to ${receiver.email}`);
            })
            .catch((error) => {
              if (error.code === 'ECONNABORTED') {
                console.warn(
                  `Email sending timed out for ${receiver.email}. Consider increasing the timeout setting.`
                );
              } else {
                console.error('Email error:', error);
              }
            })
        );
  
        await Promise.allSettled(emailPromises);
      }
    
    };
  
    return (
      <MessageInput overrideSubmitHandler={customOverrideSubmitHandler} {...props} />
    );
}

export default CustomMessageInput