import React, { useEffect, useState } from "react";
import { use } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import useChatFilter from "../StatesStore/ChatFilter";

const ChatFilter = () => {
  const { chatFilterType, setChatFilterType } = useChatFilter();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <style jsx>
        {`
          .secondHeader3 ul .btn {
            line-height: 1.8 !important;
          }
          .pillSelected {
            color: #fff !important;
            background-color: RGBA(
              20,
              25,
              30,
              var(--bs-bg-opacity, 1)
            ) !important;
          }
          .pillNotSelected {
            color: #000 !important;
            background-color: RGBA(
              238,
              240,
              242,
              var(--bs-bg-opacity, 1)
            ) !important;
          }
          [data-bs-theme="dark"] .pillSelected {
            color: #000 !important;
            background-color: RGBA(
              238,
              240,
              242,
              var(--bs-bg-opacity, 1)
            ) !important;
          }
          [data-bs-theme="dark"] .pillNotSelected {
            color: #fff !important;
            background-color: RGBA(
              20,
              25,
              30,
              var(--bs-bg-opacity, 1)
            ) !important;
          }
        `}
      </style>
      <nav
        className={`navbar navbar-light d-block shadow-sm mb-0 secondHeader3 ${
          !isSmallScreen ? "rounded-top-2" : ""
        }`}
      >
        <div
          className={`container w-100 bg-mode ${
            !isSmallScreen ? "rounded-top-2" : ""
          }`}
        >
          <ul
            className="d-flex flex-row justify-content-between align-items-center w-100 mb-0 hiddenScrollbar p-0 py-1 py-lg-2 rounded-0 bg-mode gap-3"
            style={{
              overflowX: "auto",
              whiteSpace: "nowrap",
            }}
          >
            <div
              className={`btn px-3 py-0 badge rounded-pill ${
                chatFilterType === "all" ? "pillSelected" : "pillNotSelected"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setChatFilterType("all");
              }}
            >
              All
            </div>
            <div
              className={`btn px-3 py-0 badge rounded-pill ${
                chatFilterType === "unread" ? "pillSelected" : "pillNotSelected"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setChatFilterType("unread");
              }}
            >
              Unread
            </div>
            <div
              className={`btn px-3 py-0 badge rounded-pill ${
                chatFilterType === "shoppingList"
                  ? "pillSelected"
                  : "pillNotSelected"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setChatFilterType("shoppingList");
              }}
            >
              List
            </div>
            <div
              className={`btn px-3 py-0 badge rounded-pill ${
                chatFilterType === "invitedShoppingList"
                  ? "pillSelected"
                  : "pillNotSelected"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setChatFilterType("invitedShoppingList");
              }}
            >
              Invited Lists
            </div>
            <div
              className={`btn px-3 py-0 badge rounded-pill ${
                chatFilterType === "archived"
                  ? "pillSelected"
                  : "pillNotSelected"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setChatFilterType("archived");
              }}
            >
              Archived
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default ChatFilter;
