import React, { useState, useEffect, useRef } from "react";
import ProductList from "./ProductList";
import ListComments from "./ListComments";
import { Link, useLocation } from "react-router-dom";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  where,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import useNotification from "../hooks/useNotification";
import { ChatLeftTextFill, ThreeDotsVertical } from "react-bootstrap-icons";
import ProfilePicture from "../UserProfile/ProfilePicture";
import EmptyList from "./EmptyList";
import ProductList2 from "./ProductList2";
import ShoppingListCard from "./ShoppingListCard";

const SavedLists = () => {
  const [selectedShopList, setSelectedShopList] = useState({});
  const [listId, setListId] = useState("");
  const { user } = UserAuth();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const [activeAccordion, setActiveAccordion] = useState([]);
  const [showComments, setShowComments] = useState({});
  const [savedShoppingListData, setSavedShoppingListData] = useState({});
  const location = useLocation();
  const initialRenderRef = useRef(true);

  useEffect(() => {

    const fetchSavedShoppingLists = async () => {
      if (!user) return;

      try {
        const savedListsRef = collection(db, "users", user.uid, "savedLists");
        const querySnapshot = await getDocs(savedListsRef);

        const savedLists = await Promise.all(
          querySnapshot.docs.map(async (listDoc) => {
            const listData = listDoc.data(); // Rename `doc` to `listDoc` for clarity
            const productIds = listData.productIds || [];

            // Fetch user data for the saved list
            const userRef = doc(db, "users", listData.userId); // Use Firestore `doc` function
            const userDoc = await getDoc(userRef);
            const userData = userDoc.exists() ? userDoc.data() : null;

            return {
              id: listDoc.id, // Use the document ID as the list ID
              ...listData,
              name: listData.title, // Map title to name for consistency
              products: productIds.map((productId) => ({ id: productId })), // Map productIds to objects

              userId: listData.userId,
              userName: userData.userName,
              photo: userData.photo,

            };
          })
        );

        console.log("savedLists", savedLists);
        setSavedShoppingListData(savedLists);
      } catch (error) {
        console.error("Error fetching saved lists: ", error);
      }
    };
    fetchSavedShoppingLists();

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleChat = (listId) => {
    if (!activeAccordion.includes(listId)) {
      setActiveAccordion([...activeAccordion, listId]);
      setShowComments((prev) => ({
        ...prev,
        [listId]: true,
      }));
    } else {
      setShowComments((prev) => ({
        ...prev,
        [listId]: !prev[listId],
      }));
    }
  };

  useEffect(() => {
    const urlListId = location.pathname.split("/").pop();
    if (urlListId && urlListId !== "savedLists") {
      const matchingList = savedShoppingListData.find(
        (list) => list.id === urlListId
      );
      if (matchingList) {
        // Bring the matching list to the top
        const reorderedList = [
          matchingList,
          ...savedShoppingListData.filter((list) => list.id !== urlListId),
        ];

        setSavedShoppingListData(reorderedList);
      }
      setActiveAccordion([...activeAccordion, urlListId]);
      setShowComments((prev) => ({
        ...prev,
        [urlListId]: true,
      }));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (initialRenderRef.current && savedShoppingListData.length > 0) {
      initialRenderRef.current = false;
    }
  }, [savedShoppingListData]);

  const toggleAccordion = (listId) => {
    setActiveAccordion((prev) =>
      prev.includes(listId)
        ? prev.filter((id) => id !== listId)
        : [...prev, listId]
    );
  };

  return (
    <>
      {savedShoppingListData.length > 0 ? (
        savedShoppingListData.map((list) => (
          <div
            className="card mb-0 rounded-0 pt-0 pb-0 shoppingList"
            key={list.id}
            onClick={() => toggleAccordion(list.id)}
          >
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex align-items-center ps-3 pt-2">
                <div
                  className="avatar me-2"
                  style={{ height: "40px", width: "40px" }}
                >
                  <Link to={`/userProfile/${list?.userId}`}>
                    <ProfilePicture
                      userData={list}
                      className="avatar-img rounded-2"
                    />
                  </Link>
                </div>
                <div>
                  <div className="d-flex align-items-center gap-2">
                    <h5
                      className="card-title mb-0"
                      style={{ cursor: "pointer" }}
                    >
                      {list.title || "Untitled List"}
                    </h5>
                    <div
                      className="bg-primary fw-normal text-white rounded-pill"
                      style={{ fontSize: "10px", padding: "1px 8px" }}
                    >
                      {list.productIds?.length || 0}
                    </div>
                  </div>
                  <i className=" small mb-0">
                    by{" "}
                    <Link
                      to={`/userProfile/${list?.userId}`}
                      className="text-decoration-none card-title "
                    >
                      {list?.userName}
                    </Link>
                  </i>
                </div>
              </div>

              {/* <div className="d-flex nav gap-2 pb-0 align-items-center pe-3 pt-2">
                <li
                  className="nav-item"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleChat(list.id);
                  }}
                >
                  <Link className="nav-link icon btn btn-light p-2">
                    <ChatLeftTextFill className="fs-6" /> Chat
                  </Link>
                </li>
              </div> */}
            </div>

            <div onClick={(e) => e.stopPropagation()}>
              {activeAccordion.includes(list.id) && (
                <>
                  <hr className="m-0 p-0" />
                  <div
                    className={`card ${"px-0"
                      } bg-transparent border border-0 mb-0`}
                  >
                    <div
                      className="card-body p-0 bg-mode d-flex hiddenScrollbar"
                      style={{ overflow: "auto", whiteSpace: "nowrap" }}
                    >
                      {list.products.map((product, index) => (
                        <ShoppingListCard
                          product={product}
                          listName={list.title}
                          shoppingListData={list}
                          userId={user?.uid}
                          listId={list.id}
                          ownerId={list.userId}
                          key={index}
                          notify
                        />
                      ))}
                    </div>
                  </div>
                  <hr className="m-0 p-0" />
                  {!!showComments[list.id] && (
                    <ListComments
                      userId={user?.uid}
                      listId={list.id}
                      shoppingListData={savedShoppingListData}
                      selectedShopList={list.title}
                      role="viewer"
                      invitedBy={list.userId}
                      ownerId={list.userId}
                      notify={false}
                      show={!!showComments[list.id]}
                      toggleChat={toggleChat}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        ))
      ) : (
        <div
          className="card mb-1 mt-2 rounded-2 pt-0 pb-0 profileShoppingList px-5"
          style={{ height: "25rem", maxHeight: "25rem" }}
        >
          <EmptyList
            content={`You haven't saved any shopping lists yet`}
          />
        </div>
      )}
    </>
  );
};

export default SavedLists;