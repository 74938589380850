
export const getFilters = (filterType, user) => {
    if (filterType === "unread") {
      return {
        members: { $in: [user?.uid] },
        has_unread: true,
        archived: false, 
      };
    } else if (filterType === "shoppingList") {
      return {
        members: { $in: [user?.uid] },
        type: "team",
        archived: false, 
      };
    } else if (filterType === "archived") {
      return {
        members: { $in: [user?.uid] },
        archived: true, 
      };
    }
    
    return {
      members: { $in: [user?.uid] },
      archived: false, 
    };
  };