import React, { useState } from "react";

import { Container, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { toastOptions } from "../../lib/toastOptions";
const GoogleFormSubmission = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    hearAboutUs: "",
    hearAboutUsOther: "",
    phoneNumber: "",
    comments: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const urls = {
    //   url:
    //     "https://docs.google.com/forms/d/e/1FAIpQLSdGY8AhTJeXwz5-9hKrN3L6yAmQJRmHQJyTIrMmZU9tHTmCDg/viewform?usp=pp_url&entry.2005620554=Asad&entry.1045781291=a@a.com&entry.1065046570=Addi&entry.1166974658=03122492189&entry.839337160=Meow+meow",
    //   url2:
    //     "https://docs.google.com/forms/d/e/1FAIpQLSdGY8AhTJeXwz5-9hKrN3L6yAmQJRmHQJyTIrMmZU9tHTmCDg/viewform?usp=pp_url&entry.2005620554=AB&entry.1045781291=a@a.com&entry.1709967864=Social+Media&entry.1065046570=asjkjas&entry.1166974658=121928&entry.1862407124=Option+2&entry.839337160=kskkdkjkakjas",
    //   url3:
    //     "https://docs.google.com/forms/d/e/1FAIpQLSdGY8AhTJeXwz5-9hKrN3L6yAmQJRmHQJyTIrMmZU9tHTmCDg/viewform?usp=pp_url&entry.2005620554=Asad&entry.1045781291=a@a.com&entry.1709967864=__other_option__&entry.1709967864.other_option_response=Home&entry.1065046570=kasak&entry.1166974658=1928192&entry.1862407124=Option+2&entry.839337160=ijasasaj",
    // };
    // Replace with your actual Google Form URL and entry IDs
    const formURL =
      "https://docs.google.com/forms/d/e/1FAIpQLSdGY8AhTJeXwz5-9hKrN3L6yAmQJRmHQJyTIrMmZU9tHTmCDg/formResponse";
    console.log("formData", formData);
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("entry.2005620554", formData.name); // Replace with your Name field's entry ID
    formDataToSubmit.append("entry.1045781291", formData.email); // Replace with your Email field's entry ID
    formDataToSubmit.append(
      "entry.1709967864",
      formData.hearAboutUs === "Other"
        ? "__other_option__"
        : formData.hearAboutUs
    ); // Replace with your hearAboutUs field's entry ID
    if (formData.hearAboutUs === "Other") {
      formDataToSubmit.append(
        "entry.1709967864.other_option_response",
        formData.hearAboutUsOther
      ); // Replace with your hearAboutUsOther field's entry ID
    }
    formDataToSubmit.append("entry.1065046570", formData.address); // Replace with your Address field's entry ID
    formDataToSubmit.append("entry.1166974658", formData.phoneNumber); // Replace with your Phone Number field's entry ID
    formDataToSubmit.append("entry.839337160", formData.comments); // Replace with your Comments field's entry ID
    formDataToSubmit.append("entry.1862407124", "Option 2"); // Replace with your Comments field's entry ID
    fetch(formURL, {
      method: "POST",
      body: formDataToSubmit,
      mode: "no-cors", // Important: Google Forms doesn't send a response back
    })
      .then(() => toast.success("Form submitted successfully!", toastOptions))
      .catch((error) => toast.warn("Error submitting form!", toastOptions));
  };

  return (
    <Container className="p-0">
      <div>
        <p className="mt-3 fs-4 grey">Tell us about yourself</p>
      </div>
      <Form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
        <Form.Group controlId="name">
          <Form.Control
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Control
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="address">
          <Form.Control
            type="text"
            name="address"
            placeholder="Your Address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="hearAboutUs">
          <Form.Select
            name="hearAboutUs"
            value={formData.hearAboutUs}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              How did you hear about us
            </option>
            <option value="Social Media">Social Media</option>
            <option value="Friends/Family">Friends/Family</option>
            <option value="Online Search">Online Search</option>
            <option value="Other">Other</option>
          </Form.Select>
        </Form.Group>
        {formData.hearAboutUs === "Other" && (
          <Form.Group controlId="hearAboutUsOther">
            <Form.Control
              type="text"
              name="hearAboutUsOther"
              placeholder="Other"
              value={formData.hearAboutUsOther}
              onChange={handleChange}
              required
            />
          </Form.Group>
        )}
        <Form.Group controlId="phoneNumber">
          <Form.Control
            type="tel"
            name="phoneNumber"
            placeholder="Your Phone Number"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            pattern="\d*"
          />
        </Form.Group>
        <Form.Group controlId="comments">
          <Form.Control
            as="textarea"
            name="comments"
            placeholder="Your Comments"
            value={formData.comments}
            onChange={handleChange}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default GoogleFormSubmission;

/* https://docs.google.com/forms/d/e/1FAIpQLSdGY8AhTJeXwz5-9hKrN3L6yAmQJRmHQJyTIrMmZU9tHTmCDg/viewform?usp=pp_url&entry.2005620554=Asad&entry.1045781291=a@a.com&entry.1065046570=Addi&entry.1166974658=03122492189&entry.839337160=Meow+meow */

// [{
//     Men: [{ "Western Wear": "Jackets", "Western Wear": "Shorts" }],
//   },
//   {
//     Women: [{ "Western Wear": "Jackets", "Western Wear": "Shorts" }],
//   }];
