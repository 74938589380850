import React, { useEffect, useState } from "react";
import { Card, Dropdown, Modal } from "react-bootstrap";
import { db } from "../../FirebaseConfig";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  updateDoc,
} from "firebase/firestore";
import ListShareMenu from "../../components/ListShareMenu";
import { getBaseURL } from "../../lib/getBaseURL";
import { useParams } from "react-router-dom";
import { UserAuth } from "../../Context";
import InvitePeople from "../../ShoppingList/InvitePeople";
import Settings from "../../ShoppingList/Settings";
import CartModal from "../../Modals/CartModal";
import useModalStore from "../../StatesStore/ModalStore";
import { useFetchUserData } from "../../hooks/user/useFetchUserData";
import ShoppingListProductCard from "./ShoppingListProductCard";
import ShoppingListProductCard2 from "./ShoppingListProductCard2";
import useThemeState from "../../StatesStore/Theme";
const ProfileShoppingListCard = ({
  list,
  index,
  shoppingListData,
  setShoppingListData,
}) => {
  const [allProducts, setAllProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstThreeProducts, setFirstThreeProducts] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const { uid } = useParams();
  const baseURL = getBaseURL();
  const { user } = UserAuth();
  const { openModal } = useModalStore();
  const { userData } = useFetchUserData(uid);
  // console.log("userData", userData);
  const otherSVG = process.env.PUBLIC_URL + "/assets/svgs/other.svg";
  const moreSVGBlack = process.env.PUBLIC_URL + "/assets/svgs/B-More.svg";
  const moreSVGWhite = process.env.PUBLIC_URL + "/assets/svgs/More.svg";
  const { theme } = useThemeState();
  const fetchProducts = async (product) => {
    if (product?.id) {
      const productRef = doc(db, "products", product?.id);
      const productSnapshot = await getDoc(productRef);
      if (productSnapshot.exists()) {
        setFirstThreeProducts((prev) => [...prev, productSnapshot.data()]);
      }
    }
  };
  const handleClick = () => {
    const DATA = {
      deleteFunction: handleDeleteList,
      displayName: list?.name,
    };
    openModal("ConfirmDelete", "shoppingList", DATA);
  };
  const handleDeleteList = async () => {
    if (!user) return;

    try {
      const selectedShoppingListDoc = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        list?.id
      );

      // Get the listId before deleting the shopping list
      const listId = list?.listId;

      if (!listId) throw new Error("ListId not found.");

      // Fetch all member document IDs from the "members" subcollection
      const membersCollectionRef = collection(
        selectedShoppingListDoc,
        "members"
      );
      const membersSnap = await getDocs(membersCollectionRef);
      const memberIds = membersSnap.docs.map((doc) => doc.id);

      // Delete the shopping list document
      await deleteDoc(selectedShoppingListDoc);
      console.log("Shopping list deleted successfully");

      // For each member ID, delete the corresponding document from their "invitedList" subcollection
      const deleteInvitedListPromises = memberIds.map(async (memberId) => {
        const invitedListDoc = doc(
          db,
          "users",
          memberId,
          "invitedList",
          listId
        );
        await deleteDoc(invitedListDoc);
        console.log(
          `Deleted listId ${listId} from user ${memberId}'s invitedList`
        );
      });

      Promise.all(deleteInvitedListPromises);

      console.log("All related documents deleted successfully.");

      // Fetch the product count for the shopping list
      const docCountRef = collection(selectedShoppingListDoc, "products");
      const docCountSnap = await getDocs(docCountRef);
      const countDocs = docCountSnap.size;

      // Update the local state by removing the deleted list
      const remainingLists = shoppingListData.filter(
        (list) => list?.id !== list?.id
      );

      // Sort the remaining lists by dateCreated in descending order
      remainingLists.sort((a, b) => a.dateCreated - b.dateCreated);

      // Update the local state
      setShoppingListData(remainingLists);

      // Update the user document with the decremented count
      const userRef = doc(db, "users", user?.uid);
      await updateDoc(userRef, {
        shoppingListCount: increment(-countDocs),
      });

      const chatsRef = doc(db, "chats", listId);
      await deleteDoc(chatsRef);

      console.log("State and Firestore updated successfully.");
    } catch (error) {
      console.error("Error deleting the shopping list:", error.message);
    }
  };
  const closeOtherDropdown = (list) => {
    let dropdowns = document.getElementsByClassName(`Other`);
    dropdowns.forEach((dropdown) => {
      if (dropdown.classList.contains(list?.name.split(" ").join(""))) {
        if (dropdown.classList.contains("show")) {
          dropdown.classList.remove("show");
          dropdown.children[0].classList.remove("show");
          dropdown.children[0].ariaExpanded = "false";
          dropdown.children[1].classList.remove("show");
        } else {
          dropdown.classList.add("show");
          dropdown.children[0].classList.add("show");
          dropdown.children[0].ariaExpanded = "false";
          dropdown.children[1].classList.add("show");
        }
      } else {
        dropdown.classList.remove("show");
        dropdown.children[0].classList.remove("show");
        dropdown.children[0].ariaExpanded = "false";
        dropdown.children[1].classList.remove("show");
      }
    });
  };
  const handleClose = () => setIsModalOpen(false);

  useEffect(() => {
    const products = shoppingListData
      .filter(
        (shopList) =>
          shopList.name === list.name && shopList.listId === list.listId
      )
      .map((list) => list.products)[0];
    // console.log("products", products);
    setAllProducts(products);
    const initialProducts = products.slice(0, 3);
    // console.log("initialProducts", initialProducts);
    initialProducts.map((product) => fetchProducts(product));
  }, [list]);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <Card
        style={{
          width: isSmallScreen ? "100%" : "49.3%",
        }}
      >
        <style jsx>
          {`
            .shopListName:hover {
              color: var(--bs-link-hover-color);
            }
            .dropdown-toggle::after {
              display: none;
            }
            .profileShoppingListModal .modal-dialog {
              // max-width: 58.5rem;
              max-width: 63.5rem;
            }
          `}
        </style>
        {/* Images */}
        <div
          className="d-flex gap-2 p-2 pb-0 w-100"
          style={{ cursor: allProducts?.length > 0 ? "pointer" : "default" }}
          onClick={() => {
            if (allProducts?.length > 0) setIsModalOpen(true);
          }}
        >
          {/* Main Image */}
          {allProducts?.length === 0 ? (
            <div>
              <div
                style={{ height: "13.5rem" }}
                className="d-flex align-items-center justify-content-center w-100"
              >
                <div className="h6 px-4 text-center">
                  {uid === user?.uid ? "You" : userData?.userName} haven't added
                  any products in {list.name}
                </div>
              </div>
            </div>
          ) : (
            <div
              // className="w-100"
              style={{
                position: "relative",
                height: "13.5rem",
                width: allProducts?.length === 1 ? "100%" : "50%",
              }}
            >
              <Card.Img
                variant="top"
                className="rounded-2"
                src={firstThreeProducts[0]?.imageUrl}
                alt="No products to show"
                style={{
                  objectFit: "cover",
                  objectPosition: "top",
                  height: "100%",
                }}
              />
              <div
                className="h-100 w-100 rounded-2"
                style={{
                  position: "absolute",
                  top: "0",
                  zIndex: 2,
                  background:
                    "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))",
                }}
              ></div>
            </div>
          )}
          {/* Second Images */}
          {allProducts?.length > 2 ? ( // Three Images
            <div className="d-flex flex-column gap-2" style={{ width: "50%" }}>
              {/* 1st image */}
              <div>
                <Card.Img
                  variant="top"
                  className="rounded-2"
                  src={firstThreeProducts[1]?.imageUrl}
                  style={{
                    objectFit: "cover",
                    height: "6.5rem",
                  }}
                />
              </div>
              {/* 2nd image */}
              <div style={{ position: "relative" }}>
                <Card.Img
                  variant="top"
                  className="rounded-2"
                  src={firstThreeProducts[2]?.imageUrl}
                  style={{
                    objectFit: "cover",
                    height: "6.5rem",
                  }}
                />
                <div
                  className="h-100 w-100 rounded-2"
                  style={{
                    position: "absolute",
                    top: "0",
                    zIndex: 2,
                    background:
                      "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))",
                  }}
                ></div>
              </div>
            </div>
          ) : (
            allProducts?.length > 1 && ( // Two Images
              <div
                style={{
                  position: "relative",
                  height: "13.5rem",
                  width: "50%",
                }}
              >
                <Card.Img
                  variant="top"
                  className="rounded-2"
                  src={firstThreeProducts[1]?.imageUrl}
                  style={{
                    objectFit: "cover",
                    width: "20.5rem",
                    height: "100%",
                    objectPosition: "top",
                  }}
                />
                <div
                  className="h-100 w-100 rounded-2"
                  style={{
                    position: "absolute",
                    top: "0",
                    zIndex: 2,
                    background:
                      "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))",
                  }}
                ></div>
              </div>
            )
          )}
        </div>
        {allProducts?.length === 0 && <hr className="m-0" />}
        <div className="p-2 d-flex justify-content-between gap-2">
          {/* Shopping List Name */}
          <div
            className="h4 shopListName mb-0 text-truncate"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (allProducts?.length > 0) setIsModalOpen(true);
            }}
          >
            {list.name}
          </div>
          {/* Reaction Buttons */}
          <div className="d-flex gap-2">
            <ListShareMenu
              list={list}
              listName={list.name}
              myName={user?.displayName}
              listLink={`${baseURL}/#/shoppingList/${list.listId}/${uid}`}
              listId={list.listId}
              userId={user?.uid}
              products={list.products}
              componentName={"ProfilePage"}
            />
            {user && user?.uid === uid && (
              <>
                <div>
                  <InvitePeople
                    selectedShopList={list}
                    listId={list.listId}
                    listName={list?.name}
                    myName={user?.displayName}
                    listLink={`${baseURL}/#/shoppingList/${list.listId}/${uid}`}
                    componentName={"ProfilePage"}
                  />
                </div>
                <div
                  className={`dropdown ${list?.name.split(" ").join("")} Other`}
                >
                  <div
                    className={`p-0 dropdown-toggle`}
                    role="button"
                    id={`${list.name}Dropdown`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={() => {
                      closeOtherDropdown(list);
                    }}
                  >
                    <img
                      width={"28px"}
                      src={theme === "dark" ? moreSVGWhite : moreSVGBlack}
                      alt=""
                    />
                  </div>
                  <ul
                    className="dropdown-menu dropdown-menu-end mt-0 p-2 pt-0"
                    aria-labelledby={`${list.name}Dropdown`}
                  >
                    <li className="p-0" onClick={(e) => e.stopPropagation()}>
                      <Settings selectedShopList={list.name} />
                    </li>
                    {/* <li className="mb-2 p-0" onClick={(e) => e.stopPropagation()}>
                <CartModal
                  listName={list.name}
                  listProducts={list.products}
                  userId={user?.uid}
                />
              </li> */}
                    <li className="p-0" onClick={(e) => e.stopPropagation()}>
                      <div className="d-flex align-items-center">
                        <li className="nav-item" onClick={handleClick}>
                          <div className="icon-md btn btn-light p-0 text-body">
                            <i className="bi bi-trash-fill fs-6"> </i>
                          </div>
                          <span className="mx-2 h6 fw-light">Delete list</span>
                        </li>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </Card>
      <Modal
        className="profileShoppingListModal"
        show={isModalOpen}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="modalLabelCreateEvents">
            Shopping List {">"} {list?.name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          className="d-flex gap-3 flex-wrap"
          style={{ overflow: "auto", maxHeight: "38rem" }}
        >
          {allProducts.map((product) => (
            <ShoppingListProductCard
              list={list}
              product={product}
              ownerId={userData?.userId}
            />
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileShoppingListCard;
