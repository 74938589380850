import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  orderBy,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Slider, InputNumber } from "antd";
import useSearchFilter from "../StatesStore/SearchFilters";
import { getCategoryDisplayName } from "../lib/getCategoryName";
import LoadingSpinner from "../UserProfile/LoadingSpinner";

export function SideNavBrandDropdown({ dataId }) {
  const setSearchFilter = useSearchFilter((state) => state.setSearchFilter);
  const SearchFilter = useSearchFilter((state) => state.SearchFilter);

  const handleBrandChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = SearchFilter;
    let updatedBrands = [...currentFilter.brand];

    if (checked) {
      updatedBrands.push(name);
    } else {
      updatedBrands = updatedBrands.filter((brand) => brand !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      brand: updatedBrands,
    };

    setSearchFilter(updatedFilter);
    // console.log('Current filter state in Brands:', updatedFilter);
  };

  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  const getBrands = async () => {
    try {
      const brandsCollectionRef = collection(db, "brands");
      const brandsQuery = query(brandsCollectionRef, orderBy("name", "asc"));
      const brandsSnapshot = await getDocs(brandsQuery);
      const brandsData = brandsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setBrands(brandsData);
    } catch (error) {
      console.error("Error fetching brands:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBrands();
  }, []); // Fetch brands only once on component mount

  return (
    <div className="collapse show mt-2" id={dataId}>
      <div className="overflow-auto" style={{ maxHeight: "300px" }}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          brands.map((brand) => (
            <div key={brand.id} className="text-wrap align-items-center my-2">
              <input
                className="form-check-input"
                type="checkbox"
                name={brand.id}
                onChange={handleBrandChange}
                checked={SearchFilter.brand.includes(brand.id)}
              />
              <label className="form-check-label mx-2">
                {brand.displayName}
              </label>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export function SideNavPriceRangeDropDown({ dataId }) {
  // State to hold the current value of the slider
  const [range, setRange] = useState([0, 90000]); // Initial value set to [0, 90000]

  const setSearchFilter = useSearchFilter((state) => state.setSearchFilter);
  const SearchFilter = useSearchFilter((state) => state.SearchFilter);

  // Function to handle changes in the slider value
  const handleRangeChange = (newRange) => {
    setRange(newRange);
    const updatedFilter = {
      ...SearchFilter,
      minprice: newRange[0],
      maxprice: newRange[1],
    };
    setSearchFilter(updatedFilter);
    // console.log('Current filter state:', updatedFilter);
  };

  // Function to handle changes in the min value input box
  const handleMinInputChange = (value) => {
    const newRange = [value, range[1]];
    setRange(newRange);
    const updatedFilter = {
      ...SearchFilter,
      minprice: value,
      maxprice: range[1],
    };
    setSearchFilter(updatedFilter);
  };

  // Function to handle changes in the max value input box
  const handleMaxInputChange = (value) => {
    const newRange = [range[0], value];
    setRange(newRange);
    const updatedFilter = {
      ...SearchFilter,
      minprice: range[0],
      maxprice: value,
    };
    setSearchFilter(updatedFilter);
  };

  return (
    <div className="collapse show mt-2" id={dataId}>
      <div className="flex-1 justify-content-center ">
        {/* Price Range Slider */}
        <div className="rounded-5 mb-3">
          <Slider
            range
            value={range}
            onChange={handleRangeChange}
            min={0}
            max={90000}
            step={500}
            tipFormatter={(value) => `${value}`}
          />
        </div>

        <div className="d-flex gap-2">
          <div className="d-flex flex-column ">
            <label>Min</label>
            <InputNumber
              className="w-100"
              min={0}
              max={90000}
              value={range[0]}
              onChange={handleMinInputChange}
              placeholder={range[0]}
            />
          </div>
          <div className="d-flex flex-column ">
            <label>Max</label>
            <InputNumber
              className="w-100"
              min={0}
              max={90000}
              value={range[1]}
              onChange={handleMaxInputChange}
              placeholder={range[1]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function SideNavGenderDropDown({ dataId }) {
  const setSearchFilter = useSearchFilter((state) => state.setSearchFilter);
  const SearchFilter = useSearchFilter((state) => state.SearchFilter);
  const handleGenderChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useSearchFilter.getState().SearchFilter;
    let updatedGender = [...currentFilter.gender];

    if (checked) {
      updatedGender.push(name);
    } else {
      updatedGender = updatedGender.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      gender: updatedGender,
    };
    console.log("updatedFilter gender", updatedFilter);
    setSearchFilter(updatedFilter);
    // console.log('Current filter state in Gender', updatedFilter);
  };

  return (
    <div className="collapse show mt-2" id={dataId}>
      {/* Gender filter section */}
      <ul className="gender-filter list-unstyled">
        <li className="form-check">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="Men"
                id={`${dataId}-men`}
                onChange={handleGenderChange} // Call handleGenderChange on change
                checked={SearchFilter.gender.includes("Men")} // Check if "Men" is included in the gender filter array
              />
              <label className="form-check-label" htmlFor={`${dataId}-men`}>
                Men
              </label>
            </div>
          </div>
        </li>
        <li className="form-check">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="Women"
                id={`${dataId}-women`}
                onChange={handleGenderChange} // Call handleGenderChange on change
                checked={SearchFilter.gender.includes("Women")} // Check if "Women" is included in the gender filter array
              />
              <label className="form-check-label" htmlFor={`${dataId}-women`}>
                Women
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export function SideNavCategoryDropDown({ dataId }) {
  const SearchFilter = useSearchFilter((state) => state.SearchFilter);
  const setSearchFilter = useSearchFilter((state) => state.setSearchFilter);

  const handleCategoryChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useSearchFilter.getState().SearchFilter;
    let updatedCategories = [...currentFilter.subCategory];

    if (checked) {
      updatedCategories.push(name);
    } else {
      updatedCategories = updatedCategories.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      subCategory: updatedCategories,
    };

    setSearchFilter(updatedFilter);
  };

  // console.log('Current Brand filter state in Category', SearchFilter);

  return (
    <div className="collapse show mt-2" id={dataId}>
      {/* Gender filter section */}
      <ul className="gender-filter list-unstyled">
        <li className="form-check">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="Ready to Wear"
                id={`${dataId}-Ready to Wear`}
                onChange={handleCategoryChange} // Call handleGenderChange on change
                checked={SearchFilter.subCategory.includes("Ready to Wear")} // Check if s included in the gender filter array
              />
              <label
                className="form-check-label"
                htmlFor={`${dataId}-Ready to Wear`}
              >
                Ready to Wear
              </label>
            </div>
          </div>
        </li>
        <li className="form-check">
          <div className="text-wrap justify-content-between align-items-center">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="Unstitched"
                id={`${dataId}-Unstitched`}
                onChange={handleCategoryChange} // Call handleGenderChange on change
                checked={SearchFilter.subCategory.includes("Unstitched")} // Check if "Unstitched" is included in the gender filter array
              />
              <label
                className="form-check-label"
                htmlFor={`${dataId}-Unstitched`}
              >
                Unstitched
              </label>
            </div>
          </div>
        </li>
        <li className="form-check">
          <div className="text-wrap justify-content-between align-items-center">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="Western Wear"
                id={`${dataId}-Western Wear`}
                onChange={handleCategoryChange}
                checked={SearchFilter.subCategory.includes("Western Wear")} // Check if "Unstitched" is included in the gender filter array
              />
              <label
                className="form-check-label"
                htmlFor={`${dataId}-Western Wear`}
              >
                Western Wear
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export function SideNavSubCategoryDropDown({ dataId }) {
  const [subSubCategory, setSubSubCategory] = useState([]);
  const [displaySubCatMap, setDisplaySubCatMap] = useState({});
  const SearchFilter = useSearchFilter((state) => state.SearchFilter);
  const setSearchFilter = useSearchFilter((state) => state.setSearchFilter);
  const handleSubSubCategoryChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useSearchFilter.getState().SearchFilter;
    let updatedsubSubCategory = [...currentFilter.subSubCategory];

    if (checked) {
      updatedsubSubCategory.push(name);
    } else {
      updatedsubSubCategory = updatedsubSubCategory.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      subSubCategory: updatedsubSubCategory,
    };

    setSearchFilter(updatedFilter);
  };

  useEffect(() => {
    const fetchSubSubCategories = async () => {
      try {
        const productsCollectionRef = collection(db, "products");
        const querySnapshot = await getDocs(productsCollectionRef);
        const subSubCategorySet = new Set();
        const displaySubCatMap = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.subSubCategory && data.subSubCategory !== "None") {
            subSubCategorySet.add(data.subSubCategory);
            displaySubCatMap[data.subSubCategory] = getCategoryDisplayName(
              data.subSubCategory
            );
          }
        });

        // Sort subSubCategorySet
        const sortedSubSubCategory = Array.from(subSubCategorySet).sort(
          (a, b) => {
            const aIsNumeric = !isNaN(a);
            const bIsNumeric = !isNaN(b);

            if (aIsNumeric && bIsNumeric) {
              return a - b; // both are numbers
            } else if (aIsNumeric) {
              return -1; // a is a number, b is a string
            } else if (bIsNumeric) {
              return 1; // a is a string, b is a number
            } else {
              return a.localeCompare(b); // both are strings
            }
          }
        );

        setSubSubCategory(sortedSubSubCategory);
        setDisplaySubCatMap(displaySubCatMap);
      } catch (error) {
        console.error("Error fetching subSubCategory:", error);
      }
    };

    fetchSubSubCategories();
  }, [dataId]);

  console.log("Current Brand filter state in subSubCategory", SearchFilter);

  return (
    <div className="collapse show mt-2" id={dataId}>
      <div className="overflow-auto" style={{ maxHeight: "300px" }}>
        {subSubCategory?.map((subCat) => (
          <div
            key={subCat}
            className="d-flex align-items-center justify-content-between w-100 mb-2"
          >
            <div className="me-2">
              <input
                className="form-check-input"
                type="checkbox"
                name={subCat}
                onChange={handleSubSubCategoryChange}
                checked={SearchFilter.subSubCategory.includes(subCat)}
              />
            </div>
            <div className="flex-grow-1 overflow-hidden text-truncate">
              <label className="text-wrap form-check-label">
                {displaySubCatMap[subCat]}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export function SideNavSizeDropDown({ dataId }) {
  const [alphabeticSizes, setAlphabeticSizes] = useState([]);
  const [numericSizes, setNumericSizes] = useState([]);
  const SearchFilter = useSearchFilter((state) => state.SearchFilter);
  useEffect(() => {
    const fetchSizes = async () => {
      try {
        const sizesCollectionRef = collection(db, "sizes");
        const sizesSnapshot = await getDocs(sizesCollectionRef);

        let alphabeticSizes = [];
        let numericSizes = [];

        sizesSnapshot.forEach((doc) => {
          const data = doc.data();
          if (doc.id === "type1") {
            alphabeticSizes = data.Alphabetic;
          } else if (doc.id === "type2") {
            numericSizes = data.Numbers;
          }
        });

        setAlphabeticSizes(alphabeticSizes);
        setNumericSizes(numericSizes);
      } catch (error) {
        console.error("Error fetching sizes:", error);
      }
    };

    fetchSizes();
  }, [dataId]);

  const setSearchFilter = useSearchFilter((state) => state.setSearchFilter);

  const handleSizeChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useSearchFilter.getState().SearchFilter;
    let updatedSize = [...currentFilter.size];

    if (checked) {
      updatedSize.push(name);
    } else {
      updatedSize = updatedSize.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      size: updatedSize,
    };

    setSearchFilter(updatedFilter);
    // Update the URL with the selected sizes
    //   const newSearchParams = new URLSearchParams(searchParams);
    //   newSearchParams.set('size', updatedSize.join(','));
    // setSearchParams(newSearchParams);
  };

  return (
    <>
      {alphabeticSizes.length > 0 && (
        <div className={`collapse show mt-2`} id={dataId}>
          <div className="overflow-auto" style={{ maxHeight: "300px" }}>
            <div className="d-flex">
              <div className="me-4">
                {alphabeticSizes?.map((size) => (
                  <div
                    key={size}
                    className="text-wrap justify-content-start align-items-center my-2"
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={size}
                      onChange={handleSizeChange}
                      checked={SearchFilter.size.includes(size)}
                    />
                    <label className="form-check-label mx-2">{size}</label>
                  </div>
                ))}
              </div>
              <div>
                {numericSizes?.map((size) => (
                  <div
                    key={size}
                    className="text-wrap justify-content-start align-items-center my-2"
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name={size}
                      onChange={handleSizeChange}
                      checked={SearchFilter.size.includes(size)}
                    />
                    <label className="form-check-label mx-2">{size}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export function SideNavColorDropDown({ dataId }) {
  const [colors, setColors] = useState([]);
  const SearchFilter = useSearchFilter((state) => state.SearchFilter);

  useEffect(() => {
    const fetchColors = async () => {
      if (!dataId) return;

      try {
        const productsCollectionRef = doc(db, "colors", "Colors");
        const querySnapshot = await getDoc(productsCollectionRef);
        const colorSet = new Set();
        const colors = querySnapshot.data().Colors;
        colors.forEach((color) => colorSet.add(color));

        const sortedColors = [...colorSet].sort((a, b) =>
          a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
        );

        setColors(sortedColors);
      } catch (error) {
        console.error("Error fetching colors:", error);
      }
    };
    fetchColors();
  }, [dataId]);

  const setSearchFilter = useSearchFilter((state) => state.setSearchFilter);

  const handColorChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useSearchFilter.getState().SearchFilter;
    let updatedColor = [...currentFilter.color];

    if (checked) {
      updatedColor.push(name);
    } else {
      updatedColor = updatedColor.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      color: updatedColor,
    };

    setSearchFilter(updatedFilter);
  };

  return (
    <div className="collapse show mt-2" id={dataId}>
      <div className="overflow-auto" style={{ maxHeight: "300px" }}>
        {colors.map((color) => (
          <div
            key={color}
            className="text-wrap justify-content-start align-items-center my-2"
          >
            <input
              className="form-check-input"
              type="checkbox"
              name={color}
              onChange={handColorChange}
              checked={SearchFilter.color.includes(color)}
            />
            <label className="form-check-label mx-2">{color}</label>
          </div>
        ))}
      </div>
    </div>
  );
}
