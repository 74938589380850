import React, { useState, useEffect, useMemo } from "react";
import fetchProducts from "../lib/productUtils";
import { getCategoryDisplayName } from "../lib/getCategoryName";
import { UserAuth } from "../Context";
import useBrandProducts from "../StatesStore/brandProducts";
import useBrandFilter from "../StatesStore/BrandFilters";
import useLogActivity from "../hooks/user/useLogActivity";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import ProductCardBrandsPage from "../Products/ProductCardBrandsPage";
import { ArrowDownUp, ChevronDown, Funnel } from "react-bootstrap-icons";
import { Slider, InputNumber } from "antd";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Accordion } from "react-bootstrap";
import Skeleton from "../Products/Skeleton";
import BrandProductsSkeleton from "./BrandProductsSkeleton";
import GoogleAdCard4 from "../NewsFeed/GoogleAdCard4";
import ProductGoogleAdCard from "../Cards/ProductGoogleAdCard";
import GoogleAdCardMobile from "../NewsFeed/GoogleAdCardMobile";
import ProductGoogleAdCardMobile from "../Cards/ProductGoogleAdCardMobile";

function BrandProducts2({ brandName, activeAccordion, setActiveAccordion }) {
  const setBrandProduct = useBrandProducts((state) => state.setBrandProduct);
  const BrandFilter = useBrandFilter((state) => state.BrandFilter);
  const setBrandFilter = useBrandFilter((state) => state.setBrandFilter);

  const { user } = UserAuth();

  const { logActivity } = useLogActivity(user?.uid);

  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(14);
  const [bname, setBname] = useState("");
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countLeft, setCountLeft] = useState(true);
  const [prevSubCategory, setPrevSubCategory] = useState(null);
  const [prevSubSubCategory, setPrevSubSubCategory] = useState(null);
  const [columnCount, setColumnCount] = useState(getColumnCount());

  const filters = [
    { value: "NF", name: "Newest First" },
    { value: "PL", name: "Price - Low to High" },
    { value: "PH", name: "Price - High to Low" },
    { value: "DL", name: "Discount - Low to High" },
    { value: "DH", name: "Discount - High to Low" },
  ];

  const [availableGenders, setAvailableGenders] = useState([]);
  const [subSubCategory, setSubSubCategory] = useState([]);
  const [displaySubCatMap, setDisplaySubCatMap] = useState({});
  const [range, setRange] = useState([0, 90000]); // Initial value set to [0, 90000]
  const [filter, setFilter] = useState("NF");
  const [alphabeticSizes, setAlphabeticSizes] = useState([]);
  const [numericSizes, setNumericSizes] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  useEffect(() => {
    if (!brandName) return;
    const fetchAvailableGenders = async () => {
      const brandRef = doc(db, "brands", brandName);
      const brandSnap = await getDoc(brandRef);
      if (brandSnap.exists()) {
        const data = brandSnap?.data()?.category;
        setAvailableGenders(data);
      }
    };
    const fetchSubSubCategories = async () => {
      if (!brandName) return;

      try {
        const productsCollectionRef = collection(db, "products");
        const q = query(
          productsCollectionRef,
          where("supplier", "==", brandName)
        );
        const querySnapshot = await getDocs(q);
        const subSubCategorySet = new Set();
        const displaySubCatMap = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.subSubCategory && data.subSubCategory !== "None") {
            subSubCategorySet.add(data.subSubCategory);
            displaySubCatMap[data.subSubCategory] = getCategoryDisplayName(
              data.subSubCategory
            );
          }
        });

        // Sort subSubCategorySet
        const sortedSubSubCategory = Array.from(subSubCategorySet).sort(
          (a, b) => {
            const aIsNumeric = !isNaN(a);
            const bIsNumeric = !isNaN(b);

            if (aIsNumeric && bIsNumeric) {
              return a - b; // both are numbers
            } else if (aIsNumeric) {
              return -1; // a is a number, b is a string
            } else if (bIsNumeric) {
              return 1; // a is a string, b is a number
            } else {
              return a.localeCompare(b); // both are strings
            }
          }
        );

        setSubSubCategory(sortedSubSubCategory);
        setDisplaySubCatMap(displaySubCatMap);
      } catch (error) {
        console.error("Error fetching subSubCategory:", error);
      }
    };
    const fetchSizes = async () => {
      try {
        const sizesCollectionRef = collection(db, "sizes");
        const sizesSnapshot = await getDocs(sizesCollectionRef);

        let alphabeticSizes = [];
        let numericSizes = [];

        sizesSnapshot.forEach((doc) => {
          const data = doc.data();
          if (doc.id === "type1") {
            alphabeticSizes = data.Alphabetic;
          } else if (doc.id === "type2") {
            numericSizes = data.Numbers;
          }
        });

        setAlphabeticSizes(alphabeticSizes);
        setNumericSizes(numericSizes);

        if (brandName) {
          const brandDocRef = doc(db, "brands", brandName);
          const brandDoc = await getDoc(brandDocRef);
          if (brandDoc.exists()) {
            setAvailableSizes(brandDoc.data().sizes || []);
          } else {
            console.error("No such brand!");
          }
        }
      } catch (error) {
        console.error("Error fetching sizes:", error);
      }
    };
    const fetchColors = async () => {
      if (!brandName) return;

      try {
        const productsCollectionRef = collection(db, "products");
        const q = query(
          productsCollectionRef,
          where("supplier", "==", brandName)
        );
        const querySnapshot = await getDocs(q);
        const colorSet = new Set();
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.colors) {
            data.colors.forEach((color) => colorSet.add(color));
          }
        });

        const sortedColors = [...colorSet].sort((a, b) =>
          a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" })
        );

        setColors(sortedColors);
      } catch (error) {
        console.error("Error fetching colors:", error);
      }
    };

    const fetchAvailableColors = async () => {
      if (!brandName) return;

      try {
        const brandDocRef = doc(db, "brands", brandName);
        const brandDoc = await getDoc(brandDocRef);
        if (brandDoc.exists()) {
          const data = brandDoc.data();
          if (data.colors) {
            setAvailableColors(data.colors);
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching available colors:", error);
      }
    };
    fetchAvailableGenders();
    fetchSubSubCategories();
    fetchSizes();
    fetchAvailableColors();
    fetchColors();
  }, [brandName]);
  const handleGenderChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedGender = [...currentFilter.category];

    if (checked) {
      updatedGender.push(name);
    } else {
      updatedGender = updatedGender.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      category: updatedGender,
    };

    setBrandFilter(updatedFilter);
  };
  const handleCategoryChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedCategories = [...currentFilter.subCategory];

    if (checked) {
      updatedCategories.push(name);
    } else {
      updatedCategories = updatedCategories.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      subCategory: updatedCategories,
    };

    setBrandFilter(updatedFilter);
  };
  const handleSubSubCategoryChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedsubSubCategory = [...currentFilter.subSubCategory];

    if (checked) {
      updatedsubSubCategory.push(name);
    } else {
      updatedsubSubCategory = updatedsubSubCategory.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      subSubCategory: updatedsubSubCategory,
    };

    setBrandFilter(updatedFilter);
  };
  // Function to handle changes in the slider value
  const handleRangeChange = (newRange) => {
    setRange(newRange);
    const updatedFilter = {
      ...BrandFilter,
      minprice: newRange[0],
      maxprice: newRange[1],
    };
    setBrandFilter(updatedFilter);
    // console.log('Current filter state:', updatedFilter);
  };

  // Function to handle changes in the min value input box
  const handleMinInputChange = (value) => {
    const newRange = [value, range[1]];
    setRange(newRange);
    const updatedFilter = {
      ...BrandFilter,
      minprice: value,
      maxprice: range[1],
    };
    setBrandFilter(updatedFilter);
  };

  // Function to handle changes in the max value input box
  const handleMaxInputChange = (value) => {
    const newRange = [range[0], value];
    setRange(newRange);
    const updatedFilter = {
      ...BrandFilter,
      minprice: range[0],
      maxprice: value,
    };
    setBrandFilter(updatedFilter);
  };
  const handleSizeChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedSize = [...currentFilter.size];

    if (checked) {
      updatedSize.push(name);
    } else {
      updatedSize = updatedSize.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      size: updatedSize,
    };

    setBrandFilter(updatedFilter);
  };
  const handColorChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedColor = [...currentFilter.color];

    if (checked) {
      updatedColor.push(name);
    } else {
      updatedColor = updatedColor.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      color: updatedColor,
    };

    setBrandFilter(updatedFilter);
  };
  useEffect(() => {
    function handleResize() {
      setColumnCount(getColumnCount());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getColumnCount() {
    const screenWidth = window.innerWidth;
    let count = 2;
    if (screenWidth >= 440) {
      count = 3;
    }
    if (screenWidth >= 768) {
      count = 4;
    }
    if (screenWidth >= 900) {
      count = 5;
    }
    return count;
  }

  useEffect(() => {
    setCurrentPage(1);
    setAllProducts([]);
    setBrandProduct([]);
    setLastVisible(null);
    setCountLeft(true);
    window.scrollTo(0, 0);

    const activityData = {
      brand: brandName,
      type: "viewedBrand",
    };

    logActivity(activityData);
  }, [bname, subSubCategory]);

  useEffect(() => {
    // Reset lastVisible and count when category changes
    if (subSubCategory !== prevSubSubCategory) {
      setLastVisible(null);
      setCountLeft(true);
    }

    // Update prevSubCategory and prevSubSubCategory
    setPrevSubSubCategory(subSubCategory);
  }, [subSubCategory, countLeft]);

  useEffect(() => {
    setBname(brandName);
    console.log("CHANGED");
    // const queryParams = new URLSearchParams(window.location.search);
    // const sizes = queryParams.get('size') ? queryParams.get('size').split(',') : [];
    // console.log("sizes",sizes);
    fetchProducts({
      activeBrands: [],
      brandName,
      category: BrandFilter.category,
      subCategory: BrandFilter.subCategory,
      subSubCategory: BrandFilter.subSubCategory || subSubCategory,
      prevSubSubCategory,
      prevSubCategory,
      lastVisible,
      currentPage,
      setAllProducts,
      setLoading,
      setLastVisible,
      setCountLeft,
      filter,
      sizes: BrandFilter.size,
      colors: BrandFilter.color,
      minprice: BrandFilter.minprice,
      maxprice: BrandFilter.maxprice,
    });
  }, [
    brandName,
    BrandFilter.subCategory,
    subSubCategory,
    currentPage,
    filter,
    BrandFilter,
    BrandFilter.size,
    BrandFilter.color,
    BrandFilter.minprice,
    BrandFilter.maxprice,
    BrandFilter.category,
  ]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const filteredProducts = allProducts;
  const isMobileScreen = window.innerWidth <= 768;
  return (
    <>
      <div className="card bg-transparent border-0">
        {/* Card header START */}
        <div className="card-header d-flex d-md-none align-items-center justify-content-around border-0 p-0 mb-3 rounded-2 stickyHeader">
          <style jsx>{`
            #flush-headingMobile .accordion-button::after {
              display: none;
            }
          `}</style>

          <div
            className="d-flex align-items-center gap-2 py-2 ps-5"
            style={{ width: "11rem" }}
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasFilterNav"
            aria-controls="offcanvasFilterNav"
          >
            <Funnel className="fs-5" />
            <span>Filters</span>
          </div>
          <div className="vr m-0 p-0" />
          <div className="py-2 ps-2" style={{ width: "12rem" }}>
            <div
              className="accordion border-0 py-0 position-static rounded-end-2"
              style={{ width: "13rem" }}
            >
              <h2
                className="accordion-header"
                style={{ padding: "0rem 2rem 0rem 2.5rem" }}
                id="flush-headingMobile"
              >
                <button
                  className="accordion-button collapsed d-flex align-items-center gap-2 ps-3"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseMobile"
                  aria-expanded="false"
                  aria-controls="flush-collapseMobile"
                >
                  <ArrowDownUp className="fs-5" />
                  Sort
                </button>
              </h2>
              <div
                id="flush-collapseMobile"
                style={{ width: "12.7rem" }}
                className="accordion-collapse collapse position-absolute top-100 end-0 bg-white shadow rounded-bottom-2"
                aria-labelledby="flush-headingMobile"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body px-0 pt-2 pb-0">
                  <div className="overflow-auto" style={{ maxHeight: "300px" }}>
                    {filters.map((filterOption) => (
                      <>
                        <div
                          key={filterOption.value}
                          className="text-wrap d-flex justify-content-start align-items-center my-2 px-2"
                          onClick={() => {
                            setFilter(filterOption.value);
                            const accordionButton = document.querySelector(
                              '[data-bs-target="#flush-collapseMobile"]'
                            );
                            accordionButton.click();
                          }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={filter === filterOption.value}
                          />
                          <label className="form-check-label mx-2">
                            {filterOption.name}
                          </label>
                        </div>
                        <hr className="m-0 p-0" />
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="card-header d-none d-md-block border p-0 mb-3 rounded-2 stickyHeader hiddenScrollbar"
        >
          <Accordion
            className="d-flex align-items-center position-relative filter-accordion w-100"
            // defaultActiveKey="0"
            flush
            activeKey={activeAccordion ?? null}
          >
            <style jsx>{`
              .filter-accordion .accordion-button::after {
                display: none;
              }
              .accordion-button:not(.collapsed) .accordion-chevron {
                transform: rotate(180deg) !important;
              }
            `}</style>
            <Accordion.Item
              className="border-0 py-2 position-static rounded-start-2"
              style={{ width: "13rem" }}
              eventKey={1}
            >
              <Accordion.Header
                onClick={() => toggleAccordion(1)}
                style={{ padding: "0rem 2rem 0rem 2.5rem" }}
              >
                Gender
                <ChevronDown
                  className="ms-2 accordion-chevron"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                />
              </Accordion.Header>
              <Accordion.Body
                style={{ width: "10.25rem" }}
                className="position-absolute top-100 bg-white shadow rounded-bottom-2"
              >
                <ul className="p-0 m-0" aria-labelledby="genderMenu">
                  <li className="form-check py-1 d-flex align-items-center gap-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="Men"
                      id={`genderMenu-men`}
                      onChange={handleGenderChange}
                      checked={BrandFilter.category.includes("Men")}
                      disabled={!availableGenders.includes("Men")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`genderMenu-men`}
                    >
                      Men
                    </label>
                  </li>
                  <li className="form-check py-1 d-flex align-items-center gap-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="Women"
                      id={`genderMenu-women`}
                      onChange={handleGenderChange}
                      checked={BrandFilter.category.includes("Women")}
                      disabled={!availableGenders.includes("Women")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`genderMenu-women`}
                    >
                      Women
                    </label>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <div className="vr m-0 p-0" />

            <Accordion.Item
              className="border-0 py-2 position-static"
              style={{ width: "13rem" }}
              eventKey={2}
            >
              <Accordion.Header
                onClick={() => toggleAccordion(2)}
                style={{ padding: "0rem 2rem 0rem 2.5rem" }}
              >
                Category
                <ChevronDown
                  className="ms-2 accordion-chevron"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                />
              </Accordion.Header>
              <Accordion.Body
                style={{ width: "10.25rem" }}
                className="position-absolute top-100 bg-white shadow rounded-bottom-2"
              >
                <ul
                  className="m-0 p-0 gender-filter list-unstyled"
                  aria-labelledby="categoryMenu"
                >
                  <li className="form-check">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="Ready to Wear"
                          id={`categoryMenu-Ready to Wear`}
                          onChange={handleCategoryChange}
                          checked={BrandFilter.subCategory.includes(
                            "Ready to Wear"
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`categoryMenu-Ready to Wear`}
                        >
                          Ready to Wear
                        </label>
                      </div>
                    </div>
                  </li>
                  <li className="form-check">
                    <div className="text-wrap justify-content-between align-items-center">
                      <div>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="Unstitched"
                          id={`categoryMenu-Unstitched`}
                          onChange={handleCategoryChange}
                          checked={BrandFilter.subCategory.includes(
                            "Unstitched"
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`categoryMenu-Unstitched`}
                        >
                          Unstitched
                        </label>
                      </div>
                    </div>
                  </li>
                  <li className="form-check">
                    <div className="text-wrap justify-content-between align-items-center">
                      <div>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="Western Wear"
                          id={`categoryMenu-Western Wear`}
                          onChange={handleCategoryChange}
                          checked={BrandFilter.subCategory.includes(
                            "Western Wear"
                          )}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`categoryMenu-Western Wear`}
                        >
                          Western Wear
                        </label>
                      </div>
                    </div>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <div className="vr m-0 p-0" />

            <Accordion.Item
              className="border-0 py-2 position-static"
              style={{ width: "15.5rem" }}
              eventKey={3}
            >
              <Accordion.Header
                onClick={() => toggleAccordion(3)}
                style={{ padding: "0rem 2rem 0rem 2.5rem" }}
              >
                Sub-Category
                <ChevronDown
                  className="ms-2 accordion-chevron"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                />
              </Accordion.Header>
              <Accordion.Body
                style={{ width: "12.25rem" }}
                className="position-absolute top-100 bg-white shadow rounded-bottom-2"
              >
                <ul
                  className="p-0 m-0 subCategory-filter list-unstyled"
                  aria-labelledby="subCategoryMenu"
                >
                  <div className="overflow-auto" style={{ maxHeight: "300px" }}>
                    {subSubCategory?.map((subCat) => (
                      <div
                        key={subCat}
                        className="d-flex align-items-center justify-content-between w-100 mb-2"
                      >
                        <div className="me-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={subCat}
                            onChange={handleSubSubCategoryChange}
                            checked={BrandFilter.subSubCategory.includes(
                              subCat
                            )}
                          />
                        </div>
                        <div className="flex-grow-1 overflow-hidden text-truncate">
                          <label className="text-wrap form-check-label">
                            {displaySubCatMap[subCat]}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <div className="vr m-0 p-0" />

            <Accordion.Item
              className="border-0 py-2 position-static"
              style={{ width: "13rem" }}
              eventKey={4}
            >
              <Accordion.Header
                onClick={() => toggleAccordion(4)}
                style={{ padding: "0rem 2rem 0rem 2.5rem" }}
              >
                Price
                <ChevronDown
                  className="ms-2 accordion-chevron"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                />
              </Accordion.Header>
              <Accordion.Body
                style={{ width: "10.25rem" }}
                className="position-absolute top-100 bg-white shadow rounded-bottom-2"
              >
                <ul
                  className="p-0 price-filter list-unstyled"
                  aria-labelledby="priceMenu"
                >
                  <div className="flex-1 justify-content-center">
                    <div className="rounded-5 mb-3">
                      <Slider
                        range
                        value={range}
                        onChange={handleRangeChange}
                        min={0}
                        max={90000}
                        step={500}
                        tipFormatter={(value) => `${value}`}
                      />
                    </div>

                    <div className="d-flex gap-2">
                      <div className="d-flex flex-column">
                        <label>Min</label>
                        <InputNumber
                          className="w-100"
                          min={0}
                          max={90000}
                          value={range[0]}
                          onChange={handleMinInputChange}
                          placeholder={range[0]}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <label>Max</label>
                        <InputNumber
                          className="w-100"
                          min={0}
                          max={90000}
                          value={range[1]}
                          onChange={handleMaxInputChange}
                          placeholder={range[1]}
                        />
                      </div>
                    </div>
                  </div>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <div className="vr m-0 p-0" />

            <Accordion.Item
              className="border-0 py-2 position-static"
              style={{ width: "13rem" }}
              eventKey={5}
            >
              <Accordion.Header
                onClick={() => toggleAccordion(5)}
                style={{ padding: "0rem 2rem 0rem 2.5rem" }}
              >
                Size
                <ChevronDown
                  className="ms-2 accordion-chevron"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                />
              </Accordion.Header>
              <Accordion.Body
                style={{ width: "10.25rem" }}
                className="position-absolute top-100 bg-white shadow rounded-bottom-2"
              >
                <ul
                  className="p-0 m-0 size-filter list-unstyled"
                  aria-labelledby="sizeMenu"
                >
                  {alphabeticSizes.length > 0 && (
                    <div
                      className="overflow-auto"
                      style={{ maxHeight: "300px" }}
                    >
                      <div className="d-flex">
                        <div className="me-4">
                          {alphabeticSizes?.map((size) => (
                            <div
                              key={size}
                              className="text-wrap justify-content-start align-items-center my-2"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={size}
                                onChange={handleSizeChange}
                                checked={BrandFilter.size.includes(size)}
                                disabled={!availableSizes.includes(size)}
                              />
                              <label className="form-check-label mx-2">
                                {size}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div>
                          {numericSizes?.map((size) => (
                            <div
                              key={size}
                              className="text-wrap justify-content-start align-items-center my-2"
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name={size}
                                onChange={handleSizeChange}
                                checked={BrandFilter.size.includes(size)}
                                disabled={!availableSizes.includes(size)}
                              />
                              <label className="form-check-label mx-2">
                                {size}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <div className="vr m-0 p-0" />

            <Accordion.Item
              className="border-0 py-2 position-static"
              style={{ width: "13rem" }}
              eventKey={6}
            >
              <Accordion.Header
                onClick={() => toggleAccordion(6)}
                style={{ padding: "0rem 2rem 0rem 2.5rem" }}
              >
                Color
                <ChevronDown
                  className="ms-2 accordion-chevron"
                  style={{
                    transition: "transform 0.2s ease-in-out",
                    transform: "rotate(0deg)",
                  }}
                />
              </Accordion.Header>
              <Accordion.Body
                style={{ width: "10.25rem" }}
                className="position-absolute top-100 bg-white shadow rounded-bottom-2"
              >
                <ul
                  className="p-0 m-0 color-filter list-unstyled"
                  aria-labelledby="colorMenu"
                >
                  <div className="overflow-auto" style={{ maxHeight: "300px" }}>
                    {colors
                      .filter(
                        (color) =>
                          availableColors.includes(color) &&
                          isNaN(color) &&
                          !["XXS", "XS", "S", "M", "L", "XL", "XXL"].includes(
                            color
                          )
                      )
                      .map((color) => (
                        <div
                          key={color}
                          className="text-wrap justify-content-start align-items-center my-2"
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={color}
                            onChange={handColorChange}
                            checked={BrandFilter.color.includes(color)}
                          />
                          <label className="form-check-label mx-2">
                            {color}
                          </label>
                        </div>
                      ))}
                  </div>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <div className="vr m-0 p-0" />

            <Accordion.Item
              className="border-0 py-2 position-static rounded-end-2"
              style={{ width: "13rem" }}
              eventKey={7}
            >
              <Accordion.Header
                onClick={() => toggleAccordion(7)}
                style={{ padding: "0rem 2rem 0rem 3rem" }}
              >
                <ArrowDownUp className="fs-5 me-2" />
                Sort
              </Accordion.Header>
              <Accordion.Body
                style={{ width: "13rem" }}
                className="position-absolute top-100 end-0 bg-white shadow"
              >
                <div className="overflow-auto" style={{ maxHeight: "300px" }}>
                  {filters.map((filterOption) => (
                    <>
                      <div
                        key={filterOption.value}
                        className="text-wrap d-flex justify-content-start align-items-center my-2 px-2"
                        onClick={() => {
                          setFilter(filterOption.value);
                        }}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filter === filterOption.value}
                        />
                        <label className="form-check-label mx-2">
                          {filterOption.name}
                        </label>
                      </div>
                      <hr className="m-0 p-0" />
                    </>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>

        {/* Card body START */}
        <div className="card-body px-2 mx-1 mx-lg-0 pb-0">
          <div className="row g-4">
            {/* Product item START */}
            {loading
              ? Array.from({ length: 15 }).map((_, index) => (
                  <div
                    key={`skeleton-${index}`}
                    className={`col-6 col-sm-4 col-md-3 col-lg-20 ${
                      index % 2 === 0 ? "ps-0 pe-1" : "ps-1 pe-0"
                    } px-md-1 mt-2 mt-lg-1`}
                >

                  <BrandProductsSkeleton />

                </div>
              ))
             : 
              filteredProducts.map((product, index) => (
                <>
                  {
                    (index + 1) % 12 === 0 && index !== 0 ? (
                      isMobileScreen ? (
                        <ProductGoogleAdCardMobile />
                      ) : (
                        <ProductGoogleAdCard />
                      )
                    ) : null
                  }
                  <div
                    className={`col-6 col-sm-4 col-md-3 col-lg-20 ${index % 2 === 0 ? "ps-0 pe-1" : "ps-1 pe-0"
                      } px-md-1 mt-2 mt-lg-1`}
                    key={index}
                  >
                    <ProductCardBrandsPage product={product} />
                  </div>
                  {/* {(index + 1) % 12 === 0 && index !== 0 && <GoogleAdCard4 />} */}

                </>
              ))
            }

            {/* Product item END */}
          </div>
        </div>
        {/* Card body END */}

        {/*} Pagination */}
      </div>

      <button
        className={`btn btn-loader  mt-12  btn-primary-soft ${
          loading ||
          !countLeft ||
          filteredProducts.length < currentPage * itemsPerPage
            ? "disabled"
            : ""
        }`}
        onClick={
          !countLeft
            ? null
            : () => {
                if (!loading) {
                  setLoading(true);
                  setCurrentPage((prevValue) => prevValue + 1);
                }
              }
        }
      >
        {loading ? (

          <div class="spinner-grow spinner-grow-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>

        ) : (
          <span className="load-text">
            {filteredProducts.length >= currentPage * itemsPerPage
              ? "Load more"
              : "No more products to show"}
          </span>
        )}
      </button>
    </>
  );
}

export default BrandProducts2;
