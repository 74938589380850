import React from "react";
import { Link, useLocation } from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import { UserAuth } from "../Context";
import useModalStore from "../StatesStore/ModalStore";
import BrandsMenu from "./BrandsMenu";
import HeaderMenu4 from "./HeaderMenu4";
import CategoryMenu3 from "./CategoryMenu3";
import useSearchBarOpenState from "../StatesStore/SearchBarOpen";
import useSearchInputStore from "../StatesStore/SearchInputStore";

function SecondHeader3() {
  const location = useLocation();
  const { user } = UserAuth();
  const { openModal } = useModalStore();
  const { setIsSearchBarOpen } = useSearchBarOpenState();
  const { setSearchInput } = useSearchInputStore();

  const handleOutsideClick = () => {
    const brand = document.getElementById("brandsCollapse");
    if (brand.classList.contains("show")) {
      brand.classList.remove("show");
    }
    // const menCategory = document.getElementById("menCollapse");
    // if (menCategory.classList.contains("show")) {
    //   menCategory.classList.remove("show");
    // }
    // const womenCategory = document.getElementById("womenCollapse");
    // if (womenCategory.classList.contains("show")) {
    //   womenCategory.classList.remove("show");
    // }
  };
  return (
    <>
      <nav
        className="navbar navbar-light navbar-expand-md d-block shadow-sm mb-0 secondHeader3"
        onClick={() => {
          setIsSearchBarOpen(false);
          setSearchInput("");
        }}
      >
        <style jsx>
          {`
            .secondHeader3 .navbar-nav .nav-link {
              line-height: 2.5;
            }
            .secondHeader3 {
              background-color: var(--bs-heading-color, inherit);
            }
            // .secondHeader3 .nav-link {
            //   color: var(--bs-body-bg, inherit) !important;
            // }
            .secondHeader3 .nav-link {
              color: var(--bs-gray-400) !important;
            }
            [data-bs-theme="dark"] .secondHeader3 {
              // background-color: rgba(var(--bs-secondary-rgb), 0.1) !important;
              // background-color: rgb(44 45 47) !important;
              // background-color: rgb(43 47 56) !important;
              background-color: #353539 !important;
            }
            [data-bs-theme="dark"] .secondHeader3 .nav-link {
              color: var(--bs-gray-600) !important;
            }
            .secondHeader3 .nav-link.active {
              color: var(--bs-navbar-active-color) !important;
            }
            .secondHeader3 .nav-link:hover {
              color: var(--bs-navbar-active-color) !important;
            }
            .hiddenScrollbar::-webkit-scrollbar {
              display: none;
            }
            .hiddenScrollbar {
              -ms-overflow-style: none; /* IE and Edge */
              scrollbar-width: none; /* Firefox */
            }
          `}
        </style>
        <div className="container w-100">
          <ul
            className="d-flex d-md-none flex-row justify-content-between align-items-center gap-4 navbar-nav w-100 hiddenScrollbar"
            style={{ overflow: "scroll hidden", whiteSpace: "nowrap" }}
          >
            <li className="nav-item">
              <Link
                className={`nav-link text-white2 ${location.pathname ===
                  "/discover" && "active"}`}
                to={"/discover"}
                style={{ fontSize: "0.9rem" }}
                aria-current="page"
                onClick={() => {
                  handleOutsideClick();
                }}
              >
                Discover
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/Men"}
                style={{ fontSize: "0.9rem" }}
                className={`nav-link text-white2 btn text-decoration-none ${location.pathname ===
                  "/Men" && "active"}`}
                id="menCollapse"
              >
                Men
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/Women"}
                style={{ fontSize: "0.9rem" }}
                className={`nav-link text-white2 btn text-decoration-none ${location.pathname ===
                  "/Women" && "active"}`}
                id="womenCollapse"
              >
                Women
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={"/brands"}
                style={{ fontSize: "0.9rem" }}
                // data-bs-toggle="collapse"
                // data-bs-target="#brandsCollapse"
                aria-label="Brands & Categories navigation"
                className={`nav-link text-white2 btn text-decoration-none ${location.pathname ===
                  "/brands" && "active"}`}
                aria-controls="brandsCollapse"
                // aria-expanded="false"
                onClick={() => {
                  handleOutsideClick();
                }}
              >
                Brands
              </Link>
              <div
                id="brandsCollapse"
                className="pb-0 collapse navbar-collapse rounded-2 rounded-top-0"
                style={{ zIndex: 1 }}
              >
                {/* <HeaderMenu type={"brand"} /> */}
              </div>
            </li>
            {/* <li className="nav-item">
              <Link
                className={`nav-link text-white2 ${location.pathname === "/" &&
                  "active"}`}
                to={user?.emailVerified ? "/" : "#"}
                style={{ fontSize: "0.9rem" }}
                onClick={(e) => {
                  if (!user) {
                    e.preventDefault(); // Prevent routing
                    openModal("SignInModal", "", ""); // Show the SignIn modal
                    return;
                  } else if (!user?.emailVerified) {
                    openModal("VerifyEmailModal", "", {
                      userName: user?.displayName,
                      email: user?.email,
                    });
                    return;
                  }
                  handleOutsideClick();
                }}
              >
                News Feed
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                style={{ fontSize: "0.9rem" }}
                className={`dropdown-item nav-link fw-light text-white2 ${
                  location.pathname === "/staticPages/termsAndConditions"
                    ? "active"
                    : ""
                }`}
                to={"/staticPages/termsAndConditions"}
              >
                Terms of Service
              </Link>
            </li>
            <li className="nav-item">
              <Link
                style={{ fontSize: "0.9rem" }}
                className={`dropdown-item nav-link fw-light text-white2 ${
                  location.pathname.includes("/contactUs") ? "active" : ""
                }`}
                to={"/staticPages/contactUs"}
              >
                Contact us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                style={{ fontSize: "0.9rem" }}
                className={`dropdown-item nav-link fw-light text-white2 ${
                  location.pathname === "/staticPages/privacyPolicy"
                    ? "active"
                    : ""
                }`}
                to={"/staticPages/privacyPolicy"}
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
          <ul className="d-none d-md-flex flex-row justify-content-between align-items-center navbar-nav w-100 ms-1">
            <div className="d-flex gap-4">
              <li className="nav-item">
                <Link
                  className={`nav-link text-white2 ps-0 ${location.pathname ===
                    "/discover" && "active"}`}
                  to={"/discover"}
                  aria-current="page"
                  onClick={() => {
                    handleOutsideClick();
                  }}
                >
                  Discover
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  to={"/Men"}
                  className={`nav-link text-white2 ${location.pathname ===
                    "/Men" && "active"}`}
                  id="menCollapse"
                >
                  Men
                </Link>
              </li>
              <li className="nav-item ">
                <Link
                  to={"/Women"}
                  className={`nav-link text-white2 ${location.pathname ===
                    "/Women" && "active"}`}
                  id="womenCollapse"
                >
                  Women
                </Link>
              </li>
              <li className="nav-item dropdown ">
                <Link
                  to={"/brands"}
                  className={`nav-link text-white2 ${location.pathname ===
                    "/brands" && "active"}`}
                  style={{ cursor: "pointer" }}
                  id="brandMenu"
                  // data-bs-toggle="dropdown"
                  // aria-haspopup="true"
                  // aria-expanded="false"
                >
                  Brands
                </Link>
                {/* <BrandsMenu /> */}
              </li>
              {/* <li className="nav-item">
                <Link
                  className={`nav-link text-white2 ${location.pathname ===
                    "/" && "active"}`}
                  to={user?.emailVerified ? "/" : "#"}
                  onClick={(e) => {
                    if (!user) {
                      e.preventDefault(); // Prevent routing
                      openModal("SignInModal", "", ""); // Show the SignIn modal
                      return;
                    } else if (!user?.emailVerified) {
                      openModal("VerifyEmailModal", "", {
                        userName: user?.displayName,
                        email: user?.email,
                      });
                      return;
                    }
                    handleOutsideClick();
                  }}
                >
                  News Feed
                </Link>
              </li> */}
            </div>
            <div className="d-flex">
              <li className="nav-item">
                <Link
                  style={{ fontSize: "0.8rem" }}
                  className={`px-2 nav-link text-white2 fw-light pt-1 ${location.pathname ===
                    "/staticPages/contactUs" && "active"}`}
                  to={"/staticPages/contactUs"}
                  aria-current="page"
                  onClick={() => {}}
                >
                  Contact us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  style={{ fontSize: "0.8rem" }}
                  className={`px-2 nav-link text-white2 fw-light pt-1 ${location.pathname ===
                    "/staticPages/termsAndConditions" && "active"}`}
                  to={"/staticPages/termsAndConditions"}
                  aria-current="page"
                  onClick={() => {}}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  style={{ fontSize: "0.8rem" }}
                  className={`ps-2 nav-link text-white2 fw-light pt-1 ${location.pathname ===
                    "/staticPages/privacyPolicy" && "active"}`}
                  to={"/staticPages/privacyPolicy"}
                  aria-current="page"
                  onClick={() => {}}
                >
                  Privacy Policy
                </Link>
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default SecondHeader3;
