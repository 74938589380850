import React from "react";
import { Chat, Channel, MessageList, Window } from "stream-chat-react";
import useChatClient from "../StatesStore/ChatClient";
import "stream-chat-react/dist/css/v2/index.css";
import { UserAuth } from "../Context";
import { EmojiPicker } from "stream-chat-react/emojis";
import { encodeToMp3 } from "stream-chat-react/mp3-encoder";
import "./Chat.css";
import SideBar from "./SideBar";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import { ChannelHeader } from "./ChannelHeader";
import CustomMessageInput from "./CustomMessageInput";
import ProductsInChat from "./ProductsInChat";
import useThemeState from "../StatesStore/Theme";
import { CustomAttachmentSelector } from "./CustomAttachmentSelector";
import CustomAttachment from "./CustomAttachment";

function Chat2() {
  const { user } = UserAuth();
  const { theme } = useThemeState();
  const client = useChatClient((state) => state.client);

  if (!client) {
    return <LoadingSpinner />;
  }

  const containerStyle = {
    display: "flex",
    height: "84vh",
    width: "100%",
    overflow: "hidden",
    gap: "0.5rem",
  };

  const channelWindowStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderRadius: "0px 4px 4px 0px",
  };

  // const customRenderText = (text) => {
  //   return <span>{text}</span>;
  // };

  // const CustomAttachment = (props) => {
  //   const { attachments } = props;
  //   const [attachment] = attachments || [];
  //   if (attachment?.type === "product") {
  //     return <ProductCard3 product={attachment?.product} isStrip={true} />;
  //   }

  //   return <Attachment {...props} />;
  // };
  // const customRenderMessages = (options) => {
  //   const { messages, internalMessageProps, groupedMessages } = options;
  //   const elements = [];

  //   messages.forEach((message) => {
  //     if (message.product) {
  //       elements.push(
  //         <li key={message.id} className="w-25 h-25">
  //           <ProductCard3 product={message?.product} isStrip={false} />
  //         </li>
  //       );
  //     } else {
  //       elements.push(
  //         defaultRenderMessages({ ...options, messages: [message] })
  //       );
  //     }
  //   });

  //   return elements;
  // };

  // const WrappedMessageList = () => (
  //   <MessageList
  //     renderText={customRenderText}
  //     closeReactionSelectorOnClick={true}
  //   />
  // );

  if (!user) return;
  return (
    <>
      <style>
        {`
        .str-chat {
          --str-chat__border-radius-circle: 4px; 
          --str-chat__avatar-background-color: #dddddd;
          --str-chat__avatar-color:#222222;
           --str-chat__channel-preview-active-background-color: #e7f0fd;
        }
        .str-chat__li{
           padding-inline: 0.5rem !important;
        }
        // .str-chat__message .str-chat__message-bubble  {
        //   border-radius: 0.5rem !important;
        //   background-color: transparent !important;
        // }
           
      `}
      </style>
      {/* <button
        className="border-0 rounded-2 me-2 icon-md btn btn-light p-0 position-absolute z-1 d-lg-none mb-4"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasSideChatbar"
        aria-controls="offcanvasSideChatbar"
      >
        <span className="btn btn-primary p-2">
          <List className="fs-4" />
        </span>
      </button> */}
      <Chat
        client={client}
        customClasses={{
          channelList: `str-chat__theme-${theme} str-chat__channel-list`,
          channel: `str-chat__theme-${theme} str-chat__channel`,
        }}
      >
        <div style={containerStyle} className="mx-1">
          <SideBar />

          <div style={channelWindowStyle} className="rounded-2 border">
            <Channel
              EmojiPicker={EmojiPicker}
              Attachment={CustomAttachment}
              enrichURLForPreview
              AttachmentSelector={CustomAttachmentSelector}
            >
              <Window>
                {/* <Users /> */}
                <ChannelHeader />
                <ProductsInChat />
                {/* <ChannelHeader /> */}
                <MessageList
                  messageActions={["edit", "delete", "quote", "react"]}
                  closeReactionSelectorOnClick={true}
                  additionalMessageInputProps={{
                    urlEnrichmentConfig: { enrichURLForPreview: true },
                  }}
                  // renderMessages={customRenderMessages}
                />
                <CustomMessageInput
                  audioRecordingConfig={{
                    transcoderConfig: { encoder: encodeToMp3 },
                  }}
                  audioRecordingEnabled
                  urlEnrichmentConfig={{ enrichURLForPreview: true }}
                />
              </Window>
            </Channel>
          </div>
        </div>
      </Chat>
    </>
  );
}

export default Chat2;
