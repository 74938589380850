import React, { useEffect, useState } from "react";
import { Link, redirect, useNavigate, useSearchParams } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import {
  setDoc,
  doc,
  serverTimestamp,
  getFirestore,
  getDoc,
} from "firebase/firestore";
import { auth, db, facebookProvider, googleProvider } from "../FirebaseConfig";
import WebFont from "webfontloader";
import { UserAuth } from "../Context";
import { useForm, Controller } from "react-hook-form";
import { toastOptions } from "../lib/toastOptions";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import useUserCountry from "../StatesStore/UserCountry";
import { generateReferralCode } from "../lib/generateReferralCode";
import { handleInvite } from "../Referral/HandleInvite";
import IncrementInviteClicks from "../Referral/IncrementInviteClicks";
import TermsAndConditions from "./TermsAndConditions";
import { Button } from "react-bootstrap";
import useModalStore from "../StatesStore/ModalStore";
import useUserStore from "../StatesStore/UserStore";
import { storeReferralCode } from "../lib/storeReferralCode";
import { getAllStoredReferralCodes } from "../lib/storedReferralCode";
import { WelcomeUser } from "../EmailSystem/sendEmails";
import useUrlState from "../StatesStore/CurrentURL";
import usePointsStore from "../StatesStore/PointsTable";
const Signup = () => {
  const { open, closeModal } = useModalStore();
  const navigate = useNavigate();
  const { logOut } = UserAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const country = useUserCountry((state) => state.country);
  //const createUser = useUserStore((state)=>state.createUser)
  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get("ref");
  const [fullname, setFullname] = useState("");
  const { setUser, createId } = useUserStore(); //for storing user
  const { user } = UserAuth();
  const pointsTable = usePointsStore((state) => state.pointsTable);
  const url = useUrlState((state) => state.url);
  const redirectUser = useNavigate();
  googleProvider.addScope("email");
  facebookProvider.addScope("email");
  const googleLogoPath =
    process.env.PUBLIC_URL + "/assets/images/logo/google-logo.svg";
  const facebookLogoPath =
    process.env.PUBLIC_URL + "/assets/images/logo/facebook-logo.svg";

  useEffect(() => {
    if (inviteCode) {
      console.log("inviteCode", inviteCode);

      storeReferralCode(inviteCode);
      IncrementInviteClicks(inviteCode);
    }
    const referralCodes = getAllStoredReferralCodes();
    console.log("Referral Codes to be sent:", referralCodes);
  }, []);
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);
  if (open) {
    closeModal();
  }
  const { control, handleSubmit, setError, formState } = useForm({
    defaultValues: {
      name: "",
      email: "",
      pass: "",
      confirmPass: "",
      gender: "",
      countryCode: "",
      phoneNumber: "",
      birthday: "",
      terms: false,
    },
  });

  const { errors } = formState;

  const handleSubmission = async (data) => {
    const {
      name,
      email,
      pass,
      confirmPass,
      countryCode,
      phoneNumber,
      terms,
    } = data;
    console.log("data", data);
    //setting submit button disable to avoid user firing repeatitive events
    const regex = /^[a-zA-Z\s]*$/;
    // Validate if the fields are not empty
    const errors = {};
    if (!pass) {
      errors.pass = "Password is required";
    }
    if (!confirmPass) {
      errors.confirmPass = "Confirm Password is required";
    }
    if (pass.length < 6) {
      errors.pass = "Password must be at least 6 characters";
    }

    if (!regex.test(name)) {
      errors.name = "Name can only contain alphabets";
    }
    // If there are errors, set the error messages and return
    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([field, message]) => {
        setError(field, {
          type: "manual",
          message,
        });
      });
      return;
    }

    // Check if the password and confirm password match
    if (pass !== confirmPass) {
      setError("confirmPass", {
        type: "manual",
        message: "Password and Confirm Password do not match",
      });
      return;
    }
    setSubmitButtonDisabled(true);
    // Uppercase the first letter of each word
    const NAME = name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
    // Split full name into firstName and lastName
    const [firstName, ...lastNameArray] = NAME.split(" ");
    const lastName = lastNameArray.join(" ");

    // Convert the photo to base64
    let photoBase64;
    if (data.photo) {
      photoBase64 = data.photo.split(",")[1];
    }
    try {
      const authUser = await createUserWithEmailAndPassword(auth, email, pass);
      console.log("authuser", authUser);
      //createUser(email,pass)
      // Set displayName using updateProfile
      await updateProfile(authUser.user, {
        displayName: NAME,
      });
      // Concatenate country code and phone number
      const fullPhoneNumber = `${countryCode}${phoneNumber}`;
      const userDocRef = doc(db, "users", authUser.user.uid);

      // Generate random numbers for followers, following, and postCount
      const followers = 0;
      const following = 0;
      const postCount = 0;
      const connectionCount = 0;
      const referralCode = generateReferralCode();
      try {
        // Use setDoc to create or update the document
        await setDoc(userDocRef, {
          email,
          userName: NAME,
          firstName,
          lastName,
          birthday: null,
          phoneNumber: fullPhoneNumber,
          overview: null,
          products: null,
          photo: photoBase64 || null, // Set to null if photoBase64 is undefined
          userTitle: null,
          timestamp: serverTimestamp(),
          region: country || null,
          followers,
          following,
          postCount,
          connectionCount,
          underAge: null,
          parentalConsent: null,
          favCategories: [],
          gender: null,
          location: "",
          terms: terms, //adding this to users object in firestore
          heardAboutUs: "",
          referralCode: referralCode,
          isVerified: false,
        });
      } catch (error) {
        console.error(error, " SetDoc");
      }
      const newUser = {
        userName: NAME,
        email,
        firstName,
        lastName,
        birthday: null,
        phoneNumber: fullPhoneNumber,
        overview: null,
        products: null,
        photo: photoBase64 || null, // Set to null if photoBase64 is undefined
        userTitle: null,
        timestamp: serverTimestamp(),
        region: country || null,
        followers,
        following,
        postCount,
        connectionCount,
        underAge: null,
        parentalConsent: null,
        favCategories: [],
        gender: null,
        location: "",
        terms: terms, //adding this to users object in firestore
        heardAboutUs: "",
        referralCode: referralCode,
        isVerified: false,
      };
      console.log("newUser", newUser);
      await setUser(newUser);
      createId(authUser.user.uid);
      // After sign-up, search for the user with the referral code and add invite
      handleInvite(inviteCode, authUser, email, pointsTable);
      console.log("Data", authUser.user);
      toast.success(
        "Check you inbox for email verification link",
        toastOptions
      );
      await sendEmailVerification(authUser.user)
        .then(() => {
          // Email verification sent!
          console.log("Email verification sent!");
          navigate("/VerifyEmail");
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(
            "Error sending email verification:",
            errorCode,
            errorMessage
          );
        });
    } catch (err) {
      setSubmitButtonDisabled(false);
      console.error("Sign up error:", err);
      if (err.code === "auth/email-already-in-use") {
        setError("email", {
          type: "manual",
          message: "Email already exists",
        });
      } else {
        console.error(err);
      }
      toast.error("Sign up failed. Please try again.", toastOptions);
    }
  };

  React.useEffect(() => {
    if (submitButtonDisabled) {
      console.log("button disables");
    }
  }, [submitButtonDisabled, logOut]);

  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ["Nunito Sans", "sans-serif"],
      },
    });
  }, []);
  const googleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("result", result);
      return result;
    } catch (error) {
      throw error;
    }
  };
  const facebookSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);
      console.log(result.user);
      return result;
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        const pendingCred = FacebookAuthProvider.credentialFromError(error);
        const email = error.customData.email;
        const signInMethods = await fetchSignInMethodsForEmail(auth, email);
        console.log(signInMethods);
        if (signInMethods.includes("google.com")) {
          try {
            alert(
              "Account with this email " +
                email +
                " already exists. You need to login with gmail first in order to link both accounts"
            );
            const userCredential = await signInWithPopup(auth, googleProvider);
            await linkWithCredential(userCredential.user, pendingCred);
            console.log("Successfully linked existing account with Facebook");
          } catch (linkError) {
            console.error("Error linking credential:", linkError);
          }
        } else if (signInMethods.includes("password")) {
          try {
            const password = prompt(
              "Account with this email " +
                email +
                " already existed. Enter you password in order to link both accounts:"
            );
            const userCredential = await signInWithEmailAndPassword(
              auth,
              email,
              password
            ); // No password needed
            await linkWithCredential(userCredential.user, pendingCred);
            console.log("Successfully linked existing account with Facebook");
          } catch (linkError) {
            console.error("Error linking credential:", linkError);
          }
        } else {
          console.log("Other sign-in methods detected:", signInMethods);
          // Handle other provider linking if needed
        }
      } else {
        console.error("Error during sign-in:", error);
      }
    }
  };
  const handleGoogleSignIn = async () => {
    try {
      const result = await googleSignIn();
      // Check if the result is defined and has a 'user' property
      if (result && result.user) {
        // Extract user information from the Google sign-in result
        const { user } = result;
        if (user) {
          console.log("user google", user);
          // console.log(user.emailVerified);
          user.emailVerified = true;
          user.email = user.providerData[0].email;
          // console.log("BEFORE CREATING ID");
          createId(user.uid);
          if (!user.displayName) {
            const displayName = "DefaultDisplayName";
            await updateProfile(user, { displayName });
          }

          // Store user information in Firestore
          const db = getFirestore();
          const userRef = doc(db, "users", user.uid);

          // Check if the user already exists in Firestore
          const docSnap = await getDoc(userRef);
          console.log("here ser google", user);
          //new user
          if (!docSnap.exists()) {
            await WelcomeUser(user.displayName, user.providerData[0]?.email);
            console.log("BEFORE SETING IT TRUE");
            auth.currentUser.emailVerified = true;
            fetch(process.env.REACT_APP_API + "/api/updateEmailVerified", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ uid: user.uid }),
            });
            const referralCode = generateReferralCode();
            const displayNameParts = (
              user.displayName || "DefaultDisplayName"
            ).split(" ");
            //make user dictionary
            const newUser = {
              userName: user.displayName || "DefaultDisplayName",
              email: user.email,
              firstName: displayNameParts[0] || null,
              lastName: displayNameParts.slice(1).join(" ") || null,
              birthday: user.birthday || null,
              phoneNumber: user.phoneNumber || null,
              gender: user.gender || null,
              photo: user.photoURL || null,
              overview: user.overview || null,
              products: user.products || null,
              timestamp: serverTimestamp(),
              location: null,
              userTitle: null,
              terms: false, //adding this to users object in firestore
              heardAboutUs: "",
              referralCode: referralCode,
              isVerified: true,
            };
            handleInvite(inviteCode, user.uid, user.email, pointsTable);
            //save the state of user
            await setUser(newUser);
            //set document in firestore here
            await setDoc(userRef, newUser);
            //user has no gender or birthday
            try {
              toast.success("Sign in with google successfull!", toastOptions);
              //redirect to more questions
              redirectUser("/SignInInput");
            } catch (error) {
              console.log("Error Signing in");
              toast.error("Sign up failed. Please try again.", toastOptions);
              redirect("/SignIn");
            }
          } else {
            console.log("GOING BACK TO OLD URL BYEEE!!");
            if (user.gender === null || user.birthday === null)
              redirectUser("/SignInInput");
            redirectUser(url);
          }
        }
      }
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      if (error.code === "auth/popup-closed-by-user") {
        // alert("Google Sign-In popup closed. Please try again.");
        redirectUser("/SignIn");
      } else if (error.code === "auth/cancelled-popup-request") {
        console.error(error);
        // alert("Google Sign-In request cancelled. Please try again.");
      } else {
        console.error(error);
        // alert("Google Sign-In failed. Please try again.");
      }
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const result = await facebookSignIn();
      console.log(result);
      // Check if the result is defined and has a 'user' property
      if (result && result.user) {
        // Extract user information from the Google sign-in result
        const { user } = result;
        if (user) {
          console.log("user facebook", user);
          createId(user.uid);
          if (!user.displayName) {
            const displayName = "DefaultDisplayName";
            await updateProfile(user, { displayName });
          }

          // Store user information in Firestore
          const db = getFirestore();
          const userRef = doc(db, "users", user.uid);

          // Check if the user already exists in Firestore
          const docSnap = await getDoc(userRef);
          console.log("here ser facebook", user);
          //new user
          if (!docSnap.exists()) {
            await WelcomeUser(user.displayName, user.providerData[0]?.email);
            fetch(
              "https://us-central1-duplookflock-beada.cloudfunctions.net/api/api/updateEmailVerified",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ uid: user.uid }),
              }
            );
            auth.currentUser.emailVerified = true;
            const referralCode = generateReferralCode();
            const displayNameParts = (
              user.displayName || "DefaultDisplayName"
            ).split(" ");
            //make user dictionary
            const newUser = {
              userName: user.displayName || "DefaultDisplayName",
              email: user.email,
              firstName: displayNameParts[0] || null,
              lastName: displayNameParts.slice(1).join(" ") || null,
              birthday: user.birthday || null,
              phoneNumber: user.phoneNumber || null,
              gender: user.gender || null,
              photo: user.photoURL || null,
              overview: user.overview || null,
              products: user.products || null,
              timestamp: serverTimestamp(),
              location: null,
              userTitle: null,
              terms: false, //adding this to users object in firestore
              heardAboutUs: "",
              referralCode: referralCode,
              isVerified: true,
            };
            handleInvite(inviteCode, user.uid, user.email, pointsTable);
            //user has no gender or birthday
            //save the state of user
            await setUser(newUser);
            //set document in firestore here
            await setDoc(userRef, newUser);
            //redirect to gender/birthday compoennt
            try {
              toast.success("Sign in with Facebook successfull!", toastOptions);
              //redirect to more questions
              redirectUser("/SignInInput");
            } catch (error) {
              console.log("Error Signing in");
              toast.error("Sign up failed. Please try again.", toastOptions);
              redirect("/SignIn");
            }
          } else {
            redirectUser(url);
          }
        }
      }
    } catch (error) {
      console.error("Error during Facebook sign-in:", error);
      if (error.code === "auth/popup-closed-by-user") {
        // alert("Facebook Sign-In popup closed. Please try again.");
        redirectUser("/login");
      } else if (error.code === "auth/cancelled-popup-request") {
        console.error(error);
        // alert("Facebook Sign-In request cancelled. Please try again.");
      } else {
        console.error(error);
        // alert("Facebook Sign-In failed. Please try again.");
      }
    }
  };
  return (
    <>
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100 py-2">
          <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
            <div className="card card-body rounded-3 p-4">
              <div className="text-center">
                <Link className="text-body text-center fs-icon " to="/">
                  <div
                    style={{
                      fontFamily: "Nunito Sans",
                    }}
                  >
                    <i>lookflock&nbsp;</i>
                  </div>
                </Link>
                <span className="d-block ">
                  Already have an account?{" "}
                  <Link to="/SignIn">
                    {" "}
                    <u className="text-body">Sign in here</u>
                  </Link>
                </span>
              </div>

              <div className="mt-4">
                <form onSubmit={handleSubmit(handleSubmission)}>
                  {/*Name  */}
                  <div className="mb-3 input-group ">
                    <div className="input-group">
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              name="name"
                              type="text"
                              className="form-control"
                              value={fullname}
                              placeholder="Full Name"
                              onChange={(e) => setFullname(e.target.value)}
                              {...field}
                              required
                            />
                          </>
                        )}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        marginLeft: "5px",
                        marginTop: "5px",
                      }}
                    >
                      {errors.name?.message}
                    </span>
                  </div>
                  {/* Email */}
                  <div className="mb-3 input-group">
                    <div className="input-group">
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              autoComplete="new-email"
                              {...field}
                              required
                            />
                          </>
                        )}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        marginLeft: "5px",
                        marginTop: "5px",
                      }}
                    >
                      {errors.email?.message}
                    </span>
                  </div>
                  {/** password  */}
                  <div className="mb-0 ">
                    <div className="input-group">
                      <Controller
                        name="pass"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              className="form-control fakepassword"
                              type={showPassword ? "text" : "password"}
                              id="new-psw-input"
                              placeholder="Password"
                              autoComplete="current-password"
                              {...field}
                            />
                            <span
                              className="input-group-text p-0"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <i
                                className={`fakepasswordicon fa-solid fa-eye${
                                  showPassword ? "-slash" : ""
                                } cursor-pointer p-2 w-40px`}
                              ></i>
                            </span>
                          </>
                        )}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        marginLeft: "5px",
                        marginTop: "5px",
                      }}
                    >
                      {errors.pass?.message}
                    </span>
                    <span
                      style={{
                        color: "red",
                        marginLeft: "5px",
                        marginTop: "5px",
                      }}
                    >
                      {errors.passwordLength?.message}
                    </span>
                  </div>
                  {/* Confirm Pass field  */}
                  <div className="mb-0 position-relative">
                    <div className="input-group ">
                      <Controller
                        name="confirmPass"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              className="form-control fakepassword"
                              type={showPassword ? "text" : "password"}
                              id="confirm-psw-input"
                              placeholder="Confirm Password"
                              autoComplete="current-password"
                              {...field}
                            />
                            <span
                              className="input-group-text p-0"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <i
                                className={`fakepasswordicon fa-solid fa-eye${
                                  showPassword ? "-slash" : ""
                                } cursor-pointer p-2 w-40px`}
                              ></i>
                            </span>
                          </>
                        )}
                      />
                    </div>
                    <span
                      style={{
                        color: "red",
                        marginLeft: "5px",
                        marginTop: "5px",
                      }}
                    >
                      {errors.confirmPass?.message}
                    </span>
                  </div>
                  <div className="d-grid mb-3">
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={!formState.isValid || submitButtonDisabled}
                    >
                      Sign Up
                    </Button>
                  </div>
                  <div className="container d-flex flex-column justify-content-center align-items-center mt-4">
                    <div className="w-100 d-flex align-items-center">
                      <hr className="flex-grow-1" />
                      <span className="px-2"> Or continue with </span>
                      <hr className="flex-grow-1" />
                    </div>

                    <div className="d-flex justify-content-center my-1">
                      <button className="btn mx-4" onClick={handleGoogleSignIn}>
                        <img
                          src={googleLogoPath}
                          className="mx-1"
                          alt="Google"
                          style={{
                            width: "40px",
                            height: "40px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                      </button>
                      <button
                        className="btn mx-4"
                        onClick={handleFacebookSignIn}
                      >
                        <img
                          src={facebookLogoPath}
                          className="mx-1"
                          alt="Facebook"
                          style={{
                            width: "40px",
                            height: "40px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                      </button>{" "}
                    </div>
                  </div>
                  {/** Terms and condition */}
                  <TermsAndConditions />
                </form>

                {/* <p className="text-center ">
                  ©2024 Lookflock. All rights reserved
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
