import React, { useEffect, useState } from "react";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import UserPosts from "./UserPosts";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import { UserAuth } from "../Context";
import UserProfileHeader4 from "./UserProfileHeader4";
import ShoppingList2 from "./ProfileShoppingList/ShoppingLists2";
import UserConnections from "./UserConnections";
import UserFollowing from "./UserFollowingTab";
import UserFollower from "./UserFollowerTab";
//importing sidebar links here

function ProfilePage2() {
  const { uid } = useParams(); // Extract the "uid" parameter from the URL
  const { user } = UserAuth();
  const location = useLocation();
  const { userData } = useFetchUserData(uid);
  const [isShoppingListOpen, setIsShoppingListOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const getSelectedOptionFromUrl = () => {
    const pathSegments = location.pathname.split("/");
    if (pathSegments.includes("posts")) return "posts";
    if (pathSegments.includes("shoppinglist")) return "shoppinglist";
    if (pathSegments.includes("userfollowing")) return "following";
    if (pathSegments.includes("userfollowers")) return "follower";

    return "posts"; // Default
  };

  const [selectedOption, setSelectedOption] = useState(
    getSelectedOptionFromUrl()
  );
  useEffect(() => {
    document.body.scrollIntoView({ behavior: "smooth" });
    document.body.scrollTo(0, 0);
    console.log("SCROLL View ProfilePage2");
  }, [uid]);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <>
      {/* // Main content START */}
      {/* <div className="vstack px-0 ms-0 ms-lg-1 me-0 me-lg-2"> */}
      <div className="d-flex gap-2 w-100 pt-lg-4 mt-1 mx-2 mx-lg-0">
        {isShoppingListOpen ? (
          <ShoppingList2
            isShoppingListOpen={isShoppingListOpen}
            setIsShoppingListOpen={setIsShoppingListOpen}
          />
        ) : (
          <div
            className={`d-flex ${
              isSmallScreen ? "flex-column" : "flex-row"
            } w-100`}
          >
            <div
              className={`d-flex flex-column`}
              style={{ width: isSmallScreen ? "100%" : "51.5rem" }}
            >
              {/* <div className="col-lg-8 vstack mt-0 mt-lg-5 px-0"> */}
              <UserProfileHeader4
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
              {isSmallScreen ? (
                <div className="mb-2 stickyHeader bg-mode">
                  <ul
                    className={`navbar nav nav-bottom-line align-items-start justify-content-start h5 gap-3`}
                  >
                    <li className="nav-item">
                      <NavLink
                        to={`/userProfile/${uid}/posts`}
                        className={`px-0 py-2 fw-normal nav-link ${
                          selectedOption === "posts" ? "active" : ""
                        }`}
                        onClick={() => setSelectedOption("posts")}
                      >
                        Posts
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={`/userProfile/${uid}/shoppinglist`}
                        className={`px-0 py-2 fw-normal nav-link ${
                          selectedOption === "shoppinglist" ? "active" : ""
                        }`}
                        onClick={() => setSelectedOption("shoppinglist")}
                      >
                        Shopping List
                      </NavLink>
                    </li>
                  </ul>
                </div>
              ) : (
                <hr className="my-4 border-2" />
              )}
              <Routes>
                <Route
                  path="/"
                  element={
                    isSmallScreen ? (
                      <UserPosts userData={userData} user={user} />
                    ) : (
                      <ShoppingList2
                        isShoppingListOpen={isShoppingListOpen}
                        setIsShoppingListOpen={setIsShoppingListOpen}
                      />
                    )
                  }
                />
                <Route
                  path="/shoppinglist"
                  element={
                    <ShoppingList2
                      isShoppingListOpen={isShoppingListOpen}
                      setIsShoppingListOpen={setIsShoppingListOpen}
                    />
                  }
                />
                {isSmallScreen && (
                  <Route
                    path="/posts"
                    element={<UserPosts userData={userData} user={user} />}
                  />
                )}
              </Routes>
            </div>
            {!isSmallScreen && (
              <>
                <div className="vr mx-5"></div>
                <div style={{ width: isSmallScreen ? "100%" : "100%" }}>
                  <div className="h4 mb-3">Posts</div>
                  <div
                    className="rounded-2 border hiddenScrollbar"
                    style={{ overflow: "auto", maxHeight: "71.5vh" }}
                  >
                    <UserPosts userData={userData} user={user} />
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default ProfilePage2;
