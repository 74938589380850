import { useContext, React, useEffect, useState } from "react";
import Main from "./Main";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SignUp from "./Authentication/SignUp";
import SignIn from "./Authentication/SignIn";
import ResetPassword from "./Authentication/ResetPassword";
import ForgotPassword from "./Authentication/ForgotPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContextProvider, UserAuth, InterestContext } from "./Context";
import ProductDetails from "./ProductDetails";
import Header from "./Header/Header";
import Header3 from "./Header/Header3";
import BrandsPage from "./SideNav/BrandsPage";
import UserSettings from "./Authentication/UserSettings";
import ProfilePage from "./UserProfile/ProfilePage";
import RegionBrands from "./UserProfile/RegionBrands";
import StaticPages from "./StaticPages/StaticPages";
import TermsOfService from "./StaticPages/TermsOfService";
import GenderBdInput from "./Authentication/GenderBdInput";
import ModalManager from "./Modals/ModalManager";
import PrivacyPolicy from "./StaticPages/PrivacyPolicy";
import MoreQuestions from "./Authentication/MoreQuestions";
import VerifyEmail from "./Authentication/VerifyEmail";
import ContactUs from "./ContactUs";
import PhoneAuth from "./PhoneAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "./FirebaseConfig";
import usefavStore from "./StatesStore/FavStore";
import useSaveStore from "./StatesStore/SaveStore";
import useLikeStore from "./StatesStore/LikeStore";
import ConvertLogostoUrl from "./lib/convertLogostoUrls";
import SignInInput from "./Authentication/SignInInput";
import usePointsStore from "./StatesStore/PointsTable";
import Chat from "./Chat/Chat";
import BrandPostForm from "./Brands/CreateBrandPost";
import BrandHandlerSettings from "./Brands/BrandHandlerSettings";
import TransferLogoUrlsComponent from "./lib/convertLogostoUrls";
import SecondHeader from "./Header/SecondHeader";
import SideNavUserProfile from "./SideNav/SideNavUserProfile";
import UserSettings2 from "./Authentication/UserSettings2";
import UnderAgeWarning from "./Notifications/UnderAgeWarning";
import IncomepleteProfile from "./Notifications/IncompleteProfile";
import UserSettings3 from "./Authentication/UserSettings3";
import SearchHeader from "./Header/SearchHeader";
import io from "socket.io-client";
import useSocketStore from "./StatesStore/Socket";
import LandingPage from "./LandingPage/LandingPage";
import TestingStep1 from "./LandingPage/TestingStep1";
import LandingPage2 from "./LandingPage2/LandingPage2";
import SecondHeader2 from "./Header/SecondHeader2";
import SecondHeader3 from "./Header/SecondHeader3";
import useSearchBarOpenState from "./StatesStore/SearchBarOpen";
import useChatOpen from "./StatesStore/ChatOpen";
import ChatFilter from "./Chat/ChatFilter";
import ProfilePage2 from "./UserProfile/ProfilePage2";
const queryClient = new QueryClient();
function App() {
  const { user, underAge, parentalConsent, incompleteProfile } = UserAuth(); // use the UserAuth hook to
  // const { user } = UserAuth(); // Ensure that UserAuth is used after AuthContextProvider
  const { interestedProducts, setInterestedProducts } = useContext(
    InterestContext
  );
  const location = useLocation();
  const addSave = useSaveStore((state) => state.addSave);
  const savedProducts = useSaveStore((state) => state.savedProducts);
  const addLike = useLikeStore((state) => state.addLike);
  const likedProducts = useLikeStore((state) => state.likedProducts);
  const setPointsTable = usePointsStore((state) => state.setPointsTable);
  const navigate = useNavigate();
  const setSocket = useSocketStore((state) => state.setSocket);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const { setIsSearchBarOpen } = useSearchBarOpenState();
  const { isChatOpen } = useChatOpen();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const fetchSavedData = async () => {
      if (!user) return;
      const savedPostRef = collection(db, "users", user?.uid, "saved");
      const savedPostData = await getDocs(savedPostRef);
      savedPostData.docs.map((post) => {
        addSave(post?.id);
      });
    };
    fetchSavedData();
  }, [user]);
  useEffect(() => {
    const fetchLikesData = async () => {
      if (!user) return;
      const likePostRef = collection(db, "users", user?.uid, "likeList");
      const likePostData = await getDocs(likePostRef);
      likePostData.docs.map((post) => {
        addLike(post?.id);
      });
    };
    fetchLikesData();
  }, [user]);
  useEffect(() => {
    if (!savedProducts) return;
    console.log("Save", savedProducts);
    console.log("REACT_API", process.env.REACT_APP_API);
  }, [savedProducts]);
  useEffect(() => {
    if (!likedProducts) return;
    console.log("Liked", likedProducts);
  }, [likedProducts]);

  useEffect(() => {
    const fetchPointsTable = async () => {
      try {
        // Create a query to get the most recent document based on timestamp
        const q = query(
          collection(db, "pointsTable"),
          orderBy("timestamp", "desc"), // Order by timestamp in descending order
          limit(1) // Limit the result to 1 document
        );

        const querySnapshot = await getDocs(q);

        // Fetch the first document
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          setPointsTable({ id: doc.id, ...doc.data() });
        }
      } catch (error) {
        console.error("Error fetching points table:", error);
      }
    };

    fetchPointsTable();
  }, []);
  // Redirect to SignIn if the user is null and tries to access /settings
  useEffect(() => {
    if (!user && location.pathname.startsWith("/settings")) {
      navigate("/SignIn");
    }
  }, [user, location, navigate]);
  return (
    <AuthContextProvider>
      <>
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <ModalManager />
          {location.pathname === "/SignUp" ||
          location.pathname === "/SignIn" ||
          location.pathname === "/SignInInput" ||
          location.pathname === "/TempSignInInput" ||
          location.pathname === "/Reset" ||
          location.pathname === "/UserInfo" ||
          location.pathname === "/VerifyEmail" ||
          location.pathname === "/PhoneAuth" ||
          location.pathname === "/ForgotPassword" ? (
            <div className="bg-image object-fit-scale bgImg bgImgUrl">
              <Routes>
                <Route path="/SignIn" element={<SignIn />} />
                {/* <Route path="/SignInInput" element={<GenderBdInput />} /> */}
                <Route path="/SignInInput" element={<SignInInput />} />
                <Route path="/SignUp" element={<SignUp />} />
                <Route path="/Reset" element={<ResetPassword />} />
                <Route path="/ForgotPassword" element={<ForgotPassword />} />
                {/* <Route path="/UserInfo" element={<MoreQuestions />} /> */}
                <Route path="/VerifyEmail" element={<VerifyEmail />} />
                <Route path="/PhoneAuth" element={<PhoneAuth />} />
              </Routes>
            </div>
          ) : (
            <>
              {(!isSmallScreen || !isChatOpen) && (
                <header className="navbar-light sticky-top header-static bg-transparent">
                  <InterestContext.Provider
                    value={{ interestedProducts, setInterestedProducts }}
                  >
                    <div className="d-none d-md-block">
                      <Header3 />
                    </div>
                    <div className="d-block d-md-none">
                      <Header />
                    </div>
                    {(user ||
                      (!user &&
                        location.pathname !== "/landing" &&
                        location.pathname !== "/landing2")) && (
                      <>
                        {location.pathname.includes("/chat") &&
                        isSmallScreen ? (
                          <ChatFilter />
                        ) : (
                          <SecondHeader3 />
                        )}
                      </>
                    )}
                  </InterestContext.Provider>
                </header>
              )}
              <div
                className={`${
                  location.pathname.includes("/chat") ? "" : "pt-2"
                } pt-md-0`}
              ></div>
              <div className="d-md-none">
                <div
                  className={
                    user ||
                    (location.pathname !== "/landing" &&
                      location.pathname !== "/landing2")
                      ? "pt-0"
                      : "pt-2"
                  }
                ></div>
              </div>
              <main
                id="main"
                onClick={() => {
                  setIsSearchBarOpen(false);
                }}
                style={{
                  marginTop: user
                    ? location.pathname.includes("hoppingList")
                      ? isSmallScreen
                        ? "1.8rem"
                        : "3rem"
                      : location.pathname.includes("chat")
                      ? isSmallScreen
                        ? isChatOpen
                          ? ""
                          : "2rem"
                        : "3rem"
                      : "3rem"
                    : location.pathname === "/landing" ||
                      location.pathname === "/landing2"
                    ? ""
                    : "3rem",
                }}
              >
                <div className={`container px-0 px-lg-2 ps-lg-3`} id="App">
                  {/* <style jsx>
                    {`
                      ::-webkit-scrollbar {
                        width: 8px;
                      }

                      ::-webkit-scrollbar-thumb {
                        background-color: #888;
                        border-radius: 10px;
                      }

                      ::-webkit-scrollbar-thumb:hover {
                        background-color: #555;
                      }
                    `}
                  </style> */}
                  {user &&
                  !location.pathname.includes("/userProfile") &&
                  underAge &&
                  !parentalConsent ? (
                    <div className="d-flex mb-2 mx-1 ms-2">
                      {/* <div className="col-lg-20"></div> */}
                      <UnderAgeWarning user={user} />
                      {/* <div className="col-lg-20"></div> */}
                    </div>
                  ) : null}
                  {user &&
                    !location.pathname.includes("/userProfile") &&
                    incompleteProfile && (
                      <div className="mb-2 ms-1">
                        {/* <div className="col-lg-20"></div> */}
                        <IncomepleteProfile user={user} />
                        {/* <div className="col-lg-20"></div> */}
                      </div>
                    )}
                  {/* <div className="row g-5"> */}
                  <div
                    className={`d-flex ${
                      location.pathname.includes("hoppingList") ||
                      location.pathname.includes("chat")
                        ? ""
                        : "mb-3"
                    }`}
                  >
                    {location.pathname.includes("/userProfile") && (
                      // <SideNavUserProfile />
                      <div className="col-lg-20"></div>
                    )}
                    {location.pathname.includes("/settings") && (
                      <SideNavUserProfile />
                    )}
                    <Routes>
                      <Route
                        path="/PostCreate/:id"
                        element={<BrandPostForm />}
                      />
                      <Route
                        path="/ProductDetails/:id"
                        element={<ProductDetails />}
                      />
                      <Route path="/brands" element={<BrandsPage />} />
                      <Route path="/settings/*" element={<UserSettings2 />} />
                      <Route
                        path="/testSettings/*"
                        element={<UserSettings3 />}
                      />
                      <Route
                        path="/settings/brandHandler"
                        element={<UserSettings tab={"brandHandler"} />}
                      />
                      {/* <Route
                        path="/userProfile2/:uid/*"
                        element={<ProfilePage />}
                      /> */}
                      <Route
                        path="/userProfile/:uid/*"
                        element={<ProfilePage />}
                      />
                      {/* <Route path="/chat1" element={<Chat />} /> */}
                      <Route path="/staticPages/*" element={<StaticPages />} />
                      <Route path="/*" element={<Main />} />
                      <Route path="/testingStep1" element={<TestingStep1 />} />
                      {!user && (
                        <Route path="/landing2" element={<LandingPage />} />
                      )}
                      {!user && (
                        <Route path="/landing" element={<LandingPage2 />} />
                      )}
                    </Routes>
                    {location.pathname.includes("/userProfile") && (
                      // <SideNavUserProfile />
                      <div className="col-lg-20"></div>
                    )}
                  </div>
                </div>
              </main>
            </>
          )}
        </QueryClientProvider>
      </>
    </AuthContextProvider>
  );
}
export default App;
