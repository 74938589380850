import React from "react";

const HearAboutUs = ({
  selectedOption,
  setSelectedOption,
  handleSubmission,
  handleNext,
}) => {
  const options = [
    { id: "search-engine", value: "Search Engine", label: "Search Engine" },
    { id: "social-media", value: "Social Media", label: "Social Media" },
    { id: "college-visit", value: "College Visit", label: "College Visit" },
    { id: "friends-family", value: "Friends/Family", label: "Friends/Family" },
    { id: "others", value: "Others", label: "Others" },
  ];
  const handleOptionClick = (value) => {
    setSelectedOption(value);
  };
  return (
    <>
      <div>
        <p className="mt-3 fs-4 grey">How did you hear about us?</p>
      </div>
      <form
        onSubmit={(e) => {
          handleSubmission(e);
          // handleNext();
        }}
      >
        <div id="options" className="d-flex flex-wrap justify-content-center">
          {options.map((option) => (
            <React.Fragment key={option.id}>
              <input
                type="radio"
                id={option.id}
                name="selectedOption"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => handleOptionClick(option.value)}
                style={{ display: "none" }}
              />
              <label
                htmlFor={option.id}
                className={`option-button btn ${
                  selectedOption === option.value
                    ? "selected btn-light"
                    : "btn-outline-light"
                }`}
                onClick={() => handleOptionClick(option.value)}
              >
                {option.label}
              </label>
            </React.Fragment>
          ))}
        </div>
        <div className="d-grid mt-3">
          <button type="submit" className="btn btn-primary flex-grow-1">
            Finish Registration
          </button>
        </div>
      </form>
    </>
  );
};

export default HearAboutUs;
