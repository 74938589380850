import React from "react";

const Gender = ({ gender, setGender, handleNext, userStoreUser }) => {
  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };
  const handleSubmitGender = (e) => {
    e.preventDefault();
    userStoreUser.gender = gender;
    handleNext();
  };
  return (
    <>
      <div>
        <p className="mt-3 fs-4 grey">What is your gender?</p>
      </div>
      <form onSubmit={(e) => handleSubmitGender(e)}>
        <div className="mb-3 input-group flex-grow-1">
          <select
            value={gender}
            className="form-control"
            onChange={handleChangeGender}
            required
          >
            <option value="" disabled>
              Gender
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="d-grid">
          <button type="submit" className="btn btn-primary flex-grow-1">
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default Gender;
