import React from 'react'
import BrandDisplay from '../NewsFeed/BrandDisplay';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function BrandProductsSkeleton() {
    return (
        <div className="m-1 m-sm-1">
    
          <Card>
            <div style={{ position: "relative", height: "auto",minHeight:'22em' }}>
           
              <Link to={`/Details/`}>
                <Card.Img
                  className="skeleton"
                  style={{
                    height: `22em`,
                    objectFit: "cover",
                 
                    transition: "opacity 0.5s ease-in-out",
                  }}
                
                  loading="lazy"
                />
              </Link>
            </div>
    
            {/* Price Tag */}
         
            {/* Plus Button to add  in the list */}
         
            {/* Strip */}
           
          </Card>
        
        </div>
      );
}

export default BrandProductsSkeleton