import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { Card } from "react-bootstrap";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
function BrandsPage() {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const refs = useRef({});
  const letterRef = useRef();
  useEffect(() => {
    const getBrands = async () => {
      try {
        const brandsCollectionRef = collection(db, "brands");
        const brandsQuery = query(brandsCollectionRef, orderBy("name", "asc"));
        const brandsSnapshot = await getDocs(brandsQuery);
        let brandsData = [];
        brandsSnapshot.docs.map((doc) => {
          if (doc.data().active)
            brandsData.push({
              ...doc.data(),
              id: doc.id,
            });
        });
        setBrands(brandsData);
      } catch (error) {
        console.error("Error fetching brands:", error);
      } finally {
        setLoading(false);
      }
    };

    getBrands();
  }, []);

  // Extract unique first letters from brand names
  const uniqueFirstLetters = Array.from(
    new Set(brands.map((brand) => brand.name[0]))
  ).sort();
  // Generate an array containing all English alphabets
  const allAlphabets = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode("A".charCodeAt(0) + i)
  );

  const scrollToLetter = (l, id) => {
    let targetLetter = l.toUpperCase();
    const availableLetters = uniqueFirstLetters.map((l) => l.toUpperCase());
    if (!availableLetters.includes(targetLetter)) {
      // Find the nearest available l
      const nearestLetter = availableLetters.reduce((a, b) => {
        return Math.abs(b.charCodeAt(0) - targetLetter.charCodeAt(0)) <
          Math.abs(a.charCodeAt(0) - targetLetter.charCodeAt(0))
          ? b
          : a;
      });

      // Update the targetLetter to the nearest available letter
      targetLetter = nearestLetter;
    }
    const element = refs.current[id];
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      // letterRef.current.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  };
  return (
    <>
      {/* <SideNavUserProfile /> */}
      <style jsx>
        {`
          .thinScrollBar::-webkit-scrollbar {
            display: none;
          }
          .thinScrollBar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }
          .hover-div:hover {
            background-color: rgb(245, 245, 245);
          }
          [data-bs-theme="dark"] .hover-div:hover {
            background-color: rgb(10, 10, 10);
          }
        `}
      </style>
      {/* <div className="col-md-8 col-lg-6 vstack p-0 mt-0 mt-lg-5 ms-1 "> */}
      <div className="vstack px-0 ms-0 ms-lg-1 col-12 col-lg-8">
        <Card className="bg-transparent border-0">
          <Card.Header className="p-3 rounded-2 mb-2 stickyHeader border">
            <h3 className="mb-2">Brands Directory</h3>
            <div
              className="alphabet-list navbar thinScrollBar"
              style={{ overflowX: "auto" }}
            >
              <ul className="nav d-flex flex-row flex-nowrap list-unstyled gap-2">
                {allAlphabets.map((l, index) => (
                  <li className="nav-item">
                    <button
                      to={`#${l}`}
                      key={l}
                      className={`btn ${
                        uniqueFirstLetters.includes(l)
                          ? "btn-light"
                          : "border-0"
                      } nav-link text-uppercase `}
                      onClick={() => scrollToLetter(l, index)}
                      disabled={!uniqueFirstLetters.includes(l)}
                    >
                      {l}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </Card.Header>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <Card.Body
              className="p-0 thinScrollBar bg-mode rounded-2 border"
              ref={letterRef}
            >
              {allAlphabets.map((letter, index) =>
                uniqueFirstLetters.includes(letter) ? (
                  <div
                    key={letter}
                    id={letter}
                    ref={(el) => (refs.current[index] = el)} // Store refs by item ID
                    className="hover-div"
                  >
                    <div className="d-flex gap-2 align-items-center py-3 px-2">
                      <div className="px-2 text-center">
                        <h4 className="m-0">{letter}</h4>
                      </div>
                      <div
                        className="p-0 position-relative"
                        style={{ width: isSmallScreen ? "88%" : "96%" }}
                      >
                        <div
                          className="d-flex flex-row gap-3 hiddenScrollbar"
                          style={{
                            overflow: "auto",
                            whiteSpace: "nowrap",
                            width: "100%",
                          }}
                        >
                          {brands
                            .filter((brand) => brand.name[0] === letter)
                            .map((brand) => (
                              <div key={brand.id}>
                                <ul className="list-unstyled m-0">
                                  <li key={index}>
                                    <Link
                                      to={`/BrandHome/${brand.name}`}
                                      className="d-flex flex-column align-items-center gap-2 text-center"
                                      style={{ width: "5.5rem" }}
                                    >
                                      <img
                                        className="rounded-2 position-relative avatar avatar-lg border"
                                        src={brand?.logoUrl}
                                        alt=""
                                        loading="lazy"
                                      />
                                      <div
                                        className="nav-item text-wrap h6 mb-0 fw-light"
                                        style={{
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        {brand.displayName}
                                      </div>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            ))}
                        </div>
                        {/* {brands.filter((brand) => brand.name[0] === letter)
                        .length %
                        2 !==
                        0 && (
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6 border-right"></div>
                        </div>
                      )} */}
                      </div>
                    </div>
                    <hr className="border-top border-2 my-0" />
                  </div>
                ) : (
                  <div
                    key={letter}
                    id={letter}
                    ref={(el) => (refs.current[index] = el)} // Store refs by item ID
                  ></div>
                )
              )}
            </Card.Body>
          )}
        </Card>
      </div>
    </>
  );
}

export default BrandsPage;
