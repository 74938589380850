import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Chat,
  Channel,
  MessageList,
  Window,
  Attachment,
  defaultRenderMessages,
  ChannelList,
} from "stream-chat-react";
import useChatClient from "../StatesStore/ChatClient";
import "stream-chat-react/dist/css/v2/index.css";
import { UserAuth } from "../Context";
import { Button, ButtonGroup, Card, Nav } from "react-bootstrap";
import { EmojiPicker } from "stream-chat-react/emojis";
import { encodeToMp3 } from "stream-chat-react/mp3-encoder";
import "./Chat.css";
import SideBar from "./SideBar";
import ProductCard3 from "../Products/ProductCard3";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import { ChannelHeader } from "./ChannelHeader";
import CustomMessageInput from "./CustomMessageInput";
import ProductsInChat from "./ProductsInChat";
import ChatFilter from "./ChatFilter";
import { getFilters } from "./lib.js/getFilters";
import useThemeState from "../StatesStore/Theme";
import CustomAttachment from "./CustomAttachment";
import { CustomAttachmentSelector } from "./CustomAttachmentSelector";
import useChatOpen from "../StatesStore/ChatOpen";
import useChatFilter from "../StatesStore/ChatFilter";

function ChatMobile() {
  const [loading, setLoading] = useState(true);
  const { user } = UserAuth();
  const navigate = useNavigate();
  const { chatId } = useParams();
  const client = useChatClient((state) => state.client);
  const { isChatOpen, setIsChatOpen } = useChatOpen();
  const { chatFilterType } = useChatFilter();
  const { theme } = useThemeState();

  if (!client) {
    return (
      <div className="w-100">
        <LoadingSpinner />
      </div>
    );
  }

  const containerStyle = {
    display: "flex",
    height: "93vh",
    flexDirection: "column",
    width: "100%",
    overflow: "hidden",
    position: "absolute",
    bottom: "0",
  };

  const channelListStyle = {
    flex: 1,
    maxWidth: "300px",
    borderLeft: "1px solid rounded",
    overflowY: "auto",
    backgroundColor: "#f8f9fa",
    borderRadius: "4px 0 0 4px",
  };

  const channelWindowStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    borderRadius: "0px 4px 4px 0px",
  };

  const sort = [{ pinned_at: -1 }, { last_message_at: -1 }, { updated_at: -1 }];

  const options = {
    state: true,
    presence: true,
    // limit: 10,
  };
  const customRenderText = (text) => {
    return <span>{text}</span>;
  };

  const customRenderMessages = (options) => {
    const { messages, internalMessageProps, groupedMessages } = options;
    const elements = [];

    messages.forEach((message) => {
      if (message.product) {
        elements.push(
          <li key={message.id} className="w-25 h-25">
            <ProductCard3 product={message?.product} isStrip={false} />
          </li>
        );
      } else {
        elements.push(
          defaultRenderMessages({ ...options, messages: [message] })
        );
      }
    });

    return elements;
  };

  const filters = getFilters(chatFilterType, user);

  if (!user) return;
  return (
    <>
      <style>
        {`
        .str-chat {
          --str-chat__border-radius-circle: 4px; 
          --str-chat__avatar-background-color: #dddddd;
          --str-chat__avatar-color:#222222;
           --str-chat__channel-preview-active-background-color: transparent !important;
        }
        .str-chat__message.str-chat__message--me .str-chat__message-bubble{
          background-color: transparent !important;
        }
        .str-chat__main-panel-inner, .str-chat__message-list-main-panel{
          margin-bottom: 3rem;
        }
           
      `}
      </style>
      <Chat
        client={client}
        customClasses={{
          channelList: `str-chat__theme-${theme} str-chat__channel-list`,
          channel: `str-chat__theme-${theme} str-chat__channel`,
        }}
      >
        {!isChatOpen ? (
          <Card
            className="w-100 rounded-0 p-0 pb-2 pt-0 mt-0 border-0"
            style={{ height: "100vh" }}
          >
            <Card.Body
              className="rounded-0 p-0 h-100"
              onClick={() => setIsChatOpen(true)}
            >
              <Nav className="d-flex flex-column nav-pills nav-pills-soft gap-4">
                <span
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <ChannelList
                    filters={filters}
                    sort={sort}
                    options={options}
                  />
                </span>
              </Nav>
            </Card.Body>
          </Card>
        ) : (
          <div className="w-100">
            <Channel
              EmojiPicker={EmojiPicker}
              Attachment={CustomAttachment}
              AttachmentSelector={CustomAttachmentSelector}
            >
              <Window>
                <div
                  className="bg-mode"
                  style={{ position: "sticky", top: "0", zIndex: "10" }}
                >
                  <ChannelHeader setIsChatOpen={setIsChatOpen} />
                  <ProductsInChat />
                </div>
                <div style={{ minHeight: document.body.offsetHeight - 113 }}>
                  <MessageList
                    messageActions={["edit", "delete", "quote", "react"]}
                    closeReactionSelectorOnClick={true}
                    additionalMessageInputProps={{
                      urlEnrichmentConfig: { enrichURLForPreview: true },
                    }}
                  />
                </div>
                <style jsx>
                  {`
                    .str-chat__dialog-overlay {
                      position: inherit !important;
                    }
                  `}
                </style>
                <div
                  style={{
                    position: "fixed",
                    bottom: 0,
                    zIndex: 12,
                    width: "100%",
                  }}
                >
                  <CustomMessageInput
                    audioRecordingConfig={{
                      transcoderConfig: { encoder: encodeToMp3 },
                    }}
                    audioRecordingEnabled
                    urlEnrichmentConfig={{ enrichURLForPreview: true }}
                  />
                </div>
              </Window>
            </Channel>
          </div>
        )}
      </Chat>
    </>
  );
}

export default ChatMobile;
