import { create } from 'zustand';

const useBrandFilter = create((set) => ({
   BrandFilter: {
    brand:[],
    color : [],
    size : [],
    category : [],
    subCategory : [],
    subSubCategory : [],
    minprice : 0,
    maxprice : 90000,
   },
  setBrandFilter: (filter) => set((state) => ({...state, BrandFilter: filter })),
}));

export default useBrandFilter;

