import React from "react";

const Brands = ({ brands, setBrands, handleNext, allBrands }) => {
  const handleBrandClick = (brand) => {
    // If brand is already added, remove it; otherwise, add it
    if (brands.includes(brand.name)) {
      setBrands((prevBrands) =>
        prevBrands.filter((item) => item !== brand.name)
      );
    } else {
      setBrands((prevBrands) => [...prevBrands, brand.name]);
    }
  };
  const handleSubmitBrands = (e) => {
    e.preventDefault();
    handleNext();
  };

  return (
    <>
      <div>
        <p className="mt-3 fs-4 grey">What are your favourite brands?</p>
      </div>
      <form onSubmit={(e) => handleSubmitBrands(e)}>
        <ul
          className="list-inline d-flex flex-wrap gap-3 ms-4 ps-3 mb-3"
          style={{ maxHeight: "19.5rem", overflowY: "auto" }}
        >
          {allBrands.map((brand, index) => (
            <li key={index} onClick={() => handleBrandClick(brand)}>
              <div
                className={`d-flex flex-column align-items-center gap-2 text-center hoverClass rounded-2`}
                style={{
                  width: "7rem",
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    right: "1.2rem",
                  }}
                >
                  {brands.includes(brand.name) && (
                    <i className="bi bi-check-circle-fill text-primary fs-5"></i>
                  )}
                </div>

                <img
                  className="rounded-2 position-relative avatar avatar-xl border"
                  src={brand?.logoUrl}
                  alt=""
                  loading="lazy"
                />
                <div
                  className="nav-item text-wrap h6 mb-0 fw-light"
                  style={{
                    wordWrap: "break-word",
                  }}
                >
                  {brand.label}
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="d-grid mt-3">
          <button
            type="submit"
            className="btn btn-primary flex-grow-1"
            disabled={brands.length < 3}
          >
            {brands.length < 3 ? "Select atleast 3" : "Next"}
          </button>
        </div>
      </form>
    </>
  );
};

export default Brands;
