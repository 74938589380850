import React, { useEffect, useRef, useState } from "react";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProductCard5 from "../Products/ProductCard5";
import { decryptChat, encryptChat } from "../ShoppingList/Encryption";
import useChatClient from "../StatesStore/ChatClient";
import { generateChatId } from "./lib.js/generateChatId";
import { Chat, Channel, ChannelHeader, MessageInput, MessageList, Thread, Window, ChannelList, Attachment } from "stream-chat-react";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import { EmojiPicker } from "stream-chat-react/emojis";
import { encodeToMp3 } from 'stream-chat-react/mp3-encoder';
import ProductCard3 from "../Products/ProductCard3";
function ChatToast({ isOpen, onClose, userData, user, uid }) {
  const [msg, setMsg] = useState("");
  const [msgs, setMsgs] = useState([]);
  const [isRecipientOnline, setIsRecipientOnline] = useState(false);
  const [channel, setChannel] = useState(null);
  const client = useChatClient((state) => state.client);

  useEffect(() => {
    if (!client || !user || !uid) return;

    const setupChannel = async () => {
      try {
        // Create a unique channel ID for the conversation
        const channelId = generateChatId(user.uid, uid)

        // Initialize the channel
        const channel = client.channel('messaging', channelId, {
          members: [user.uid, uid],
        });

        await channel.watch();

        // Listen for new messages
        channel?.on('message.new', (event) => {
          setMsgs((prevMsgs) => [...prevMsgs, {
            content: event.message.text,
            senderId: event.user.id,
            type: 'text',
            timestamp: new Date(event.created_at)
          }]);
        });

        // Listen for presence changes
        client?.on('user.presence.changed', (event) => {
          if (event.user.id !== user.uid) {
            setIsRecipientOnline(event.user.online);
          }
        });
        const response1 = await channel.query({
          messages: { limit: 100 },
          presence: true,
          state: true,
        });

        // Get initial online status of members
        const members = response1.members;
        console.log('members', members);

        members.forEach(member => {
          if (member.user.id !== user.uid) {
            setIsRecipientOnline(member.user.online);
          }
        });
        // Load previous messages
        const response = await channel.query({ messages: { limit: 50 } });
        const formattedMessages = response.messages.map(message => ({
          content: message.text,
          senderId: message.user.id,
          type: 'text',
          timestamp: new Date(message.created_at)
        }));

        setMsgs(formattedMessages);
        setChannel(channel);
      } catch (error) {
        console.error("Error setting up Stream channel:", error);
      }
    };

    setupChannel();

    return () => {
      if (channel) {
        channel.stopWatching();
      }
    };
  }, [client, user, uid]);

  const sendMsg = async (e) => {
    e.preventDefault();
    // scrollToBottom();

    if (msg?.trim() === "" || !channel) return;

    const encryptedMsg = encryptChat(msg);

    try {
      await channel.sendMessage({
        text: msg,
      });

      setMsg("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  if (!client) {
    return null;
  }
  const containerStyle = {
    display: "flex",
    height: "50vh",
    width: "100%",
    overflow: "hidden",
    gap: "10px",
  };

  const channelListStyle = {
    flex: 1,
    maxWidth: "300px",
    borderLeft: "1px solid rounded",
    overflowY: "auto",
    backgroundColor: "#f8f9fa",
    borderRadius: "4px 0 0 4px",
  };

  const channelWindowStyle = {
    flex: 3,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    borderRadius: "0px 4px 4px 0px",
  };
  const CustomAttachment = (props) => {
    const { attachments } = props;
    const [attachment] = (attachments || []);
    if (attachment?.type === 'product') {
      return (
        <ProductCard3 product={attachment?.product} isStrip={false} />
      );
    }

    return <Attachment {...props} />;
  };
  return (
    <>
      <style>
        {`
        .str-chat {
   
          --str-chat__border-radius-circle: 4px; 
          --str-chat__avatar-background-color: #dddddd;
          --str-chat__avatar-color:#222222;
           --str-chat__channel-preview-active-background-color: #e7f0fd;
        }
           
      `}
      </style>
      <div
        aria-live="polite"
        aria-atomic="true"
        className="position-relative"
        data-bs-scroll="true"
      >
        <div className="toast-container toast-chat d-flex gap-3 align-items-end custom-scrollbar">
          <div
            id="chatToast"
            className={`toast mb-0 bg-mode ${isOpen ? "show" : ""}`}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-bs-autohide="false"
          >
            <div className="toast-header bg-mode">
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex">
                  <div className="flex-shrink-0 avatar me-2 ">
                    <ProfilePicture
                      userData={userData}
                      className="avatar-img rounded"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mb-0 mt-1">{userData?.userName}</h6>
                    <div className="small text-secondary">
                      <i
                        className={`fa-solid fa-circle ${isRecipientOnline ? "text-success" : "text-muted"
                          } me-1`}
                      ></i>
                      {isRecipientOnline ? "Online" : "Offline"}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <a
                    className="btn btn-secondary-soft-hover py-1 px-2"
                    data-bs-toggle="collapse"
                    href="#collapseChat"
                    aria-expanded="false"
                    aria-controls="collapseChat"
                  >
                    <i className="bi bi-dash-lg"></i>
                  </a>
                  <button
                    className="btn btn-secondary-soft-hover py-1 px-2"
                    onClick={onClose}
                    aria-label="Close"
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="toast-body p-0 collapse show" id="collapseChat">
              <div className="">
                <Chat client={client} theme="">
                  <div style={containerStyle}>

                    <div style={channelWindowStyle}>
                      <Channel channel={channel} EmojiPicker={EmojiPicker} Attachment={CustomAttachment}>
                        <Window>
                          <MessageList messageActions={['edit', 'delete', 'quote', 'react']} closeReactionSelectorOnClick={true}
                          />
                          <MessageInput focus audioRecordingConfig={{ transcoderConfig: { encoder: encodeToMp3 } }}
                            audioRecordingEnabled />
                        </Window>

                      </Channel>
                    </div>
                  </div>
                </Chat>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatToast;