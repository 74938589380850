import React from "react";
import {
  AttachmentSelector,
  defaultAttachmentSelectorActionSet,
} from "stream-chat-react";

import { ShoppingListModalContent } from "./ShoppingListModalContent";
import { ProductsModalContent } from "./ProductsModalContent";
import {
  ProductsAttachmentAction,
  ShoppingListAttachmentAction,
} from "./AttachmentAction";

const attachmentSelectorActionSet = [
  {
    ActionButton: ShoppingListAttachmentAction,
    ModalContent: ShoppingListModalContent,
    type: "shoppingList",
  },
  {
    ActionButton: ProductsAttachmentAction,
    ModalContent: ProductsModalContent,
    type: "products",
  },
  ...defaultAttachmentSelectorActionSet,
];

export const CustomAttachmentSelector = () => (
  <>
    <style jsx>
      {`
        .str-chat__modal.str-chat__create-poll-modal .str-chat__modal__inner {
          display: flex !important;
        }
      `}
    </style>
    <AttachmentSelector
      attachmentSelectorActionSet={attachmentSelectorActionSet}
    />
  </>
);
