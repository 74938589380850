import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "./Products/ProductCard";
import fetchProducts from "./lib/productUtils";
import useActiveBrandsStore from "./StatesStore/ActiveBrands";
import { getCategoryDisplayName } from "./lib/getCategoryName";
import SideNavAllProducts from "./SideNav/SideNavAllProducts"; // Importing the SideNav for filters
import useBrandFilter from "./StatesStore/BrandFilters";
import {
  ArrowDownUp,
  ChevronDown,
  Funnel,
  List,
  Sliders,
} from "react-bootstrap-icons";
import ProductCard5 from "./Products/ProductCard5";
import ProductCard6 from "./Products/ProductCard6";
import { Accordion } from "react-bootstrap";
import { InputNumber, Slider } from "antd";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "./FirebaseConfig";

function AllProducts() {
  let { subCategory } = useParams();
  let { subSubCategory } = useParams();
  const { BrandFilter, setBrandFilter } = useBrandFilter();
  let { category } = useParams();
  let { catLabel: categoryLabel } = "";

  if (typeof subCategory === "undefined") {
    categoryLabel = "All Products";
  } else {
    // Map category names to display names
    const mappedSubCategory = getCategoryDisplayName(subCategory);
    const mappedSubSubCategory = getCategoryDisplayName(subSubCategory);

    categoryLabel = mappedSubCategory + " - " + mappedSubSubCategory;
  }
  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [filter, setFilter] = useState("NF");
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countLeft, setCountLeft] = useState(true);
  // const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 900);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [range, setRange] = useState([0, 90000]); // Initial value set to [0, 90000]
  const [alphabeticSizes, setAlphabeticSizes] = useState([]);
  const [numericSizes, setNumericSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setBrandFilter({
      brand: [],
      color: [],
      size: [],
      category: [],
      subCategory: [],
      subSubCategory: [],
      minprice: 0,
      maxprice: 90000,
    });
  }, []);

  // Filter state from SideNav
  useEffect(() => {
    if (!category || !subCategory || !subSubCategory) return;
    const getBrands = async () => {
      try {
        const brandsCollectionRef = collection(db, "brands");
        const brandsQuery = query(brandsCollectionRef, orderBy("name", "asc"));
        const brandsSnapshot = await getDocs(brandsQuery);
        const brandsData = brandsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setBrands(brandsData);
      } catch (error) {
        console.error("Error fetching brands:", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchSizes = async () => {
      try {
        const sizesCollectionRef = collection(db, "sizes");
        const sizesSnapshot = await getDocs(sizesCollectionRef);

        let alphabeticSizes = [];
        let numericSizes = [];

        sizesSnapshot.forEach((doc) => {
          const data = doc.data();
          if (doc.id === "type1") {
            alphabeticSizes = data.Alphabetic;
          } else if (doc.id === "type2") {
            numericSizes = data.Numbers;
          }
        });

        setAlphabeticSizes(alphabeticSizes);
        setNumericSizes(numericSizes);
      } catch (error) {
        console.error("Error fetching sizes:", error);
      }
    };
    const fetchColors = async () => {
      try {
        const colorsCollectionRef = doc(db, "colors", "Colors");
        const colorsDoc = await getDoc(colorsCollectionRef);

        if (colorsDoc.exists()) {
          setColors(colorsDoc.data().Colors);
        } else {
          console.error("No colors document found!");
        }
      } catch (error) {
        console.error("Error fetching colors:", error);
      }
    };
    getBrands();
    fetchSizes();
    fetchColors();
    setCurrentPage(1);
    setAllProducts([]);
    setLastVisible(null);
  }, [subCategory, subSubCategory, category]);

  console.log(
    "From All Products subCategory, subSubCategory, category",
    subCategory,
    subSubCategory,
    category
  );

  useEffect(() => {
    // Ensure each of category, subCategory, and subSubCategory are arrays
    const ensureArray = (value) => (Array.isArray(value) ? value : [value]);

    fetchProducts({
      brandName: BrandFilter.brand[0],
      category: ensureArray(category),
      subCategory: ensureArray(subCategory),
      subSubCategory: ensureArray(subSubCategory),
      lastVisible,
      currentPage,
      setAllProducts,
      setLoading,
      setLastVisible,
      setCountLeft,
      filter,
      sizes: BrandFilter.size,
      colors: BrandFilter.color,
      minprice: BrandFilter.minprice,
      maxprice: BrandFilter.maxprice,
    });
  }, [
    subCategory,
    subSubCategory,
    currentPage,
    category,
    filter,
    BrandFilter.brand,
    BrandFilter.size,
    BrandFilter.color,
    BrandFilter.minprice,
    BrandFilter.maxprice,
    BrandFilter,
  ]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  allProducts.sort((a, b) => {
    switch (filter) {
      case "PL":
        return parseInt(a.newPrice) - parseInt(b.newPrice);
      case "PH":
        return parseInt(b.newPrice) - parseInt(a.newPrice);
      case "DL":
        return parseInt(a.discount) - parseInt(b.discount);
      case "DH":
        return parseInt(b.discount) - parseInt(a.discount);
      default:
        // Return default sorting by dateCreated if needed
        return 0;
    }
  });
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const filters = [
    { value: "NF", name: "Newest First" },
    { value: "PL", name: "Price - Low to High" },
    { value: "PH", name: "Price - High to Low" },
    { value: "DL", name: "Discount - Low to High" },
    { value: "DH", name: "Discount - High to Low" },
  ];
  const handleBrandChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = BrandFilter;
    let updatedBrands = [...currentFilter.brand];

    if (checked) {
      updatedBrands.push(name);
    } else {
      updatedBrands = updatedBrands.filter((brand) => brand !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      brand: updatedBrands,
    };

    setBrandFilter(updatedFilter);
    // console.log('Current filter state in Brands:', updatedFilter);
  };
  // Function to handle changes in the slider value
  const handleRangeChange = (newRange) => {
    setRange(newRange);
    const updatedFilter = {
      ...BrandFilter,
      minprice: newRange[0],
      maxprice: newRange[1],
    };
    setBrandFilter(updatedFilter);
    // console.log('Current filter state:', updatedFilter);
  };
  // Function to handle changes in the min value input box
  const handleMinInputChange = (value) => {
    const newRange = [value, range[1]];
    setRange(newRange);
    const updatedFilter = {
      ...BrandFilter,
      minprice: value,
      maxprice: range[1],
    };
    setBrandFilter(updatedFilter);
  };
  // Function to handle changes in the max value input box
  const handleMaxInputChange = (value) => {
    const newRange = [range[0], value];
    setRange(newRange);
    const updatedFilter = {
      ...BrandFilter,
      minprice: range[0],
      maxprice: value,
    };
    setBrandFilter(updatedFilter);
  };
  const handleSizeChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedSize = [...currentFilter.size];

    if (checked) {
      updatedSize.push(name);
    } else {
      updatedSize = updatedSize.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      size: updatedSize,
    };

    setBrandFilter(updatedFilter);
  };
  const handleColorChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedColor = [...currentFilter.color];

    if (checked) {
      updatedColor.push(name);
    } else {
      updatedColor = updatedColor.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      color: updatedColor,
    };

    setBrandFilter(updatedFilter);
  };
  return (
    <>
      {/* Side navigation for filters */}

      {/* <div className="col-md-8 col-lg-6 vstack gap-4"> */}
      <div className="vstack gap-0 ms-0 ms-lg-1">
        <div className="card bg-transparent border-0">
          {/* Card header START */}
          <div className="card-header d-flex d-md-none align-items-center justify-content-around border-0 p-0 mb-3 rounded-2 stickyHeader">
            <style jsx>{`
              #flush-headingMobile .accordion-button::after {
                display: none;
              }
            `}</style>

            <div
              className="d-flex align-items-center gap-2 py-2 ps-5"
              style={{ width: "11rem" }}
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasAllProductsSideNav"
              aria-controls="offcanvasAllProductsSideNav"
            >
              <Funnel className="fs-5" />
              <span>Filters</span>
            </div>
            <div className="vr m-0 p-0" />
            <div className="py-2 ps-2" style={{ width: "12rem" }}>
              <div
                className="accordion border-0 py-0 position-static rounded-end-2"
                style={{ width: "13rem" }}
              >
                <h2
                  className="accordion-header"
                  style={{ padding: "0rem 2rem 0rem 2.5rem" }}
                  id="flush-headingMobile"
                >
                  <button
                    className="accordion-button collapsed d-flex align-items-center gap-2 ps-3"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseMobile"
                    aria-expanded="false"
                    aria-controls="flush-collapseMobile"
                  >
                    <ArrowDownUp className="fs-5" />
                    Sort
                  </button>
                </h2>
                <div
                  id="flush-collapseMobile"
                  style={{ width: "12.7rem" }}
                  className="accordion-collapse collapse position-absolute top-100 end-0 bg-white shadow rounded-bottom-2"
                  aria-labelledby="flush-headingMobile"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body px-0 pt-2 pb-0">
                    <div
                      className="overflow-auto"
                      style={{ maxHeight: "300px" }}
                    >
                      {filters.map((filterOption) => (
                        <>
                          <div
                            key={filterOption.value}
                            className="text-wrap d-flex justify-content-start align-items-center my-2 px-2"
                            onClick={() => {
                              setFilter(filterOption.value);
                              const accordionButton = document.querySelector(
                                '[data-bs-target="#flush-collapseMobile"]'
                              );
                              accordionButton.click();
                            }}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={filter === filterOption.value}
                            />
                            <label className="form-check-label mx-2">
                              {filterOption.name}
                            </label>
                          </div>
                          <hr className="m-0 p-0" />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="border mb-3 hiddenScrollbar 
            card-header d-none d-md-flex flex-lg-row align-items-start justify-content-between p-0 rounded-2 stickyHeader"
          >
            <h1
              className="card-title h4 text-start mb-0 p-2 pb-1 text-truncate"
              style={{ maxWidth: document.body.offsetWidth - 742 }}
            >
              {categoryLabel}
            </h1>
            <Accordion
              className="d-flex align-items-center position-relative filter-accordion border-lg-top "
              flush
              activeKey={activeAccordion ?? null}
            >
              <style jsx>{`
                .filter-accordion .accordion-button::after {
                  display: none;
                }
                .accordion-button:not(.collapsed) .accordion-chevron {
                  transform: rotate(180deg) !important;
                }
              `}</style>

              {/* <div className="vr m-0 p-0" /> */}

              <Accordion.Item
                className="border border-top-0 border-bottom-0 py-2 position-static"
                style={{ width: "9rem" }}
                eventKey={3}
              >
                <Accordion.Header
                  onClick={() => toggleAccordion(3)}
                  style={{ padding: "0rem 2rem 0rem 2.5rem" }}
                >
                  Brands
                  <ChevronDown
                    className="ms-2 accordion-chevron"
                    style={{
                      transition: "transform 0.2s ease-in-out",
                      transform: "rotate(0deg)",
                    }}
                  />
                </Accordion.Header>
                <Accordion.Body
                  // style={{ width: "9rem" }}
                  className="position-absolute top-100 bg-white shadow rounded-bottom-2 pe-1"
                >
                  <ul
                    className="p-0 brand-filter list-unstyled"
                    aria-labelledby="brandMenu"
                  >
                    <div
                      className="overflow-auto"
                      style={{ maxHeight: "300px" }}
                    >
                      {brands.map((brand) => (
                        <div
                          key={brand.id}
                          className="text-wrap align-items-center my-2"
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name={brand.id}
                            onChange={handleBrandChange}
                            checked={BrandFilter.brand.includes(brand.id)}
                          />
                          <label className="form-check-label mx-2">
                            {brand.displayName}
                          </label>
                        </div>
                      ))}
                    </div>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              {/* <div className="vr m-0 p-0" /> */}

              <Accordion.Item
                className="border border-top-0 border-bottom-0 py-2 position-static"
                style={{ width: "9rem" }}
                eventKey={4}
              >
                <Accordion.Header
                  onClick={() => toggleAccordion(4)}
                  style={{ padding: "0rem 2rem 0rem 2.5rem" }}
                >
                  Price
                  <ChevronDown
                    className="ms-2 accordion-chevron"
                    style={{
                      transition: "transform 0.2s ease-in-out",
                      transform: "rotate(0deg)",
                    }}
                  />
                </Accordion.Header>
                <Accordion.Body
                  style={{ width: "9rem" }}
                  className="position-absolute top-100 bg-white shadow rounded-bottom-2"
                >
                  <ul
                    className="p-0 price-filter list-unstyled"
                    aria-labelledby="priceMenu"
                  >
                    <div className="flex-1 justify-content-center">
                      <div className="rounded-5 mb-3">
                        <Slider
                          range
                          value={range}
                          onChange={handleRangeChange}
                          min={0}
                          max={90000}
                          step={500}
                          tipFormatter={(value) => `${value}`}
                        />
                      </div>

                      <div className="d-flex gap-2">
                        <div className="d-flex flex-column">
                          <label>Min</label>
                          <InputNumber
                            className="w-100"
                            min={0}
                            max={90000}
                            value={range[0]}
                            onChange={handleMinInputChange}
                            placeholder={range[0]}
                          />
                        </div>
                        <div className="d-flex flex-column">
                          <label>Max</label>
                          <InputNumber
                            className="w-100"
                            min={0}
                            max={90000}
                            value={range[1]}
                            onChange={handleMaxInputChange}
                            placeholder={range[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              {/* <div className="vr m-0 p-0" /> */}

              <Accordion.Item
                className="border border-top-0 border-bottom-0 py-2 position-static"
                style={{ width: "9rem" }}
                eventKey={5}
              >
                <Accordion.Header
                  onClick={() => toggleAccordion(5)}
                  style={{ padding: "0rem 2rem 0rem 2.5rem" }}
                >
                  Size
                  <ChevronDown
                    className="ms-2 accordion-chevron"
                    style={{
                      transition: "transform 0.2s ease-in-out",
                      transform: "rotate(0deg)",
                    }}
                  />
                </Accordion.Header>
                <Accordion.Body
                  style={{ width: "9rem" }}
                  className="position-absolute top-100 bg-white shadow rounded-bottom-2"
                >
                  <ul
                    className="p-0 m-0 size-filter list-unstyled"
                    aria-labelledby="sizeMenu"
                  >
                    {alphabeticSizes.length > 0 && (
                      <div
                        className="overflow-auto"
                        style={{ maxHeight: "300px" }}
                      >
                        <div className="d-flex">
                          <div className="me-4">
                            {alphabeticSizes?.map((size) => (
                              <div
                                key={size}
                                className="text-wrap justify-content-start align-items-center my-2"
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name={size}
                                  onChange={handleSizeChange}
                                  checked={BrandFilter.size.includes(size)}
                                />
                                <label className="form-check-label mx-2">
                                  {size}
                                </label>
                              </div>
                            ))}
                          </div>
                          <div>
                            {numericSizes?.map((size) => (
                              <div
                                key={size}
                                className="text-wrap justify-content-start align-items-center my-2"
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name={size}
                                  onChange={handleSizeChange}
                                  checked={BrandFilter.size.includes(size)}
                                />
                                <label className="form-check-label mx-2">
                                  {size}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              {/* <div className="vr m-0 p-0" /> */}

              <Accordion.Item
                className="border border-top-0 border-bottom-0 py-2 position-static"
                style={{ width: "9rem" }}
                eventKey={6}
              >
                <Accordion.Header
                  onClick={() => toggleAccordion(6)}
                  style={{ padding: "0rem 2rem 0rem 2.5rem" }}
                >
                  Color
                  <ChevronDown
                    className="ms-2 accordion-chevron"
                    style={{
                      transition: "transform 0.2s ease-in-out",
                      transform: "rotate(0deg)",
                    }}
                  />
                </Accordion.Header>
                <Accordion.Body
                  // style={{ width: "10.25rem" }}
                  className="position-absolute top-100 bg-white shadow rounded-bottom-2 pe-1"
                >
                  <ul
                    className="p-0 m-0 color-filter list-unstyled"
                    aria-labelledby="colorMenu"
                  >
                    <div
                      className="overflow-auto"
                      style={{ maxHeight: "300px" }}
                    >
                      <div className="d-flex flex-column">
                        {colors?.map((color) => (
                          <div
                            key={color}
                            className="text-wrap justify-content-start align-items-center my-2"
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name={color}
                              onChange={handleColorChange}
                              checked={BrandFilter.color.includes(color)}
                            />
                            <label className="form-check-label mx-2">
                              {color}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              {/* <div className="vr m-0 p-0" /> */}

              <Accordion.Item
                className="border border-top-0 border-bottom-0 py-2 position-static rounded-end-2"
                style={{ width: "9rem" }}
                eventKey={7}
              >
                <Accordion.Header
                  onClick={() => toggleAccordion(7)}
                  style={{ padding: "0rem 2rem 0rem 3rem" }}
                >
                  <ArrowDownUp className="fs-5 me-2" />
                  Sort
                </Accordion.Header>
                <Accordion.Body
                  // style={{ width: "12rem" }}
                  className="position-absolute top-100 end-0 bg-white shadow p-0 pt-1"
                >
                  <div className="overflow-auto" style={{ maxHeight: "300px" }}>
                    {filters.map((filterOption) => (
                      <>
                        <div
                          key={filterOption.value}
                          className="text-wrap d-flex justify-content-start align-items-center my-2 px-2"
                          onClick={() => {
                            setFilter(filterOption.value);
                          }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={filter === filterOption.value}
                          />
                          <label className="form-check-label mx-2">
                            {filterOption.name}
                          </label>
                        </div>
                        <hr className="m-0 p-0" />
                      </>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>

          {/* Card body START */}
          <div className="card-body px-2 pt-0 pb-2">
            <div className="row">
              {/* Product item START */}
              {allProducts.map((product, index) => {
                return (
                  <div className="col-6 col-sm-4 col-md-3 col-lg-20 px-0">
                    <ProductCard6 key={index} product={product} />
                  </div>
                );
              })}
              {/* Product item END */}
            </div>
          </div>
          {/* Card body END */}
        </div>

        <button
          className={`btn btn-loader  mt-12 btn-primary-soft ${
            loading ||
            !countLeft ||
            allProducts.length < currentPage * itemsPerPage
              ? "disabled"
              : ""
          }`}
          onClick={
            !countLeft
              ? null
              : () => {
                  if (!loading) {
                    setLoading(true);
                    setCurrentPage((prevValue) => prevValue + 1);
                  }
                }
          }
        >
          {loading ? (
            <div className="spinner-grow spinner-grow-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span className="load-text">
              {countLeft ? "Load more" : "No More products to show"}
            </span>
          )}
        </button>
      </div>
      <SideNavAllProducts />
    </>
  );
}

export default AllProducts;
