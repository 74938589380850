import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import { useParams, useLocation } from "react-router-dom";
import useSearchedProducts from "../StatesStore/searchedProducts";
import useSearchFilter from "../StatesStore/SearchFilters";
import SideNavSearch from "../SideNav/SideNavSearch";
import { Funnel } from "react-bootstrap-icons";
import ProductCard6 from "../Products/ProductCard6";
import ProductGoogleAdCard from "../Cards/ProductGoogleAdCard";
import ProductGoogleAdCardMobile from "../Cards/ProductGoogleAdCardMobile";

//algolia object initialization and index mapping
const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);

const index = client.initIndex("products"); //name of index in algolia is products

export default function Products() {
  //**********************  Router Functions  **********************************//
  const location = useLocation();
  const { input } = useParams();
  const products = useSearchedProducts((state) => state.products);

  //*********************** other states     *************************************/
  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(9);
  const [loading, setLoading] = useState(true);
  const [countLeftProduct, setCountLeftProduct] = useState(false);
  const [paramInput, setParamInput] = useState(input);
  const [totalProductCount, setTotalProductCount] = useState(0); //total product count

  const [result, setResult] = useState(false);
  const setSearchedProduct = useSearchedProducts(
    (state) => state.setSearchedProduct
  );
  const searchFilter = useSearchFilter((state) => state.SearchFilter);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  //******************************* Functions **********************************************/
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    console.log("Page count in useeffect", currentPage);
    setResult(false);
    setAllProducts([]);
    setSearchedProduct([]);
    setCurrentPage(0);
    setParamInput(input);
    retrieveData(0);
    setResult(true);
    setCurrentPage(currentPage + 1);
  }, [location.pathname, input]); //only updates when user refreshes

  //**********************   Handle Load More ********************************************/
  const handleLoad = () => {
    if (!loading) {
      setResult(false);
      setLoading(true);
      setCurrentPage(currentPage + 1);
      retrieveData(currentPage + 1); // Fetch data for the next page
      setResult(true);
    }
  };
  useEffect(() => {
    setResult(false);

    setCurrentPage(0);
    retrieveData(0);
    setResult(true);
  }, [searchFilter]);
  // ****************** async call to retrieve data **************************************/

  const retrieveData = async (page) => {
    if (input.length >= 3) {
      try {
        const offset = page * itemsPerPage;

        let filters = [];

        if (searchFilter.gender.length) {
          filters.push(
            `category:${searchFilter.gender.map((g) => `"${g}"`).join(" OR ")}`
          );
        }
        if (searchFilter.brand.length) {
          filters.push(
            `supplier:${searchFilter.brand.map((b) => `"${b}"`).join(" OR ")}`
          );
        }
        if (searchFilter.subCategory.length) {
          filters.push(
            `subCategory:${searchFilter.subCategory
              .map((sc) => `"${sc}"`)
              .join(" OR ")}`
          );
        }
        if (searchFilter.subSubCategory.length) {
          filters.push(
            `subSubCategory:${searchFilter.subSubCategory
              .map((ssc) => `"${ssc}"`)
              .join(" OR ")}`
          );
        }
        if (searchFilter.size.length) {
          filters.push(
            `sizes:${searchFilter.size.map((s) => `"${s}"`).join(" OR ")}`
          );
        }
        if (searchFilter.color.length) {
          filters.push(
            `colors:${searchFilter.color.map((c) => `"${c}"`).join(" OR ")}`
          );
        }
        filters.push(
          `newPrice >= ${searchFilter.minprice} AND newPrice <= ${searchFilter.maxprice}`
        );

        const data = await index.search(input, {
          offset,
          length: itemsPerPage,
          filters: filters.join(" AND "),
        });

        setLoading(false);
        setAllProducts([...allProducts, data.hits]);
        setTotalProductCount(data.nbHits);
        setSearchedProduct(data.hits);
        setCountLeftProduct(data.nbHits > data.offset);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };

  // console.log('global products in search 1111', products);
  console.log("products in search 1111", products);
  console.log("searchFilter in search 1111", searchFilter);
  const filteredProducts = products.filter(
    (product) =>
      (searchFilter.gender.length === 0 ||
        searchFilter.gender.includes(product.category)) &&
      (searchFilter.brand.length === 0 ||
        searchFilter.brand.includes(product.supplier)) &&
      (searchFilter.subCategory.length === 0 ||
        searchFilter.subCategory.includes(product.subCategory)) &&
      (searchFilter.subSubCategory.length === 0 ||
        searchFilter.subSubCategory.includes(product.subSubCategory)) &&
      (searchFilter.size.length === 0 ||
        (product.sizes &&
          product.sizes.some((size) => searchFilter.size.includes(size)))) &&
      (searchFilter.color.length === 0 ||
        (product.colors &&
          product.colors.some((color) =>
            searchFilter.color.includes(color)
          ))) &&
      searchFilter.minprice <= product.newPrice &&
      searchFilter.maxprice >= product.newPrice
  );
  console.log("value of current page :", currentPage);

  console.log("value of countLeft page :", countLeftProduct);
  const isMobileScreen = window.innerWidth <= 768;
  return (
    <>
      <div className="vstack gap-4">
        <div className="productsSection border-0 card p-0 bg-transparent">
          <div className="card-header border-0 p-2 rounded-2 stickyHeader">
            {result && (
              <div className="card-title d-flex justify-content-between align-items-center mb-0">
                <h3 className="m-0">
                  {totalProductCount} product
                  {totalProductCount > 1 ? "s" : ""} found from search results
                </h3>
                <button
                  className="border-0 rounded-2 me-1 d-flex align-items-center pe-3"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasSideSearchbar"
                  aria-controls="offcanvasSideSearchbar"
                >
                  <span className="btn btn-mode p-1 p-sm-2">
                    {/* <span className="navbar-toggler-icon"> */}
                    {/* <span className="line"></span>
                      <span className="line"></span>
                      <span className="line"></span> */}
                    {/* </span> */}
                    <Funnel className="fs-4" />
                  </span>
                  <div className="h6 mb-0 fw-light">Filters</div>
                </button>
              </div>
            )}
          </div>
          <div className="card-body px-2 mx-1 mx-lg-0 pb-0 mt-1 py-0">
            <div className="row g-4 mt-0">
              {paramInput.length >= 3 && filteredProducts.length ? (
                filteredProducts.map((product, index) => (
                  <>
                   {
                    (index + 1) % 9 === 0 && index !== 0 ? (
                      isMobileScreen ? (
                        <ProductGoogleAdCardMobile />
                      ) : (
                        <ProductGoogleAdCard />
                      )
                    ) : null
                  }
                  <div
                    className={`col-6 col-sm-4 col-md-3 col-lg-20 ${
                      index % 2 === 0 ? "ps-0 pe-1" : "ps-1 pe-0"
                    } px-md-1 mt-2 mt-lg-1`}
                  >
                    <ProductCard6 key={index} product={product} />
                  </div>
                    </>
                ))
              ) : (
                <div>
                  {paramInput.length >= 3
                    ? "No products match your search criteria."
                    : "Need at least three characters to search"}
                </div>
              )}
            </div>
          </div>
        </div>
        {totalProductCount > 0 && (
          <button
            className={`btn btn-loader btn-primary-soft ${
              loading ||
              !countLeftProduct ||
              totalProductCount <= currentPage * itemsPerPage
                ? "disabled"
                : ""
            }`}
            onClick={handleLoad}
          >
            {/* Loading button condition */}
            {loading ? (
              <div className="spinner-grow spinner-grow-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <span className="load-text">
                {countLeftProduct ? "Load more" : "No more products to show"}
              </span>
            )}
          </button>
        )}
      </div>
      <SideNavSearch /> {/* Always display the side nav */}
    </>
  );
}
