import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { getBaseURL } from "../lib/getBaseURL";
import { UserAuth } from "../Context";
import ShoppingListProductCard from "../UserProfile/ProfileShoppingList/ShoppingListProductCard";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import { db } from "../FirebaseConfig";
import ListShareMenu from "../components/ListShareMenu";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { Link } from "react-router-dom";
const ShoppingList = ({ list }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstThreeProducts, setFirstThreeProducts] = useState([]);
  const baseURL = getBaseURL();
  const { user } = UserAuth();
  const { userData } = useFetchUserData(list?.ownerId);
  // console.log("userData", userData);
  const fetchProducts = async (product) => {
    if (product?.id) {
      const productRef = doc(db, "products", product?.id);
      const productSnapshot = await getDoc(productRef);
      if (productSnapshot.exists()) {
        setFirstThreeProducts((prev) => [...prev, productSnapshot.data()]);
      }
    }
  };
  const handleClose = () => setIsModalOpen(false);

  useEffect(() => {
    if (!list) return;
    const fetchShopListData = async () => {
      const shopListRef = collection(
        db,
        "users",
        list?.ownerId,
        "shoppingList",
        list?.id,
        "products"
      );
      const shopListSnap = await getDocs(shopListRef);
      const shopList = shopListSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllProducts(shopList);
      setFirstThreeProducts([]);
      const initialProducts = shopList.slice(0, 3);
      initialProducts.map((product) => fetchProducts(product));
    };
    fetchShopListData();
  }, [list]);

  return (
    <div className="col-12 col-sm-6 col-md-4 col-lg-4 m-0 p-1">
      <Card>
        <style jsx>
          {`
            .shopListName:hover {
              color: var(--bs-link-hover-color);
            }
            .dropdown-toggle::after {
              display: none;
            }
            .searchShoppingListModal .modal-dialog {
              // max-width: 58.5rem;
              max-width: 63.5rem;
            }
          `}
        </style>
        {/* Images */}
        <div
          className="d-flex gap-2 p-2 pb-0 w-100"
          style={{ cursor: allProducts?.length > 0 ? "pointer" : "default" }}
          onClick={() => {
            if (allProducts?.length > 0) setIsModalOpen(true);
          }}
        >
          {/* Main Image */}
          {allProducts?.length === 0 ? (
            <div>
              <div
                style={{ height: "13.5rem" }}
                className="d-flex align-items-center justify-content-center w-100"
              >
                <div className="h6 px-4 text-center">
                  {list?.ownerId === user?.uid ? "You" : userData?.userName}{" "}
                  haven't added any products in {list?.listName}
                </div>
              </div>
            </div>
          ) : (
            <div
              // className="w-100"
              style={{
                position: "relative",
                height: "13.5rem",
                width: allProducts?.length === 1 ? "100%" : "50%",
              }}
            >
              <Card.Img
                variant="top"
                className="rounded-2"
                src={firstThreeProducts[0]?.imageUrl}
                alt="No products to show"
                style={{
                  objectFit: "cover",
                  objectPosition: "top",
                  height: "100%",
                }}
              />
              <div
                className="h-100 w-100 rounded-2"
                style={{
                  position: "absolute",
                  top: "0",
                  zIndex: 2,
                  background:
                    "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))",
                }}
              ></div>
            </div>
          )}
          {/* Second Images */}
          {allProducts?.length > 2 ? ( // Three Images
            <div className="d-flex flex-column gap-2" style={{ width: "50%" }}>
              {/* 1st image */}
              <div>
                <Card.Img
                  variant="top"
                  className="rounded-2"
                  src={firstThreeProducts[1]?.imageUrl}
                  style={{
                    objectFit: "cover",
                    height: "6.5rem",
                  }}
                />
              </div>
              {/* 2nd image */}
              <div style={{ position: "relative" }}>
                <Card.Img
                  variant="top"
                  className="rounded-2"
                  src={firstThreeProducts[2]?.imageUrl}
                  style={{
                    objectFit: "cover",
                    height: "6.5rem",
                  }}
                />
                <div
                  className="h-100 w-100 rounded-2"
                  style={{
                    position: "absolute",
                    top: "0",
                    zIndex: 2,
                    background:
                      "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))",
                  }}
                ></div>
              </div>
            </div>
          ) : (
            allProducts?.length > 1 && ( // Two Images
              <div
                style={{
                  position: "relative",
                  height: "13.5rem",
                  width: "50%",
                }}
              >
                <Card.Img
                  variant="top"
                  className="rounded-2"
                  src={firstThreeProducts[1]?.imageUrl}
                  style={{
                    objectFit: "cover",
                    width: "20.5rem",
                    height: "100%",
                    objectPosition: "top",
                  }}
                />
                <div
                  className="h-100 w-100 rounded-2"
                  style={{
                    position: "absolute",
                    top: "0",
                    zIndex: 2,
                    background:
                      "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))",
                  }}
                ></div>
              </div>
            )
          )}
        </div>
        {allProducts?.length === 0 && <hr className="m-0" />}
        <div className="d-flex gap-2 p-2 w-100 align-items-center">
          <Link to={`/userProfile/${list?.ownerId}`}>
            <div className="avatar avatar-md">
              <ProfilePicture userData={userData} />
            </div>
          </Link>
          <div
            className="d-flex flex-column"
            style={{ minWidth: "75%", width: "100%" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              {/* Shopping List Name */}
              <div
                className="h5 shopListName mb-0 text-truncate"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (allProducts?.length > 0) setIsModalOpen(true);
                }}
              >
                {list.listName}
              </div>
              {/* Reaction Buttons */}
              <div className="d-flex gap-2">
                <ListShareMenu
                  list={list}
                  listName={list?.listName}
                  myName={user?.displayName}
                  listLink={`${baseURL}/#/shoppingList/${list.listId}/${list?.ownerId}`}
                  listId={list.listId}
                  userId={user?.uid}
                  products={list.products}
                  componentName={"ProfilePage"}
                />
              </div>
            </div>
            <i>
              <div className="h6 mb-0 fw-light text-truncate">
                <span className="text-body">Created by </span>
                <Link to={`/userProfile/${list?.ownerId}`}>
                  {userData?.userName}
                </Link>
              </div>
            </i>
          </div>
        </div>
      </Card>
      <Modal
        className="searchShoppingListModal"
        show={isModalOpen}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="modalLabelCreateEvents">
            Shopping List {">"} {list?.listName}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          className="d-flex gap-3 flex-wrap"
          style={{ overflow: "auto", maxHeight: "38rem" }}
        >
          {allProducts.map((product) => (
            <ShoppingListProductCard
              list={list}
              product={product}
              ownerId={list?.ownerId}
            />
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ShoppingList;
