import React from "react";
import EmptyList from "../../ShoppingList/EmptyList";
import ProfileShoppingListCard from "./ProfileShoppingListCard";

const MyList3 = ({ shoppingListData, setShoppingListData }) => {
  return (
    <span className="d-flex w-100 flex-wrap gap-2">
      {shoppingListData.length > 0 ? (
        shoppingListData.map((list, index) => {
          return (
            <>
              <ProfileShoppingListCard
                list={list}
                index={index}
                shoppingListData={shoppingListData}
                setShoppingListData={setShoppingListData}
              />
            </>
          );
        })
      ) : (
        <div
          className="card mb-1 mt-2 rounded-2 pt-0 pb-0 profileShoppingList w-100"
          style={{ height: "25rem", maxHeight: "25rem" }}
        >
          <EmptyList content={"There is no list available to display"} />
        </div>
      )}
    </span>
  );
};

export default MyList3;
