import React, { useEffect, useRef, useState } from "react";
import TestBrandCard from "./TestBrandCard";
import TestProductCard from "./TestProductCard";
import TestPromotionCard from "./TestPromotionCard";
import TestUserCard from "./TestUserCard";
import testdata from "./testData.json";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import useFetch from "../../hooks/useFetch";
import "./style.css";
import TestProductCard2 from "./TestProductCard2";
import ShareFeed from "../../NewsFeed/ShareFeed";
import { UserAuth } from "../../Context";
import RecentlyOnlineUsers from "../../components/RecentlyOnlineUsers";
import NotificationOnFeed from "../../NewsFeed/NotificationOnFeed";
import RecentlyJoinedUsers from "../../components/RecentlyJoinedUsers";
import ShoppingListPostCard from "../../UserPosts/ShopPost/ShoppingListPostCard";
import TestProductCard3 from "./TestProductCard3";

export default function NewsFeed() {
  const [masonry, setmasonry] = useState();
  const [newsFeedItems, setNewsFeedItems] = useState([]);
  const { user } = UserAuth();
  const [expandedCards, setExpandedCards] = useState({}); // Track expanded state of cards

  //api endpoint for logged-out user
  let url = process.env.REACT_APP_API + `/api/logoutFeed`;
  const { data, isLoading } = useFetch(url);

  // Invoke Masonry Function
  function initMasonry() {
    var elem = document.querySelector(".grid");
    var grid = new Masonry(elem, {
      itemSelector: ".grid-item",
      columnWidth: ".grid-sizer",
      transitionDuration: "0.3s",
      stagger: 20,
    });
    //Update masonry cards as each image loads
    imagesLoaded(elem).on("progress", function () {
      grid.layout();
    });
    setmasonry(grid);
  }

  //Handles Expanded Effect on Click
  const handleClick = (productId) => {
    setExpandedCards((prev) => ({
      ...prev,
      [productId]: !prev[productId], // Toggle expand/collapse for this card
    }));

    setTimeout(() => {
      masonry.layout();
    }, 300);
  };

  //Update Masonry upon setting NewsFeed
  useEffect(() => {
    if (isLoading) return;
    initMasonry();
  }, [newsFeedItems]);

  //fetch NewsFeed Items
  useEffect(() => {
    setNewsFeedItems(data || []); // Use fetched data directly for logged-out users
  }, [data]);

  //Spinner
  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="px-3 w-100">
      <div className="grid">
        <div className="grid-sizer"></div>
        {user && (
          <>
            {/* Optional components for logged-in users */}
          </>
        )}
        {newsFeedItems.map((item) => {
          if (item.by === "product") {
            return (
              <div
                key={item.id}
                className={`grid-item ${expandedCards[item.id] ? "grid-item--width2" : ""}`}
              >
                <TestProductCard2
                  product={item}
                  isExpanded={expandedCards[item.id]}
                  handleClick={() => handleClick(item.id)}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
