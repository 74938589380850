import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth, db } from "../FirebaseConfig";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import {
  createUserWithEmailAndPassword,
  deleteUser,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { UserAuth } from "../Context";
import {
  deleteDoc,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { Accordion } from "react-bootstrap";
import useUserStore from "../StatesStore/UserStore";
import { Modal } from "react-bootstrap";
import useModalStore from "../StatesStore/ModalStore";
import TermsAndConditions from "../Authentication/TermsAndConditions";
function VerifyEmailModal() {
  const { title, data, closeModal } = useModalStore();
  const [message, setMessage] = useState(
    `Hi ${data.userName}, please verify your email address by clicking the link sent to ${data.email}`
  );
  const { user } = UserAuth();
  const { setUser, createId } = useUserStore(); //for storing user
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPass, setErrorPass] = useState("");
  const [errorConfirmPass, setErrorConfirmPass] = useState("");

  const resendEmailVerification = async () => {
    await sendEmailVerification(user);
    toast.success("Check your inbox for email verification link", toastOptions);
  };
  const changeEmailAddress = async () => {
    // const usersToBeDeletedRef = doc(db, "usersToBeDeleted", user?.uid);
    // await setDoc(usersToBeDeletedRef, {
    //   email: user?.email,
    //   timestamp: serverTimestamp(),
    // });
    closeModal();
    const userRef = doc(db, "users", user.uid);
    const userSnap = await getDoc(userRef);
    let oldUserData = userSnap.data();
    await deleteDoc(userRef);
    await deleteUser(user);
    oldUserData.email = email;
    console.log(oldUserData);
    try {
      const authUser = await createUserWithEmailAndPassword(auth, email, pass);
      await updateProfile(authUser.user, {
        displayName: oldUserData?.userName,
      });
      const userDocRef = doc(db, "users", authUser.user.uid);
      await setDoc(userDocRef, { ...oldUserData });
      await setUser(oldUserData);
      createId(authUser.user.uid);
      window.location.reload();
      await sendEmailVerification(authUser.user)
        .then(() => {
          // Email verification sent!
          toast.success(
            "Email Updated Successfully. Check your inbox for email verification link",
            toastOptions
          );
          setMessage(`Hi ${oldUserData?.userName ||
            ""}, please verify your email address by
                clicking the link sent to ${email ||
                  authUser.user?.providerData[0]?.email}`);

          // navigate("/VerifyEmail");
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error(
            "Error sending email verification:",
            errorCode,
            errorMessage,
            toastOptions
          );
        });
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setErrorEmail(
          "Email address already in use. Please enter another email address."
        );
        return;
      }
    }
    setEmail("");
    setPass("");
    setConfirmPass("");
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
    console.log("email", email);
    console.log(user?.uid);
    console.log("user?.email", user?.providerData[0]?.email);
    if (email === (user?.email || user?.providerData[0]?.email)) {
      setErrorEmail("New email cannot be same as old email");
      return;
    }
    if (pass !== confirmPass) {
      setErrorConfirmPass("Password and Confirm Password do not match");
      return;
    } else if (pass.length < 6) {
      setErrorPass("Password must be at least 6 characters long");
      return;
    } else {
      changeEmailAddress();
    }
  };
  return (
    <Modal show centered onHide={closeModal}>
      <Modal.Body className="container">
        <div className="d-flex flex-column align-items-center verifiyEmail pb-0 pt-2">
          <div className="">
            <div className="text-body  fs-icon ">
              <div
                style={{
                  fontFamily: "Nunito Sans",
                }}
              >
                <i>lookflock&nbsp;</i>
              </div>
            </div>
          </div>
          <p className="mt-1 fs-4 text-body">Verify your email address</p>
          <hr className="mt-0" />
          <p className="fs-5 text-center">{message}</p>
          <div className="px-4">
            <div
              className="btn btn-primary mb-2 w-100"
              onClick={resendEmailVerification}
            >
              Resend Email Verification
            </div>
            <Accordion flush>
              <style jsx>
                {`
                  .verifiyEmail .accordion-button::after {
                    display: none !important; /* Hides the default arrow */
                  }
                `}
              </style>

              <Accordion.Item className="bg-transparent border-0" eventKey={0}>
                <Accordion.Header>
                  {/* <div className="btn btn-primary">Change Email Address</div> */}
                  <div className="btn btn-primary w-100">
                    Change Email Address
                  </div>
                </Accordion.Header>
                <Accordion.Body className="p-0 pt-3">
                  <form
                    className="d-flex flex-column gap-2 justify-content-center "
                    onSubmit={(e) => handleSubmitForm(e)}
                  >
                    <div>
                      <input
                        style={{ height: "2.5rem" }}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setErrorEmail("");
                          setErrorPass("");
                          setErrorConfirmPass("");
                        }}
                        className="w-100 rounded-2 p-2 border border-2"
                        type="email"
                        placeholder="New Email Address"
                        required
                      />
                      <div
                        className="text-start ps-2"
                        style={{
                          color: "red",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {errorEmail}
                      </div>
                    </div>
                    <div>
                      <div className="d-flex">
                        <input
                          style={{ height: "2.5rem" }}
                          value={pass}
                          type={showPassword ? "text" : "password"}
                          onChange={(e) => {
                            setPass(e.target.value);
                            setErrorEmail("");
                            setErrorPass("");
                            setErrorConfirmPass("");
                          }}
                          className="w-100 me-1 rounded-2 p-2 border border-2"
                          placeholder="New Password"
                          required
                        />
                        <div
                          className="input-group-text p-0"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <i
                            className={`fakepasswordicon fa-solid fa-eye${
                              showPassword ? "-slash" : ""
                            } cursor-pointer p-2 w-40px`}
                          ></i>
                        </div>
                      </div>
                      <div
                        className="text-start ps-2"
                        style={{
                          color: "red",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {errorPass}
                      </div>
                    </div>
                    <div>
                      <div className="d-flex">
                        <input
                          style={{ height: "2.5rem" }}
                          value={confirmPass}
                          type={showPassword ? "text" : "password"}
                          onChange={(e) => {
                            setConfirmPass(e.target.value);
                            setErrorEmail("");
                            setErrorPass("");
                            setErrorConfirmPass("");
                          }}
                          className="w-100 me-1 rounded-2 p-2 border border-2"
                          placeholder="Confirm Password"
                          required
                        />
                        <div
                          className="input-group-text p-0"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <i
                            className={`fakepasswordicon fa-solid fa-eye${
                              showPassword ? "-slash" : ""
                            } cursor-pointer p-2 w-40px`}
                          ></i>
                        </div>
                      </div>
                      <div
                        className="text-start ps-2"
                        style={{
                          color: "red",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {errorConfirmPass}
                      </div>
                    </div>
                    <button
                      className="btn btn-primary w-100 m-auto"
                      type="submit"
                    >
                      Submit
                    </button>
                  </form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <TermsAndConditions />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default VerifyEmailModal;
