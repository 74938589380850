import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context";
import useModalStore from "../StatesStore/ModalStore";
import { dataNewsFeed, titleNewsFeed } from "../Content/ModalContent";
import { useQuery } from "@tanstack/react-query";
import { XMasonry, XBlock } from "react-xmasonry";
import UserCard from "../Cards/UserCard";
import UserCard3 from "../Cards/UserCard3";
import Skeleton from "../Products/Skeleton";
import ShareFeed from "./ShareFeed";
import axios from "axios";
import ShoppingListPostCard from "../UserPosts/ShopPost/ShoppingListPostCard";
import useFeedClient from "../StatesStore/FeedClient";
import { useInView } from "react-intersection-observer";


function StreamFeed({ columns }) {
    const [posts, setPosts] = useState([]);
    const [newPosts, setNewPosts] = useState([]);
    const [newPostAvailable, setNewPostAvailable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [hasMore, setHasMore] = useState(true);
    const { user } = UserAuth();
    const [columnCount, setColumnCount] = useState(getColumnCount());
    const feedClient = useFeedClient((state) => state.feedClient);

    const { ref: loadMoreRef, inView: loadMoreView } = useInView({
        threshold: 0.1,

    });
    const { ref: loginRef, inView: modalView } = useInView({
        threshold: 1,
        // delay :5000,
        rootMargin: "-170px",
    });

    // Fetch user posts from Stream API
    const fetchPosts = async (id_lt = null) => {
        if (!user?.uid || !feedClient || !hasMore || loading) return;
        setLoading(true);
        setError("");
    
        try {
          const response = await axios.get(`http://localhost:8083/api/getPosts/${user?.uid}`, {
            params: { limit:10, id_lt },
          });
          const newFetchedPosts = response.data.posts;
          setPosts((prevPosts) => [...prevPosts, ...newFetchedPosts]);
          setHasMore(newFetchedPosts.length >=10);
        } catch (err) {
          setError("Failed to load posts. Please try again.");
          console.error("Error fetching posts:", err);
        }
    
        setLoading(false);
      };
      useEffect(() => {
        if (loadMoreView && !loading && posts.length > 0) {
          const lastPostId = posts.length ? posts[posts.length - 1].id : null;
          fetchPosts(lastPostId);
        }
      }, [loadMoreView]);

    useEffect(() => {
        if (!user?.uid || !feedClient) return;

        fetchPosts(); // Load initial posts

        const userFeed = feedClient.feed("Flat_feed", user.uid);

        // Subscribe to real-time updates
        userFeed.subscribe((data) => {
            console.log("New activity received:", data);

            if (data.new.length > 0) {
                data.new.forEach((newPost) => {
                    if (newPost.userId === user.uid) {
                        // If the new post is from the current user, add it directly
                        setPosts((prevPosts) => [newPost, ...prevPosts]);
                    } else {
                        // Otherwise, store it separately and show the notification button
                        setNewPosts((prevNewPosts) => [newPost, ...prevNewPosts]);
                        setNewPostAvailable(true);
                    }
                });
            }
        }).then(() => {
            console.log("Listening for real-time updates...");
        }).catch((error) => {
            console.error("Subscription failed:", error);
        });

        return () => userFeed.unsubscribe(); // Cleanup on unmount
    }, [user?.uid, feedClient]);

    useEffect(() => {
        function handleResize() {
            setColumnCount(getColumnCount());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    function getColumnCount() {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 900) return 5;
        if (screenWidth >= 768) return 4;
        if (screenWidth >= 440) return 3;
        return 2;
    }

    function getTargetBlockWidth() {
        return window.innerWidth < 440 ? 200 : 250;
    }
    function getBlockWidth() {
        // Return 1 for desktop (width >= 900), else 2
        return window.innerWidth >= 900 ? 1 : 2;
    }
    console.log('posts', posts);

    const handleNewPostClick = () => {
        setPosts((prevPosts) => [...newPosts, ...prevPosts]); // Add new posts to UI
        setNewPosts([]); // Clear stored new posts
        setNewPostAvailable(false); // Hide button
    };
    const cardTypeMap = {
        shoppingList: ShoppingListPostCard,

    };
    return (
        <>
            {/* <div className="col-md-8 col-lg-6 vstack gap-4 p-0 mt-0 mt-lg-5 px-3 px-lg-0 me-2"> */}
            <div className="vstack gap-4 px-0">
                <XMasonry
                    center={false}
                    responsive={true}
                    maxColumns={columns || 5}
                    targetBlockWidth={getTargetBlockWidth()}
                >
                    <XBlock>
                        {newPostAvailable && (
                            <div
                                className="position-fixed start-50 translate-middle"
                                style={{
                                    zIndex: 1050,
                                    transform: "translate(-50%, -50%)",
                                    marginTop: '12px'
                                }}
                            >
                                <button className="btn btn-primary btn-sm" onClick={handleNewPostClick}>
                                    New Post <i className="bi bi-arrow-up"></i>
                                </button>
                            </div>
                        )}
                    </XBlock>
                    <XBlock>{user && <ShareFeed />}</XBlock>
                    {/* <XBlock width={2}>{user && <ShoppingListPostCard />}</XBlock> */}
                    {posts?.map((post, index) => {
                        const CardComponent = cardTypeMap[post.postType] || UserCard3;
                        const cardWidth = post.postType === 'shoppingList' ? 2 : getBlockWidth();
                        const isSecondLast = index === posts.length - 2;
                        return (
                            <XBlock width={cardWidth} key={post.id}>
                                <div className={`me-0 ${index === 0 ? "ms-1" : "ms-2"}`} ref={isSecondLast ? loadMoreRef : null}>
                                    <CardComponent post={post} />
                                </div>
                            </XBlock>
                        );
                    })}
                    {loading &&
                        Array.from({ length: 25 }).map((_, index) => (
                            <XBlock key={`skeleton-${index}`} width={1}>
                                <Skeleton />
                            </XBlock>
                        ))}
                    {/* <XBlock>
                        {!loading && <div ref={loadMoreRef}></div>}
                    </XBlock> */}
                </XMasonry>
                {/* {loading && (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )} */}
            </div>
        </>
    );
}

export default StreamFeed;
