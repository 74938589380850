import React from "react";
import useModalStore from "../StatesStore/ModalStore";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  increment,
  updateDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";

const DeleteShopList = ({
  user,
  list,
  shoppingListData,
  setShoppingListData,
  setSelectedShopList,
  setLoading,
}) => {
  const { openModal } = useModalStore();
  const handleClick = () => {
    const DATA = {
      deleteFunction: handleDeleteList,
      displayName: list?.name,
    };
    openModal("ConfirmDelete", "shoppingList", DATA);
  };
  const handleDeleteList = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const selectedShoppingListDoc = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        list?.name
      );

      console.log("selectedShoppingList", list);
      // Get the listId before deleting the shopping list
      const listId = list?.listId;

      if (!listId) throw new Error("ListId not found.");

      // Fetch all member document IDs from the "members" subcollection
      const membersCollectionRef = collection(
        selectedShoppingListDoc,
        "members"
      );
      const membersSnap = await getDocs(membersCollectionRef);
      const memberIds = membersSnap.docs.map((doc) => doc.id);

      console.log("Shopping list deleted successfully");

      // For each member ID, delete the corresponding document from their "invitedList" subcollection
      const deleteInvitedListPromises = memberIds.map(async (memberId) => {
        const invitedListDoc = doc(
          db,
          "users",
          memberId,
          "invitedList",
          listId
        );
        await deleteDoc(invitedListDoc);
        console.log(
          `Deleted listId ${listId} from user ${memberId}'s invitedList`
        );
      });

      Promise.all(deleteInvitedListPromises);

      console.log("All related documents deleted successfully.");

      // Fetch the product count for the shopping list
      const docCountRef = collection(selectedShoppingListDoc, "products");
      const docCountSnap = await getDocs(docCountRef);
      const countDocs = docCountSnap.size;

      // Update the local state by removing the deleted list
      const remainingLists = shoppingListData.filter(
        (list) => list?.name !== list?.name
      );

      // Sort the remaining lists by dateCreated in descending order
      remainingLists.sort((a, b) =>
        b.lastUpdated && a.lastUpdated
          ? b.lastUpdated - a.lastUpdated || a.dateCreated - b.dateCreated
          : a.dateCreated - b.dateCreated
      );

      // Set list to the name of the first list in the sorted array
      setSelectedShopList({
        name: remainingLists[0]?.name,
        listId: remainingLists[0]?.listId,
      });

      // Update the local state
      setShoppingListData(remainingLists);

      // Update the user document with the decremented count
      const userRef = doc(db, "users", user?.uid);
      await updateDoc(userRef, {
        shoppingListCount: increment(-countDocs),
      });

      const chatsRef = doc(db, "chats", listId);
      await deleteDoc(chatsRef);
      await deleteDoc(selectedShoppingListDoc);

      console.log("State and Firestore updated successfully.");
    } catch (error) {
      console.error("Error deleting the shopping list:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <li
        className="nav-item"
        onClick={async () => {
          handleClick();
        }}
      >
        <div className="icon-md btn btn-light p-0 text-body">
          <i className="bi bi-trash-fill fs-6"> </i>
        </div>
        <span className="mx-2 h6 fw-light">Delete list</span>
      </li>
    </div>
  );
};

export default DeleteShopList;
