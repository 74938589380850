import React from "react";
import { Link } from "react-router-dom";
function TermsAndConditions() {
  return (
    <div className="container my-1 px-4">
      <p className="text-center small">
        By continuing, you confirm that you agree to our
        <Link to="/staticPages/termsAndConditions" target="_blank">
          {" "}
          <u style={{ color: "grey" }}>Terms of Service</u>{" "}
        </Link>
        and
        <Link to="/staticPages/privacyPolicy" target="_blank">
          {" "}
          <u style={{ color: "grey" }}>Privacy Policy</u>.{" "}
        </Link>
        {/* <h5 className='mt-1'><Link to="/ContactUs"><u style={{ color: 'grey' }}>Contact us</u></Link></h5> */}
        <div className="mt-1">©2025 Lookflock. All rights reserved.</div>
      </p>
    </div>
  );
}

export default TermsAndConditions;
