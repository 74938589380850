import React, { useEffect, useState } from "react";
import useUserStore from "../StatesStore/UserStore"; // Import the Zustand store
import calculateAge from "../lib/calculateAge";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  serverTimestamp,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import TermsAndConditions from "./TermsAndConditions";
import WebFont from "webfontloader";
import { UserAuth } from "../Context";
import { handleReferralCodes } from "../lib/handleReferralCodes";
import Gender from "./SignInInput/Gender";
import Birthday from "./SignInInput/Birthday";
import Location from "./SignInInput/Location";
import Category from "./SignInInput/Category";
import Brands from "./SignInInput/Brands";
import HearAboutUs from "./SignInInput/HearAboutUs";
import GoogleForm from "./SignInInput/GoogleForm";
import Category2 from "./SignInInput/Category2";
import { CheckCircle, CheckCircleFill, DashLg } from "react-bootstrap-icons";
//More Questions
export default function SignInInput() {
  // Variable to change state
  const [stage, setStage] = useState(1);
  // Values which will be submitted
  const [gender, setGender] = useState("");
  const [birthday, setBirthday] = useState(new Date());
  const [location, setLocation] = useState("");
  const [brands, setBrands] = useState([]); //This will have all the primary categories
  const [allBrands, setAllBrands] = useState([]); // Updated to use state for allBrands
  const category = ["Ready to Wear", "Unstitched", "Western Wear"];
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const userStoreUser = useUserStore((state) => state.user);
  const { user } = UserAuth();
  const navigate = useNavigate();

  const handleNext = () => {
    setStage(stage + 1);
  };
  const fetchBrands = async () => {
    try {
      const brandsCol = collection(db, "brands");
      const brandSnapshot = await getDocs(brandsCol);
      let brandsArray = [];
      brandSnapshot.forEach((doc) => {
        let brand = doc.data();
        if (brand.active) {
          brandsArray.push({
            value: brand.displayName,
            label: brand.displayName,
            like: brand.like,
            name: brand.name,
            category: brand.category[0],
            logoUrl: brand.logoUrl,
          });
        }
      });
      // Sort brands based on 'like' values
      brandsArray.sort((a, b) => b.like - a.like);
      setAllBrands(brandsArray);
    } catch (error) {
      console.error("Error fetching brand display names:", error);
    }
  };
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  const fetchCategories = async () => {
    try {
      const categoriesCol = collection(db, "universalCategories");
      const categoriesSnapshot = await getDocs(categoriesCol);
      let genderArray = [];
      categoriesSnapshot.forEach((doc) => {
        let GenderData = doc.data();
        genderArray.push(GenderData);
      });
      let allSubCategories = {
        "Ready to Wear": [],
        Unstitched: [],
        "Western Wear": [],
      };
      category.map((cat) => {
        genderArray[0][cat].map((subCat) => {
          allSubCategories.push({ ...subCat, category: "Men" });
        });
        genderArray[1][cat].map((subCat) => {
          allSubCategories.push({ ...subCat, category: "Women" });
        });
        shuffleArray(allSubCategories);
      });
      // console.log("allSubCategories", allSubCategories);
      setAllCategories(allSubCategories);
    } catch (error) {
      console.error("Error fetching brand display names:", error);
    }
  };
  const fetchCategories2 = async () => {
    try {
      const categoriesCol = collection(db, "universalCategories");
      const categoriesSnapshot = await getDocs(categoriesCol);
      let genderArray = [];
      categoriesSnapshot.forEach((doc) => {
        let GenderData = doc.data();
        genderArray.push(GenderData);
      });
      // console.log("genderArray", genderArray);
      let allSubCategories = [];
      category.map((cat) => {
        genderArray[0][cat].map((subCat) => {
          allSubCategories.push({
            category: "Men",
            subCategory: cat,
            subSubCategoryName: subCat.name,
            subSubCategoryDisplayName: subCat.displayName,
            imageUrl: subCat.imageUrl,
          });
        });
        genderArray[1][cat].map((subCat) => {
          allSubCategories.push({
            category: "Women",
            subCategory: cat,
            subSubCategoryName: subCat.name,
            subSubCategoryDisplayName: subCat.displayName,
            imageUrl: subCat.imageUrl,
          });
        });
        shuffleArray(allSubCategories);
      });
      // console.log("allSubCategories", allSubCategories);
      setAllCategories(allSubCategories);
    } catch (error) {
      console.error("Error fetching brand display names:", error);
    }
  };
  const handleSubmission = async (e) => {
    e.preventDefault();
    try {
      const userDocRef = doc(db, "users", user?.uid);
      const dataToUpdate = {
        gender: gender,
        birthday: birthday.toLocaleDateString("en-us"),
        location: location,
        favBrands: brands,
        favCategories: categories,
        heardAboutUs: selectedOption,
      };
      await updateDoc(userDocRef, dataToUpdate);
      // Go through the list of favBrands
      for (const brandName of brands) {
        // Get the brand document reference
        const brandDocRef = doc(db, "brands", brandName);

        // Add the user to the 'followers' subcollection in the brand document
        const followersDocRef = doc(brandDocRef, "followers", user.uid);
        await setDoc(followersDocRef, {
          dateCreated: serverTimestamp(),
        });

        // Get the user's document reference
        const userDocRef = doc(db, "users", user.uid);

        // Add a document in the user's subcollection with the brand name
        const userBrandDocRef = doc(userDocRef, "following", brandName);
        await setDoc(userBrandDocRef, {
          timestamp: serverTimestamp(),
          type: "brand",
        });
      }
      handleReferralCodes(user.uid);
      // Navigate to the desired location
      navigate("/");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };
  useEffect(() => {
    if (!user) return;
    const checkDataToSelectStage = async () => {
      const userRef = doc(db, "users", user?.uid);
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();
      // console.log(userData);
      setGender(userData.gender || "");
      setBirthday(userData.birthday || "");
      setLocation(userData.location || "");
      setBrands(userData.favBrands || []);
      setCategories(userData.favCategories || []);
      setSelectedOption(userData.heardAboutUs || "");
      if (userData.gender === null) setStage(1);
      else if (userData.birthday === null) setStage(2);
      else if (userData.location === "" || userData.location === null)
        setStage(3);
      else if (userData.favCategories.length === 0) setStage(4);
      else if (userData.favBrands.length === 0) setStage(5);
      else if (userData.heardAboutUs === "" || userData.heardAboutUs === null)
        setStage(6);
      else navigate("/");
    };
    checkDataToSelectStage();
  }, [user]);
  //Load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Nunito Sans", "sans-serif"],
      },
    });
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      //function call
      await fetchBrands();
      //function call
      await fetchCategories2();
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    console.log(
      `gender: ${gender}\nbirthday:  ${birthday}\nlocation:  ${location}\nbrands:  ${brands}\ncategories:  ${categories}\nselectedOption:  ${selectedOption}`
    );
  }, [gender, birthday, location, brands, categories, selectedOption]);
  // useEffect(() => {
  //   console.log("gender: ", gender);
  // }, [gender]);
  // useEffect(() => {
  //   console.log("birthday: ", birthday);
  // }, [birthday]);
  // useEffect(() => {
  //   console.log("location: ", location);
  // }, [location]);
  // useEffect(() => {
  //   console.log("brands: ", brands);
  // }, [brands]);
  useEffect(() => {
    console.log("categories: ", categories);
  }, [categories]);
  // useEffect(() => {
  //   console.log("selectedOption: ", selectedOption);
  // }, [selectedOption]);

  return (
    <>
      <div className="container">
        <style jsx>
          {`
            .hoverClass:hover {
              background-color: rgb(0, 0, 0, 0.05);
              color: black;
            }
            .selected {
              background-color: rgb(0, 0, 0, 0.1);
              // color: white;
            }
          `}
        </style>
        <div className="row justify-content-center align-items-center vh-100 py-4">
          <div
            className={`col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-${
              stage === 4 || stage === 5 ? "8" : stage === 7 ? "7" : "5"
            }`}
          >
            <div className={`card card-body text-center rounded-3 p-1 p-sm-4`}>
              <div className={`mt-4`}>
                {Array.from({ length: 6 }, (_, index) => (
                  <React.Fragment key={index}>
                    {stage - 1 < index ? (
                      <CheckCircle className="text-primary fs-4" />
                    ) : (
                      <CheckCircleFill className="text-primary fs-4" />
                    )}
                    {index < 5 && <DashLg className="fs-1" />}
                  </React.Fragment>
                ))}

                {stage === 1 && (
                  <Gender
                    gender={gender}
                    setGender={setGender}
                    handleNext={handleNext}
                    userStoreUser={userStoreUser}
                  />
                )}
                {stage === 2 && (
                  <Birthday
                    birthday={birthday}
                    setBirthday={setBirthday}
                    calculateAge={calculateAge}
                    handleNext={handleNext}
                    userStoreUser={userStoreUser}
                  />
                )}
                {stage === 3 && (
                  <Location
                    location={location}
                    setLocation={setLocation}
                    handleNext={handleNext}
                  />
                )}
                {stage === 4 && (
                  <Category2
                    categories={categories}
                    setCategories={setCategories}
                    handleNext={handleNext}
                    allCategories={allCategories}
                  />
                )}
                {stage === 5 && (
                  <Brands
                    brands={brands}
                    setBrands={setBrands}
                    handleNext={handleNext}
                    allBrands={allBrands}
                  />
                )}
                {stage === 6 && (
                  <HearAboutUs
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    handleSubmission={handleSubmission}
                    handleNext={handleNext}
                  />
                )}
                {stage === 7 && <GoogleForm handleNext={handleNext} />}
              </div>
              <div className="d-grid mt-2">
                <button
                  disabled={stage === 1}
                  className={`btn btn-primary-soft flex-grow-1 
                    `}
                  // ${stage === 1 || stage === 6 ? "d-none" : "d-block"}
                  onClick={() => {
                    if (stage > 1) setStage(stage - 1);
                    setBrands([]);
                  }}
                >
                  Previous
                </button>
              </div>
              <div className="mt-2">
                <TermsAndConditions />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
