import {
  doc,
  getDoc,
  getDocs,
  setDoc,
  collection,
  query,
  where,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";
import { Bookmark, BookmarkFill } from "react-bootstrap-icons";
import useModalStore from "../StatesStore/ModalStore";
import { UserAuth } from "../Context";

function AddOrRemoveSavedList({ post }) {
  const { user } = UserAuth();
  const [isSaved, setIsSaved] = useState(false);
  const { openModal } = useModalStore();

  useEffect(() => {
    const checkIfSaved = async () => {
      if (!user || !post?.listId) return;

      try {
        const savedListRef = doc(db, "users", user.uid, "savedLists", post.listId);
        const savedListDoc = await getDoc(savedListRef);

        if (savedListDoc.exists()) {
          setIsSaved(true); 
        }
      } catch (error) {
        console.error("Error checking saved list: ", error);
      }
    };

    checkIfSaved();
  }, [user, post?.listId]);

  const handleToggleSave = async () => {
    if (!user) return;

    try {
      const { userId, listId, title } = post;

      // Check if the list is already saved
      const savedListRef = doc(db, "users", user.uid, "savedLists", listId);
      const savedListDoc = await getDoc(savedListRef);

      if (savedListDoc.exists()) {
        // If the list is already saved, delete it
        await deleteDoc(savedListRef);
        setIsSaved(false);
        console.log("List removed from saved lists!");
      } else {
        // If the list is not saved, save it
        const shoppingListCollectionRef = collection(db, "users", userId, "shoppingList");
        const shoppingListQuery = query(shoppingListCollectionRef, where("listId", "==", listId));
        const shoppingListSnapshot = await getDocs(shoppingListQuery);

        if (shoppingListSnapshot.empty) {
          console.error("Shopping list not found");
          return;
        }

        const shoppingListDoc = shoppingListSnapshot.docs[0];
        const shoppingListDocId = shoppingListDoc.id;

        const productsCollectionRef = collection(db, "users", userId, "shoppingList", shoppingListDocId, "products");
        const productsSnapshot = await getDocs(productsCollectionRef);
        const productIds = productsSnapshot.docs.map(doc => doc.id);

        await setDoc(savedListRef, {
          productIds,
          userId: user.uid,
          title: title,
          timestamp: serverTimestamp(),
        });

        setIsSaved(true);
        console.log("List saved successfully!");
      }
    } catch (error) {
      console.error("Error toggling save: ", error);
    }
  };


  const handleClick = () => {
    if (!user) {
      // Check if user is not logged in before opening modal
      openModal("SignInModal", "", ""); // Open modal here
    }
  };

  return (
    <Link
      className={`nav-link m-0 nav-item rounded-circle d-flex align-items-center justify-content-center `}
      to=""
      onClick={() => {
        if (!user?.emailVerified) {
          openModal("VerifyEmailModal", "", {
            userName: user?.displayName,
            email: user?.email,
          });
        }
        else {
          handleToggleSave();
          setIsSaved(!isSaved);
        }
      }}
    >
      {!user && (
        <Link
          to=""
          className={`nav-link m-0 p-0 nav-item`}
          onClick={handleClick}
        >
          <Bookmark
            style={{ color: "rgb(255 0 0 / 90%)" }}
            className="fs-4 text-body"
          />
        </Link>
      )}

      {user && (
        <>
          {isSaved ? (
            <BookmarkFill
              style={{ color: "rgb(255 0 0 / 90%)" }}
              className="fs-4"
            />
          ) : (
            <Bookmark
              style={{ color: "rgb(255 0 0 / 90%)" }}
              className="fs-4"
            />
          )}
        </>
      )}
    </Link>
  );
}

export default AddOrRemoveSavedList;