import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddToList from "../components/AddToList";
import { Card } from "react-bootstrap";
import "./style.css";
import BrandDisplay from "../NewsFeed/BrandDisplay";
import formatPrice from "../lib/formatPrice";
import AddToChat from "../components/AddToChat";
import AddToCart from "../components/AddToCart";
import AddOrRemoveLike from "./AddOrRemoveLike";

const Skeleton = ({ product }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="shadow-sm p-2 rounded-2 mt-2 bg-mode m-1">
      <div className="d-flex flex-column">
        <div style={{ position: "relative", height: "auto" }}>
          <div>
            <Card.Img
              className="rounded-2 skeleton"
              style={{
                transition: "opacity 0.5s ease-in-out",
                objectFit: "cover",
                height: isSmallScreen ? "12rem" : "14rem",
                display: "inline",
                objectPosition: "top",
              }}
              loading="lazy"
            />
          </div>
          <div
            style={{
              position: "absolute",
              backgroundColor: "#59A800",
              color: "white",
              width: "4rem",
              fontSize: "10px",
              alignContent: "center",
              height: "1.5rem",
            }}
            className="rounded-pill text-center top-0 end-0 m-1"
          ></div>
        </div>
        <div className={`d-flex flex-column  mt-2`}>
          <div className="d-flex align-items-center">
            {/* Logo */}
            <div
              className="avatar me-2 skeleton rounded-2"
              style={{ width: "40px", height: "40px" }}
            ></div>
            <div
              className="d-flex flex-column justify-content-center mb-2 "
              style={{ width: "78%" }}
            >
              <div className="mb-1 skeleton">
                <BrandDisplay type={"ProductCard"} />
              </div>
              <div className="mb-0 skeleton">
                <BrandDisplay type={"ProductCard"} />
              </div>
            </div>
          </div>
          <div className="mt-2 d-flex justify-content-between align-items-center skeleton">
            <div className=" skeleton">
              <BrandDisplay type={"ProductCard"} />
            </div>
          </div>
          <div className="mt-1 d-flex justify-content-between align-items-center skeleton">
            <div className=" skeleton">
              <BrandDisplay type={"ProductCard"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
