import { doc, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import algoliasearch from "algoliasearch";

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);
const indexShoppingList = client.initIndex("shoppingList"); // Name of index in Algolia is shoppingList

export const saveShoppingListToAlgolia = async (user, listId) => {
  console.log("algolia save user", user);
  console.log("algolia save listId", listId);
  try {
    const shoppingListRef = doc(
      db,
      "users",
      user?.uid || user?.id,
      "shoppingList",
      listId
    );
    try {
      const shoppingListDoc = await getDoc(shoppingListRef);
      const shoppingList = shoppingListDoc.data();
      const dateCreated =
        shoppingList?.dateCreated?.seconds || new Date().getTime() / 1000;
      const lastUpdated =
        shoppingList?.lastUpdated?.seconds || new Date().getTime() / 1000;

      let shopListData = {
        objectID: shoppingList?.listId,
        id: shoppingListDoc.id,
        dateCreated,
        lastUpdated,
        listId: shoppingList?.listId,
        listName: shoppingList?.listName,
        sizes: shoppingList?.sizes,
        visibility: shoppingList?.visibility,
        ownerId: user?.uid || user?.id,
      };
      await indexShoppingList.saveObject(shopListData);
      // return shopListData;
    } catch (error) {
      console.error("Error setting up snapshot listener:", error);
    }
  } catch (error) {
    console.error(error);
  }
};
export const deleteShoppingListFromAlgolia = async (listId) => {
  try {
    console.log("algolia delete listId", listId);
    await indexShoppingList.deleteObject(listId);
  } catch (error) {
    console.error(error);
  }
};
