import React, { useState, useEffect, useRef } from "react";
import ProductList from "./ProductList";
import ListComments from "./ListComments";
import { Link, useSearchParams } from "react-router-dom";
import Settings from "./Settings";
import { UserAuth } from "../Context";
import { getBaseURL } from "../lib/getBaseURL";
import ListShareMenu from "../components/ListShareMenu";
import { ChatLeftTextFill, ThreeDotsVertical } from "react-bootstrap-icons";
import InvitePeople from "./InvitePeople";
import CartModal from "../Modals/CartModal";
import EmptyList from "./EmptyList";
import InvitedMembersList from "./InvitedMembersList";
import ProductList2 from "./ProductList2";
import CloneModal from "./CloneModal";
import { FaPen, FaCheck, FaTimes } from "react-icons/fa";
import DeleteShopList from "./DeleteShopList";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { listNameExists } from "../lib/listNameExists";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import { saveShoppingListToAlgolia } from "../lib/algoliaFunctions";
const ShoppingListName = ({ list, user }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showPencil, setShowPencil] = useState(false);
  const [name, setName] = useState(list?.name);
  const [error, setError] = useState("");
  // console.log("list", list);
  const handleRename = async () => {
    setIsEditing(false);
    if (listNameExists(user, name) === true) {
      toast.error("List name already exists", toastOptions);
      setName(list?.name);
      return;
    }
    const listRef = doc(db, "users", user?.uid, "shoppingList", list?.id);
    await updateDoc(listRef, { listName: name });
    if (list?.visibility === "Public")
      saveShoppingListToAlgolia(user, list?.id);
  };
  return (
    <>
      {isEditing ? (
        <div className="d-flex flex-column">
          <div
            className="d-flex align-items-center gap-2"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="text"
              value={name}
              onChange={(e) => {
                if (e.target.value.length <= 16) setName(e.target.value);
                setError("");
                if (/^\d+$/.test(e.target.value)) {
                  setError("List name cannot be completely numeric");
                } else if (
                  /[!@#$%^&*,.?":{}|<>\-_=+\;()'/\\[\]]/.test(e.target.value)
                ) {
                  setError("List name cannot contain special characters");
                } else if (e.target.value.length > 15) {
                  setError("List name cannot be longer than 15 characters");
                }
              }}
              className="form-control form-control-sm w-50"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleRename();
                }
              }}
            />
            <button
              onClick={(e) => {
                handleRename();
                e.stopPropagation();
              }}
              className="btn btn-success btn-sm"
            >
              <FaCheck />
            </button>
            <button
              onClick={(e) => {
                setIsEditing(false);
                setName(list?.name);
                setError("");
                e.stopPropagation();
              }}
              className="btn btn-danger btn-sm"
            >
              <FaTimes />
            </button>
          </div>
          {error && <div className="text-danger">{error}</div>}
        </div>
      ) : (
        <div
          className="d-flex align-items-center gap-2 w-100"
          onMouseEnter={() => setShowPencil(true)}
          onMouseLeave={() => setShowPencil(false)}
        >
          <h5 style={{ cursor: "pointer" }} className="card-title p-0 m-0">
            {name}
          </h5>
          {showPencil && (
            <div
              onClick={(e) => {
                setIsEditing(true);
                e.stopPropagation();
              }}
              style={{ cursor: "pointer" }}
            >
              <FaPen />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const MyList = ({
  shoppingListData,
  setShoppingListData,
  activeAccordion,
  setActiveAccordion,
  loading,
  setLoading,
}) => {
  // The list which is currently selected
  const [selectedShopList, setSelectedShopList] = useState({});
  // Current List ID
  const [listId, setListId] = useState("");
  const baseURL = getBaseURL();
  const { user } = UserAuth();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  // Get search parameters from the URL
  const [searchParams] = useSearchParams();
  // Extract 'name' and 'id' from the URL query params
  const listName = searchParams.get("name");
  const urlId = searchParams.get("id");
  // const [activeAccordion, setActiveAccordion] = useState(shoppingListData[0]?.listId);
  const [showComments, setShowComments] = useState({});
  // console.log("activeAccordion", activeAccordion, shoppingListData[0]?.listId);
  const initialRenderRef = useRef(true);
  const refs = useRef({});

  // Effect to handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const toggleChat = (listId, index) => {
    // Check if the current list is closed
    if (!activeAccordion.includes(listId)) {
      // Open the list by setting activeAccordion to the current listId
      setActiveAccordion([...activeAccordion, listId]);
      // Then toggle the chat visibility for this list
      setShowComments((prev) => ({
        ...prev,
        [listId]: true, // Open the chat
      }));
      // const element = document.getElementById(`${listId}chatContainer`);
      // if (element) {
      //   element.scrollIntoView({ behavior: "smooth", block: "start" });
      // }
    } else {
      // If the list is already open, just toggle the chat visibility
      setShowComments((prev) => ({
        ...prev,
        [listId]: !prev[listId], // Toggle chat on/off
      }));
      // const element = document.getElementById(`${listId}chatContainer`);
      // if (element) {
      //   element.scrollIntoView({ behavior: "smooth", block: "start" });
      // }
    }
    elementToScroll(index);
  };

  const elementToScroll = (index) => {
    // const element = document.getElementById(`${listId}listContainer`);
    let totalHeight = 0;
    for (let i = 0; i < index; i++) {
      const element = refs.current[i];
      const rect = element.getBoundingClientRect();
      console.log("element", element);
      console.log("element index", index);
      console.log("element rect", rect);
      totalHeight += rect.height;
    }
    console.log("element totalHeight", totalHeight);
    // document.body.scrollTop = totalHeight;
    // document.body.scrollTo(0, totalHeight);
    document.body.scrollTo({ top: totalHeight, behavior: "smooth" });
  };

  useEffect(() => {
    if (initialRenderRef.current && shoppingListData.length > 0) {
      // setActiveAccordion([shoppingListData[0]?.listId]);
      initialRenderRef.current = false;
    }
    // If 'name' and 'id' are present in the URL, find the matching shopping list

    if (listName && urlId && shoppingListData?.length > 0) {
      const matchedList = shoppingListData.find(
        (shopList) => shopList.name === listName && shopList.listId === urlId
      );
      if (matchedList) {
        setSelectedShopList({
          name: matchedList.name,
          listId: matchedList.listId,
          products: matchedList.products,
        });
        setListId(matchedList.listId);
      }
    } else if (shoppingListData?.length > 0) {
      // Fallback to default selection if URL params are not present
      setSelectedShopList({
        name: shoppingListData[0]?.name,
        listId: shoppingListData[0]?.listId,
        products: shoppingListData[0]?.products,
      });
      setListId(shoppingListData[0]?.listId);
    }
  }, [listName, urlId, shoppingListData]);

  useEffect(() => {
    // setActiveAccordion(shoppingListData[0]?.listId);
    if (!selectedShopList) return;
    console.log("selectedShopList111", selectedShopList);
  }, [selectedShopList]);

  const toggleAccordion = (listId, index) => {
    setActiveAccordion((prev) =>
      prev.includes(listId)
        ? prev.filter((id) => id !== listId)
        : [...prev, listId]
    ); // Toggle accordion state
    elementToScroll(index);
  };
  const closeOtherDropdown = (list, componentName) => {
    let dropdowns = document.getElementsByClassName(`Other`);
    dropdowns.forEach((dropdown) => {
      if (dropdown.classList.contains(list?.name.split(" ").join(""))) {
        if (dropdown.classList.contains("show")) {
          dropdown.classList.remove("show");
          dropdown.children[0].classList.remove("show");
          dropdown.children[0].ariaExpanded = "false";
          dropdown.children[1].classList.remove("show");
        } else {
          if (componentName !== "Share") {
            dropdown.classList.add("show");
            dropdown.children[0].classList.add("show");
            dropdown.children[0].ariaExpanded = "false";
            dropdown.children[1].classList.add("show");
          }
        }
      } else {
        dropdown.classList.remove("show");
        dropdown.children[0].classList.remove("show");
        dropdown.children[0].ariaExpanded = "false";
        dropdown.children[1].classList.remove("show");
      }
    });
  };
  const closeShareDropdown = (className, list) => {
    let dropdowns = document.getElementsByClassName(className);
    dropdowns.forEach((dropdown) => {
      if (
        dropdown.classList.contains(list.name.split(" ").join("") + list.listId)
      ) {
        if (dropdown.classList.contains("show")) {
          dropdown.classList.remove("show");
          dropdown.children[0].classList.remove("show");
          dropdown.children[0].ariaExpanded = "false";
          dropdown.children[1].classList.remove("show");
        }
      } else {
        dropdown.classList.remove("show");
        dropdown.children[0].classList.remove("show");
        dropdown.children[0].ariaExpanded = "false";
        dropdown.children[1].classList.remove("show");
      }
    });
  };

  return (
    <>
      <span>
        {shoppingListData.length > 0 &&
          shoppingListData.map((list, index) => {
            return (
              <>
                <div
                  className="card m-0 p-0 rounded-0 shoppingList border border-top-0"
                  key={list.listId}
                  ref={(el) => (refs.current[index] = el)}
                  id={`${list.listId}listContainer`}
                  onClick={() => toggleAccordion(list.listId, index)} // Toggle accordion on card click
                >
                  <style jsx>
                    {`
                      .shoppingList .dropdown-toggle:after {
                        display: none;
                      }
                      .stickyHeader2 {
                        position: sticky;
                        top: 9rem;
                        z-index: 10;
                      }
                    `}
                  </style>
                  {/* Shopping List Header */}
                  <div className="d-flex justify-content-between align-items-center ps-3 py-2 py-md-2 pe-md-1 bg-mode">
                    <div className="w-50">
                      <ShoppingListName list={list} user={user} />
                      {!isSmallScreen && (
                        <span
                          onClick={(e) => e.stopPropagation()} // Prevent toggle when clicking on the title
                        >
                          <InvitedMembersList
                            listName={list.id}
                            userId={user?.uid}
                            listId={list.listId}
                          />
                        </span>
                      )}
                    </div>

                    {/* DropDown for small screen */}
                    {isSmallScreen ? (
                      <div className="d-flex nav align-items-center">
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <InvitePeople
                            selectedShopList={list}
                            listId={list.listId}
                            listName={list?.id}
                            myName={user?.displayName}
                            listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                          />
                        </div>
                        <li
                          className="nav-item ms-2"
                          onClick={(e) => {
                            e.stopPropagation(); //Prevent toggle when clicking on li
                          }}
                        >
                          <Link
                            className="nav-link icon btn btn-light p-2"
                            onClick={() => toggleChat(list.listId, index)}
                          >
                            <ChatLeftTextFill className="fs-6" /> Chat
                          </Link>
                        </li>
                        <div
                          className={`dropdown ${list?.name
                            .split(" ")
                            .join("")} Other`}
                        >
                          <div
                            className={`nav-link btn p-0 fs-1`}
                            id={`dropdownMenuButton${list.name}`}
                            data-bs-toggle="dropdown"
                            onClick={(e) => {
                              e.stopPropagation();
                              closeOtherDropdown(list);
                              closeShareDropdown("Share", list);
                            }}
                            aria-expanded="false"
                          >
                            <ThreeDotsVertical className="mb-1" />
                          </div>
                          <ul
                            className="dropdown-menu dropdown-menu-end mt-0 p-2"
                            aria-labelledby={`dropdownMenuButton${list.name}`}
                          >
                            <li
                              onClick={(e) => {
                                e.stopPropagation();
                                closeOtherDropdown(list, "Share");
                              }}
                            >
                              <ListShareMenu
                                list={list}
                                listName={list.name}
                                myName={user?.displayName}
                                listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                                listId={list.listId}
                                userId={user?.uid}
                                products={list.products}
                              />
                            </li>
                            <li
                              className="p-0"
                              onClick={(e) => {
                                e.stopPropagation();
                                closeOtherDropdown(list);
                              }}
                            >
                              <CloneModal
                                list={list}
                                listName={list?.name}
                                userId={user?.uid}
                                shoppingListData={shoppingListData}
                                setShoppingListData={setShoppingListData}
                                setLoading={setLoading}
                              />
                            </li>
                            <li
                              className="p-0"
                              onClick={(e) => {
                                e.stopPropagation();
                                closeOtherDropdown(list);
                              }}
                            >
                              <Settings selectedShopList={list.id} />
                            </li>
                            <li
                              className="mb-2 p-0"
                              onClick={(e) => {
                                e.stopPropagation();
                                closeOtherDropdown(list);
                              }}
                            >
                              <CartModal
                                listName={list.id}
                                listProducts={list.products}
                                userId={user?.uid}
                              />
                            </li>
                            <li
                              className="p-0"
                              onClick={(e) => {
                                e.stopPropagation();
                                closeOtherDropdown(list);
                              }}
                            >
                              <DeleteShopList
                                user={user}
                                list={list}
                                shoppingListData={shoppingListData}
                                setShoppingListData={setShoppingListData}
                                setSelectedShopList={setSelectedShopList}
                                setLoading={setLoading}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <ul className="d-flex nav p-0 align-items-center">
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <InvitePeople
                            selectedShopList={list}
                            listId={list.listId}
                            listName={list?.id}
                            myName={user?.displayName}
                            listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                          />
                        </li>
                        <li
                          className="ms-2"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Link
                            className="nav-link icon btn btn-light p-2"
                            onClick={() => toggleChat(list.listId, index)}
                          >
                            <ChatLeftTextFill className="fs-6" /> Chat
                          </Link>
                        </li>
                        <li
                          className="ms-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            closeOtherDropdown(list, "Share");
                          }}
                        >
                          <ListShareMenu
                            list={list}
                            listName={list.name}
                            myName={user?.displayName}
                            listLink={`${baseURL}/#/shoppingList/${list.listId}/${user?.uid}`}
                            listId={list.listId}
                            userId={user?.uid}
                            products={list.products}
                          />
                        </li>
                        {/* DropDown for medium screen */}
                        <div
                          className={`dropdown ${list?.name
                            .split(" ")
                            .join("")} Other`}
                        >
                          <div
                            className={`nav-link btn p-0 fs-3 dropdown-toggle`}
                            id={`${list.name}Dropdown`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={(e) => {
                              e.stopPropagation();
                              closeOtherDropdown(list);
                              closeShareDropdown("Share", list);
                            }}
                          >
                            <ThreeDotsVertical className="mb-1" />
                          </div>
                          <ul
                            className="dropdown-menu dropdown-menu-end mt-0 p-2"
                            aria-labelledby={`${list.name}Dropdown`}
                          >
                            <li
                              className="p-0"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <CloneModal
                                list={list}
                                listName={list?.name}
                                userId={user?.uid}
                                shoppingListData={shoppingListData}
                                setShoppingListData={setShoppingListData}
                                setLoading={setLoading}
                              />
                            </li>
                            <li
                              className="p-0"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <Settings selectedShopList={list.id} />
                            </li>
                            <li
                              className="mb-2 p-0"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <CartModal
                                listName={list.id}
                                listProducts={list.products}
                                userId={user?.uid}
                              />
                            </li>
                            <li
                              className="p-0"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <DeleteShopList
                                user={user}
                                list={list}
                                shoppingListData={shoppingListData}
                                setShoppingListData={setShoppingListData}
                                setSelectedShopList={setSelectedShopList}
                                setLoading={setLoading}
                              />
                            </li>
                          </ul>
                        </div>
                      </ul>
                    )}
                  </div>

                  {/* Shopping List Body */}
                  {activeAccordion.includes(list.listId) && (
                    <>
                      <hr className="m-0 p-0" />
                      <div onClick={(e) => e.stopPropagation()}>
                        {/* {shoppingListData?.length > 0 && <hr className="m-0 p-0" />} */}
                        <ProductList2
                          shoppingListData={shoppingListData}
                          selectedShopListName={list.name}
                          selectedShopListId={list.listId}
                          userId={user?.uid}
                          componentName={"ShoppingList"}
                          ownerId={user?.uid}
                        />
                        {!!showComments[list.listId] && (
                          <>
                            <hr className="m-0 p-0" />
                            <ListComments
                              userId={user?.uid}
                              listId={list.listId}
                              ownerId={user?.uid}
                              shoppingListData={shoppingListData}
                              setShoppingListData={setShoppingListData}
                              selectedShopList={list?.name}
                              notify={false}
                              show={!!showComments[list.listId]}
                              toggleChat={toggleChat}
                              reference={(el) => (refs.current[index] = el)}
                            />
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </>
            );
          })}
        {shoppingListData?.length === 0 && !loading && (
          <div
            className="card mb-1 mt-2 rounded-2 pt-0 pb-0 profileShoppingList"
            style={{ height: "25rem", maxHeight: "25rem" }}
          >
            <EmptyList content={"There is no list available to display"} />
          </div>
        )}
      </span>
    </>
  );
};

export default MyList;
