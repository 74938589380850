import React, { useState, useEffect } from "react";
import ProductCard from "../Products/ProductCard";
import algoliasearch from "algoliasearch";
import { useParams, useLocation, Link } from "react-router-dom";
import useSearchedProducts from "../StatesStore/searchedProducts";
import useSearchFilter from "../StatesStore/SearchFilters";
import UserProfileCard from "../Header/userProfileCard";
import { UserAuth } from "../Context";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import ProductCard6 from "../Products/ProductCard6";
import ShoppingList from "./ShoppingList";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { saveShoppingListToAlgolia } from "../lib/algoliaFunctions";
//algolia object initialization and index mapping
const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);

const index = client.initIndex("products"); //name of index in algolia is products
const indexUsers = client.initIndex("users"); // Name of index in Algolia is users
const indexShoppingList = client.initIndex("shoppingList"); // Name of index in Algolia is shoppingList

export default function SearchResults() {
  //**********************  Router Functions  **********************************//
  const location = useLocation();
  const { input } = useParams();
  const products = useSearchedProducts((state) => state.products);

  //*********************** other states     *************************************/
  const [allProducts, setAllProducts] = useState([]);
  const [allUsers, setAllUsers] = useState([]); // Corrected line
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [countLeftUser, setCountLeftUser] = useState(false);
  const [countLeftProduct, setCountLeftProduct] = useState(false);
  const [paramInput, setParamInput] = useState(input);
  const [totalProductCount, setTotalProductCount] = useState(0); //total product count
  const [totalUserCount, setTotalUserCount] = useState(0); //total User count
  const [allShoppingLists, setAllShoppingLists] = useState([]);
  const [countLeftShoppingList, setCountLeftShoppingList] = useState(false);
  const [totalShoppingListCount, setTotalShoppingListCount] = useState(0); //total User count

  const { user } = UserAuth();

  const [result, setResult] = useState(false);
  const setSearchedProduct = useSearchedProducts(
    (state) => state.setSearchedProduct
  );
  const searchFilter = useSearchFilter((state) => state.SearchFilter);

  //******************************* Functions **********************************************/
  useEffect(() => {
    console.log("Page count in useeffect", currentPage);
    setResult(false);
    setAllProducts([]);
    setSearchedProduct([]);
    setCurrentPage(0);
    setParamInput(input);
    retrieveData(0);
    setResult(true);
    setCurrentPage(currentPage + 1);
  }, [location.pathname, input]); //only updates when user refreshes

  //**********************   Handle Load More ********************************************/
  // ****************** async call to retrieve data **************************************/
  const retrieveData = async (page) => {
    if (input.length >= 3) {
      // console.log('Page before fetch: ', page);
      try {
        const offset = page * itemsPerPage;
        const data = await index.search(input, {
          offset,
          length: itemsPerPage,
        });
        setLoading(false);

        // Create a Set to ensure unique products
        const uniqueProducts = new Set([...allProducts, ...data.hits]);

        // Convert the Set back to an array
        const updatedProducts = Array.from(uniqueProducts);

        setAllProducts(updatedProducts);
        setTotalProductCount(data.nbHits);
        console.log("all products in search 1111", updatedProducts);

        setSearchedProduct(data.hits);

        if (data.nbHits > data.offset) {
          setCountLeftProduct(true);
        } else {
          setCountLeftProduct(false);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }

      try {
        const offset = page * itemsPerPage;
        const data = await indexUsers.search(input, {
          offset,
          length: itemsPerPage,
          typoTolerance: "strict", // Enable strict typo tolerance
          // Contexts:['firstName', 'lastName', 'email', 'userName'], // Searchable contexts
          synonyms: false, // Disable synonyms
        });

        // // Create a Set to store unique users based on a unique identifier (e.g., user ID)
        // const uniqueUserSet = new Set(allUsers.map(user => user.email));

        // // Filter out duplicates and convert back to an array
        // const uniqueUsers = data.hits.filter(user => !uniqueUserSet.has(user.email));
        console.log("Data hits in search", data.hits);
        setLoading(false);
        const uniqueUsers = data.hits.filter(
          (user) => user.objectUser?.isVerified
        );
        setAllUsers(uniqueUsers);
        setTotalUserCount(data.nbHits);
        setCountLeftUser(data.nbHits > offset + itemsPerPage);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }

      try {
        const offset = page * itemsPerPage;
        const data = await indexShoppingList.search(input, {
          typoTolerance: "strict", // Enable strict typo tolerance
          synonyms: false, // Disable synonyms
        });

        // // Create a Set to store unique users based on a unique identifier (e.g., user ID)
        // const uniqueUserSet = new Set(allUsers.map(user => user.email));

        // // Filter out duplicates and convert back to an array
        // const uniqueUsers = data.hits.filter(user => !uniqueUserSet.has(user.email));
        console.log("Data hits in search", data.hits);
        setLoading(false);
        // const uniqueShoppingLists = data.hits.filter(
        //   (user) => user.visibility === "public"
        // );
        // setAllShoppingLists(uniqueShoppingLists);
        setAllShoppingLists(data.hits);
        setTotalShoppingListCount(data.nbHits);
        setCountLeftShoppingList(data.nbHits > offset + itemsPerPage);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };
  // console.log('global products in search 1111', products);
  const filteredProducts = products.filter(
    (product) =>
      (searchFilter.gender.length === 0 ||
        searchFilter.gender.includes(product.category)) &&
      (searchFilter.brand.length === 0 ||
        searchFilter.brand.includes(product.supplier)) &&
      searchFilter.minprice <= product.newPrice &&
      searchFilter.maxprice >= product.newPrice
  );

  const isFilter =
    searchFilter?.gender.length > 0 ||
    searchFilter?.brand.length > 0 ||
    searchFilter?.size.length > 0 ||
    searchFilter?.minprice > 0 ||
    searchFilter?.maxprice < 90000;

  // console.log("value of current page :", currentPage);

  // console.log("value of countLeft page :", countLeftProduct);
  const getAllShoppingLists = async () => {
    try {
      const usersRef = collection(db, "users");
      const usersSnap = await getDocs(usersRef);
      await Promise.all(
        usersSnap.docs.map(async (user) => {
          const userRef = doc(db, "users", user?.id);
          const shoppingListRef = collection(userRef, "shoppingList");
          let q;
          // q = query(shoppingListRef);
          q = query(shoppingListRef, where("visibility", "==", "Public")); // Fetch only public shopping lists
          try {
            const shoppingListDocs = await getDocs(q);
            const shoppingListData = shoppingListDocs.docs.map(
              async (document) => {
                return saveShoppingListToAlgolia(user, document.id);
              }
            );
            await Promise.all(shoppingListData);
          } catch (error) {
            console.error("Error setting up snapshot listener:", error);
          }
        })
      );
    } catch (error) {
      console.error(error);
    }
    {
      /* {
  "id": "Favorites (2)",
  "name": "Favorites (2)",
  "dateCreated": 1742339935,
  "lastUpdated": 1742341185,
  "products": [
    {
      "id": "12599325753504",
      "timestamp": {
        "seconds": 1742339936,
        "nanoseconds": 45000000
      },
      "likeCount": 0,
      "updatedAt": {
        "seconds": 1742339936,
        "nanoseconds": 45000000
      },
      "size": []
    },
    {
      "id": "12706201436320",
      "timestamp": {
        "seconds": 1742339936,
        "nanoseconds": 228000000
      },
      "likeCount": 0,
      "updatedAt": {
        "seconds": 1742339936,
        "nanoseconds": 228000000
      },
      "size": []
    }
  ],
  "listId": "a97132e5-92d2-4849-819a-c01e4d471ad2",
  "visibility": "public",
  "ownerId": "2owZ9Nyd9gauVFtCiGi8W6kHcj72",
  "objectID": "cfb51b4d2c309_dashboard_generated_id"
}
  */
    }
  };

  return (
    // <div className="col-md-8 col-lg-6 vstack p-0 mt-0 mt-lg-5 ">
    <div style={{ width: "50%" }} className="ms-0 ms-lg-1 vstack px-0 gap-2">
      {loading ? (
        <LoadingSpinner />
      ) : allUsers.length === 0 &&
        filteredProducts.length === 0 &&
        allShoppingLists.length === 0 ? (
        <div
          style={{ height: "35rem" }}
          className="w-100 card d-flex justify-content-center align-items-center"
        >
          <h3 className="fw-semibold">
            No Users or Products or Shopping Lists Found
          </h3>
        </div>
      ) : (
        <></>
      )}
      {/* <div className="btn btn-primary w-100 mb-3" onClick={getAllShoppingLists}>
        Add all data of shopping list
      </div> */}
      {allUsers.length > 0 && (
        <div className="userSection card p-0">
          <div className="card-header border-0 p-2 pb-0">
            {result && (
              <div className="card-title d-flex justify-content-between align-items-center mb-0">
                <h4>Users</h4>
                {(countLeftUser > 8 ||
                  totalUserCount > currentPage * itemsPerPage) && (
                  <Link to={`${location.pathname}/users`} className="nav-link">
                    <u>View All {totalUserCount} Users</u>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="card-body p-2 pt-0">
            <div className="row g-4 m-0">
              {paramInput.length >= 3 && allUsers.length ? (
                allUsers?.map((searchUser, index) => {
                  return (
                    <UserProfileCard
                      key={index}
                      searchUser={searchUser}
                      user={user}
                    />
                  );
                })
              ) : (
                <div>Need At least three Characters to search</div>
              )}
            </div>
          </div>
        </div>
      )}
      {filteredProducts.length > 0 && (
        <div className="productsSection card p-0">
          <div className="card-header border-0 p-2 pb-0">
            {result && (
              <div className="card-title d-flex justify-content-between align-items-center">
                <h4>Products</h4>
                {(countLeftProduct > 8 ||
                  totalProductCount > currentPage * itemsPerPage) && (
                  <Link
                    to={`${location.pathname}/products`}
                    className="nav-link"
                  >
                    <u>View All {totalProductCount} Products</u>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="card-body p-1 pt-0">
            <div className="row g-4 p-0 m-0">
              {paramInput.length >= 3 && filteredProducts.length ? (
                filteredProducts.map((product, index) => (
                  <div
                    className={
                      product &&
                      `col-6 col-sm-4 col-md-3 col-lg-20 p-1 m-0 h-100`
                    }
                  >
                    <ProductCard6 key={index} product={product} />
                  </div>
                ))
              ) : (
                <div>Need At least three Characters to search</div>
              )}
            </div>
          </div>
        </div>
      )}
      {allShoppingLists.length > 0 && (
        <div className="shoppingListsSection card p-0">
          <div className="card-header border-0 p-2 pb-0">
            {result && (
              <div className="card-title d-flex justify-content-between align-items-center mb-0">
                <h4>Shopping Lists</h4>
                {(countLeftShoppingList > 8 ||
                  totalShoppingListCount > currentPage * itemsPerPage) && (
                  <Link
                    to={`${location.pathname}/shoppinglists`}
                    className="nav-link"
                  >
                    <u>View All {totalShoppingListCount} Shopping Lists</u>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="card-body p-1 pt-0">
            {/* <div className="d-flex w-100 flex-wrap gap-2"> */}
            <div className="row g-4 p-0 m-0">
              {paramInput.length >= 3 && allShoppingLists.length ? (
                allShoppingLists.map((shoppingList, index) => (
                  <>
                    <ShoppingList key={index} list={shoppingList} user={user} />
                  </>
                ))
              ) : (
                <div>Need At least three Characters to search</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
