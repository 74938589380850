import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import useModalStore from "../StatesStore/ModalStore";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
function ConfirmDelete() {
  const { title, data, closeModal } = useModalStore();
  const [loading, setLoading] = useState(false);
  return (
    <Modal show centered onHide={closeModal} className="confirmDelete">
      <style jsx>
        {`
          ${data?.componentName === "ShopListProdCard" &&
            `.confirmDelete .modal-content {
            margin: 0rem 13rem;
            box-shadow: 0 0.5rem 1rem rgba(83, 88, 93, 0.25) !important;
          }`}
        `}
      </style>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <i
          style={{ fontSize: "4.5rem" }}
          className="text-body bi bi-exclamation-triangle"
        ></i>
        <div className="mt-4 fs-5 text-body">
          Are you sure you want to delete {data?.displayName}
          {data?.type === "cart" ? " from cart?" : ""}
          {title === "shoppingListProduct"
            ? ` from ${data?.shoppingListName}?`
            : ""}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button
          variant="primary-soft"
          className="w-25 mx-1"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button
          variant="danger-soft"
          className="w-25 mx-1"
          onClick={async () => {
            if (data?.type === "cart") data?.deleteFunction(data?.id);
            else {
              try {
                setLoading(true);
                await data?.deleteFunction();
              } catch (error) {
                console.log(error);
              } finally {
                setLoading(false);
              }
            }
            closeModal();
            if (title === "shoppingList") {
              toast.success(
                data?.displayName + " Deleted Successfully",
                toastOptions
              );
            }
          }}
        >
          {loading ? (
            <div
              className="spinner-border text-white"
              style={{ width: "1rem", height: "1rem" }}
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            "Delete"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDelete;
