import { Adsense } from '@ctrl/react-adsense';
import React from 'react'

function ProductGoogleAdCard() {
    //     return (
    //     <div
    //       style={{
    //         width: "224px",
    //         height: "330px",
    //         display: "inline-block",
    //         // marginBottom: "4px",
    //         marginTop: "4px",
    //         padding: "14px",
    //         // paddingLeft:'7px',
    //         marginLeft:'7px',
    //         marginRight:'5px',
    //         // marginBottom:'12px',
    //         backgroundColor: "rgba(220, 85, 85, 0.1)", // Light gray background
    //         // border: "1px solid #ddd", // Subtle border
    //         // borderRadius: "8px", // Rounded corners
    //         boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
    //       }}
    //       className=""
    //     >
    //       {/* Blank space */}
    //     </div>
    //   );

    return (
        <Adsense
            style={{
                width: "228px",
                height: "330px",
                display: "block",
                marginTop:'2px',
                // marginLeft: '-2px',
                marginRight: '5px',
                padding:'2px',
                paddingRight:'4px'
            }}
            client="ca-pub-6917679702799024"
            slot="3975798246"
            format=""
        />
    );
}

export default ProductGoogleAdCard