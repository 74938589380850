import React, { useEffect, useRef, useState } from "react";
import UserMenuHeader from "../Authentication/UserMenuHeader";
import SignInMenuHeader from "../Authentication/SignInMenuHeader";
import { UserAuth } from "../Context";
import HeaderMenu2 from "./HeaderMenu2";
import { Link, useHistory, useLocation } from "react-router-dom";

import "./style.css";
import { Button, Container, FormControl, Nav, Navbar } from "react-bootstrap";
import useSearchInputStore from "../StatesStore/SearchInputStore";
import UserMenuHeader3 from "../Authentication/UserMenuHeader3";
import useSearchBarOpen2State from "../StatesStore/SearchBarOpen2";

function Header() {
  const { user } = UserAuth();
  const location = useLocation();
  const [activeAccordion, setActiveAccordion] = useState(null);
  // const { isSearchBarOpen2, setIsSearchBarOpen2 } = useSearchBarOpen2State();
  // searchInput state is used to store user searchInput
  // const [searchInput, setSearchInput] = useState("");
  const {
    searchInput,
    setSearchInput,
    resetSearchInput,
  } = useSearchInputStore();
  // Handle outside click
  const handleOutsideClick = () => {
    const main = document.getElementById("main");
    // const navbarCollapsible = document.getElementById("navbarCollapse");
    const brand = document.getElementById("brandsCollapse");
    // const category = document.getElementById("categoryCollapse");
    const menCategory = document.getElementById("menCollapse");
    const womenCategory = document.getElementById("womenCollapse");

    setSearchInput(""); // Reset searchInput state to empty
    function fun() {
      if (main && main.classList.contains("show")) {
        main.setAttribute("data-bs-toggle", "collapse");
        main.setAttribute("data-bs-target", "#navbarCollapse");
      }
      if (user && brand && brand.classList.contains("show")) {
        brand.classList.remove("show");
      }
      // if (category.classList.contains("show")) {
      //   category.classList.remove("show");
      // }
      if (user && menCategory && menCategory.classList.contains("show")) {
        menCategory.classList.remove("show");
      }
      if (user && womenCategory && womenCategory.classList.contains("show")) {
        womenCategory.classList.remove("show");
      }

      setActiveAccordion(0);
      // document.querySelector(".brandsScrollBar").scrollTo(0, 0);
      // console.log(main);
      // console.log(navbarCollapsible);
    }
    setTimeout(fun, 400);
  };

  useEffect(() => {
    const main = document.getElementById("main");
    main.addEventListener("click", handleOutsideClick);
    // const brand = document.getElementById("brandsCollapse");
    // const category = document.getElementById("categoryCollapse");
    // brand.addEventListener("click", handleOutsideClick);
    // category.addEventListener("click", handleOutsideClick);

    setSearchInput("");
    return () => {
      main.removeEventListener("click", handleOutsideClick);
      // brand.removeEventListener("click", handleOutsideClick);
      // category.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchInput("");
    if (searchInput.length > 2) {
      window.location.href = `#/search/${searchInput}`;
    }
  };
  const handleLogoClick = () => {
    setSearchInput(""); // Reset searchInput state to empty
  };
  return (
    <>
      <Navbar className="shadow-sm bg-mode border border-0 border-bottom">
        <Container className="text-center align">
          {/* Logo START */}
          <Link
            className="text-body text-center fs-icon"
            to="/"
            onClick={handleLogoClick}
          >
            <div style={{ fontFamily: "Nunito Sans" }}>
              <i>lookflock &nbsp;</i>
            </div>
          </Link>
          {/* <a class="navbar-brand" href="index.html">
            <img
              class="light-mode-item navbar-brand-item"
              src="assets/images/logo.svg"
              alt="logo"
            />
            <img
              class="dark-mode-item navbar-brand-item"
              src="assets/images/logo.svg"
              alt="logo"
            />
          </a> */}
          {/* Logo END */}

          {/* Nav Search START */}
          <Nav className=" nav mt-0 flex-nowrap justify-content align-items-center px-0 ">
            <Nav.Item className="w-100">
              <form
                className="rounded position-relative"
                onSubmit={handleSearch}
              >
                <FormControl
                  className="ps-5 bg-light"
                  type="search"
                  name="searchInput"
                  placeholder="Search..."
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button
                  variant="mode"
                  type="submit"
                  className="bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y"
                >
                  <i className="bi bi-search fs-5"></i>
                </Button>
              </form>
            </Nav.Item>
          </Nav>

          {/* Responsive navbar toggler */}
          <div className="ms-auto my-1 py-4"></div>
          {/* <div className="navbar-nav ms-auto bg-transparent">
            <button
              id="closeNav"
              className="d-none d-lg-block border border-0 nav-link ms-auto bg-transparent"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleOutsideClick}
            >
              Menu
              <i className="fs-6 bi bi-chevron-down px-2"></i>
            </button>
            <button
              className="d-lg-none d-block navbar-toggler ms-auto icon-md btn btn-light p-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </span>
            </button>
          </div> */}

          {/* Nav right START */}
          <ul className="nav flex-nowrap align-items-center list-unstyled position-relative ps-2 gap-2">
            {user ? <UserMenuHeader3 /> : <SignInMenuHeader />}
          </ul>
        </Container>
      </Navbar>
      {/* <Navbar className="shadow-sm bg-mode border border-0 border-bottom">
        <Container className="text-center align">
          <Link
            className="text-body text-center fs-icon"
            to="/"
            onClick={handleLogoClick}
          >
            <div style={{ fontFamily: "Nunito Sans" }}>
              <i>lookflock &nbsp;</i>
            </div>
          </Link>
          <style jsx>
            {`
              .searchBarFull {
                width: 2.5rem;
                transition: width 0.2s ease;
              }
              .searchBarFull.show {
                width: 23rem;
              }
            `}
          </style>
          <Nav className="nav mt-0 flex-nowrap justify-content align-items-center px-0 ms-auto">
            <Nav.Item>
              <form
                className={`rounded position-relative searchBarFull ${
                  isSearchBarOpen2 ? "show" : ""
                }`}
                onSubmit={handleSearch}
              >
                <FormControl
                  className={`${
                    isSearchBarOpen2 ? "ps-5 bg-light" : "ps-2 border-0"
                  } `}
                  type="search"
                  name="searchInput"
                  placeholder={isSearchBarOpen2 ? "Search..." : ""}
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button
                  variant="mode"
                  type="submit"
                  className="bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y"
                  onClick={() => {
                    setIsSearchBarOpen2(!isSearchBarOpen2);
                  }}
                >
                  <i className="bi bi-search fs-4"></i>
                </Button>
              </form>
            </Nav.Item>
          </Nav>
          <ul className="nav flex-nowrap align-items-center list-unstyled position-relative ps-2 gap-2">
            {user ? <UserMenuHeader3 /> : <SignInMenuHeader />}
          </ul>
        </Container>
      </Navbar> */}

      {/* Collapsed Content */}
      {/* <div className="bg-mode collapse navbar-collapse" id="navbarCollapse">
        <div className="py-4 container">
          <HeaderMenu2
            setSearchInput={setSearchInput}
            // handleOutsideClick={handleOutsideClick}
            activeAccordion={activeAccordion}
            setActiveAccordion={setActiveAccordion}
          />
        </div>
      </div> */}
    </>
  );
}

export default Header;
