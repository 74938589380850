import React from "react";
import {
  SideNavPriceRangeDropDown,
  SideNavGenderDropDown,
  SideNavSizeDropDown,
  SideNavColorDropDown,
  SideNavCategoryDropDown,
  SideNavSubCategoryDropDown,
} from "./SideNavBrandDropDown";

export default function BrandsPageSideNav({ brandName }) {
  // console.log('Results are',Result);

  return (
    <div className="offcanvas-body d-block bg-mode rounded-2 p-0 ">
      {/* Card START */}
      <p className="fw-semibold m-0 fs-2 p-3 pb-0">Filters &nbsp;</p>
      {/* GENDER */}
      <div className="p-3 pb-2">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseGender"
          aria-expanded="false"
          aria-controls="collapseGender"
        >
          Gender &nbsp;
        </p>
        <SideNavGenderDropDown
          dataId={"collapseGender"}
          brandName={brandName}
        />
      </div>
      <hr className="m-0" />

      <div className="p-3 pb-2">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseCategory"
          aria-expanded="false"
          aria-controls="collapseCategory"
        >
          Category &nbsp;
        </p>
        <SideNavCategoryDropDown dataId={"collapseCategory"} />
      </div>
      <hr className="m-0" />
      <div className="p-3 pb-2 pe-2">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSubCategory"
          aria-expanded="false"
          aria-controls="collapseSubCategory"
        >
          Sub-Category &nbsp;
        </p>
        <SideNavSubCategoryDropDown
          dataId={"collapseSubCategory"}
          brandName={brandName}
        />
      </div>
      <hr className="m-0" />
      <div className="p-3 pb-2">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapsePriceRange"
          aria-expanded="false"
          aria-controls="collapsePriceRange"
        >
          Price &nbsp;
        </p>
        <SideNavPriceRangeDropDown dataId={"collapsePriceRange"} />
      </div>
      <hr className="m-0" />
      {/* PRICE RANGE */}
      <SideNavSizeDropDown dataId={"collapseSize"} brandName={brandName} />
      <hr className="m-0" />
      <div className="p-3 pb-2">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseColor"
          aria-expanded="false"
          aria-controls="collapseColor"
        >
          Color &nbsp;
        </p>
        <SideNavColorDropDown dataId={"collapseColor"} brandName={brandName} />
      </div>
      <hr className="m-0" />
    </div>
  );
}
