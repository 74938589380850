import React, { useState, useEffect, useRef } from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db, auth } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import {
  EmailAuthProvider,
  updatePassword,
  reauthenticateWithCredential,
} from "firebase/auth";
import { toastOptions } from "../lib/toastOptions";
import useUserCountry from "../StatesStore/UserCountry";
// import { Link } from 'react-router-dom';
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import { Link } from "react-router-dom";
const AccountSetting = () => {
  const { user } = UserAuth();
  const [activeTab, setActiveTab] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    birthday: "",
    gender: "",
    phoneNumber: "",
    email: "",
    overview: "",
    location: "",
    userTitle: "",
    region: "",
    requestList: [],
    sendList: [],
    friendList: [],
  });
  const libraries = ["places"];
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const isGoogleUser =
    user &&
    user.providerData &&
    user.providerData.some((provider) => provider.providerId === "google.com");
  const [passwordMismatchError, setPasswordMismatchError] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const country = useUserCountry((state) => state.country);
  const inputRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDeRZ4H3Pg1YWsVb-ZX1GDqNN7hp7PaRw8",
    libraries,
  });

  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      console.log(place.formatted_address);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
      setUserData({ ...userData, location: place.formatted_address });
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user && user.uid) {
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userDataFromDb = userDocSnapshot.data();
            console.log(userDataFromDb.birthday);
            setUserData({
              firstName: userDataFromDb.firstName || "",
              lastName: userDataFromDb.lastName || "",
              userName: userDataFromDb.userName || "",
              birthday: userDataFromDb.birthday || "",
              gender: userDataFromDb.gender || "",
              phoneNumber: userDataFromDb.phoneNumber || "",
              email: userDataFromDb.email || "",
              overview: userDataFromDb.overview || "",
              userTitle: userDataFromDb.userTitle || "",
              location: userDataFromDb.location || "",
              region: userDataFromDb.region || "",
              requestList: userDataFromDb.requestList || [],
              sendList: userDataFromDb.sendList || [],
              friendList: userDataFromDb.friendList || [],
            });
          }
        }
      } catch (error) {
        console.error("Error fetching user data from database", error);
      }
    };

    fetchUserData();
  }, [user, country]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!nameRegex.test(userData.firstName)) {
      setFirstNameError("First name should only contain letters.");
      return;
    } else {
      setFirstNameError("");
    }

    if (!nameRegex.test(userData.lastName)) {
      setLastNameError("Last name should only contain letters.");
      return;
    } else {
      setLastNameError("");
    }
    try {
      const userDocRef = doc(db, "users", user.uid);
      const mergedUserName = `${userData.firstName} ${userData.lastName}`;
      await updateDoc(userDocRef, {
        firstName: userData.firstName
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        lastName: userData.lastName
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        userName: mergedUserName,
        birthday: userData.birthday,
        gender: userData.gender,
        phoneNumber: userData.phoneNumber,
        email: userData.email,
        overview: userData.overview,
        userTitle: userData.userTitle,
        location: userData.location,
        requestList: userData.requestList,
        sendList: userData.sendList,
        friendList: userData.friendList,
      });
      console.log("User profile updated successfully");
      toast.success("Profile updated successfully", toastOptions);
    } catch (error) {
      console.error("Error updating user profile", error);
      toast.error("Error updating user profile", toastOptions);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      if (auth && user && user.email) {
        if (newPassword === confirmPassword) {
          const credential = EmailAuthProvider.credential(
            user.email,
            currentPassword
          );
          await reauthenticateWithCredential(auth.currentUser, credential);
          await updatePassword(auth.currentUser, newPassword);
          console.log("Password updated successfully");
          toast.success("Password updated successfully", toastOptions);
          setPasswordMismatchError("");
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
        } else {
          console.error("New password and confirm password do not match");
          setPasswordMismatchError(
            "New password and confirm password do not match"
          );
        }
      } else {
        console.error("Auth object or user information is not available");
      }
    } catch (error) {
      console.error("Error updating password", error);
      if (error.code === "auth/wrong-password") {
        setPasswordMismatchError("Current password is incorrect");
      } else {
        setPasswordMismatchError("Error updating password. Please try again.");
      }
    }
  };
  return (
    <>
      <div className="card" style={{ minHeight: "38rem" }}>
        {/* Tab Navigation */}
        <ul className="card-header nav nav-tabs nav-bottom-line2 active justify-content-center justify-content-md-start my-0 pe-3 py-0 ps-4">
          <li className="nav-item">
            <Link
              className={`nav-link pt-3 pb-2 ${
                activeTab === "account" ? "active" : ""
              }`}
              // onClick={() => setActiveTab("account")}
            >
              Account Settings
            </Link>
          </li>
        </ul>
        <div className="card-body ms-1">
          <form className="row g-3" onSubmit={(e) => handleFormSubmit(e)}>
            <div className="col-sm-6">
              <label for="firstName" className="form-label">
                First name
              </label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                value={userData.firstName}
                onChange={(e) => {
                  setUserData({ ...userData, firstName: e.target.value });
                  setFirstNameError("");
                }}
              />
              {firstNameError && (
                <span style={{ color: "red" }}>{firstNameError}</span>
              )}
            </div>
            <div className="col-lg-6">
              <label for="lastName" className="form-label">
                Last name
              </label>
              <input
                id="lastName"
                type="text"
                className="form-control"
                value={userData.lastName}
                onChange={(e) => {
                  setUserData({ ...userData, lastName: e.target.value });
                  setLastNameError("");
                }}
              />
              {lastNameError && (
                <span style={{ color: "red" }}>{lastNameError}</span>
              )}
            </div>
            <div className="col-sm-6">
              <label for="nickName" className="form-label">
                Nickname/Title
              </label>
              <input
                id="nickName"
                type="text"
                className="form-control"
                value={userData.userTitle}
                onChange={(e) =>
                  setUserData({ ...userData, userTitle: e.target.value })
                }
              />
            </div>
            <div className="col-lg-6">
              <label for="birthday" className="form-label">
                Birthday{" "}
              </label>
              <div className="input-group form-control">
                {userData.birthday}
              </div>
            </div>
            <div className="col-lg-6">
              <label for="email" className="form-label">
                Email
              </label>
              <div className="input-group form-control">{userData.email}</div>
            </div>
            <div className="col-sm-6">
              <label for="phoneNumber" className="form-label">
                Phone number
              </label>
              <input
                id="phoneNumber"
                type="text"
                className="form-control"
                value={userData.phoneNumber}
                onChange={(e) =>
                  setUserData({ ...userData, phoneNumber: e.target.value })
                }
              />
            </div>
            <div className="col-lg-6">
              <label for="location" className="form-label">
                Location
              </label>
              {isLoaded && (
                <StandaloneSearchBox
                  onLoad={(ref) => (inputRef.current = ref)}
                  onPlacesChanged={handlePlaceChanged}
                >
                  <input
                    id="location"
                    type="text"
                    className="form-control flatpickr"
                    value={userData.location}
                    onChange={(e) =>
                      setUserData({ ...userData, location: e.target.value })
                    }
                    placeholder="Enter Location"
                  />
                </StandaloneSearchBox>
              )}
            </div>
            <div className="col-12">
              <label for="overview" className="form-label">
                Overview
              </label>
              <textarea
                id="overview"
                className="form-control"
                rows="4"
                value={userData.overview}
                onChange={(e) =>
                  setUserData({ ...userData, overview: e.target.value })
                }
              ></textarea>
              <small>Character limit: 300</small>
            </div>
            <div className="col-12 text-end">
              <button type="submit" className="btn btn-sm btn-primary mb-0">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* <div className="card mb-4">
        <div className="card-header border-0 pb-0">
          <h1 className="h5 card-title">Account Settings</h1>
        </div>
        <div className="card-body">
          <form className="row g-3" onSubmit={(e) => handleFormSubmit(e)}>
            <div className="col-sm-6">
              <label for="firstName" className="form-label">
                First name
              </label>
              <input
                type="text"
                id="firstName"
                className="form-control"
                value={userData.firstName}
                onChange={(e) => {
                  setUserData({ ...userData, firstName: e.target.value });
                  setFirstNameError("");
                }}
              />
              {firstNameError && (
                <span style={{ color: "red" }}>{firstNameError}</span>
              )}
            </div>
            <div className="col-lg-6">
              <label for="lastName" className="form-label">
                Last name
              </label>
              <input
                id="lastName"
                type="text"
                className="form-control"
                value={userData.lastName}
                onChange={(e) => {
                  setUserData({ ...userData, lastName: e.target.value });
                  setLastNameError("");
                }}
              />
              {lastNameError && (
                <span style={{ color: "red" }}>{lastNameError}</span>
              )}
            </div>
            <div className="col-sm-6">
              <label for="nickName" className="form-label">
                Nickname/Title
              </label>
              <input
                id="nickName"
                type="text"
                className="form-control"
                value={userData.userTitle}
                onChange={(e) =>
                  setUserData({ ...userData, userTitle: e.target.value })
                }
              />
            </div>
            <div className="col-lg-6">
              <label for="birthday" className="form-label">
                Birthday{" "}
              </label>
              <div className="input-group form-control">
                {userData.birthday}
              </div>
            </div>
            <div className="col-sm-6">
              <label for="phoneNumber" className="form-label">
                Phone number
              </label>
              <input
                id="phoneNumber"
                type="text"
                className="form-control"
                value={userData.phoneNumber}
                onChange={(e) =>
                  setUserData({ ...userData, phoneNumber: e.target.value })
                }
              />
            </div>
            <div className="col-lg-6">
              <label for="location" className="form-label">
                Location
              </label>
              {isLoaded && (
                <StandaloneSearchBox
                  onLoad={(ref) => (inputRef.current = ref)}
                  onPlacesChanged={handlePlaceChanged}
                >
                  <input
                    id="location"
                    type="text"
                    className="form-control flatpickr"
                    value={userData.location}
                    onChange={(e) =>
                      setUserData({ ...userData, location: e.target.value })
                    }
                    placeholder="Enter Location"
                  />
                </StandaloneSearchBox>
              )}
            </div>
            <div className="col-12">
              <label for="overview" className="form-label">
                Overview
              </label>
              <textarea
                id="overview"
                className="form-control"
                rows="4"
                value={userData.overview}
                onChange={(e) =>
                  setUserData({ ...userData, overview: e.target.value })
                }
              ></textarea>
              <small>Character limit: 300</small>
            </div>
            <div className="col-12 text-end">
              <button type="submit" className="btn btn-sm btn-primary mb-0">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div> */}
      {!isGoogleUser && (
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h5 className="card-title">Change your password</h5>
          </div>
          <div className="card-body">
            <form className="row g-3" onSubmit={(e) => handleChangePassword(e)}>
              <div className="col-12">
                <label className="form-label">Current password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter current password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
              </div>
              {passwordMismatchError && (
                <div className="text-danger mt-2">{passwordMismatchError}</div>
              )}
              <div className="col-12">
                <label className="form-label">New password</label>
                <div className="input-group">
                  <input
                    className="form-control"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="input-group-text p-0"
                  >
                    <i
                      className={`fakepasswordicon fa-solid fa-eye${
                        showPassword ? "" : "-slash"
                      } cursor-pointer p-2 w-40px`}
                    ></i>
                  </span>
                </div>
              </div>
              <div className="col-12">
                <label className="form-label">Confirm password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="col-12 text-end">
                <button type="submit" className="btn btn-primary mb-0">
                  Update password
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountSetting;
