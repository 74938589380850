import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { Modal, Form, Button, Container, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import useModalStore from "../StatesStore/ModalStore";
import { db } from "../FirebaseConfig";

function ContactUsModal() {
  const { closeModal } = useModalStore();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  // const image = process.env.PUBLIC_URL + '/assets/images/contactUs/model.svg';
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contactUs"), {
        name: name,
        email: email,
        subject: subject,
        message: message,
        createdAt: new Date(),
      });
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      // toast.success("Thank you for contacting us!", toastOptions);
      setIsSubmit(true);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <Modal show centered onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Contact us</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <Card className="rounded-2 border-0 p-0" style={{ height: "100%" }}>
          <Card.Body className="p-0">
            {!isSubmit ? (
              <Form
                onSubmit={handleSubmit}
                className="d-flex flex-column gap-3"
              >
                <Form.Group controlId="formName">
                  {/* <Form.Label>Name</Form.Label> */}
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formEmail">
                  {/* <Form.Label>Email</Form.Label> */}
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formSubject">
                  {/* <Form.Label>Subject</Form.Label> */}
                  <Form.Control
                    type="text"
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formMessage">
                  {/* <Form.Label>Message</Form.Label> */}
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button variant="primary-soft" type="submit" className="w-100">
                  Submit
                </Button>
              </Form>
            ) : (
              <Container className="d-flex flex-column justify-content-center align-items-center my-auto h-75">
                <h2>Thank you for contacting us!</h2>
                <div className="mb-1 h5 fw-light grey">
                  We have received your message and we will get back to you
                  soon.
                </div>
                <div className="h5 fw-light grey">
                  You can discover more products from here{" "}
                  <Link className="text-primary" to="/discover">
                    Discover
                  </Link>
                </div>
              </Container>
            )}
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}

export default ContactUsModal;
