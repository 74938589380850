import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useChannelStateContext, useChatContext } from "stream-chat-react";
import { UserAuth } from "../Context";

import { Card } from "react-bootstrap";
import ProfilePicture from "../UserProfile/ProfilePicture";
import useChatClient from "../StatesStore/ChatClient";
export const ChannelHeader = ({ setIsChatOpen }) => {
  const { user } = UserAuth();
  const { channel } = useChannelStateContext();
  const [channelUsers, setChannelUsers] = useState([]);
  const { client } = useChatContext();

  // Update the list of channel users dynamically
  useEffect(() => {
    const updateChannelUsers = (event) => {
      if (!client) return;
      if (!channel?.state) return;

      setChannelUsers(
        Object.values(channel.state.members).map((member) => ({
          id: member.user_id,
          name: member.user.name || "Unknown User",
          online: member.user.online || false,
          photo: member.user.image || "", // Assuming avatars are available
        }))
      );
    };

    // Initial update
    updateChannelUsers();

    // Listen for real-time presence changes
    client.on("user.presence.changed", updateChannelUsers);

    // Cleanup listener on unmount
    return () => {
      client.off("user.presence.changed", updateChannelUsers);
    };
  }, [channel, client]);

  if (!channel || !user) {
    return null;
  }

  return (
    <Card.Header className="d-flex justify-content-start align-items-center pt-2 pb-1 ps-2 border-bottom bg-mode">
      <button
        onClick={() => setIsChatOpen(false)}
        className="btn me-2 p-1 d-md-none"
      >
        <i className="fa-solid fa-arrow-left"></i>
      </button>
      {channel.type === "messaging" && channelUsers.length === 2 ? (
        // Private messaging channel with two members
        <Link
          to={`/userProfile/${
            channelUsers.find((member) => member.id !== user?.uid)?.id
          }`}
          className="d-flex align-items-center"
        >
          <div className="flex-shrink-0 avatar-sm me-2">
            <ProfilePicture
              userData={channelUsers.find((member) => member.id !== user?.uid)}
            />
          </div>
          <div className="d-block flex-grow-1">
            <h6 className="mb-0 mt-1">
              {channelUsers.find((member) => member.id !== user?.uid)?.name ||
                "User"}
            </h6>
            <div className="small ">
              <i
                className={`fa-solid fa-circle ${
                  channelUsers.find((member) => member.id !== user?.uid)?.online
                    ? "text-success"
                    : "text-muted"
                } me-1`}
              ></i>
              {channelUsers.find((member) => member.id !== user?.uid)?.online
                ? "Online"
                : "Offline"}
            </div>
          </div>
          {/* <div className="d-block ps-2">
            <h6 className="mb-0 mt-1">
              {channelUsers.find((member) => member.id !== user?.uid)?.name ||
                "User"}
            </h6>
            <h6 className="mb-0 fw-light">
              <i
                className={`fa-solid fa-circle ${
                  channelUsers.find((member) => member.id !== user?.uid)?.online
                    ? "text-success"
                    : "text-muted"
                } me-1`}
              ></i>
              {channelUsers.find((member) => member.id !== user?.uid)?.online
                ? "Online"
                : "Offline"}
            </h6>
          </div> */}
        </Link>
      ) : (
        <div className="d-flex">
          {/* {channelUsers.length === 1 ? (
            channelUsers.map((member) => (
              <div key={member.id} className="flex-shrink-0 avatar me-2">
                <ProfilePicture userData={member} />
              </div>
            ))
          ) : (
            <ul
              className={`mb-0 avatar-group avatar-group-${
                channelUsers.length === 2 ? "two" : "three"
              }`}
            >
              {channelUsers.slice(0, 3).map((member) => (
                <li key={member.id} className="avatar avatar-xs">
                  <ProfilePicture userData={member} />
                </li>
              ))}
            </ul>
          )} */}
          <div className="flex-shrink-0 avatar-sm me-2">
            <ProfilePicture userData={{ userName: channel.data.name }} />
          </div>
          <div className="d-block">
            <h6 className="mb-0 mt-1">
              {channel.data.name || "Group Chat"}
              <i className="fw-light">
                {" "}
                <span className="text-body">by</span>{" "}
                {channel.data.created_by.name
                  .split(" ")
                  .slice(0, 2)
                  .join(" ")}
              </i>
            </h6>
            <h6 className="mb-0 fw-light">
              {channelUsers
                .map((member) => member.name.split(" ")[0])
                .join(", ")}
            </h6>
          </div>
        </div>
      )}
    </Card.Header>
  );
};
