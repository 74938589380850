import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
  startAfter,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";

const fetchProducts = async ({
  activeBrands,
  brandName,
  category = [],
  subCategory = [],
  subSubCategory = [],
  sizes = [],
  lastVisible,
  currentPage,
  setAllProducts,
  setLoading,
  setLastVisible,
  setCountLeft,
  prevSubSubCategory,
  prevSubCategory,
  filter, 
  colors = [],
  minprice=null,
  maxprice=null
}) => {
  console.log("Active Brands from productUtils.js", activeBrands);
  console.log("sizes", sizes);

  if (
    subCategory !== prevSubCategory ||
    subSubCategory !== prevSubSubCategory ||
    (subCategory !== prevSubCategory && subSubCategory === prevSubSubCategory)
  ) {
    setCountLeft(true);
    setLastVisible(null);
  }

  // const filteredBrands = activeBrands?.filter((brand) => {
  //   return brand.name !== null && brand.name.trim(); // Handle empty brand names
  // });

  // Create combinations of sizes and colors
  const sizeColorCombos = sizes?.length > 0 && colors?.length > 0 ? 
    sizes.flatMap(size => colors.map(color => `${size}_${color}`)) : [];

    // console.log("sizeColorCombos type:", typeof sizeColorCombos, "content:", sizeColorCombos);
    // Log the first element to see its type
console.log("First element type:", typeof sizeColorCombos[0]);

// Or log all elements to see their types
sizeColorCombos.forEach((element, index) => {
  console.log(`Element ${index} type:`, typeof element);
});
console.log("i am from All products2 subCategory, subSubCategory, category",subCategory, subSubCategory, category);
  const productsCollectionRef = collection(db, "products");
  console.log("category?.length ",category?.length,category);
  console.log("and category",category);
  const categoryQueries = [
    // ...(filteredBrands?.length > 0 ? [where("supplier", "in", filteredBrands.slice(0, 5).map((brand) => brand.name))] : []),
    ...(brandName ? [where("supplier", "==", brandName)] : []),
    ...(activeBrands?.length > 0 ? [where("supplier", "in", activeBrands)] : []),
    ...(category?.length > 0 ? [where("category", "in", category)] : []),
    ...(subCategory?.length > 0 ? [where("subCategory", "in", subCategory)] : []),
    ...(subSubCategory?.length > 0 && !subSubCategory.includes("All")  ? [where("subSubCategory", "in", subSubCategory)] : []),
    ...(sizes?.length > 0 && sizes?.length === 0 ? [where("sizes", "array-contains-any", sizes)] : []),
    ...(colors?.length > 0 && colors?.length === 0 ? [where("colors", "array-contains-any", colors)] : []),
    ...(sizeColorCombos?.length > 0 ? [where("sizeColors", "array-contains-any", sizeColorCombos)] : []),
    ...(minprice !== null ? [where("newPrice", ">=", minprice)] : []),
    ...(maxprice !== null ? [where("newPrice", "<=", maxprice)] : []),
  ];

  console.log("color size combo", sizes.flatMap(size => colors.map(color => `${size}-${color}`)));

  let orderByField = "dateCreated";
  let orderDirection = "desc";

  switch (filter) {
    case "PL":
      orderByField = "newPrice";
      orderDirection = "asc";
      break;
    case "PH":
      orderByField = "newPrice";
      orderDirection = "desc";
      break;
    case "DL":
      orderByField = "discount";
      orderDirection = "asc";
      break;
    case "DH":
      orderByField = "discount";
      orderDirection = "desc";
      break;
    default:
      orderByField = "dateCreated";
      orderDirection = "desc";
  }

  const orderQueries = [
    orderBy(orderByField, orderDirection),
    ...(lastVisible && currentPage !== 1 ? [startAfter(lastVisible)] : []),
    limit(14),
  ];

  const data = query(productsCollectionRef, ...categoryQueries, ...orderQueries);

  try {
    setLoading(true)
    const querySnapshot = await getDocs(data);

    if (querySnapshot.size === 0) {
      setCountLeft(false);
    } else {
      setCountLeft(true);
    }
    const productsData = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    if (currentPage === 1) {
      setAllProducts(productsData);
    } else {
      setAllProducts((prevProducts) => [...prevProducts, ...productsData]);
    }

    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching products:", error);
    setLoading(false);
  }
};

export default fetchProducts;
