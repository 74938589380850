export const sendDetailView = async (userId, productId) => {
    try {
        const response = await fetch('http://localhost:3001/detailView', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, productId }),
        });

        if (!response.ok) {
            throw new Error('Failed to send detail view');
        }

        const data = await response.json();
        console.log('Detail view sent successfully:', data);
    } catch (error) {
        console.error('Error sending detail view:', error);
    }
};