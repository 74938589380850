import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import axios from "axios";
import useModalStore from "../StatesStore/ModalStore";
import handlefollowUser from "../hooks/user/followUser";
import handleUnfollowUser from "../hooks/user/unfollowUser";
// import UserActiveFollowers from "./ActiveFollowers";
// import axios from "axios"; // Assuming you are using axios for HTTP requests

export default function BrandFollower({ followerList, componentName }) {
  const { user } = UserAuth();
  const { openModal } = useModalStore();
  const [myfollowinglist, setMyFollowinglist] = useState([]);

  const myID = user;

  const fetchMyFollowingList = async () => {
    if (!myID) return;
    try {
      // const response = await axios.get(process.env.REACT_APP_API+`/api/userFollowing/${uid}`);
      const response = await axios.get(
        process.env.REACT_APP_API + `/api/userFollowing/${myID?.uid}`
      );
      // console.log("response.data", response.data);
      setMyFollowinglist(response.data);
    } catch (error) {
      console.error("Error fetching following list:", error);
    }
  };
  useEffect(() => {
    if (!followerList) return;
    fetchMyFollowingList();
  }, [followerList]);
  useEffect(() => {
    // if (!followerList) return;
    // fetchMyFollowingList();
    console.log("BRAND myfollowinglist", myfollowinglist);
  }, [myfollowinglist]);

  const handleClick = () => {
    openModal("SignInModal", "", "");
  };

  const handleClickUnfollow = async (userData) => {
    const DATA = {
      titleContent: "Confirm Unfollow?",
      content: "Are you sure you want to unfollow " + userData?.userName,
      buttonContent: "Unfollow",
      type: "Unfollow",
      UID: myID?.uid,
      friendUID: userData?.uid,
      yesFunction: handleUnfollowUser,
      setMyFollowinglist: setMyFollowinglist,
      myFollowingList: myfollowinglist,
    };
    openModal("ConfirmModal", "Unfollow", DATA);
  };

  return (
    <>
      <div
        className={`card pt-3`}
        // style={{ height: "23rem", overflowY: "auto" }}
      >
        {/* Card header START */}
        <div class="card-header border-0 pb-0 pt-0 mb-2">
          <h5 class="card-title">{followerList?.length || 0} Followers</h5>
        </div>
        {/* Card header END */}
        {/* Card body START */}
        <div class="card-body pt-0">
          {/* followers Item */}
          {followerList.map((user) => {
            return (
              <div class="d-flex align-items-center mb-4" key={user?.uid}>
                {/* Avatar */}
                <div class="avatar me-3 mb-0">
                  <Link
                    to={`/userProfile/${user?.uid}`}
                    class="avatar-img rounded-circle"
                  >
                    <ProfilePicture userData={user} avatarSize={42} />
                  </Link>
                </div>
                {/* Info */}
                <div class="w-100">
                  <div class="d-sm-flex align-items-start">
                    <h6 class="mb-0">
                      <Link
                        to={`/userProfile/${user?.uid}`}
                        className="h6 mb-0"
                      >
                        {user?.userName
                          ? user?.userName
                              .split(" ")
                              .slice(0, 2)
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")
                          : ""}
                      </Link>
                    </h6>
                    <p className="small ms-sm-2 mb-0">
                      {" "}
                      {user?.userTitle || ""}
                    </p>
                  </div>
                  {/* Connections START */}
                </div>
                {/* Button */}
                <div class="ms-md-auto d-flex">
                  {!(myID?.uid === user?.uid) ? (
                    myfollowinglist.some(
                      (following) =>
                        following?.id === user?.uid &&
                        following?.type === "user"
                    ) ? (
                      <button
                        className="btn btn-primary me-2"
                        style={{
                          minWidth: "6rem",
                          width: "fit-content",
                          padding: "0.375rem 0.75rem",
                        }}
                        onClick={async () => {
                          // await handleUnfollowUser(myID?.uid, user?.uid);
                          await handleClickUnfollow(user);
                        }}
                      >
                        Unfollow
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary me-2"
                        style={{
                          minWidth: "6rem",
                          width: "fit-content",
                          padding: "0.375rem 0.75rem",
                        }}
                        onClick={async () => {
                          if (myID) {
                            if (!myID?.emailVerified) {
                              openModal("VerifyEmailModal", "", {
                                userName: myID?.displayName,
                                email: myID?.email,
                              });
                            } else {
                              await handlefollowUser(myID?.uid, user?.uid);
                              setMyFollowinglist((prev) => [...prev, user]);
                              fetchMyFollowingList();
                            }
                          } else handleClick();
                        }}
                      >
                        Follow
                      </button>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {/* Card body END */}
      </div>
    </>
  );
}
