import React, { useEffect, useState } from "react";
import { useChannelStateContext } from "stream-chat-react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import ProductCard3 from "../Products/ProductCard3";

export const ProductsModalContent = ({ close }) => {
  const { user } = UserAuth();
  const [shoppingLists, setShoppingLists] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { channel } = useChannelStateContext();

  useEffect(() => {
    const fetchShoppingLists = async () => {
      if (user?.uid) {
        try {
          const shoppingListRef = collection(
            db,
            "users",
            user.uid,
            "shoppingList"
          );
          const querySnapshot = await getDocs(shoppingListRef);
          const lists = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setShoppingLists(lists);
        } catch (error) {
          console.error("Error fetching shopping lists:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchShoppingLists();
  }, [user?.uid]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (selectedList) {
        setLoading(true);
        try {
          const productsRef = collection(
            db,
            "users",
            user.uid,
            "shoppingList",
            selectedList,
            "products"
          );
          const productsSnapshot = await getDocs(productsRef);
          const productDetails = await Promise.all(
            productsSnapshot.docs.map(async (productDoc) => {
              const productId = productDoc.id;
              const productDocRef = doc(db, "products", productId);
              const productSnapshot = await getDoc(productDocRef);
              return productSnapshot.exists()
                ? { id: productId, ...productSnapshot.data() }
                : null;
            })
          );
          setProducts(productDetails.filter(Boolean));
        } catch (error) {
          console.error("Error fetching products:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchProducts();
  }, [selectedList, user?.uid]);

  const handleShareProducts = async () => {
    if (selectedProducts.length === 0) {
      alert("Please select at least one product to share.");
      return;
    }

    try {
      const allAttachments = products
        .filter((product) => selectedProducts.includes(product.id))
        .map((product) => ({
          image_url: product.imageUrl,
          product,
          type: "image",
        }));

      await channel.sendMessage({ attachments: allAttachments });
      close();
    } catch (error) {
      console.error("Error sharing products:", error);
      alert("Failed to share products. Please try again.");
    }
  };

  return (
    <div className="p-3 text-center w-100">
      <h4>View Products</h4>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div
            className="d-flex flex-wrap justify-content-center"
            style={{
              gap: "0.6rem",
            }}
          >
            {shoppingLists.map((list) => (
              <div
                key={list.id}
                className="d-flex align-items-center p-2 rounded-2 border gap-2"
              >
                <input
                  type="radio"
                  name="shoppingList"
                  checked={selectedList === list.id}
                  onChange={() => setSelectedList(list.id)}
                  style={{ cursor: "pointer" }}
                />
                <span>{list.name || `${list.id}`}</span>
              </div>
            ))}
          </div>
          {selectedList && (
            <div style={{ marginTop: "20px" }}>
              <h5>
                {shoppingLists.find((list) => list.id === selectedList)?.id}{" "}
                list
              </h5>
              {products.length > 0 ? (
                <div
                  className="hiddenScrollbar"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "3px",
                    overflow: "auto",
                  }}
                >
                  {products.map((product) => (
                    <div
                      key={product.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedProducts.includes(product.id)}
                        onChange={() =>
                          setSelectedProducts((prev) =>
                            prev.includes(product.id)
                              ? prev.filter((id) => id !== product.id)
                              : [...prev, product.id]
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                      <ProductCard3 product={product} isStrip={false} />
                    </div>
                  ))}
                </div>
              ) : (
                <p>No products found in this list.</p>
              )}
            </div>
          )}
        </>
      )}
      <div style={{ marginTop: "20px" }}>
        <button
          onClick={close}
          type="button"
          className="btn btn-outline-danger"
        >
          Close
        </button>
        <button
          onClick={handleShareProducts}
          type="button"
          className="ms-2 btn btn-outline-primary"
          disabled={selectedProducts.length === 0}
        >
          Share Selected Products
        </button>
      </div>
    </div>
  );
};
