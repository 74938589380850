import React, { useEffect, useState } from "react";
import {
  Col,
  Button,
  Card,
  Form,
  InputGroup,
  Nav,
  Badge,
} from "react-bootstrap";

import { UserAuth } from "../Context";

import { ChannelList } from "stream-chat-react";
import ChatFilter from "./ChatFilter";
import { getFilters } from "./lib.js/getFilters";
import useChatFilter from "../StatesStore/ChatFilter";

function SideBar() {
  const { user } = UserAuth();
  // const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const { chatFilterType } = useChatFilter();

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsSmallScreen(window.innerWidth < 768);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  const filters = getFilters(chatFilterType, user);

  const sort = [{ pinned_at: -1 }, { last_message_at: -1 }, { updated_at: -1 }];

  const options = {
    state: true,
    presence: true,
    // limit: 10,
  };

  return (
    <div className="col-lg-3 pe-0">
      <style jsx>
        {`
          // .str-chat {
          //   border-radius: var(--bs-border-radius) !important;
          //   border: 0;
          // }
          .str-chat__load-more-button__button,
          .str-chat__cta-button {
            width: 94.5% !important;
          }
          // .chat-list-content::-webkit-scrollbar {
          //   width: 6px;
          // }
          // .chat-list-content::-webkit-scrollbar-thumb {
          //   background-color: rgba(0, 0, 0, 0.2);
          //   border-radius: 10px;
          // }
          // .chat-list-content::-webkit-scrollbar-track {
          //   background: transparent;
          // }
        `}
      </style>
      <nav className="navbar navbar-expand-md m-0 p-0">
        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasSideChatbar"
          role="tablist"
        >
          <div className="offcanvas-header">
            <button
              type="button"
              className="btn-close text-reset ms-auto"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <Card className="offcanvas-body p-0 pb-2" style={{ height: "84vh" }}>
            <Card.Body className="rounded-2 p-0 h-75">
              <div
                className="chat-list-content h-100"
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <ChatFilter />
                <Nav className="d-flex flex-column nav-pills nav-pills-soft gap-4">
                  <span
                    className="rounded-2"
                    data-bs-dismiss="offcanvas"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <ChannelList
                      filters={filters}
                      sort={sort}
                      options={options}
                      // Avatar={(props) => {
                      //   console.log("props", props);
                      //   const channelName = props.name || "C";
                      //   const avatarText = channelName.charAt(0).toUpperCase();
                      //   if (
                      //     props.groupChannelDisplayInfo &&
                      //     props.groupChannelDisplayInfo.length > 2
                      //   ) {
                      //     return (
                      //       <ul
                      //         className={`mb-3 avatar-group avatar-group-three`}
                      //       >
                      //         {props.groupChannelDisplayInfo.map((member) => (
                      //           <li className="avatar avatar-xs">
                      //             <img
                      //               className={`avatar-img rounded-2 ${props.className}`}
                      //               src={member.image}
                      //               alt=""
                      //               style={{ cursor: "pointer" }}
                      //             />
                      //           </li>
                      //         ))}
                      //       </ul>
                      //     );
                      //   } else {
                      //     return (
                      //       <div className="flex-shrink-0 avatar-sm">
                      //         <img
                      //           className={`avatar-img rounded-2 ${props.className}`}
                      //           src={props.image}
                      //           alt=""
                      //           style={{ cursor: "pointer" }}
                      //         />
                      //       </div>
                      //     );
                      //   }
                      // }}
                    />
                  </span>
                </Nav>
              </div>
            </Card.Body>
          </Card>
        </div>
      </nav>
    </div>
  );
}

export default SideBar;
