import React from "react";
import {
  SideNavPriceRangeDropDown,
  SideNavSizeDropDown2,
  SideNavColorDropDown2,
  SideNavBrandsDropDown,
} from "./SideNavBrandDropDown";
export default function AllProductsFilterSideNav() {
  // console.log('Results are',Result);

  return (
    <div className="offcanvas-body d-block bg-mode rounded-2 p-0">
      {/* Card START */}
      <p className="fw-semibold m-0 fs-2 px-4 py-4">Filters &nbsp;</p>

      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseBrands"
          aria-expanded="false"
          aria-controls="collapseBrands"
        >
          Brand &nbsp;
        </p>
        <SideNavBrandsDropDown dataId={"collapseBrands"} />
      </div>
      <hr className="m-0" />
      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapsePriceRange"
          aria-expanded="false"
          aria-controls="collapsePriceRange"
        >
          Price &nbsp;
        </p>
        <SideNavPriceRangeDropDown dataId={"collapsePriceRange"} />
      </div>
      <hr className="m-0" />
      {/* PRICE RANGE */}
      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSize"
          aria-expanded="false"
          aria-controls="collapseSize"
        >
          Size &nbsp;
        </p>
        <SideNavSizeDropDown2 dataId={"collapseSize"} />
      </div>
      <hr className="m-0" />
      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseColor"
          aria-expanded="false"
          aria-controls="collapseColor"
        >
          Color &nbsp;
        </p>
        <SideNavColorDropDown2 dataId={"collapseColor"} />
      </div>
      <hr className="m-0" />
    </div>
  );
}
