import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../FirebaseConfig";
import { UserAuth } from "../../Context";
import { Card } from "react-bootstrap";
import UserDisplay from "../../UserProfile/UserDisplay";
import { useFetchUserData } from "../../hooks/user/useFetchUserData";
import ShopProducts from "./ShopProducts";
import PostReaction from "../PostReaction";

function ShoppingListPostCard({ post }) {
  const [postData, setPostData] = useState(null);
  const { userData } = useFetchUserData(post?.userId);

  const fetchShoppingListPostData = async () => {
    if (!post?.postId) {
      console.error("Post ID is required to fetch post data.");
      return;
    }

    try {
      const postDocRef = doc(db, "posts", post?.postId);
      const postDocSnap = await getDoc(postDocRef);

      if (postDocSnap.exists()) {
        const data = { id: postDocSnap.id, ...postDocSnap.data() };

        // Fetch media subcollection
        const productRef = collection(db, "posts", post?.postId, "media");
        const productSnap = await getDocs(productRef);
        const products = productSnap.docs.map((doc) => ({
          id: doc.id,
          url: doc.data().url,
        }));

        const finalPostData = { ...data, products };
        console.log("post", finalPostData);
        setPostData(finalPostData);
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching post data:", error);
    }
  };

  useEffect(() => {
    fetchShoppingListPostData();
  }, [post?.postId]); 

  if (!postData) {
    return null; 
  }

  return (
    <Card className=" m-2 mt-1 bg-transparent border-0">
      <UserDisplay userData={userData} post={postData} />
      <div className="card m-0 mt-1 px-1 pt-2 pb-0">
        <div className="title px-2 mb-0">
          <p className="m-0">{postData?.description}</p>
        </div>
        <ShopProducts shopPost={postData} />
        <div className="card-footer px-2 p-0 m-0 border-0">
          <PostReaction post={postData} />
        </div>
      </div>
    </Card>
  );
}

export default ShoppingListPostCard;
