import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { IoIosCloseCircle } from "react-icons/io";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { toastOptions } from "../lib/toastOptions";
import { toast } from "react-toastify";
import useChatClient from "../StatesStore/ChatClient";

const UserDetailsModal = ({
    show,
    onClose,
    members,
    listId,
    listName,
    userId,
    rolesOptions,
    setShowModal,
    setMemberDetails,
    loading,
}) => {
    const [DATA, setDATA] = useState({
        friendId: "",
        displayName: "",
    });
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const client = useChatClient((state) => state.client);
    if(!client){
        return null;
    }

    const handleRoleChange = async (memberId, newRole) => {
        try {
            // Reference to the members subcollection
            const memberRef = doc(
                db,
                "users",
                userId,
                "shoppingList",
                listName,
                "members",
                memberId
            );

            // Reference to the invitedList document
            const invitedListRef = doc(db, "users", memberId, "invitedList", listId);

            // Perform updates in Firestore
            await Promise.all([
                updateDoc(memberRef, { role: newRole }),
                updateDoc(invitedListRef, { role: newRole }),
            ]);

            // Update role in frontend state
            setMemberDetails((prevDetails) =>
                prevDetails.map((member) =>
                    member.id === memberId ? { ...member, role: newRole } : member
                )
            );

            toast.success("Role updated successfully", toastOptions);
        } catch (error) {
            console.error("Error updating role:", error);
            toast.error("Failed to update role. Please try again.", toastOptions);
        }
    };


    const handleRevokeUser = async (index, Id) => {

        console.log('handleRevokeUser', Id);

        setShowModal(false);
        const docRef = doc(
            db,
            "users",
            userId,
            "shoppingList",
            listName,
            "members",
            Id
        );
        const invitedListRef = doc(db, "users", Id, "invitedList", listId);
        const docSnap = await getDoc(docRef);
        const channel = client.channel("team", listId);
        await channel.removeMembers([Id]);
        try {
            if (docSnap.exists) {
                // Remove the friend from the array
                await deleteDoc(docRef);
                await deleteDoc(invitedListRef);
                const newData = members?.filter((memeber) => memeber.id !== Id);
                setMemberDetails(newData);
                console.log("friendsData", newData);
                toast.success("Access revoked successfully!", toastOptions);
            }
            // await updateDoc(chatRef,{members:[...updatedMembers]})

        } catch (error) {
            console.error(error);
            toast.error("Error removing user!", toastOptions);
        }
    };
    return (
        <>
            <Modal show={show} onHide={onClose} centered onClick={(e) => e.stopPropagation()}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className="h4 fw-semibold">Invited Members</span>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        members?.length > 0 ? (
                            <>

                                <div className="d-flex flex-column  justify-content-center align-items-center pe-3 pb-1 pt-0">
                                    {members.map((member, index) => (
                                        <div
                                            key={member.id}
                                            className="d-flex justify-content-between align-items-center w-100 my-1"
                                        >
                                            <div className="w-50 ms-3 d-flex align-items-center">

                                                <div className={`avatar avatar-xs text-center d-none d-sm-inline-block`} >
                                                    <ProfilePicture userData={member} className='avatar-img rounded' />
                                                </div>
                                                <div className="ms-2">
                                                    <div className="h6  m-0">
                                                        {member.userName}
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end align-items-center">
                                                <Form.Select
                                                    style={{ height: "2rem", paddingLeft: "9.5px" }}
                                                    className="py-1 border-0 bg-transparent"
                                                    onChange={(event) =>
                                                        handleRoleChange(member.id, event.target.value)
                                                    }
                                                    value={member.role || "commentor"}
                                                >
                                                    {rolesOptions.map((option) => (
                                                        <option key={option.name} value={option.name}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                <IoIosCloseCircle
                                                    className="fs-4 m-0 "
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setDATA({
                                                            friendId: member.id,
                                                            displayName: member?.userName,
                                                        });
                                                        // console.log("data", data);
                                                        setShowRemoveModal(true);
                                                    }}

                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        ) : (
                            <p className="text-center text-muted">No members found.</p>
                        )}
                </Modal.Body>

            </Modal>

            <Modal
                show={showRemoveModal}
                onHide={() => setShowRemoveModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Remove User</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <i
                        style={{ fontSize: "4.5rem" }}
                        className="text-body bi bi-exclamation-triangle"
                    ></i>
                    <div className="mt-4 fs-5 text-body">
                        Are you sure you want to remove {DATA.displayName} from {listName}?
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="primary-soft"
                        className="w-25 mx-1"
                        onClick={() => {
                            setDATA({
                                friendId: "",
                                displayName: "",
                            });
                            setShowRemoveModal(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger-soft"
                        className="w-25 mx-1"
                        onClick={() => {
                            console.log("data.friendId", DATA.friendId);
                            handleRevokeUser("", DATA.friendId);
                            setDATA({
                                friendId: "",
                                displayName: "",
                            });
                            setShowRemoveModal(false);
                        }}
                    >
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UserDetailsModal;
