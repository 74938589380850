import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import uuid4 from "uuid4";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";
import {
  getDoc,
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  getDocs,
  collection,
  increment,
  onSnapshot,
  where,
  query,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import useModalStore from "../StatesStore/ModalStore";
import usePoints from "../hooks/user/usePoints";
import usePointsStore from "../StatesStore/PointsTable";
import useFavStore from "../StatesStore/FavStore";
import useLogActivity from "../hooks/user/useLogActivity";
import useChatClient from "../StatesStore/ChatClient";
import { Modal } from "react-bootstrap";
import axios from "axios";

function AddToList({ product, componentName, setFavouritesCount }) {
  const { user } = UserAuth();
  const [savedLists, setSavedLists] = useState([]);
  const [shoppingList, setShoppingList] = useState({});
  const [newListName, setNewListName] = useState("");
  const [invitedLists, setInvitedLists] = useState([]);
  const { addPoints, removePoints } = usePoints();
  const [error, setError] = useState(false);
  const pointsTable = usePointsStore((state) => state.pointsTable);
  const setFavorites = useFavStore((state) => state.setFavorites);
  const deleteFavorite = useFavStore((state) => state.deleteFavorite);
  const { logActivity } = useLogActivity(user?.uid);
  const [invitedListsStatus, setInvitedListsStatus] = useState({});
  const client = useChatClient((state) => state.client);
  const [show, setShow] = useState(false);
  // console.log("product", product);
  // console.log("shoppingList", shoppingList);
  const handleClose = () => setShow(false);
  useEffect(() => {
    if (!shoppingList) return;
    console.log("shoppingList", shoppingList);
  }, [shoppingList]);
  useEffect(() => {
    if (!invitedLists) return;
    console.log("invitedLists", invitedLists);
  }, [invitedLists]);

  const fetchData = async () => {
    if (!user) return;

    const shoppingListRef = collection(db, "users", user?.uid, "shoppingList");

    const unsubscribe = onSnapshot(
      shoppingListRef,
      async (shoppingListSnapshot) => {
        const newListNames = shoppingListSnapshot.docs.map((doc) => ({
          id: doc?.id,
          listName: doc.data().listName,
        }));
        setSavedLists(newListNames);

        const updatedShoppingList = {};

        const listPromises = shoppingListSnapshot.docs.map(async (docSnap) => {
          const listName = docSnap?.id;
          const productsRef = collection(
            db,
            "users",
            user?.uid,
            "shoppingList",
            listName,
            "products"
          );
          const productsSnapshot = await getDocs(productsRef);
          const productIds = productsSnapshot.docs.map(
            (productDoc) => productDoc?.id
          );

          updatedShoppingList[listName] = productIds;
          updatedShoppingList[
            listName
          ].dateCreated = docSnap.data().dateCreated;
          updatedShoppingList[
            listName
          ].lastUpdated = docSnap.data().lastUpdated;
        });

        await Promise.all(listPromises);

        setShoppingList(updatedShoppingList);
      }
    );
    const favoritesProductsRef = collection(
      db,
      "users",
      user?.uid,
      "shoppingList",
      "Favorites",
      "products"
    );

    const unsubscribeFavorites = onSnapshot(
      favoritesProductsRef,
      (productsSnapshot) => {
        const favoritesProducts = productsSnapshot.docs.map(
          (productDoc) => productDoc?.id
        );
        setShoppingList((prevShoppingList) => ({
          ...prevShoppingList,
          Favorites: favoritesProducts,
        }));
        if (favoritesProducts.length > 0) {
          // console.log("Favorites products updated:", favoritesProducts);
          // Update your state with the new products
          // setFavoritesProducts(favoritesProducts);
        } else {
          console.log("No products found in Favorites.");
        }
      }
    );
    // return unsubscribe;
    return () => {
      unsubscribe();
      unsubscribeFavorites();
    };
  };
  // console.log("shopping list from add to list", shoppingList);
  const fetchInvitedLists = async () => {
    if (!user) return;

    const invitedListRef = collection(db, "users", user?.uid, "invitedList");
    const invitedQuery = query(invitedListRef, where("role", "==", "editor"));
    const invitedSnapshot = await getDocs(invitedQuery);

    const invitedPromises = invitedSnapshot.docs.map(async (docSnap) => {
      const { userId } = docSnap.data();

      // Fetch inviter details
      const inviterDoc = await getDoc(doc(db, "users", userId));
      const inviterName = inviterDoc.exists()
        ? inviterDoc.data().userName
        : "Unknown";

      // Fetch the matching shopping list document
      const shoppingListRef = collection(db, "users", userId, "shoppingList");
      const shoppingListQuery = query(
        shoppingListRef,
        where("listId", "==", docSnap?.id)
      );
      const shoppingListSnapshot = await getDocs(shoppingListQuery);

      let listName = "Unknown List";
      let id = "";

      if (!shoppingListSnapshot.empty) {
        const shoppingListDoc = shoppingListSnapshot.docs[0];
        listName = shoppingListDoc?.data().listName; // Use the document ID as listName
        id = shoppingListDoc?.id; // Use the document ID as listName
      }

      return {
        id: id,
        listId: docSnap?.id,
        inviterName,
        listName,
        userId,
      };
    });

    const resolvedInvitedLists = (await Promise.all(invitedPromises)).filter(
      (list) => list !== null
    );
    setInvitedLists(resolvedInvitedLists);
  };

  const { openModal } = useModalStore();
  const [selectedSize, setSelectedSize] = useState("");

  const handleClick = () => {
    if (!user) {
      openModal("SignInModal", "", "");
    } else if (!user?.emailVerified) {
      openModal("VerifyEmailModal", "", {
        userName: user?.displayName,
        email: user?.email,
      });
    }
  };

  const isProductInList = (listName) => {
    const list = shoppingList[listName];
    if (list) {
      return list.includes(product?.id);
    }
    return false;
  };

  const isProductInAnyList = () => {
    return Object.values(shoppingList).some((list) =>
      list.includes(product?.id)
    );
  };
  const isProductInInvitedList = async (invitedList) => {
    if (!user || !invitedList || !product?.id) return false; // Ensure necessary data exists
    // console.log('isProductInInvitedList invitedList',invitedList);

    try {
      // Reference to the product document in the invited user's shopping list
      const productDocRef = doc(
        db,
        "users",
        invitedList.userId,
        "shoppingList",
        invitedList.id,
        "products",
        product?.id
      );

      const productDoc = await getDoc(productDocRef);
      return productDoc.exists();
    } catch (error) {
      console.error("Error checking product in invited list:", error);
      return false;
    }
  };

  const textClass =
    componentName === "strip2"
      ? "text-secondary"
      : componentName === "productDetails" ||
        componentName === "BrandPage" ||
        componentName === "chat"
      ? "text-light"
      : "text-body";

  const handleListClick = async (listName, size = "") => {
    if (!user) return;
    console.log("listName", listName);
    const userRef = doc(db, "users", user?.uid);
    const productRef2 = doc(db, "products", product?.id);
    const inShoppingListRef = doc(productRef2, "inShoppingList", user?.uid);

    try {
      const listDocRef = doc(db, "users", user?.uid, "shoppingList", listName);
      const productDocRef = doc(listDocRef, "products", product?.id);
      const productDoc = await getDoc(productDocRef);
      const inShoppingListDoc = await getDoc(inShoppingListRef);
      // Update the local state
      setShoppingList((prevState) => {
        const list = prevState[listName] || [];
        let updatedList;
        if (productDoc.exists()) {
          // Remove product ID
          updatedList = list.filter((id) => id !== product?.id);
        } else {
          // Add product ID
          updatedList = [...list, product?.id];
        }
        return {
          ...prevState,
          [listName]: updatedList,
        };
      });
      if (productDoc.exists()) {
        // If the product is in the list, remove it
        await deleteDoc(productDocRef);

        // Remove the list name from the inShoppingList array
        if (inShoppingListDoc.exists()) {
          const { listNames } = inShoppingListDoc.data();
          const newListNames = listNames.filter((name) => name !== listName);
          if (newListNames.length === 0) {
            // If no more lists contain this product, remove the document
            await deleteDoc(inShoppingListRef);
          } else {
            // Otherwise, update the document with the new list of names
            await updateDoc(inShoppingListRef, { listNames: newListNames });
          }
        }

        // Decrement the shoppingListCount
        await updateDoc(userRef, {
          shoppingListCount: increment(-1),
        });

        if (listName === "Favorites") {
          setFavouritesCount((prevCount) => Math.max(prevCount - 1, 0));
          deleteFavorite(product?.id);
        }

        toast.success(`${product.name} removed from ${listName}`, toastOptions);
        // Remove the product's supplier from the list's brands array
        const listDoc = await getDoc(listDocRef);
        if (listDoc.exists()) {
          const {
            category: productCategory,
            subCategory: productSubCategory,
            subSubCategory: productSubSubCategory,
          } = product;
          const {
            category = [],
            subCategory = [],
            subSubCategory = [],
            tags: listTags = [],
          } = listDoc.data();

          // Helper function to remove the first occurrence of a value from an array
          const removeFirstOccurrence = (arr, value) => {
            const index = arr.indexOf(value); // Find the index of the first occurrence
            if (index !== -1) {
              arr.splice(index, 1); // Remove the first occurrence
            }
            return arr; // Return the updated array
          };

          // Remove only the first occurrence of each value
          const updatedCategories = removeFirstOccurrence(
            [...category],
            productCategory
          );
          const updatedSubCategories = removeFirstOccurrence(
            [...subCategory],
            productSubCategory
          );
          const updatedSubSubCategories = removeFirstOccurrence(
            [...subSubCategory],
            productSubSubCategory
          );

          // Update the document with the modified arrays
          await updateDoc(listDocRef, {
            category: updatedCategories,
            subCategory: updatedSubCategories,
            subSubCategory: updatedSubSubCategories,
          });

          // Remove the first occurrence of listTags from the product's tags
          const productDoc = await getDoc(productRef2);
          if (productDoc.exists()) {
            const { tags: productTags = [] } = productDoc.data();
            const updatedProductTags = removeFirstOccurrence(
              [...productTags],
              ...listTags
            );

            await updateDoc(productRef2, {
              tags: updatedProductTags,
            });
          }
        }

        const activityData = {
          productId: product?.id,
          ...product,
          type: "productRemovedFromList",
        };

        logActivity(activityData);
      } else {
        // If the product is not in the list, add it
        await setDoc(productDocRef, {
          size: size,
          timestamp: serverTimestamp(),
          likeCount: 0,
          updatedAt: serverTimestamp(),
        });

        // Update or create the inShoppingList entry
        if (inShoppingListDoc.exists()) {
          const { listNames } = inShoppingListDoc.data();
          if (!listNames.includes(listName)) {
            await updateDoc(inShoppingListRef, {
              listNames: [...listNames, listName],
              timestamp: serverTimestamp(),
              updatedAt: serverTimestamp(),
            });
          }
        } else {
          await setDoc(inShoppingListRef, {
            userId: user?.uid,
            listNames: [listName],
            timestamp: serverTimestamp(),
            updatedAt: serverTimestamp(),
          });
        }

        // Update or create the inShoppingList entry
        if (inShoppingListDoc.exists()) {
          const { listNames } = inShoppingListDoc.data();
          if (!listNames.includes(listName)) {
            await updateDoc(inShoppingListRef, {
              listNames: [...listNames, listName],
              timestamp: serverTimestamp(),
            });
          }
        } else {
          await setDoc(inShoppingListRef, {
            userId: user?.uid,
            listNames: [listName],
            timestamp: serverTimestamp(),
          });
        }

        // Add the user to the product's shoppingList subcollection
        // await setDoc(productShoppingListDocRef, {
        //   timestamp: serverTimestamp(),
        // });
        // Increment the shoppingListCount
        await updateDoc(userRef, {
          shoppingListCount: increment(1),
        });
        toast.success(`${product.name} added to ${listName}`, toastOptions);

        // Add the product's supplier to the list's brands array (allow duplicates)
        const listDoc = await getDoc(listDocRef);
        if (listDoc.exists()) {
          const {
            brands = [],
            category = [],
            subCategory = [],
            subSubCategory = [],
            tags: listTags = [],
          } = listDoc.data();
          // Append the supplier to the array (duplicates allowed)
          await updateDoc(listDocRef, {
            brands: [...brands, product.supplier],
          });

          await updateDoc(listDocRef, {
            brands: [...brands, product.supplier],
            category: [...category, product?.category || null],
            subCategory: [...subCategory, product?.subCategory || null],
            subSubCategory: [
              ...subSubCategory,
              product?.subSubCategory || null,
            ],
          });

          // Add the listTags to the product's tags
          const productDoc = await getDoc(productRef2);
          if (productDoc.exists()) {
            const { tags: productTags = [] } = productDoc.data();
            await updateDoc(productRef2, {
              tags: [...productTags, ...listTags], // Add listTags to productTags
            });
          }
        } else {
          // If the list document doesn't exist, create it with the supplier
          await setDoc(listDocRef, {
            brands: [product.supplier],
            lastUpdated: serverTimestamp(),
          });
        }
        if (listName === "Favorites") {
          // Add to favorites store
          setFavorites(product?.id);
          setFavouritesCount((prevCount) => prevCount + 1);
        }

        const activityData = {
          productId: product?.id,
          ...product,
          type: "productAddedToList",
        };

        logActivity(activityData);
      }
      await updateDoc(listDocRef, { lastUpdated: serverTimestamp() });
    } catch (error) {
      console.error("Error handling list click:", error.message);
    }
  };

  useEffect(() => {
    if (!user) return;
    fetchData();
    fetchInvitedLists();
  }, [user]);
  useEffect(() => {
    const updateInvitedListsStatus = async () => {
      const statusMap = {};
      for (const list of invitedLists) {
        statusMap[list?.id] = await isProductInInvitedList(list);
      }
      setInvitedListsStatus(statusMap);
    };

    if (invitedLists.length > 0) {
      updateInvitedListsStatus();
    }
  }, [invitedLists, product?.id]);
  const handleCreateNewList = async (size = "") => {
    const listName = newListName.trim();

    if (listName) {
      // Capitalize the first letter
      const capitalizedListName =
        listName.charAt(0).toUpperCase() + listName.slice(1);

        const response = await axios.post(`${process.env.REACT_APP_GEMINI}/getTags`, {
          text: capitalizedListName,
        });
      
        
        const tags = response.data.result;
  
        const listId = await addToSavedList(capitalizedListName, size, tags);

        setSavedLists([
          ...savedLists,
          { id: listId, listName: capitalizedListName },
        ]);
      setSavedLists([
        ...savedLists,
        { id: listId, listName: capitalizedListName },
      ]);
      toast.success(
        `${product.name} added to ${capitalizedListName} `,
        toastOptions
      );
      setNewListName(""); // Clear input after saving
      if (listId) {
        // Create a team channel for the newly created list
         await createTeamChannel(
          listId,
          capitalizedListName,
          user?.uid
        );

        // Set the user as admin in the channel
        // await channel.addMembers([user?.uid], { text: `${user.displayName} created the list.`, role: "admin" });
      }
    
       // Add the listTags to the product's tags
       const productRef = doc(db, "products", product?.id);
       const productDoc = await getDoc(productRef);
       if (productDoc.exists()) {
         await updateDoc(productRef, {
           tags: tags, // Add listTags to productTags
         });
       }
      //adding points
      const postingPoints = pointsTable.creatingShoppingList || 0;

      addPoints({
        userId: user?.uid,
        type: "creatingShoppingList",
        pointsAwarded: postingPoints,
        fields: {
          listName: listName,
        },
      });
    }
  };

  const handleInvitedListClick = async (invitedList) => {
    if (!user) return;

    const { id, listName, userId } = invitedList;

    try {
      const inviterShoppingListRef = doc(
        db,
        "users",
        userId,
        "shoppingList",
        id,
        "products",
        product?.id
      );
      const inviterUserRef = doc(db, "users", userId);
      const productDoc = await getDoc(inviterShoppingListRef);
      const isInList = productDoc.exists();

      if (isInList) {
        // Remove from list
        await deleteDoc(inviterShoppingListRef);
        setInvitedListsStatus((prev) => ({
          ...prev,
          [invitedList?.id]: false,
        }));
        toast.success(`${product.name} removed from ${listName}`, toastOptions);
        await updateDoc(inviterUserRef, {
          shoppingListCount: increment(-1),
        });
      } else {
        setInvitedListsStatus((prev) => ({
          ...prev,
          [invitedList?.id]: true,
        }));
        // Add to list
        await setDoc(inviterShoppingListRef, {
          size: selectedSize,
          timestamp: serverTimestamp(),
          likeCount: 0,
          updatedAt: serverTimestamp(),
        });

        toast.success(
          `${product.name} added to ${listName} by ${invitedList.inviterName}`,
          toastOptions
        );

        await updateDoc(inviterUserRef, {
          shoppingListCount: increment(1),
        });
      }
    } catch (error) {
      console.error("Error handling invited list:", error.message);
      toast.error("Error updating list", toastOptions);
    }
  };
  const addToSavedList = async (listName, size, tags = []) => {
    if (!user) return;

    try {
      const listDocRef = doc(db, "users", user?.uid, "shoppingList", listName);

      // Check if the list document exists
      const listDoc = await getDoc(listDocRef);
      let listId;
      if (!listDoc.exists()) {
        // If the list does not exist, create it
        listId = uuid4();
        await setDoc(listDocRef, {
          dateCreated: serverTimestamp(),
          lastUpdated: serverTimestamp(),
          listName: listName,
          listId: listId,
          visibility: "Private",
          tags: tags,
        });
      } else {
        // If the list already exists, use its existing listId
        listId = listDoc.data().listId;
      }

      // Add the product to the products subcollection
      const productDocRef = doc(listDocRef, "products", product?.id);
      await setDoc(productDocRef, {
        size: size,
        timestamp: serverTimestamp(),
        likeCount: 0,
      });

      // Add the user to the product's shoppingList subcollection
      const productShoppingListDocRef = doc(
        db,
        "products",
        product?.id,
        "shoppingList",
        user?.uid
      );
      await setDoc(productShoppingListDocRef, {
        timestamp: serverTimestamp(),
      });
      // Increment the shoppingListCount
      await updateDoc(doc(db, "users", user?.uid), {
        shoppingListCount: increment(1),
      });

      const activityData = {
        productId: product?.id,
        ...product,
        type: "productAddedToList",
      };

      logActivity(activityData);
      // Update the local state
      setShoppingList((prevState) => {
        const list = prevState[listName] || [];
        const updatedList = [...list, product?.id];
        return {
          ...prevState,
          [listName]: updatedList,
        };
      });
      console.log("Product added to the list successfully!");
      return listId;
    } catch (error) {
      console.error("Error adding product to the list:", error.message);
      return null;
    }
  };
  const createTeamChannel = async (listId, listName, creatorId) => {
    try {
      const channel = await client.channel("team", listId, {
        name: listName,
        image: `https://ui-avatars.com/api/?name=${listName}`,
      });

      await channel.create();
      await channel.addMembers([
        { user_id: creatorId, channel_role: "channel_moderator" },
      ]);
      console.log("Team channel created successfully:", channel.id);
      return channel;
    } catch (error) {
      console.error("Error creating team channel:", error);
      throw error;
    }
  };
  return (
    <>
      <li className={`nav-item p-0 list-unstyled`}>
        {user && (
          <>
            {componentName === "chat" ? (
              <>
                <div
                  className={`nav-link btn d-flex gap-1 align-items-center ${textClass} bg-default`}
                  onClick={() => setShow(true)}
                >
                  <i
                    className="bi bi-bookmark p-0 m-0"
                    style={{
                      fontSize: "0.7rem",
                    }}
                  ></i>
                  Save
                </div>
              </>
            ) : (
              <>
                <Link
                  style={{ paddingTop: "0.1rem" }}
                  className={`nav-link d-flex gap-1 align-items-center ${textClass} bg-default`}
                  id="feedActionList"
                  data-bs-toggle={user?.emailVerified ? "dropdown" : ""}
                  aria-expanded="false"
                  onClick={() => handleClick()}
                >
                  <i
                    className={`bi bi-bookmark${
                      isProductInAnyList() ? "-fill" : ""
                    } text-secondary p-0 m-0`}
                    style={{
                      fontSize:
                        componentName === "strip" || componentName === "strip2"
                          ? "0.8rem"
                          : "",
                    }}
                  ></i>
                  {componentName === "strip" || componentName === "strip2"
                    ? ""
                    : componentName === "BrandPage"
                    ? "Save"
                    : "Save to List"}
                </Link>
                <ul
                  className="dropdown-menu dropdown-menu-end pb-2"
                  aria-labelledby="feedActionList"
                  style={{ width: "10rem" }}
                >
                  <div className="p-2 py-0 text-center">
                    <h6>Save to List</h6>
                  </div>
                  <div style={{ maxHeight: "14.5rem", overflow: "auto" }}>
                    {/* Render Combined Lists */}
                    {savedLists.map((list) => (
                      <li key={list?.id}>
                        <label
                          style={{ cursor: "pointer" }}
                          className="dropdown-item d-flex gap-2 px-3"
                        >
                          <input
                            type="checkbox"
                            checked={isProductInList(list?.id)}
                            onChange={() =>
                              handleListClick(list?.id, selectedSize)
                            }
                          />
                          <div className="text-truncate">{list?.listName}</div>
                        </label>
                      </li>
                    ))}

                    {/* Render Invited Lists */}
                    {invitedLists.map((list) => (
                      <li key={list?.id}>
                        <label
                          style={{ cursor: "pointer" }}
                          className="dropdown-item d-flex gap-2 px-3"
                        >
                          <input
                            type="checkbox"
                            checked={invitedListsStatus[list?.id] || false}
                            onChange={() => handleInvitedListClick(list)}
                          />
                          {list.listName}
                          <div className="text-truncate">
                            by {list.inviterName}
                          </div>
                        </label>
                      </li>
                    ))}
                  </div>
                  {/* Divider */}
                  {(savedLists.length > 0 || invitedLists.length > 0) && (
                    <li>
                      <hr className="dropdown-divider mt-0" />
                    </li>
                  )}
                  {/* Create New List */}
                  <li>
                    <div className="input-group dropdown-item d-flex gap-2 px-3">
                      <input
                        type="text"
                        className="form-control form-control-sm focus-ring focus-ring-secondary py-1 px-2 text-decoration-none border rounded-2"
                        placeholder="Create new list"
                        value={newListName}
                        onChange={(e) => {
                          setError("");
                          if (e.target.value.length <= 16)
                            setNewListName(e.target.value);
                          setError("");
                          if (/^\d+$/.test(e.target.value)) {
                            setError("List name cannot be completely numeric");
                          } else if (
                            /[!@#$%^&*,.?":{}|<>\-_=+\;()'/\\[\]]/.test(
                              e.target.value
                            )
                          ) {
                            setError(
                              "List name cannot contain special characters"
                            );
                          } else if (e.target.value.length > 15) {
                            setError(
                              "List name cannot be longer than 15 characters"
                            );
                          }
                        }}
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-primary border rounded-2"
                        disabled={newListName?.length === 0 || error}
                        onClick={() => handleCreateNewList(selectedSize)}
                      >
                        Save
                      </button>
                    </div>
                    {error && (
                      <div
                        className="text-danger px-3 "
                        style={{ fontSize: "0.7rem" }}
                      >
                        {error}
                      </div>
                    )}
                  </li>
                </ul>
              </>
            )}
          </>
        )}
        {!user && (
          <Link
            to=""
            className={`nav-link btn d-flex gap-1 align-items-center ${textClass} bg-default`}
            onClick={handleClick}
          >
            <i
              className="bi bi-bookmark p-0 m-0"
              style={{
                fontSize:
                  componentName === "strip" || componentName === "strip2"
                    ? "0.8rem"
                    : "",
              }}
            ></i>
            {componentName === "strip" || componentName === "strip2"
              ? ""
              : componentName === "chat"
              ? "Save"
              : "Save to List"}
          </Link>
        )}
      </li>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Save to List</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <ul className="navbar-nav">
            {/* Render Combined Lists */}
            {savedLists.map((list) => (
              <li key={list?.id}>
                <label
                  style={{ cursor: "pointer" }}
                  className="dropdown-item d-flex gap-2"
                >
                  <input
                    type="checkbox"
                    checked={isProductInList(list?.id)}
                    onChange={() =>
                      handleListClick(list?.id?.listName, selectedSize)
                    }
                  />
                  {list?.listName}
                </label>
              </li>
            ))}

            {/* Render Invited Lists */}
            {invitedLists.map((list) => (
              <li key={list?.id}>
                <label
                  style={{ cursor: "pointer" }}
                  className="dropdown-item d-flex gap-2"
                >
                  <input
                    type="checkbox"
                    checked={invitedListsStatus[list?.id] || false}
                    onChange={() => handleInvitedListClick(list)}
                  />
                  {list.listName}
                  <span className="ms-auto">by {list.inviterName}</span>
                </label>
              </li>
            ))}

            {/* Divider */}
            {(savedLists.length > 0 || invitedLists.length > 0) && (
              <li>
                <hr className="dropdown-divider" />
              </li>
            )}

            {/* Create New List */}
            <li>
              <div className="input-group d-flex gap-2 p-0 mt-2">
                <input
                  type="text"
                  className="form-control form-control-sm focus-ring focus-ring-secondary py-1 px-2 text-decoration-none border rounded-2"
                  placeholder="Create new list"
                  value={newListName}
                  onChange={(e) => {
                    setError("");
                    if (e.target.value.length <= 16)
                      setNewListName(e.target.value);
                    if (/^\d+$/.test(e.target.value)) {
                      setError("List name cannot be completely numeric");
                    } else if (
                      /[!@#$%^&*,.?":{}|<>\-_=+[\;()'/]/.test(e.target.value)
                    ) {
                      setError("List name cannot contain special characters");
                    } else if (e.target.value.length > 15) {
                      setError("List name cannot be longer than 15 characters");
                    }
                  }}
                />
                <button
                  type="button"
                  className="btn btn-sm btn-primary border rounded-2"
                  disabled={newListName?.length === 0 || error}
                  onClick={() => {
                    handleClose();
                    if (newListName.length > 0)
                      handleCreateNewList(selectedSize);
                  }}
                >
                  Save
                </button>
              </div>
            </li>
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddToList;
