import React from "react";

const Category2 = ({
  categories,
  setCategories,
  handleNext,
  allCategories,
}) => {
  const category = ["Ready to Wear", "Unstitched", "Western Wear"];

  const handleSubmitCategories = (e) => {
    e.preventDefault();
    handleNext();
  };
  //Check if both objects are equal
  const areObjectsEqual = (subCategory1, subCategory2) => {
    return (
      Object.keys(subCategory1).length === Object.keys(subCategory2).length &&
      Object.keys(subCategory1).every(
        (key) => subCategory1[key] === subCategory2[key]
      )
    );
  };
  const handleCategoryClick = (subCategory) => {
    // console.log(select);
    // Check if the category is already added
    const isAlreadyAdded = categories.some((item) =>
      areObjectsEqual(item, subCategory)
    );
    //If added then remove
    if (isAlreadyAdded) {
      setCategories((prevCategory) =>
        prevCategory.filter((item) => !areObjectsEqual(item, subCategory))
      );
    } else {
      setCategories((prevCategory) => [...prevCategory, subCategory]);
    }
  };
  const categoryData = () => {
    return (
      <ul
        className={`list-inline d-flex flex-row flex-wrap gap-4 mt-2 mb-1 hiddenScrollbar w-100 ps-1`}
      >
        {allCategories?.map((item, index) => {
          return (
            <li
              key={index}
              className={`rounded-2 hoverClass`}
              onClick={() => handleCategoryClick(item)}
            >
              <div
                className="d-flex flex-column align-items-center text-center"
                style={{ cursor: "pointer" }}
              >
                <div className="position-relative">
                  <div
                    style={{
                      zIndex: "0",
                    }}
                    className="bg-body position-absolute bottom-0 start-0 h-75 w-100 rounded-2"
                  ></div>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 21,
                      right: "0.2rem",
                    }}
                  >
                    {categories.some((element) =>
                      areObjectsEqual(element, item)
                    ) && (
                      <i className="bi bi-check-circle-fill text-primary fs-5"></i>
                    )}
                  </div>
                  <img
                    style={{
                      zIndex: "20",
                      height: "10rem",
                      objectFit: "cover",
                    }}
                    width={"110rem"}
                    className="rounded-2 position-relative"
                    src={item?.imageUrl}
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div
                  className="nav-item text-wrap mt-2 h6 fw-light"
                  style={{
                    width: "5rem",
                    fontSize: "0.8rem",
                    wordWrap: "break-word",
                  }}
                >
                  {item.subSubCategoryDisplayName}
                  {item.subCategory === "Unstitched" && " (Unstitched)"}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <div>
        <p className="mt-3 fs-5 grey">What are your favourite categories?</p>
      </div>
      <form onSubmit={(e) => handleSubmitCategories(e)}>
        <div style={{ maxHeight: "20.5rem", overflowY: "auto" }}>
          {/* {category.map((cat) => {
            return (
              <div className="mt-1 d-flex flex-column align-items-start">
                <li
                  className="fs-6 text-mute my-1"
                  style={{
                    borderRadius: "20px",
                    listStyleType: "none",
                  }}
                >
                  <div className={`h5 mb-0 fw-light }`}>
                    <b>{cat}</b>
                  </div>
                </li>
                {categoryData(cat)}
              </div>
            );
          })} */}
          {categoryData()}
        </div>
        <div className="d-grid mt-2">
          <button
            type="submit"
            className="btn btn-primary flex-grow-1"
            disabled={categories.length < 5}
          >
            {categories.length < 5 ? "Select atleast 5" : "Next"}
          </button>
        </div>
      </form>
    </>
  );
};

export default Category2;
