import React, { useEffect, useState } from "react";
import { useChannelStateContext } from "stream-chat-react";
import { db } from "../FirebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  writeBatch,
} from "firebase/firestore";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import Slider from "react-slick";

const ProductsInChat = () => {
  const { channel } = useChannelStateContext();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (!channel || channel.type !== "team") {
      setLoading(false);
      return;
    }

    async function fetchProductIds() {
      try {
        const creatorId =
          channel.created_by || (channel.data && channel.data.created_by.id);
        console.log("creatorId", creatorId);

        if (!creatorId) {
          console.error("Channel creator ID not found.");
          setLoading(false);
          return;
        }

        const shoppingListRef = collection(
          db,
          "users",
          creatorId,
          "shoppingList"
        );
        const shoppingListQuery = query(
          shoppingListRef,
          where("listId", "==", channel.id)
        );
        const shoppingListSnapshot = await getDocs(shoppingListQuery);

        if (!shoppingListSnapshot.empty) {
          // Assuming there is only one matching document.
          const shoppingListDoc = shoppingListSnapshot.docs[0];
          const productsRef = collection(
            db,
            "users",
            creatorId,
            "shoppingList",
            shoppingListDoc.id,
            "products"
          );
          const productsSnapshot = await getDocs(productsRef);
          const ids = productsSnapshot.docs.map((doc) => doc.id);

          // Fetch product details for each product ID
          const productDetails = await Promise.all(
            ids.map(async (id) => {
              const productRef = doc(db, "products", id);
              const productSnapshot = await getDoc(productRef);
              if (productSnapshot.exists()) {
                const productData = { id, ...productSnapshot.data() };
                return productData;
              }
              return null;
            })
          );

          // Filter out null values and set the products state
          setProducts(productDetails.filter((product) => product !== null));
        } else {
          console.log("No shopping list found with listId:", channel.id);
          setProducts([]);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchProductIds();
  }, [channel]);

  // Only render if the channel exists and is a team channel.
  if (!channel || channel.type !== "team") return null;

  const settings = {
    speed: 500,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 6,
    dots: false,
    swipe: false,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: products.length < 6 ? products.length : 6,
          slidesToScroll: products.length < 6 ? products.length : 6,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: products.length < 5 ? products.length : 5,
          slidesToScroll: products.length < 5 ? products.length : 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  return (
    <div className="px-2 pt-2 pb-1 border-bottom">
      {loading ? (
        <LoadingSpinner height="20vh" />
      ) : (
        <div
          style={{ overflow: "auto", whiteSpace: "nowrap" }}
          // {...settings}
          className={`w-100 m-0 d-flex gap-2 hiddenScrollbar ${
            isSmallScreen ? "position-relative vw-100" : ""
          }`}
        >
          {products.length > 0 &&
            products.map((product) => (
              <div key={product.id} id={product.id}>
                {product.imageUrl && (
                  <img
                    src={product.imageUrl}
                    alt={product.name}
                    style={{
                      width: "6rem",
                      maxWidth: "16rem",
                      height: "8rem",
                      borderRadius: "4px",
                    }}
                    onError={() =>
                      (document.getElementById(product.id).style.display =
                        "none")
                    }
                  />
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ProductsInChat;
