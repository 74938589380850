import React, { useEffect, useState } from "react";
import { useChannelStateContext } from "stream-chat-react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { Button } from "react-bootstrap";

export const ShoppingListModalContent = ({ close }) => {
  const { user } = UserAuth();
  const [shoppingLists, setShoppingLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedList, setSelectedList] = useState(null);
  const [isSharing, setIsSharing] = useState(false);
  const { channel } = useChannelStateContext();

  useEffect(() => {
    const fetchShoppingLists = async () => {
      if (user?.uid) {
        try {
          const shoppingListRef = collection(
            db,
            "users",
            user.uid,
            "shoppingList"
          );
          const querySnapshot = await getDocs(shoppingListRef);
          const lists = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setShoppingLists(lists);
        } catch (error) {
          console.error("Error fetching shopping lists:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchShoppingLists();
  }, [user?.uid]);

  const handleShareList = async () => {
    if (!selectedList) {
      alert("Please select a shopping list to share.");
      return;
    }

    setIsSharing(true);

    try {
      const productsRef = collection(
        db,
        "users",
        user?.uid,
        "shoppingList",
        selectedList,
        "products"
      );
      const productsSnapshot = await getDocs(productsRef);
      const allAttachments = await Promise.all(
        productsSnapshot.docs.map(async (productDoc) => {
          const productId = productDoc.id;
          const productDocRef = doc(db, "products", productId);
          const productSnapshot = await getDoc(productDocRef);
          return productSnapshot.exists()
            ? {
                image_url: productSnapshot.data().imageUrl,
                product: productSnapshot.data(),
                type: "image",
              }
            : null;
        })
      );

      await channel.sendMessage({
        attachments: allAttachments.filter(Boolean),
      });
      close();
    } catch (error) {
      console.error("Error sharing shopping list:", error);
      alert("Failed to share shopping list. Please try again.");
    } finally {
      setIsSharing(false);
    }
  };

  return (
    <div className="p-3 text-center">
      <h4>Share Shopping List</h4>
      {loading ? (
        <p>Loading shopping lists...</p>
      ) : shoppingLists.length > 0 ? (
        <div
          className="d-flex flex-wrap justify-content-center"
          style={{
            gap: "0.6rem",
          }}
        >
          {shoppingLists.map((list) => (
            <div
              key={list.id}
              className="d-flex align-items-center p-2 rounded-2 border gap-2"
            >
              <input
                type="radio"
                name="shoppingList"
                checked={selectedList === list?.id}
                onChange={() => setSelectedList(list?.id)}
                style={{ cursor: "pointer" }}
              />
              <span>{list?.name || list?.id}</span>
            </div>
          ))}
        </div>
      ) : (
        <p>No shopping lists found.</p>
      )}
      <div style={{ marginTop: "20px" }}>
        <Button onClick={close} variant="danger-soft" className="btn">
          Close
        </Button>
        <Button
          onClick={handleShareList}
          variant="primary-soft"
          className="ms-2 btn "
          disabled={!selectedList || isSharing}
        >
          {isSharing ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            "Share Selected List"
          )}
        </Button>
      </div>
    </div>
  );
};
