import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  increment,
  serverTimestamp,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../FirebaseConfig";
import { Card } from "react-bootstrap";
import formatPrice from "../../lib/formatPrice";
import { UserAuth } from "../../Context";
import useModalStore from "../../StatesStore/ModalStore";
import { toast } from "react-toastify";
import { toastOptions } from "../../lib/toastOptions";
import useNotification from "../../hooks/useNotification";
import AddToCart from "../../components/AddToCart";
import { Link } from "react-router-dom";

const ShoppingListProductCard = ({
  list,
  product,
  ownerId,
  shoppingListData,
}) => {
  const [productData, setProductData] = useState(null);
  const [hasLiked, setHasLiked] = useState(false);
  const { user } = UserAuth();
  const { openModal } = useModalStore();
  const { addNotification, deleteNotification } = useNotification();
  const [showProduct, setShowProduct] = useState(true);
  const [brandData, setBrandData] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);
  const fetchProduct = async (id) => {
    const productRef = doc(db, "products", id);
    const productSnapshot = await getDoc(productRef);
    if (productSnapshot.exists()) {
      setProductData(productSnapshot.data());
    }
  };
  const handleTrashIconClick = async () => {
    if (!user) return;

    const userRef = doc(db, "users", user?.uid);
    const shoppingListRef = collection(userRef, "shoppingList");

    try {
      const listDocRef = doc(shoppingListRef, list?.id);
      const listDoc = await getDoc(listDocRef);

      if (listDoc.exists()) {
        // Check visibility and user role
        if (shoppingListData?.[0].visibility === "Friends") {
          const userRole = shoppingListData?.[0]?.friends?.find(
            (friend) => friend.id === user?.uid
          )?.role;
          console.log("userRole", userRole);
          if (userRole !== "editor") {
            toast.error(
              "You do not have permission to delete this item.",
              toastOptions
            );
            return;
          }
        }

        // Handle product deletion from the 'products' subcollection
        const productRef = doc(listDocRef, "products", product?.id);

        // Check if the product document exists
        const productDoc = await getDoc(productRef);
        if (productDoc.exists()) {
          // Delete the product document from the 'products' subcollection
          await deleteDoc(productRef);

          // Update the inShoppingList entry
          const productInShoppingListRef = doc(
            db,
            "products",
            product.id,
            "inShoppingList",
            user.uid
          );
          const inShoppingListDoc = await getDoc(productInShoppingListRef);
          if (inShoppingListDoc.exists()) {
            const { listNames } = inShoppingListDoc.data();
            const newListNames = listNames.filter((name) => name !== list?.id);
            if (newListNames.length === 0) {
              // If no more lists contain this product, remove the document
              await deleteDoc(productInShoppingListRef);
            } else {
              // Otherwise, update the document with the new list of names
              await updateDoc(productInShoppingListRef, {
                listNames: newListNames,
              });
            }
          }

          // Decrement the shoppingListCount
          await updateDoc(userRef, {
            shoppingListCount: increment(-1),
          });

          toast.success(
            `${productData?.name} removed from ${list?.name}`,
            toastOptions
          );
        } else {
          console.error("Product not found in the list.");
        }
      } else {
        console.error("List document does not exist.");
      }
    } catch (error) {
      console.error(
        "Error removing product from shopping list:",
        error.message
      );
    }
  };
  const fetchBrandData = async () => {
    const brandRef = doc(db, "brands", productData?.supplier);
    try {
      const brandSnapshot = await getDoc(brandRef);
      if (brandSnapshot.exists()) {
        setBrandData(brandSnapshot.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
    // setHasLoadedBrandData(true);
  };

  const handleClick = () => {
    const DATA = {
      deleteFunction: handleTrashIconClick,
      displayName: productData?.name,
      shoppingListName: list?.name,
      componentName: "ShopListProdCard",
    };
    openModal("ConfirmDelete", "shoppingListProduct", DATA);
  };
  const handleLike = async (productId) => {
    if (!user) {
      openModal("SignInModal");
      return;
    } else if (!user?.emailVerified) {
      openModal("VerifyEmailModal", "", {
        userName: user?.displayName,
        email: user?.email,
      });
      return;
    }

    // Navigate to the product within the user's shopping list
    const userRef = doc(db, "users", ownerId);
    const shoppingListRef = collection(userRef, "shoppingList");
    const productRef = doc(shoppingListRef, list?.id, "products", productId);

    const likesRef = collection(productRef, "likes");
    const userLikeRef = doc(likesRef, user?.uid);

    try {
      const userLikeSnapshot = await getDoc(userLikeRef);

      const batch = writeBatch(db);

      if (!userLikeSnapshot.exists()) {
        // User has not liked the product, add like
        setProductData((prev) => ({
          ...prev,
          likeCount: prev?.likeCount + 1,
        }));
        setHasLiked(true);
        batch.set(userLikeRef, {
          timestamp: serverTimestamp(),
        });

        // Increment likeCount in the product document
        batch.update(productRef, {
          likeCount: increment(1),
        });
        console.log("from shoppinglist card inside before addNotification");
        if (user?.uid !== ownerId) {
          console.log("from shoppinglist card inside addNotification");
          console.log("userId" + ownerId + "listId" + list?.listId);

          await addNotification({
            userId: ownerId,
            id: user?.uid,
            type: "listProductLike",
            by: "user",
            fields: {
              productId: productId,
              shopList: list?.name,
              listId: list?.listId,
            },
          });
        }

        // toast.success("Product liked!", toastOptions);
      } else {
        setProductData((prev) => ({
          ...prev,
          likeCount: prev?.likeCount - 1,
        }));
        setHasLiked(false);
        // User has already liked the product, remove like
        batch.delete(userLikeRef);

        // Decrement likeCount in the product document
        batch.update(productRef, {
          likeCount: increment(-1),
        });

        if (user?.uid !== ownerId) {
          await deleteNotification({
            userId: ownerId,
            id: user?.uid,
            type: "listProductLike",
            by: "user",
            fields: {
              productId: productId,
              shopList: list?.name,
              listId: list?.listId,
            },
          });
        }

        // toast.info("Product unliked!", toastOptions);
      }

      await batch.commit();
    } catch (error) {
      console.error("Error updating like status:", error);
      toast.error("Failed to update like status.", toastOptions);
    }
  };
  useEffect(() => {
    fetchProduct(product?.id);
  }, []);
  useEffect(() => {
    if (productData) fetchBrandData();
  }, [productData]);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const checkIfLiked = async () => {
      if (user && productData?.id) {
        const userRef = doc(db, "users", ownerId);
        const shoppingListRef = collection(userRef, "shoppingList");
        const productRef = doc(
          shoppingListRef,
          list?.id,
          "products",
          productData?.id
        );
        const likesRef = collection(productRef, "likes");
        const userLikeRef = doc(likesRef, user?.uid);
        const userLikeSnapshot = await getDoc(userLikeRef);

        if (userLikeSnapshot.exists()) {
          setHasLiked(true); // User has already liked the product
        } else {
          setHasLiked(false);
        }
      }
    };

    checkIfLiked();
  }, [user, productData?.id]);
  return (
    showProduct && (
      <div className="shadow-sm p-2 rounded-2">
        <div className="d-flex flex-column">
          <div
            style={{
              position: "relative",
              height: "15rem",
              width: isSmallScreen ? "9rem" : "13.25rem",
            }}
          >
            <Link to={`/ProductDetails/${productData?.id}`}>
              <Card.Img
                variant="top"
                className="rounded-2"
                src={productData?.imageUrl}
                alt={`Image of the product`}
                onError={() => setShowProduct(false)}
                style={{
                  objectFit: "cover",
                  objectPosition: "top",
                  height: "100%",
                }}
              />
            </Link>
            <div
              style={{
                position: "absolute",
                backgroundColor: "#59A800",
                color: "white",
                width: "4rem",
                fontSize: "10px",
                alignContent: "center",
                height: "1.5rem",
              }}
              className="rounded-pill text-center top-0 end-0 m-1"
            >
              PKR {formatPrice(productData?.newPrice)}
            </div>
          </div>
          <div
            className={`d-flex justify-content-between align-items-center mt-2 gap-2`}
          >
            <div className="d-flex gap-1 align-items-center flex-column">
              <div
                className="h6 mb-0 text-truncate fw-light"
                style={{
                  width: isSmallScreen ? "4.15rem" : "8rem",
                  fontSize: "10px",
                }}
              >
                {brandData?.displayName}
              </div>
              <div
                className="h6 mb-0 text-truncate"
                style={{
                  width: isSmallScreen ? "4.15rem" : "8rem",
                  fontSize: "12px",
                }}
              >
                {productData?.name}
              </div>
            </div>
            <div
              className="icon-sm text-white rounded-circle border border-secondary"
              onClick={() => handleLike(productData?.id)}
              style={{
                cursor: "pointer",
              }}
            >
              <i
                className={`fs-6 bi ${
                  hasLiked ? "bi-heart-fill" : "bi-heart"
                } text-secondary`}
              ></i>
            </div>
            <div className="icon-sm text-white rounded-circle border border-secondary">
              <AddToCart
                user={user}
                product={productData}
                componentName={"ShopListProdCard"}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ShoppingListProductCard;
