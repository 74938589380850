import React from "react";
import { Link } from "react-router-dom";
import { useFriendActions } from "../hooks/user/useFriendActions";
import ProfilePicture from "../UserProfile/ProfilePicture";
import useModalStore from "../StatesStore/ModalStore";

const UserProfileCard = ({ searchUser, user, componentName }) => {
  const {
    requestList,
    pendingList,
    activeList,
    handleFriendAction,
  } = useFriendActions(searchUser, user);

  const { openModal } = useModalStore();
  if (!searchUser) {
    return <div className="loading-indicator">Loading...</div>;
  }
  const handleClick = () => {
    openModal("SignInModal", "", "");
  };
  console.log("The search user is", searchUser);
  return (
    <div
      className={`${
        componentName === "Users"
          ? "col-6 col-sm-6 col-md-4 col-lg-2"
          : "col-6 col-sm-4 col-md-3 col-lg-2 p-1 m-0"
      }`}
    >
      <div className="card shadow-none text-center p-2">
        <div className="card-body p-2 pb-0">
          <div className="avatar avatar-xl">
            <Link to={`/userProfile/${searchUser.objectID}`}>
              <ProfilePicture
                userData={searchUser?.objectUser}
                className="avatar-img rounded"
              />
            </Link>
          </div>
          <h6 className="card-title mb-1 mt-3">
            <Link to={`/userProfile/${searchUser?.objectID}`}>
              <div className="text-truncate">{searchUser?.displayName}</div>
            </Link>
          </h6>
        </div>
        {searchUser?.objectID !== user?.uid ? (
          <div className="card-footer p-2 border-0">
            <button
              className="btn btn-sm btn-primary-soft w-100"
              onClick={() => {
                if (!user) {
                  handleClick();
                } else if (!user?.emailVerified) {
                  openModal("VerifyEmailModal", "", {
                    userName: user?.displayName,
                    email: user?.email,
                  });
                } else if (user) {
                  handleFriendAction(searchUser?.objectID, user);
                }
              }}
            >
              {activeList && activeList.includes(searchUser?.objectID)
                ? " Unfriend"
                : requestList && requestList.includes(searchUser?.objectID)
                ? " Cancel Request"
                : pendingList && pendingList.includes(searchUser?.objectID)
                ? " Respond"
                : " Add Friend"}
            </button>
          </div>
        ) : (
          <div className="card-footer p-2 border-0">
            <Link
              to={`/userProfile/${searchUser?.objectID}`}
              className="btn btn-sm btn-primary-soft w-100"
            >
              View
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfileCard;
