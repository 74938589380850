import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  getDoc,
  doc,
  where,
  onSnapshot,
  serverTimestamp,
  getDocs,
  updateDoc,
  setDoc,
  increment,
} from "firebase/firestore";
import Picker from "emoji-picker-react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { formatTimestampForPost } from "../lib/formatTimestampForPost";
import { UserAuth } from "../Context";
import ProfilePicture from "../UserProfile/ProfilePicture";
import useModalStore from "../StatesStore/ModalStore";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useNotification from "../hooks/useNotification";
import useSocketStore from "../StatesStore/Socket";
import useUserDataStore from "../StatesStore/UserData";
import { decryptChat, encryptChat } from "./Encryption";
import { saveListMessageToDb } from "./lib/saveListMessageToDb";
import ProductCard3 from "../Products/ProductCard3";
import { formatTimestampChat } from "../Chat/lib.js/formatTimestampChat";
import useChatClient from "../StatesStore/ChatClient";
import useThemeState from "../StatesStore/Theme";
import LoadingSpinner from "../UserProfile/LoadingSpinner";
import { EmojiPicker } from "stream-chat-react/emojis";
import { encodeToMp3 } from "stream-chat-react/mp3-encoder";

import {
  Chat,
  Channel,
  ChannelHeader,
  MessageInput,
  MessageList,
  Thread,
  Window,
  ChannelList,
} from "stream-chat-react";
import CustomMessageInput from "../Chat/CustomMessageInput";
import CustomAttachment from "../Chat/CustomAttachment";
import { CustomAttachmentSelector } from "../Chat/CustomAttachmentSelector";

const SharedListComments = ({
  shoppingListData,
  selectedShopList,
  setShoppingListData,
  userId,
  listId,
  friends,
  visibility,
  ownerId
}) => {
  console.log("selectedShopList from SharedListComments",selectedShopList);
  const endRef = useRef();
  const emojiPickerRef = useRef();
  const list = shoppingListData.find((list) => list.name === selectedShopList);
  const dateCreated = new Date(list?.dateCreated * 1000);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = dateCreated.toLocaleDateString("en-US", options);
  // const [recentCommentTime, setRecentCommentTime] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { user } = UserAuth();
  const { openModal,closeModal } = useModalStore();
  const { addNotification } = useNotification();
  const [listMembers, setListMembers] = useState(null);
 
  const userData = useUserDataStore((state) => state.userData);
  const [productData, setProductData] = useState(null);
  const commentRef = useRef();
  const client = useChatClient((state) => state.client);
  const [channel, setChannel] = useState(null);
  const { theme } = useThemeState();


useEffect(() => {
    const initializeChannel = async () => {
      if (!client || !listId ) return;
   

      try {
        const channel = client.channel("team", listId, {
          name: selectedShopList,
          members: [user?.uid],
        });

        await channel.watch();

        // Listen for new messages
        channel.on("message.new", (event) => {
          const newMessage = {
            content: event.message.text,
            senderId: event.user.id,
            userName: event.user.name,
            photo: event.user.image,
            timestamp: new Date(event.created_at).getTime(),
            type: event.message.type || "text",
            productData: event.message.productData,
            productDocID: event.message.productDocID,
          };

          setComments((prevComments) => [...prevComments, newMessage]);
        });

        setChannel(channel);
      } catch (error) {
        console.error("Error initializing channel:", error);
      }
    };

    initializeChannel();

    return () => {
      if (channel) {
        channel.stopWatching();
      }
    };
  }, [client, listId]);

 

  const handleSubmit = async (e) => {
    if(!user){
      openModal("SignInModal");
      return;
    } else if (!user?.emailVerified) {
      openModal("VerifyEmailModal", "", {
        userName: user?.displayName,
        email: user?.email,
      });
      return;
    } else{
      closeModal()
    }
    e.preventDefault();

    // Check if visibility is 'Friends' and the user has appropriate role
  if (visibility === "Friends") {
    const canPost = friends.some(
      (friend) => friend.id === user.uid && (friend.role === "editor" || friend.role === "commentor")
    );

    if (!canPost) {
      toast.warn("You do not have permission to post a comment.", toastOptions);
      return;
    }
  }
    const text = commentText;
    // Clear the comment input field
    setCommentText("");
    console.log("CLICKED");
    try {
      const encryptedMessage = encryptChat(text);
      // Create a new comment document with specified attributes
      const msgData = {
        content: encryptedMessage,
        timestamp: new Date(),
        senderId: userId,
        listId: listId,
        userName: userData?.userName,
        photo: userData?.photo,
        type: productData ? "product" : "text",
      };
      console.log("msgData from list", msgData);
      const localMsgData = {
        ...msgData,
        timestamp: new Date().getTime(), // Use local timestamp for immediate display
      };
      setComments(prevComments => [...prevComments, localMsgData]);
      // Fetch the comments again to include the newly added comment
      // socket.emit("list chat msg", {
      //   listId,
      //   msg: msgData,
      // });
      console.log('msgData listId listMembers',listId,listMembers);
      console.log('Eror is here before');
      // Fetch the comments again to include the newly added comment
      // Create a new comment document with specified attributes
      const commentDocRef = await saveListMessageToDb(msgData, listId, listMembers);

      if (productData) {
        // Add if any productRef is used in chat
        const product = await addDoc(
          collection(
            db,
            "chats",
            listId,
            "messages",
            commentDocRef,
            "product"
          ),
          { ...productData }
        );
        await updateDoc(doc(db, "chats", listId, "messages", commentDocRef), {
          productDocID: product?.id,
        });
        setProductData(null);
    
      }
      const commentDocId = commentDocRef.id;
      if (ownerId!==userId) {
        await addNotification({
          userId: ownerId,
          id: user?.uid,
          timestamp: serverTimestamp(),
          type: "listChat",
          by: "user",
          fields: {
            listId: listId,
            shopList:selectedShopList,
            commentId: commentDocId,
          },
        });
      }
      // endRef.current.scrollIntoView({ behavior: "smooth" });
      // const chatContainer = document.getElementById("chat-container"); // Replace with your chat container's ID
      // chatContainer.scrollIntoView({ behavior: "smooth" });
      // chatContainer.scrollTop = chatContainer.scrollHeight;
    } catch (error) {
      console.error("Error adding comment: ", error);
      // Handle any errors here, e.g., display an error message to the user
    }
  };
  
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const addEmoji = (emojiObject) => {
    // Implement logic to add emoji to comment text
    const emoji = emojiObject.emoji;
    setCommentText(commentText + emoji);
  };

  const handleAddProductToList = async (productID, product) => {
    const userRef = doc(
      db,
      "users",
      userId,
      "shoppingList",
      selectedShopList,
      "products",
      productID
    );
    await setDoc(userRef, {
      size: "",
      timestamp: serverTimestamp(),
      likeCount: 0,
    });
    // Increment the shoppingListCount
    await updateDoc(doc(db, "users", user.uid), {
      shoppingListCount: increment(1),
    });

    let tempListData = shoppingListData;
    tempListData.forEach((list) => {
      if (list.name === selectedShopList) {
        list.products.push(product);
      }
    });
    setShoppingListData(tempListData);
    toast.success(`Product Added to ${selectedShopList} list`, toastOptions);
  };

  const handleCommentInput = async (e) => {
    setCommentText(e.target.value);
    if (e.target.value.includes("/#/ProductDetails/")) { 
      let text = e.target.value;
      // Extract the product code
      text = text.split("/#/ProductDetails/");
      // test case checking for: if no code is written is added after ProductDetails/
      if (text[1] !== "") {
        console.log("FOUNDD", text);
        //If some extra text is used after link then do not fetch again and again for product
        if (text[1].split(" ").length === 1) {
          const productRef = doc(db, "products", text[1].split(" ")[0]);
          const productSnap = await getDoc(productRef);
          if (productSnap.exists()) {
            console.log("FOUNDD", productSnap.data());
            setProductData(productSnap.data());
            if (commentRef && commentRef.current) {
              commentRef.current.scrollTop = commentRef.current.scrollHeight;
            }
          } else setProductData(null);
        }
      }
    } else {
      setProductData(null);
    }
  };
  // const compareTime = (timestamp) => {
  //   if (!recentCommentTime || recentCommentTime !== timestamp) {
  //     setRecentCommentTime(timestamp);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  if (!client) {
    return <LoadingSpinner />;
  }
  const containerStyle = {
    display: "flex",
    height: "50vh",
    width: "100%",
    overflow: "hidden",
    gap: "10px",
  };

  const channelListStyle = {
    flex: 1,
    maxWidth: "300px",
    borderLeft: "1px solid rounded",
    overflowY: "auto",
    backgroundColor: "#f8f9fa",
    borderRadius: "4px 0 0 4px",
  };

  const channelWindowStyle = {
    flex: 3,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    borderRadius: "0px 4px 4px 0px",
  };
  return (
    <>
      <style>
        {`
        .str-chat {
          --str-chat__border-radius-circle: 4px; 
          --str-chat__avatar-background-color: #dddddd;
          --str-chat__avatar-color:#222222;
           --str-chat__channel-preview-active-background-color: #e7f0fd;
        }
      `}
      </style>
      <Chat
        client={client}
        customClasses={{
          channelList: `str-chat__theme-${theme} str-chat__channel-list`,
          channel: `str-chat__theme-${theme} str-chat__channel`,
        }}
      >
        <div style={containerStyle} id={`${listId}chatContainer`}>
          <div style={channelWindowStyle}>
            <Channel channel={channel} EmojiPicker={EmojiPicker} Attachment={CustomAttachment} AttachmentSelector={CustomAttachmentSelector}>
              <Window>
                <MessageList
                  messageActions={["edit", "delete", "quote", "react"]}
                  closeReactionSelectorOnClick={true}
                />
                 <CustomMessageInput
                  audioRecordingConfig={{
                    transcoderConfig: { encoder: encodeToMp3 },
                  }}
                  audioRecordingEnabled
                  urlEnrichmentConfig={{ enrichURLForPreview: true }}
                />
              </Window>
            </Channel>
          </div>
        </div>
      </Chat>
    </>
  );
};

export default SharedListComments;
