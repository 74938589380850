import React from "react";

function EmptyList({ content, componentName }) {
  return (
    <div
      className={`${
        componentName === "shoppingList" ? "" : "my-sm-5 py-sm-3"
      }  d-flex justify-content-center align-items-center flex-column text-center `}
    >
      <i className="display-1 text-body bi bi-bag-x"> </i>

      <h4
        className="mt-1 mb-1 text-body"
        style={{
          whiteSpace: "normal",
          width: "100%",
        }}
      >
        {content}
      </h4>
    </div>
  );
}

export default EmptyList;
