import React from 'react';

export const DialogMenuButton = ({ children, className, type, ...props }) => (
  <button className={`str-chat__dialog-menu__button d-flex align-items-center ${className || ''}`} {...props}>
    {type === "ShoppingList" ? (
      <div className='str-chat__dialog-menu__button-icon' />
    ) : (
      // <i className="bi bi-bookmark " style={{ color: "#005fff" }}></i>
      <div className='str-chat__dialog-menu__button-icon' />
    )}
    <div className='str-chat__dialog-menu__button-text'>{children}</div>
  </button>
);
