import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
const Birthday = ({
  birthday,
  setBirthday,
  calculateAge,
  userStoreUser,
  handleNext,
}) => {
  const [birthdayError, setBirthdayError] = useState("");
  const handleChangeBirthday = (date) => {
    setBirthdayError("");
    console.log(date.toLocaleDateString("en-us"));
    setBirthday(date);
  };
  const handleSubmitBirthday = (e) => {
    e.preventDefault();
    if (birthday !== "") {
      let modifiedBirthday = birthday.toLocaleDateString("en-us");
      const age = calculateAge(modifiedBirthday);
      if (age < 13) {
        console.log(
          "You must be at least 13 years old to sign in to Lookflock"
        );
        setBirthdayError(
          "You must be at least 13 years old to sign in to Lookflock!"
        );
        return;
      } else {
        userStoreUser.birthday = modifiedBirthday;
        userStoreUser.isVerified = true;
        handleNext();
      }
    } else {
      setBirthdayError("Please enter your birthday first!");
    }
  };
  return (
    <>
      <div>
        <p className="mt-3 fs-4 grey">When is your birthday?</p>
      </div>
      <form onSubmit={(e) => handleSubmitBirthday(e)}>
        <div className="mb-3 input-group flex-grow-1">
          {/* <DatePicker
            value={birthday}
            onChange={(date) => handleChangeBirthday(date)}
            className="form-control "
            locale="en-us"
            required
          /> */}
          <DatePicker
            selected={birthday}
            onChange={(date) => setBirthday(date)}
            className="w-50 text-center fs-5 rounded-2 bg-body text-body"
          />
        </div>
        <div className="text-danger text-start mb-2 ms-1">{birthdayError}</div>
        <div className="d-grid">
          <button type="submit" className="btn btn-primary flex-grow-1">
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default Birthday;
