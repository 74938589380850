import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import {
  deleteShoppingListFromAlgolia,
  saveShoppingListToAlgolia,
} from "../lib/algoliaFunctions";
function Settings({ selectedShopList }) {
  const { user } = UserAuth();
  const visibilityLevels = ["Public", "Friends", "Private"];
  const visibilityOptions = {
    Public: "Public  (Anyone can view and comment)         ",
    Friends: "Friends (Invited Friends according to roles)",
    Private: "Private (Only You can perform actions)      ",
  };
  const [visibility, setVisibility] = useState(visibilityLevels[0] || "");
  const [show, setShow] = useState(false);
  console.log("visibility is ", visibility);
  console.log("selectedShopList1 is ", selectedShopList);
  const handleClose = () => {
    setShow(false);
  };
  const fetchVisibility = async () => {
    if (!user || !selectedShopList) return;

    // Reference to the shoppingList subcollection document
    const shoppingListDocRef = doc(
      db,
      "users",
      user?.uid,
      "shoppingList",
      selectedShopList
    );
    const shoppingListDocSnap = await getDoc(shoppingListDocRef);

    if (!shoppingListDocSnap.exists()) {
      console.log("No such document in shoppingList subcollection!");
      return; // Early return if shopping list document does not exist
    }

    // Extract visibility from the shopping list document
    const visibilityFromDB =
      shoppingListDocSnap?.data()?.visibility || visibilityLevels[0];
    setVisibility(visibilityFromDB);
  };

  useEffect(() => {
    fetchVisibility();
    // eslint-disable-next-line
  }, [selectedShopList, user]);

  const handleClick = async () => {
    try {
      // Reference to the specific document in the shoppingList subcollection
      const shoppingListDocRef = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        selectedShopList
      );

      // Read the document
      const docSnap = await getDoc(shoppingListDocRef);

      if (!docSnap.exists()) {
        console.log("No such document!");
      } else {
        if (visibility === "Public") {
          console.log("saving list to algolia");
          saveShoppingListToAlgolia(user, docSnap?.id);
        } else {
          console.log("deleting list from algolia");
          deleteShoppingListFromAlgolia(docSnap?.data()?.listId);
        }
        // Update the visibility property within the specific document
        await updateDoc(shoppingListDocRef, {
          visibility: visibility, // Set the visibility to the desired value
        });
        toast.success(`Settings has been updated`, toastOptions);
        console.log("Document successfully updated with new visibility!");
      }
      handleClose();
    } catch (error) {
      console.error("Error updating visibility: ", error);
    }
  };

  return (
    <>
      <li className="nav-item my-2" onClick={() => setShow(true)}>
        <Link className="nav-link icon-md btn btn-light p-0" to="">
          <i className="bi bi-gear-fill fs-6"></i>
        </Link>
        <span className="mx-2  h6 fw-light">Settings</span>
      </li>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedShopList} List Privacy Control</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onClick={(e) => e.stopPropagation()}>
            {Object.entries(visibilityOptions).map(([level, label]) => (
              <Form.Check
                key={level}
                type="radio"
                id={level}
                name="visibility"
                label={label}
                value={level}
                checked={visibility === level}
                onChange={(e) => setVisibility(e.target.value)}
              />
            ))}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary-soft" onClick={handleClick}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <!-- Modal create events END --> */}
    </>
  );
}

export default Settings;
