import {
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Copy } from "react-bootstrap-icons";
import { db } from "../FirebaseConfig";
import uuid4 from "uuid4";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
const CloneModal = ({
  list,
  listName,
  userId,
  shoppingListData,
  setShoppingListData,
  setLoading,
}) => {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  console.log("list", list);
  const cloneShoppingList = async () => {
    try {
      setLoading(true);
      const filteredShoppingLists = [];
      shoppingListData.filter((list) => {
        if (list.name.startsWith(listName))
          filteredShoppingLists.push(list?.name);
      });
      let index = 2;
      while (filteredShoppingLists.includes(`${listName} (${index})`)) {
        index++;
      }
      console.log("filteredShoppingLists, index", filteredShoppingLists, index);

      const newListId = uuid4();
      const newShopListRef = doc(
        db,
        "users",
        userId,
        "shoppingList",
        `${listName} (${index})`
      );
      const newShopListSnap = await getDoc(newShopListRef);
      if (!newShopListSnap.exists()) {
        await setDoc(newShopListRef, {
          dateCreated: serverTimestamp(),
          lastUpdated: serverTimestamp(),
          listId: newListId,
          listName: `${listName} (${index})`,
          visibility: "Private",
        });
        const newProductRef = collection(newShopListRef, "products");
        const productRef = collection(
          db,
          "users",
          userId,
          "shoppingList",
          list?.id,
          "products"
        );
        const productSnap = await getDocs(productRef);
        const products = productSnap.docs.map((doc) => doc?.id);
        await Promise.all(
          products?.map(async (product) => {
            const productRef = doc(newProductRef, product);
            await setDoc(productRef, {
              size: [],
              timestamp: serverTimestamp(),
              likeCount: 0,
              updatedAt: serverTimestamp(),
            });
          })
        );
        const newProductSnap = await getDocs(newProductRef);
        const newProducts = newProductSnap.docs.map((doc) => ({
          id: doc?.id,
          ...doc?.data(),
        }));
        const shoppingListDataArray = [
          ...shoppingListData,
          {
            name: `${listName} (${index})`,
            dateCreated: new Date(),
            lastUpdated: new Date(),
            products: newProducts,
            listId: newListId,
            visibility: "Private",
          },
        ];
        shoppingListDataArray.sort((a, b) => b.lastUpdated - a.lastUpdated);
        setShoppingListData(shoppingListDataArray);
        toast.success(
          ` ${listName} Shopping List has been cloned as ${listName} (${index})`,
          toastOptions
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="d-flex align-items-center">
        <li className="nav-item" onClick={() => setShowModal(true)}>
          <div className="icon-md btn btn-light p-0 text-body">
            <Copy className="fs-6" />
          </div>
          <span className="mx-2 h6 fw-light">Clone Shopping List</span>
        </li>
      </div>
      <Modal show={showModal} centered onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Clone Shopping List</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <i
            style={{ fontSize: "4.5rem" }}
            className="text-body bi bi-exclamation-triangle"
          ></i>
          <div className="mt-4 fs-5 text-body">
            Are you sure you want to clone {listName}?
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="danger-soft"
            className="w-25 mx-1"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="w-25 mx-1"
            onClick={async () => {
              cloneShoppingList();
              closeModal();
            }}
          >
            Clone
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CloneModal;
