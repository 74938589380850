import { create } from "zustand";

const useSearchFilter = create((set) => ({
  SearchFilter: {
    gender: [],
    brand: [],
    size: [],
    minprice: 0,
    maxprice: 90000,
    color: [],
    subCategory: [],
    subSubCategory: [],
  },
  setSearchFilter: (filter) =>
    set((state) => ({ ...state, SearchFilter: filter })),
}));

export default useSearchFilter;
