import React from "react";
import Slider from "react-slick";
import ShoppingListCard from "./ShoppingListCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EmptyList from "./EmptyList";
import { useLocation, useParams } from "react-router-dom";
import { UserAuth } from "../Context";
const PrevArrow = ({ style, onClick }) => {
  return (
    <div
      className="slick-prev"
      style={{
        ...style,
        fontSize: "24px",
        color: "grey",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-prev-icon"
        style={{ filter: "brightness(0.5)" }}
      ></span>
    </div>
  );
};
const NextArrow = ({ style, onClick }) => {
  return (
    <div
      className="slick-next"
      style={{
        ...style,
        fontSize: "24px",
        color: "grey",
      }}
      onClick={onClick}
    >
      <span
        className="carousel-control-next-icon"
        style={{ filter: "brightness(0.5)" }}
      ></span>
    </div>
  );
};

const ProductList = ({
  shoppingListData,
  selectedShopListName,
  selectedShopListId,
  userId,
  role,
  ownerId,
  ownerName,
  notify,
  componentName,
  slidesToShow = 5,
}) => {
  const location = useLocation();
  const { user } = UserAuth();
  const { uid } = useParams();
  console.log("shoppingListData in product list", shoppingListData);
  const selectedShopListData = shoppingListData.find(
    (shopList) =>
      shopList.name === selectedShopListName &&
      shopList.listId === selectedShopListId
  );
  console.log("selectedShopListData in product list", selectedShopListData);
  const productCount = selectedShopListData
    ? selectedShopListData.products.length
    : 0;
  const settings = {
    speed: 500,
    infinite: false,
    slidesToShow: slidesToShow,
    slidesToScroll: productCount > 9 ? 4 : 1,
    dots: productCount < 6 ? false : true,
    swipe: productCount < 6 ? false : true,
    nextArrow: productCount < 6 ? <></> : <NextArrow />,
    prevArrow: productCount < 6 ? <></> : <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          dots: productCount < 5 ? false : true,
          swipe: productCount < 5 ? false : true,
          nextArrow: productCount < 5 ? <></> : <NextArrow />,
          prevArrow: productCount < 5 ? <></> : <PrevArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: false,
          swipe: productCount < 3 ? false : true,
          nextArrow: productCount < 3 ? <></> : <NextArrow />,
          prevArrow: productCount < 3 ? <></> : <PrevArrow />,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          swipe: productCount < 2 ? false : true,
          nextArrow: productCount < 2 ? <></> : <NextArrow />,
          prevArrow: productCount < 2 ? <></> : <PrevArrow />,
        },
      },
    ],
  };

  return (
    <div
      className={`card ${
        componentName === "UserProfile" ? "px-3" : "px-4"
      } bg-transparent border border-0 mb-5`}
    >
      <div className="card-body p-0 px-3 bg-transparent">
        {shoppingListData.some(
          (shopList) =>
            shopList.name === selectedShopListName &&
            shopList.products.length > 0
        ) ? (
          <Slider {...settings} className="m-0">
            {shoppingListData
              .filter(
                (shopList) =>
                  shopList.name === selectedShopListName &&
                  shopList.listId === selectedShopListId
              )
              .flatMap((shopList) =>
                shopList.products.map((product, index) => (
                  <div key={index}>
                    <ShoppingListCard
                      product={product}
                      listName={shopList.name}
                      shoppingListData={shoppingListData}
                      userId={userId}
                      role={role}
                      listId={selectedShopListId}
                      ownerId={ownerId}
                      notify
                    />
                  </div>
                ))
              )}
          </Slider>
        ) : (
          <EmptyList
            content={
              location.pathname.includes("inviteLists")
                ? `${ownerName} haven't added any products in ${selectedShopListName} yet.`
                : location.pathname.includes("shoppingLists")
                ? `You haven't added any products in ${selectedShopListName} yet.`
                : `${
                    uid === user?.uid ? "You" : ownerName
                  } haven't added any products in ${selectedShopListName}`
            }
          />
        )}
      </div>
    </div>
  );
};

export default ProductList;
