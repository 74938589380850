import {
  arrayRemove,
  arrayUnion,
  deleteDoc,
  doc,
  getDoc,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";
import { Bookmark, BookmarkFill } from "react-bootstrap-icons";
import useModalStore from "../StatesStore/ModalStore";
import useSaveStore from "../StatesStore/SaveStore";
function AddOrRemoveSaved({ post, user }) {
  // console.log("userData", user);
  const savedProducts = useSaveStore((state) => state.savedProducts);
  const addSave = useSaveStore((state) => state.addSave);
  const removeSave = useSaveStore((state) => state.removeSave);
  const [isSaved, setIsSaved] = useState(false);
  const { openModal } = useModalStore();
  const handleToggleSave = async () => {
    if (!user) return;
    console.log(user?.uid);
    console.log(post);
    const savedPostRef = doc(db, "users", user?.uid, "saved", post?.id);
    const postRef = doc(db, "posts", post?.id);
    const postDoc = await getDoc(postRef);
    const newsFeedRef = doc(db, "users", user?.uid, "newsFeed2", post?.id);
    const newsFeedDoc = await getDoc(newsFeedRef);
    if (savedProducts.includes(post?.id)) {
      //Remove the post
      await deleteDoc(savedPostRef);
      await updateDoc(postRef, {
        saves: postDoc.data().saves > 0 ? increment(-1) : 0,
      });
      //Will delete it later
      if (newsFeedDoc.exists()) {
        await updateDoc(newsFeedRef, {
          saves: newsFeedDoc.data().saves > 0 ? increment(-1) : 0,
        });
      }
      removeSave(post?.id);
      post.saves -= 1;
    } else {
      //Add the post
      await setDoc(savedPostRef, post);
      await updateDoc(postRef, {
        saves: increment(1),
      });
      //Will delete it later
      if (newsFeedDoc.exists()) {
        await updateDoc(newsFeedRef, {
          saves: increment(1),
        });
      }
      addSave(post?.id);
      post.saves ? (post.saves += 1) : (post.saves = 1);
    }
    console.log("post Saved");
  };
  const handleClick = () => {
    if (!user) {
      // Check if user is not logged in before opening modal
      openModal("SignInModal", "", ""); // Open modal here
    }
  };
  useEffect(() => {
    setIsSaved(savedProducts.includes(post?.id));
  }, []);
  return (
    <Link
      className={`nav-link m-0 nav-item rounded-circle d-flex align-items-center justify-content-center `}
      to=""
      onClick={() => {
        if (!user?.emailVerified) {
          openModal("VerifyEmailModal", "", {
            userName: user?.displayName,
            email: user?.email,
          });
        }
        else {
          handleToggleSave();
          setIsSaved(!isSaved);
        }
      }}
    >
      {!user && (
        <Link
          to=""
          className={`nav-link m-0 p-0 nav-item`}
          onClick={handleClick}
        >
          <Bookmark
            style={{ color: "rgb(255 0 0 / 90%)" }}
            className="fs-4 text-body"
          />
        </Link>
      )}

      {user && (
        <>
          {isSaved ? (
            // <BookmarkFill className="fs-4" style={{ color: "#010101a8" }} />
            <BookmarkFill
              style={{ color: "rgb(255 0 0 / 90%)" }}
              className="fs-4"
            />
          ) : (
            // <BookmarkFill className="fs-4" style={{ color: "#01010161" }} />
            <Bookmark
              style={{ color: "rgb(255 0 0 / 90%)" }}
              className="fs-4"
            />
          )}
        </>
      )}
    </Link>
  );
}

export default AddOrRemoveSaved;
