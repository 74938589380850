import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context";
import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  serverTimestamp,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, FormSelect, InputGroup, Form } from "react-bootstrap";
import { IoIosCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import {
  Link45deg,
  PersonExclamation,
  PersonPlusFill,
} from "react-bootstrap-icons";
import useNotification from "../hooks/useNotification";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { getBaseURL } from "../lib/getBaseURL";
import algoliasearch from "algoliasearch";
import CheckStatus from "../lib/checkUserStatus";
const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);
const indexUsers = client.initIndex("users"); // Name of index in Algolia is users

function InviteOnList({ selectedShopList, listId }) {
  // console.log("I am on Invite on List", selectedShopList);
  const [selectedRole, setSelectedRole] = useState("editor"); // Initialize with a default value
  const [friendId, setFriendId] = useState("");
  const [friendsData, setFriendsData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [unsharedUsers, setUnsharedUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [friendCount, setFriendCount] = useState(0);
  const { addNotification } = useNotification();

  const baseURL = getBaseURL();
  // let friendCount = 0;
  const { user } = UserAuth();
  const [DATA, setDATA] = useState({
    friendId: "",
    displayName: "",
  });
  const rolesOptions = [
    { name: "editor", label: "Editor" },
    { name: "viewer", label: "Viewer" },
    { name: "commentor", label: "Commentor" },
  ];
  const [input, setInput] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (input.trim().length > 2) {
      navigate(`/search/${input}/users`);
    }
  };

  const handleSearchBar = async (event) => {
    event.preventDefault();
    const itemsPerPage = 12;

    if (input.trim().length >= 3) {
      const searchQuery = `${input.trim()}`;
      console.log("Search input: ", searchQuery);
      try {
        const offset = 0;
        const data = await indexUsers.search(searchQuery, {
          offset,
          length: itemsPerPage,
        });
        const uniquePeople = new Set([...data.hits]);
        const updatedPeople = Array.from(uniquePeople);

        // Filter out any users that are already in the friendsData list
        const filteredPeople = updatedPeople.filter(
          (person) =>
            !friendsData.some((friend) => friend.id === person.objectID)
        );

        console.log(
          `Users in search on shopping list invite modal: `,
          filteredPeople
        );
        setSearchData(filteredPeople);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    } else {
      console.log("Please enter at least 3 characters to search.");
    }
  };

  const handleInviteClick = async (friendId) => {
    // console.log(friendId);
    // console.log(selectedRole);
    if (!friendId) return;
    try {
      const newFriend = {
        role: selectedRole,
        timestamp: serverTimestamp(),
      };

      const friendRef = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        selectedShopList?.name,
        "members",
        friendId
      );

      const friendSnap = await getDoc(friendRef);

      if (friendSnap.exists()) {
        // If the friend already exists, update the role and timestamp
        await updateDoc(friendRef, {
          role: newFriend.role,
          timestamp: serverTimestamp(),
        });
      } else {
        // If the friend does not exist, add the new friend
        await setDoc(friendRef, newFriend);
      }

      // Add to the friend's invitedList subcollection
      const invitedListRef = doc(db, "users", friendId, "invitedList", listId);

      const invitedListData = {
        userId: user?.uid,
        timestamp: serverTimestamp(),
        role: newFriend.role,
      };

      await setDoc(invitedListRef, invitedListData);

      setFriendId("");
      fetchFriends();
      // Change visibility to "Friends"
      const shoppingListRef = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        selectedShopList?.name
      );

      await updateDoc(shoppingListRef, {
        visibility: "Friends",
      });

      toast.success("Friend invited successfully!", toastOptions);
      console.log("Friend successfully added/updated!");
    } catch (error) {
      console.error("Error updating friend: ", error);
      toast.error("Error inviting friend!", toastOptions);
    }
  };

  const handleClick = async () => {
    // console.log(friendId);
    // console.log(selectedRole);
    if (!friendId) return;
    try {
      const newFriend = {
        role: selectedRole,
        timestamp: serverTimestamp(),
      };

      const friendRef = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        selectedShopList?.name,
        "members",
        friendId
      );

      const friendSnap = await getDoc(friendRef);

      if (friendSnap.exists()) {
        // If the friend already exists, update the role and timestamp
        await updateDoc(friendRef, {
          role: newFriend.role,
          timestamp: serverTimestamp(),
        });
      } else {
        // If the friend does not exist, add the new friend
        await setDoc(friendRef, newFriend);
      }

      // Add to the friend's invitedList subcollection
      const invitedListRef = doc(db, "users", friendId, "invitedList", listId);

      const invitedListData = {
        userId: user?.uid,
        timestamp: serverTimestamp(),
        role: newFriend.role,
      };

      await setDoc(invitedListRef, invitedListData);

      setFriendId("");
      fetchFriends();
      // Change visibility to "Friends"
      const shoppingListRef = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        selectedShopList?.name
      );

      await updateDoc(shoppingListRef, {
        visibility: "Friends",
      });

      await addNotification({
        userId: friendId,
        id: user?.uid,
        type: "inviteOnList",
        by: "user",
        fields: {
          shopList: selectedShopList?.name,
          listId: listId,
        },
      });

      toast.success("Friend invited successfully!", toastOptions);
      console.log("Friend successfully added/updated!");
    } catch (error) {
      console.error("Error updating friend: ", error);
      toast.error("Error inviting friend!", toastOptions);
    }
  };

  const handleRoleChange = (selectedRole) => {
    console.log("Role", selectedRole);
    setSelectedRole(selectedRole);
  };

  const handleRoleChangeSearch = (selectedRole) => {
    console.log("Role", selectedRole);
    setSelectedRole(selectedRole);
  };

  const handleNameChange = (selectedOption) => {
    console.log("Name", selectedOption);
    setFriendId(selectedOption);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (user) {
        try {
          // Reference to the connections subcollection of the logged-in user
          const connectionsRef = collection(
            db,
            "users",
            user?.uid,
            "connections"
          );
          const connectionsSnapshot = await getDocs(connectionsRef);

          const activeFriendIds = [];

          // Loop through the connections and gather active connections
          connectionsSnapshot.forEach((doc) => {
            const connectionData = doc.data();
            if (connectionData.type === "active") {
              activeFriendIds.push(doc.id); // Assuming doc.id is the friend's UID
            }
          });

          setFriendCount(activeFriendIds.length);

          // Fetch the user data for each active friend
          const userPromises = activeFriendIds.map((userId) =>
            getDoc(doc(db, "users", userId))
          );

          const userDocs = await Promise.all(userPromises);

          // Map the user data to a format suitable for the component
          const userOption = userDocs.map((userDoc) => ({
            id: userDoc.id,
            label: userDoc.data()?.userName,
          }));

          console.log("userOption", userOption);
          setUserOptions(userOption);
        } catch (error) {
          console.error("Error fetching users:", error.message);
        }
      }
    };

    fetchUsers();
  }, [user]);

  console.log("Friend Count outside", friendCount);

  const fetchFriends = async () => {
    if (!user?.uid) {
      console.error("User is undefined or null");
      return;
    }

    if (!selectedShopList?.name) {
      console.error("SelectedShopList is undefined or null");
      return;
    }

    try {
      console.log("selectedShopList", selectedShopList);
      const docRef = collection(
        db,
        "users",
        user.uid,
        "shoppingList",
        selectedShopList.name,
        "members"
      );
      const docSnap = await getDocs(docRef);

      const friendsArray = docSnap.docs.map((doc) => ({
        id: doc.id,
        role: doc.data().role,
      }));

      console.log("Friend", friendsArray);

      const friendPromises = friendsArray
        .filter((friend) => friend.id)
        .map((friend) => getDoc(doc(db, "users", friend.id)));

      const friendDocuments = await Promise.all(friendPromises);
      const friendData = friendDocuments.map((friendDoc) => {
        const rolesFromShopList =
          friendsArray.find((f) => f.id === friendDoc.id)?.role || {};
        return {
          id: friendDoc.id,
          userName: friendDoc.data()?.userName,
          photo: friendDoc.data()?.photo,
          role: rolesFromShopList,
        };
      });

      console.log("FriendData", friendData);
      setFriendsData(friendData);
    } catch (error) {
      console.error("Error fetching friends:", error);
    }
  };

  useEffect(() => {
    if (user?.uid && selectedShopList?.name) {
      fetchFriends();
      setSearchData([]);
      setInput("");
    }
  }, [user, selectedShopList]);

  useEffect(() => {
    if (userOptions.length > 0) {
      const friendIds = friendsData.map((friend) => friend.id);
      const remainingUsers = userOptions.filter(
        (user) => !friendIds.includes(user.id)
      );
      console.log("users remainingUsers", remainingUsers);
      setUnsharedUsers(remainingUsers);
    }
  }, [friendsData, userOptions]);

  const handleRevokeUser = async (index, Id) => {
    setShowModal(false);
    const docRef = doc(
      db,
      "users",
      user?.uid,
      "shoppingList",
      selectedShopList?.name,
      "members",
      Id
    );
    const invitedListRef = doc(db, "users", Id, "invitedList", listId);
    const docSnap = await getDoc(docRef);
    try {
      if (docSnap.exists) {
        // Remove the friend from the array
        await deleteDoc(docRef);
        await deleteDoc(invitedListRef);
        const newfriendsData = friendsData.filter((friend) => friend.id !== Id);
        setFriendsData(newfriendsData);
        console.log("friendsData", newfriendsData);
        toast.success("Access revoked successfully!", toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error removing user!", toastOptions);
    }
  };
  const handleRoleUpdate = async (index, Id, newRole) => {
    // console.log(Id, newRole);
    // Update the friend's role in the database
    setShowModal(false);
    const docRef = doc(
      db,
      "users",
      user?.uid,
      "shoppingList",
      selectedShopList?.name,
      "members",
      Id
    );
    const invitedListRef = doc(db, "users", Id, "invitedList", listId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists) {
      const newfriendsData = friendsData;
      newfriendsData[index].role = newRole;
      setFriendsData(newfriendsData);
      // If the friend exists, update the role
      await updateDoc(docRef, {
        role: newRole,
        timestamp: serverTimestamp(),
      });
      await updateDoc(invitedListRef, {
        role: newRole,
        timestamp: serverTimestamp(),
      });
      fetchFriends();
      toast.success(`Role updated successfully!`, toastOptions);
      console.log(`Role for friend ${Id} updated successfully!`);
    } else {
      console.log(`No friend found with ID ${Id}`);
    }
  };
  const handleButtonClick = () => {
    if (selectedShopList.products.length === 0) {
      toast.warning("Kindly add some products before sharing!", toastOptions);
    } else {
      setShowModal(true);
    }
  };
  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${baseURL}/#/shoppingList/${listId}/${user?.uid}`
    ); // Copy link to clipboard
    toast.success("Link copied", toastOptions);
  };
  return (
    <>
      <li className="nav-item" onClick={() => handleButtonClick()}>
        <Link className="nav-link icon-md btn btn-light p-0">
          <PersonPlusFill className="fs-5" />
          {/* <i className="bi bi-people-fill"></i> */}
        </Link>
        <span className="mx-2 d-sm-none h6 fw-light">Invite People</span>
      </li>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="h4 fw-semibold">Share </span>
            <span className="text-primary">{selectedShopList.name} List</span>
          </Modal.Title>
        </Modal.Header>
        {friendCount >= 0 ? (
          <div>
            <form onSubmit={(event) => handleSearchBar(event)}>
              <div
                className="input-group"
                style={{ paddingLeft: "15px", paddingRight: "15px" }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for people..."
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  required
                />
                <button className="btn btn-primary" type="submit">
                  Search
                </button>
              </div>

              {/* Add a search bar here  */}
              <div className="p-3 pb-0 d-flex justify-content-between">
                <div className="w-50 me-1">
                  <FormSelect
                    style={{ height: "2rem" }}
                    className="pt-1"
                    onChange={(event) => handleNameChange(event.target.value)}
                    value={friendId}
                  >
                    <option className="text-body" value="">
                      Select a friend
                    </option>
                    {unsharedUsers.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        className="text-body"
                      >
                        {option.label}
                      </option>
                    ))}
                  </FormSelect>
                </div>
                <div className="w-50 ms-1">
                  <FormSelect
                    className="pt-1"
                    style={{ height: "2rem" }}
                    onChange={(event) => handleRoleChange(event.target.value)}
                    value={selectedRole?.name}
                  >
                    {rolesOptions.map((option) => (
                      <option key={option.name} value={option.name}>
                        {option.label}
                      </option>
                    ))}
                  </FormSelect>
                </div>
                <Button
                  className="ms-2"
                  size="sm"
                  variant="primary-soft"
                  onClick={() => {
                    handleClick();
                    setShowModal(false);
                  }}
                >
                  Invite
                </Button>
              </div>
              <hr />

              {friendsData?.length >= 0 && (
                <>
                  <div className="ps-3">
                    <h6 className="h6 fw-semibold">Shared with: </h6>
                  </div>
                  <div className="d-flex flex-column mt-3 justify-content-center align-items-center pe-3 pb-4 pt-0">
                    {friendsData?.map((friend, index) => {
                      return (
                        <div
                          key={friend.id}
                          className="d-flex justify-content-between align-items-center w-100 my-1"
                        >
                          <div className="w-50 ms-3 d-flex align-items-center">
                            <div className="avatar  ">
                              <img
                                className="avatar-img rounded-2"
                                src={friend.objectUser.photo}
                              />
                            </div>
                            <div className="ms-2 ">
                              <div className="h6 fw-semibold m-0">
                                {friend.userName}
                              </div>
                              <CheckStatus
                                myUid={user?.uid}
                                yourEmail={friend?.email}
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-end align-items-center">
                            <FormSelect
                              style={{ height: "2rem" }}
                              className="py-1 p-1 border-0 bg-transparent"
                              onChange={async (event) => {
                                await handleRoleUpdate(
                                  index,
                                  friend.id,
                                  event.target.value
                                );
                              }}
                              value={friend.role}
                            >
                              {rolesOptions.map((option) => (
                                <option key={option.name} value={option.name}>
                                  {option.label}
                                </option>
                              ))}
                            </FormSelect>
                            <IoIosCloseCircle
                              className="fs-4 m-0 "
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setDATA({
                                  friendId: friend?.id,
                                  displayName: friend?.userName,
                                });
                                setShowRemoveModal(true);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </form>

            {searchData.length > 0 && (
              <>
                <hr />
                {searchData?.length >= 0 && (
                  <>
                    <div className="ps-3">
                      <h6 className="h6 fw-semibold">Invite: </h6>
                    </div>
                    <div className="d-flex flex-column mt-3 justify-content-center align-items-center pe-3 pb-4 pt-0">
                      {searchData?.map((friend, index) => {
                        return (
                          <div
                            key={friend.id}
                            className="d-flex justify-content-between align-items-center w-100 my-1"
                          >
                            <div className="w-50 ms-3 d-flex align-items-center">
                              <div className="avatar  ">
                                <img
                                  src={friend.objectUser.photo}
                                  className="img-fluid rounded-2"
                                />
                              </div>
                              <div className="ms-2 ">
                                <div className="h6 fw-semibold m-0">
                                  {friend.displayName}
                                </div>
                                <CheckStatus
                                  myUid={user?.uid}
                                  yourEmail={friend?.email}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                              <div className="w-50 ms-1">
                                <FormSelect
                                  className="pt-1"
                                  style={{ height: "2rem" }}
                                  onChange={(event) =>
                                    handleRoleChangeSearch(event.target.value)
                                  }
                                  value={selectedRole?.name}
                                >
                                  {rolesOptions.map((option) => (
                                    <option
                                      key={option.name}
                                      value={option.name}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </FormSelect>
                              </div>
                              <Button
                                className="ms-2"
                                size="sm"
                                variant="primary-soft"
                                onClick={() => {
                                  handleInviteClick(friend.objectID);
                                  setShowModal(false);
                                }}
                              >
                                Invite
                              </Button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            )}

            <div className="px-3 d-flex justified-content-center align-items-center">
              <hr className="w-50" />
              <div className="mx-2">OR</div>
              <hr className="w-50" />
            </div>
            <div className="px-3 mb-4">
              <div className="h6 fw-semibold">Share link:</div>
              <InputGroup className="mb-3">
                <Form.Control
                  className="rounded me-1 text-mute"
                  value={`${baseURL}/#/shoppingList/${listId}/${user?.uid}`}
                  readOnly
                />
                <Button
                  className="rounded mx-1 "
                  variant="primary-soft"
                  size="sm"
                  onClick={handleCopyLink}
                >
                  <Link45deg className="fs-6 me-1" />
                  <span>Copy Link</span>
                </Button>
              </InputGroup>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Modal>
      {/* <Modal
        show={showRemoveModal}
        onHide={() => setShowRemoveModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Revoke</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <i
            style={{ fontSize: "4.5rem" }}
            className="text-body bi bi-exclamation-triangle"
          ></i>
          <div className="mt-4 fs-5 text-body">
            Are you sure you want to revoke access from {DATA.displayName}?
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary-soft"
            className="w-25 mx-1"
            onClick={() => {
              setDATA({
                friendId: "",
                displayName: "",
              });
              setShowRemoveModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="w-25 mx-1"
            onClick={() => {
              console.log("data.friendId", DATA.friendId);
              handleRevokeUser("", DATA.friendId);
              setDATA({
                friendId: "",
                displayName: "",
              });
              setShowRemoveModal(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default InviteOnList;
