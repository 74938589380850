import React from "react";
import GetLocation from "../GetLocation";

const Location = ({ location, setLocation, handleNext }) => {
  // Location
  const handleLocationChange = (newLocation) => {
    setLocation(newLocation);
  };
  const handleSubmitLocation = (e) => {
    e.preventDefault();
    handleNext();
  };
  return (
    <>
      <div>
        <p className="mt-3 fs-4 grey">Where do you live?</p>
      </div>
      <form onSubmit={(e) => handleSubmitLocation(e)}>
        <div className="mb-3">
          <GetLocation
            onLocationChange={handleLocationChange}
            location={location}
          />
        </div>
        <div className="d-grid">
          <button type="submit" className="btn btn-primary flex-grow-1">
            {location === "" ? "Skip" : "Next"}
          </button>
        </div>
      </form>
    </>
  );
};

export default Location;
