import React, { useEffect } from "react";
import useModalStore from "../StatesStore/ModalStore";
import {
  CheckSquare,
  CheckSquareFill,
  DashSquare,
  EnvelopeCheck,
  Facebook,
  Link45deg,
  PersonPlusFill,
  PlusSquare,
  Whatsapp,
  X,
} from "react-bootstrap-icons";
import { message } from "antd";
// import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { UserAuth } from "../Context";
import { useState } from "react";
import { db } from "../FirebaseConfig";
import { saveInviteToDb } from "../lib/saveInviteToDb";
import InviteOnList from "../ShoppingList/InviteOnList";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  serverTimestamp,
  collection,
  getDocs,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import {
  Modal,
  Button,
  FormSelect,
  InputGroup,
  Form,
  FormControl,
} from "react-bootstrap";
import { IoIosCloseCircle } from "react-icons/io";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { getBaseURL } from "../lib/getBaseURL";
import algoliasearch from "algoliasearch";
import CheckStatus from "../lib/checkUserStatus";
import { checkIfEmailExists } from "../lib/checkIfEmailExists";
import useNotification from "../hooks/useNotification";
import { encryptChat } from "./Encryption";
import { saveMessageToDB } from "../Chat/lib.js/saveMsgToDb";
import useChatClient from "../StatesStore/ChatClient";
import useThemeState from "../StatesStore/Theme";
const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_ADMIN_KEY
);
const indexUsers = client.initIndex("users"); // Name of index in Algolia is users

const {
  ShoppingListMail,
  CustomShoppingListMail,
} = require("../EmailSystem/sendEmails");
function InvitePeople({
  selectedShopList,
  listId,
  myName,
  listName,
  listLink,
  componentName,
}) {
  const { data, closeModal } = useModalStore();
  const [emailUsers, setEmailUsers] = useState([{ name: "", email: "" }]);
  const [phoneUsers, setPhoneUsers] = useState([{ cc: "", phone: "" }]);
  const { user } = UserAuth();
  const [Error, setError] = useState("");
  const regex = /^[a-zA-Z\s]*$/;
  const myEmail = user?.providerData[0].email;
  const text = "Invite Friends";
  const [inviteOption, setInviteOption] = useState("users");
  const [selectedRoles, setSelectedRoles] = useState({}); // Store selected roles for all users
  const { addNotification } = useNotification();
  const [content, setContent] = useState("");
  const [isFriendsDataEmpty, setIsFriendsDataEmpty] = useState(false);
  const inviteSVGBlack = process.env.PUBLIC_URL + "/assets/svgs/B-Invite.svg";
  const inviteSVGWhite = process.env.PUBLIC_URL + "/assets/svgs/Invite.svg";
  const { theme } = useThemeState();
  // Handle role selection
  const handleRoleChange = (friendId, newRole) => {
    setMembers((prev) => ({
      ...prev,
      [friendId]: newRole,
    }));
  };

  const isFirstEmailUserEmpty =
    emailUsers[0]?.name === "" &&
    emailUsers[0]?.email === "" &&
    emailUsers?.length === 1;
  const isFirstPhoneUserEmpty =
    phoneUsers[0].cc === "" &&
    phoneUsers[0].phone === "" &&
    phoneUsers.length === 1;
  const modified = listLink.replace(
    "http://localhost:3000",
    "https://lookflock.com"
  );

  const [productImgUrls, setProductImgUrls] = useState([]);
  const [profile, setProfile] = useState("");
  const [showModal, setShowModal] = useState(false);
  //InviteOnLookflock
  const [selectedRole, setSelectedRole] = useState("editor"); // Initialize with a default value
  const [friendId, setFriendId] = useState("");
  const [friendsData, setFriendsData] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [unsharedUsers, setUnsharedUsers] = useState([]);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [friendCount, setFriendCount] = useState(0);
  const [selectedFriends, setSelectedFriends] = useState([]);
  const baseURL = getBaseURL();
  // let friendCount = 0;
  const [DATA, setDATA] = useState({
    friendId: "",
    displayName: "",
  });
  const rolesOptions = [
    { name: "commentor", label: "View & Chat" },
    { name: "editor", label: "Editor" },
    // { name: "viewer", label: "Viewer" },
    // { name: "commentor", label: "View & chat" },
  ];
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const googleLogoPath =
    process.env.PUBLIC_URL + "/assets/images/logo/google-logo.svg";
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  // DummyContacts
  const [contacts, setContacts] = useState([
    {
      photo: "",
      userName: "Asad M.Furqan",
      email: "muhammadasad98980@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
    {
      photo: "",
      userName: "Osama Siddique",
      email: "osama.siddiqui2017@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
    {
      photo: "",
      userName: "Abdul Sami",
      email: "abdulsami786125@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
    {
      photo: "",
      userName: "Fahad Ausaf",
      email: "fahad.ausaf@gmail.com",
      phoneNumber: "+923001234567",
      isSelected: false,
    },
  ]);
  const [members, setMembers] = useState({});
  const [listMembers, setListMembers] = useState({});
  const client = useChatClient((state) => state.client);
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    const initializeChannel = async () => {
      if (!client || !listId) return;

      try {
        const channel = client.channel("team", listId);

        setChannel(channel);
      } catch (error) {
        console.error("Error initializing channel:", error);
      }
    };

    initializeChannel();
  }, [client, listId]);
  useEffect(() => {
    const fetchMembers = async () => {
      const membersRef = collection(
        db,
        "users",
        user.uid,
        "shoppingList",
        listName,
        "members"
      );
      const unsubscribe = onSnapshot(membersRef, (membersSnap) => {
        const membersData = {};
        membersSnap.forEach((doc) => {
          membersData[doc.id] = doc.data().role;
        });
        setListMembers(membersData);
      });

      // Cleanup function to unsubscribe from the listener when the component unmounts
      return () => unsubscribe();
    };
    fetchMembers();
  }, [listName]);

  const handleButtonClick = () => {
    // if (selectedShopList.products.length === 0) {
    //   toast.warning("Kindly add some products before sharing!", toastOptions);
    // } else {
    setShowModal(true);
    // }
  };
  const checkEmails = async (emailUsers) => {
    const emailSet = new Set();
    emailSet.add(myEmail);
    console.log("emailSet", emailUsers);
    for (const person of emailUsers) {
      if (person.email === myEmail) {
        setError("Cannot email yourself");
        return false; // Email is same as user's email
      }
      if (emailSet.has(person.email)) {
        setError("Emails should be unique");
        return false; // Duplicate found
      }
      const emailExists = await checkIfEmailExists(person.email);
      if (emailExists.exists) {
        const handleLinkClick = () => {
          closeModal(); // Close the modal when the link is clicked
        };

        const userLink = (
          <Link
            to={`/userProfile/${emailExists.userId}`}
            onClick={handleLinkClick}
          >
            {emailExists.firstName}
          </Link>
        );

        // Set the error state with the clickable link
        setError(
          <>
            {person.email} is already on Lookflock. Add {userLink}.
          </>
        );

        return false;
      }

      emailSet.add(person.email);
    }
    return true;
  };
  const onEmailFinish = (values) => {
    values.forEach(async (userData) => {
      const { name, email } = userData;
      // console.log(user, data);
      // console.log(listLink);
      const mailFunction =
        content && content.trim() !== ""
          ? CustomShoppingListMail
          : ShoppingListMail;

      mailFunction(
        myName,
        name,
        email,
        listName,
        listLink,
        productImgUrls,
        profile,
        content
      )
        .then(() => {
          message.success({
            content: `Email sent successfully to ${email}`,
            key: "success",
          });
        })
        .catch((error) => {
          message.error(`Error sending email to ${email}`);
          console.error("Error sending email:", error);
        });

      hideModal();
      await saveInviteToDb(
        {
          name,
          email,
          type: "email",
        },
        user
      );
    });
  };
  const handleAddEmailUser = () => {
    setEmailUsers([...emailUsers, { name: "", email: "" }]);
  };
  const handleRemoveEmailUser = (index) => {
    const newEmailUsers = [...emailUsers];
    if (newEmailUsers.length > 1) {
      newEmailUsers.splice(index, 1);
      setEmailUsers(newEmailUsers);
    } else if (newEmailUsers.length === 1) {
      setEmailUsers([{ name: "", email: "" }]);
    }
  };
  const handleEmailChange = (index, field, value) => {
    const newUsers = [...emailUsers];
    newUsers[index][field] = value;
    setEmailUsers(newUsers);
  };
  const fetchShoppingListProducts = async () => {
    // console.log("listLink", listLink);
    // console.log("listId", listId);
    const elements = listLink.split("/");
    // console.log("elements", elements);
    // console.log(listLink.split("#")[0]);
    // console.log("elements[5]", elements[5]);
    // console.log(elements[6]);
    const userRef = doc(db, "users", elements[6]);
    const userSnap = await getDoc(userRef);
    const profilePhoto = userSnap?.data()?.photo;
    setProfile(profilePhoto);
    const shopListRef = collection(db, "users", elements[6], "shoppingList");
    const shopList = await getDocs(shopListRef);
    const list = shopList.docs.filter(
      (doc) => doc?.data()?.listId === elements[5]
    );
    // console.log("list123", list[0]?.id);
    const productRef = collection(shopListRef, list[0]?.id, "products");
    const productSnap = await getDocs(productRef);
    const products = productSnap?.docs.map((doc) => doc?.id);
    if (products) {
      const ImgUrls = await Promise.all(
        products?.map(async (product) => {
          const productRef = doc(db, "products", product);
          const productSnap = await getDoc(productRef);
          return { id: productSnap?.id, image: productSnap?.data()?.imageUrl };
        })
      );
      setProductImgUrls(ImgUrls);
      // console.log("ImgUrls", ImgUrls);
    }
  };
  const onFinishPhone = (values) => {
    // console.log("onFinishPhone values", values);
    // Logic to handle phone number invitations
    values.forEach(async (userData) => {
      const { name, cc, phone } = userData;
      // console.log(`Invite to phone number: ${phone} for ${name}`);
      // Add the phone invite sending logic here
      await saveInviteToDb(
        {
          phone: `${cc}${phone}`,
          type: "phone",
        },
        user
      );
    });
    message.success("Invitations sent successfully!");
    closeModal();
  };
  const handleAddPhoneUser = () => {
    setPhoneUsers([...phoneUsers, { cc: "", phone: "" }]);
  };

  const handleRemovePhoneUser = (index) => {
    const newPhoneUsers = [...phoneUsers];
    if (newPhoneUsers.length > 1) {
      newPhoneUsers.splice(index, 1);
      setPhoneUsers(newPhoneUsers);
    } else if (newPhoneUsers.length === 1) {
      setPhoneUsers([{ cc: "", phone: "" }]);
    }
  };

  const handlePhoneChange = (index, field, value) => {
    const newPhoneUsers = [...phoneUsers];
    newPhoneUsers[index][field] = value;
    setEmailUsers(newPhoneUsers);
  };
  const handleCopyLink = () => {
    const fullLink = listLink.replace(
      "http://localhost:3000",
      "https://lookflock.com"
    );
    navigator.clipboard.writeText(fullLink); // Copy link to clipboard
    message.success({
      content: `Link copied`,
      key: "success",
    });
  };

  const handleFacebookShare = () => {
    const shareQuote = encodeURIComponent(text);
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://lookflock.com/#/ProductDetails/${text}&quote=${shareQuote}`
    );
  };

  const handleWhatsappShare = () => {
    window.open(
      `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${text}: ${listLink}`
      )}`
    );
  };
  const handleRevokeUser = async (index, Id) => {
    setShowModal(false);
    const docRef = doc(
      db,
      "users",
      user?.uid,
      "shoppingList",
      selectedShopList?.id,
      "members",
      Id
    );
    const invitedListRef = doc(db, "users", Id, "invitedList", listId);
    const docSnap = await getDoc(docRef);
    try {
      if (docSnap.exists) {
        // Remove the friend from the array
        await deleteDoc(docRef);
        await deleteDoc(invitedListRef);
        const newfriendsData = friendsData.filter((friend) => friend.id !== Id);
        setFriendsData(newfriendsData);
        // console.log("friendsData", newfriendsData);
        toast.success("Access revoked successfully!", toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error removing user!", toastOptions);
    }
  };
  // console.log("listMembers !!", listMembers);

  const handleSearchBar = async (event) => {
    event.preventDefault();
    const itemsPerPage = 12;

    if (input.trim().length >= 3) {
      const searchQuery = `${input.trim()}`;
      // console.log("Search input: ", searchQuery);
      try {
        const offset = 0;
        const data = await indexUsers.search(searchQuery, {
          offset,
          length: itemsPerPage,
        });
        const uniqueUsers = new Set([...data.hits]);
        let updatedUsers = Array.from(uniqueUsers);
        // console.log("updatedUsers", updatedUsers);
        // Filter out the current user and existing members from the updatedUsers array
        updatedUsers = updatedUsers.filter(
          (updatedUser) =>
            updatedUser.objectID !== user?.uid &&
            !listMembers[updatedUser.objectID] &&
            updatedUser.objectUser.isVerified
        );

        // console.log(
        //   `Users in search on shopping list invite modal: `,
        //   updatedUsers
        // );
        if (updatedUsers.length === 0) {
          setIsFriendsDataEmpty(true);
        }
        // setSearchResults(updatedUsers);
        setFriendsData(updatedUsers);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    } else {
      console.log("Please enter at least 3 characters to search.");
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (user) {
        try {
          // Reference to the connections subcollection of the logged-in user
          const connectionsRef = collection(
            db,
            "users",
            user?.uid,
            "connections"
          );
          const connectionsSnapshot = await getDocs(connectionsRef);

          const activeFriendIds = [];

          // Loop through the connections and gather active connections
          connectionsSnapshot.forEach((doc) => {
            const connectionData = doc.data();
            if (connectionData.type === "active") {
              activeFriendIds.push(doc.id); // Assuming doc.id is the friend's UID
            }
          });

          setFriendCount(activeFriendIds.length);

          // Fetch the user data for each active friend
          const userPromises = activeFriendIds.map((userId) =>
            getDoc(doc(db, "users", userId))
          );

          const userDocs = await Promise.all(userPromises);

          // Map the user data to a format suitable for the component
          const userOption = userDocs.map((userDoc) => ({
            id: userDoc.id,
            label: userDoc.data()?.userName,
          }));

          // console.log("userOption", userOption);
          setUserOptions(userOption);
        } catch (error) {
          console.error("Error fetching users:", error.message);
        }
      }
    };

    fetchUsers();
  }, [user]);
  useEffect(() => {
    if (userOptions.length > 0) {
      const friendIds = friendsData.map((friend) => friend.id);
      const remainingUsers = userOptions.filter(
        (user) => !friendIds.includes(user.id)
      );
      // console.log("users remainingUsers", remainingUsers);
      setUnsharedUsers(remainingUsers);
    }
  }, [friendsData, userOptions]);

  useEffect(() => {
    if (!listId) return;
    fetchShoppingListProducts();
  }, [data, listId]);

  const handleCheckboxChange = (index) => {
    // Create a new array to avoid mutating state directly
    const updatedContacts = [...contacts];
    // Toggle the checked value for the specific user
    updatedContacts[index].isSelected = !updatedContacts[index].isSelected;
    // Update the state with the new user list
    setContacts(updatedContacts);
  };
  const handleSubmitUsingGoogleContacts = async () => {
    let values = [];
    if (inviteOption === "email") {
      contacts.map((contact) => {
        if (contact.isSelected) {
          values.push({ name: contact.userName, email: contact.email });
        }
      });
      setEmailUsers(values);
      if (await checkEmails(values)) {
        onEmailFinish(values);
      }
    } else if (inviteOption === "phone") {
      // contacts.map((contact) => {
      //   if (contact.isSelected) {
      //     values.push({ cc: contact.userName, phone: contact.phoneNumber });
      //   }
      // });
      // setPhoneUsers(values);
      // if (await checkEmails(values)) {
      //   onEmailFinish(values);
      // }
    }
  };
  // console.log("memebers1", members);

  const handleSelectFriend = (friendId, isSelected, friend) => {
    setMembers((prev) => {
      const updated = { ...prev };
      if (isSelected) {
        updated[friendId] = updated[friendId] || "commentor"; // Default role
        setSelectedFriends((prevSelected) => [...prevSelected, friend]);
      } else {
        delete updated[friendId];
        setSelectedFriends((prevSelected) =>
          prevSelected.filter((f) => f.objectID !== friendId)
        );
      }
      return updated;
    });
  };

  const handleInvite = async () => {
    // console.log("handleInvite members", members);
    if (!members || Object.keys(members).length === 0) {
      toast.warn("Please select members to invite.", toastOptions);
      return;
    }
    try {
      console.log(selectedShopList);
      const chatRef = doc(db, "chats", listId);
      const chatSnap = await getDoc(chatRef);
      const friends = Object.keys(members);

      const memberText = friends.length === 1 ? "member" : "members";
      // Add members to the channel
      await channel.addMembers(friends, {
        text: `new ${memberText} added to the list.`,
      });

      const promises = Object.entries(members).map(async ([friendId, role]) => {
        const docRef = doc(
          db,
          "users",
          user?.uid,
          "shoppingList",
          selectedShopList?.id,
          "members",
          friendId
        );

        const invitedListRef = doc(
          db,
          "users",
          friendId,
          "invitedList",
          listId
        );

        return Promise.all([
          setDoc(
            docRef,
            { role, timestamp: serverTimestamp() },
            { merge: true }
          ),
          setDoc(
            invitedListRef,
            { role, timestamp: serverTimestamp(), userId: user?.uid },
            { merge: true }
          ),
        ]).then(() => {
          // Add notification after both setDoc operations complete
          return addNotification({
            userId: friendId,
            id: user?.uid,
            type: "inviteOnList",
            by: "user",
            fields: {
              shopList: selectedShopList?.name,
              listId: listId,
            },
          });
        });
      });

      await Promise.all(promises);

      if (content && content.trim()) {
        const messagePromises = Object.entries(members).map(
          ([friendId, role]) => {
            const msgToBeSent = {
              content: encryptChat(content), // Encrypt the note
              receiverId: friendId,
              senderId: user?.uid,
              type: "text",
            };
            return saveMessageToDB(msgToBeSent);
          }
        );

        await Promise.all(messagePromises);
      }
      // Change visibility to "Friends"
      const shoppingListRef = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        selectedShopList?.id
      );

      await updateDoc(shoppingListRef, {
        visibility: "Friends",
      });

      toast.success("Invitations sent successfully.", toastOptions);
      hideModal();
    } catch (error) {
      console.error("Error sending invites:", error);
      toast.error(
        "Failed to send invitations. Please try again.",
        toastOptions
      );
    }
  };

  // const handleInviteImmediately = async (friendId,displayName) => {
  //   try {
  //     let role = selectedRoles[friendId];
  //     console.log('handleInviteImmediately',role);

  //     if (!role) {
  //       role = 'commentor'
  //     }

  //     const docRef = doc(
  //       db,
  //       "users",
  //       user?.uid,
  //       "shoppingList",
  //       selectedShopList?.name,
  //       "members",
  //       friendId
  //     );

  //     const invitedListRef = doc(
  //       db,
  //       "users",
  //       friendId,
  //       "invitedList",
  //       listId
  //     );

  //     await Promise.all([
  //       setDoc(
  //         docRef,
  //         { role, timestamp: serverTimestamp() },
  //         { merge: true }
  //       ),
  //       setDoc(
  //         invitedListRef,
  //         {
  //           role,
  //           timestamp: serverTimestamp(),
  //           userId: user?.uid,
  //         },
  //         { merge: true }
  //       ),
  //     ]);

  //     toast.success(`${displayName} invited successfully`, toastOptions);
  //   } catch (error) {
  //     console.error("Error inviting friend:", error);
  //     toast.error("Failed to invite friend. Please try again.", toastOptions);
  //   }
  // };
  // console.log('friendData', friendsData);
  const hideModal = () => {
    setShowModal(false);
    setIsFriendsDataEmpty(false);
    setFriendsData([]);
    setInput("");
    setContent("");
    setInviteOption("users");
    setError("");
    setEmailUsers([{ name: "", email: "" }]);
    // setListMembers({});
    setSelectedFriends([]);
    setMembers({});
  };
  return (
    <>
      <li
        className="nav-item list-unstyled"
        onClick={() => handleButtonClick()}
      >
        {componentName === "ProfilePage" ? (
          <img
            width={"28px"}
            src={theme === "dark" ? inviteSVGWhite : inviteSVGBlack}
            alt=""
            style={{ cursor: "pointer" }}
          />
        ) : (
          <Link className="nav-link icon btn btn-light p-2">
            <PersonPlusFill className="fs-5" /> Invite
          </Link>
        )}
      </li>
      <Modal
        show={showModal}
        onHide={() => hideModal()}
        centered
        className="invitePeople"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <span className="h4 fw-semibold">Invite users to </span>
            <span className="">{selectedShopList.name} list</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {/* Invite via email */}
          <ul className="nav nav-bottom-line2 px-3 justify-content-center">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  inviteOption === "users" ? "active" : ""
                }`}
                onClick={() => {
                  setInviteOption("users");
                  setIsContactModalOpen(false);
                }}
              >
                <span>Users</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className={`nav-link ${
                  inviteOption === "email" ? "active" : ""
                }`}
                onClick={() => {
                  setInviteOption("email");
                  setIsContactModalOpen(false);
                }}
              >
                <span className="d-none d-sm-block">Using Email</span>
                <span className="d-block d-sm-none">Email</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  inviteOption === "link" ? "active" : ""
                }`}
                onClick={() => {
                  setInviteOption("link");
                  setIsContactModalOpen(false);
                }}
              >
                <span>Copy Link</span>
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className={`nav-link ${inviteOption === "phone" ? "active" : ""
                  }`}
                onClick={() => {
                  setInviteOption("phone");
                  setIsContactModalOpen(false);
                }}
              >
                <span className="d-none d-sm-block">Using Phone</span>
                <span className="d-block d-sm-none">Phone</span>
              </Link>
            </li> */}
          </ul>
          <div className="mt-4 px-3 pb-3">
            {inviteOption === "email" ? (
              <div>
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    if (await checkEmails(emailUsers)) {
                      onEmailFinish(emailUsers);
                    }
                  }}
                >
                  {emailUsers.map((user, index) => (
                    <InputGroup
                      key={index}
                      className="mb-1 d-flex justify-content-center align-items-center"
                    >
                      <Form.Control
                        className="me-1 rounded h-50"
                        placeholder="Name"
                        value={user.name}
                        onChange={(e) => {
                          if (regex.test(e.target.value)) {
                            handleEmailChange(index, "name", e.target.value);
                            setError("");
                          } else {
                            setError(
                              "Name cannot contain numbers or special characters"
                            );
                          }
                        }}
                        required
                      />
                      <Form.Control
                        className="ms-1 rounded h-50"
                        type="email"
                        placeholder="Email"
                        value={user.email}
                        onChange={(e) => {
                          handleEmailChange(index, "email", e.target.value);
                        }}
                        required
                      />
                      <button
                        className="btn border-0 mx-2 px-0 py-1"
                        onClick={() => handleRemoveEmailUser(index)}
                        disabled={isFirstEmailUserEmpty}
                      >
                        <DashSquare className="fs-4" />
                      </button>
                      <button
                        className="btn border-0 mx-2 px-0 py-1"
                        onClick={handleAddEmailUser}
                      >
                        {/* <PlusCircle type="submit" /> */}
                        <PlusSquare type="submit" className="fs-4 " />
                      </button>
                    </InputGroup>
                  ))}
                  <span className="ms-2 text-danger">{Error}</span>
                  <div className="mt-0">
                    <textarea
                      className="form-control"
                      rows="2"
                      placeholder="Add a note or message (Optional)"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      maxLength={300}
                    />
                    <small>Character limit: 300</small>
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      className="rounded d-flex align-items-center"
                      type="submit"
                      variant="primary-soft"
                      size="sm"
                    >
                      <EnvelopeCheck className="fs-6 me-2" />
                      <span>Invite</span>
                    </Button>
                  </div>
                </Form>
              </div>
            ) : inviteOption === "phone" ? (
              <div>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    onFinishPhone(phoneUsers);
                  }}
                >
                  {phoneUsers.map((user, index) => (
                    <InputGroup
                      key={index}
                      className="mb-3 flex justify-content-center align-items-center"
                    >
                      <Form.Control
                        type="number"
                        value={user.cc}
                        className="me-1 rounded h-50"
                        style={{ width: "0.5rem" }}
                        name="cc"
                        placeholder="+92"
                        onChange={(e) =>
                          handlePhoneChange(index, "cc", e.target.value)
                        }
                        required
                      />
                      <Form.Control
                        type="number"
                        className="mx-1 rounded h-50 w-50"
                        name="phone"
                        placeholder="Phone"
                        value={user.phone}
                        onChange={(e) =>
                          handlePhoneChange(index, "phone", e.target.value)
                        }
                      />
                      <button
                        className="btn border-0 mx-2 px-0 py-1"
                        onClick={() => handleRemovePhoneUser(index)}
                        disabled={isFirstPhoneUserEmpty}
                      >
                        <DashSquare className="fs-4 " />
                      </button>
                      <button
                        className="btn border-0 mx-2 px-0 py-1"
                        onClick={handleAddPhoneUser}
                      >
                        <PlusSquare className="fs-4 " />
                      </button>
                    </InputGroup>
                  ))}
                  <div className="d-flex justify-content-end">
                    <Button
                      className="rounded d-flex align-items-center"
                      type="submit"
                      variant="primary-soft"
                      size="sm"
                    >
                      <EnvelopeCheck className="fs-6 me-2" />
                      <span>Invite</span>
                    </Button>
                  </div>
                </Form>
              </div>
            ) : inviteOption === "link" ? (
              <div>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="rounded me-1 text-mute"
                    value={modified}
                    readOnly
                  />
                  <Button
                    className="rounded mx-1 "
                    variant="primary-soft"
                    size="sm"
                    onClick={handleCopyLink}
                  >
                    <Link45deg className="fs-6 me-1" />
                    <span>Copy Link</span>
                  </Button>
                </InputGroup>
                <p
                  className="mb-0"
                  style={{
                    textAlign: "center",
                    color: "gray",
                  }}
                >
                  Anyone with this link can access the shopping list
                </p>
              </div>
            ) : (
              <div className="pb-2 ">
                <form onSubmit={(event) => handleSearchBar(event)}>
                  <div className="input-group ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search for people..."
                      value={input}
                      onChange={(e) => {
                        setInput(e.target.value);
                        setIsFriendsDataEmpty(false);
                      }}
                      required
                    />
                    <button className="btn btn-primary" type="submit">
                      Search
                    </button>
                  </div>
                  {selectedFriends.length > 0 && (
                    <div
                      className="alphabet-list navbar thinScrollBar p-1 pt-2 ps-3"
                      style={{ overflowX: "auto" }}
                    >
                      <ul className="nav d-flex flex-row flex-nowrap list-unstyled gap-2 pt-1 ">
                        {selectedFriends.map((friend) => {
                          return (
                            <li
                              key={friend.objectID}
                              className="btn rounded-2 btn-sm btn-primary-soft  d-flex gap-2 align-items-center ps-1 py-1"
                              onClick={() =>
                                handleSelectFriend(
                                  friend.objectID,
                                  false,
                                  friend
                                )
                              }
                            >
                              <X className="fs-5 " />

                              <div>{friend.displayName}</div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  {/* <hr /> */}
                  {friendsData?.length > 0 && (
                    <>
                      <div className="ps-3 pt-2">
                        <h6 className="h6 fw-semibold">Share access with: </h6>
                      </div>
                      <div className="d-flex flex-column mt-1 justify-content-center align-items-center pe-3 pb-1 pt-0">
                        {friendsData?.map((friend, index) => {
                          return (
                            <div
                              key={friend.id}
                              className="d-flex justify-content-between align-items-center w-100 my-1"
                            >
                              <div className="w-50 ms-3 d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  className="form-check-input me-2"
                                  checked={!!members[friend.objectID]}
                                  onChange={(e) =>
                                    handleSelectFriend(
                                      friend.objectID,
                                      e.target.checked,
                                      friend
                                    )
                                  }
                                />
                                <div
                                  className={`avatar avatar-xs text-center d-none d-sm-inline-block`}
                                >
                                  <ProfilePicture
                                    userData={friend.objectUser}
                                    className="avatar-img rounded"
                                  />
                                </div>
                                <div className="ms-2 ">
                                  <div className="h6 fw-semibold m-0">
                                    {friend.displayName}
                                  </div>
                                  <CheckStatus
                                    myUid={user?.uid}
                                    yourEmail={friend?.email}
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-end align-items-center">
                                <FormSelect
                                  style={{
                                    height: "2rem",
                                    paddingLeft: "9.5px",
                                  }}
                                  className="py-1 border-0 bg-transparent"
                                  onChange={(event) =>
                                    handleRoleChange(
                                      friend.objectID,
                                      event.target.value
                                    )
                                  }
                                  value={
                                    members[friend.objectID] ||
                                    selectedRoles[friend.objectID] ||
                                    "commentor"
                                  }
                                >
                                  {rolesOptions.map((option) => (
                                    <option
                                      key={option.name}
                                      value={option.name}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </FormSelect>
                                {/* <IoIosCloseCircle
                                    className="fs-4 m-0 "
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setDATA({
                                        friendId: friend?.id,
                                        displayName: friend?.userName,
                                      });
                                      console.log("data", data);
                                      setShowRemoveModal(true);
                                    }}
                                  /> */}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/* Text Box for adding a note/message */}
                      <div className="mt-3">
                        <textarea
                          className="form-control"
                          rows="2"
                          placeholder="Add a note or message (Optional)"
                          value={content}
                          onChange={(e) => setContent(e.target.value)}
                          maxLength={300}
                        />
                        <small>Character limit: 300</small>
                      </div>
                    </>
                  )}
                  {isFriendsDataEmpty && (
                    <div className="text-center pt-3">
                      <div className="h5 mb-0">No users found</div>
                    </div>
                  )}
                </form>
              </div>
            )}
          </div>
        </Modal.Body>
        {friendsData?.length > 0 && (
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-danger-soft me-2 btn-sm"
              onClick={hideModal}
            >
              Cancel
            </button>
            <button
              onClick={handleInvite}
              type="button"
              className="btn btn-primary-soft btn-sm"
            >
              Invite
            </button>
          </Modal.Footer>
        )}
        {inviteOption === "link" && (
          <Modal.Footer className="d-flex justify-content-center m-0 pb-2 p-0">
            <div>
              <div className="btn me-2" onClick={handleWhatsappShare}>
                <Whatsapp style={{ fontSize: "28px", color: "#00CF08" }} />
              </div>
              <div className="btn" onClick={handleFacebookShare}>
                <Facebook style={{ fontSize: "28px", color: "#0057DA" }} />
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
      <Modal
        show={showRemoveModal}
        onHide={() => {
          setShowRemoveModal(false);
          setIsFriendsDataEmpty(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Revoke</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <i
            style={{ fontSize: "4.5rem" }}
            className="text-body bi bi-exclamation-triangle"
          ></i>
          <div className="mt-4 fs-5 text-body">
            Are you sure you want to revoke access from {DATA.displayName}?
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary-soft"
            className="w-25 mx-1"
            onClick={() => {
              setDATA({
                friendId: "",
                displayName: "",
              });
              setShowRemoveModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="w-25 mx-1"
            onClick={() => {
              // console.log("data.friendId", DATA.friendId);
              handleRevokeUser("", DATA.friendId);
              setDATA({
                friendId: "",
                displayName: "",
              });
              setShowRemoveModal(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InvitePeople;
