import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useUrlState from "../StatesStore/CurrentURL";
import useCartStore from "../StatesStore/CartStore";
import useSearchInputStore from "../StatesStore/SearchInputStore";
import useSearchBarOpenState from "../StatesStore/SearchBarOpen";

function SignInMenuHeader() {
  const seturl = useUrlState((state) => state.seturl);
  const location = useLocation();
  const cartStoreCount = useCartStore((state) => state.cartItems.length);
  const [cartCount, setCartCount] = useState(cartStoreCount);
  const { isSearchBarOpen, setIsSearchBarOpen } = useSearchBarOpenState();
  const {
    searchInput,
    setSearchInput,
    resetSearchInput,
  } = useSearchInputStore();
  const getLocalCartCount = () => {
    const cart = localStorage.getItem("cart");
    return cart ? Object.keys(JSON.parse(cart)).length : 0;
  };

  useEffect(() => {
    const localCartCount = getLocalCartCount();
    setCartCount(Math.max(0, localCartCount));
  }, [cartStoreCount]);

  const handleOutsideClick = () => {
    const brand = document.getElementById("brandsCollapse");
    // const category = document.getElementById("categoryCollapse");
    const menCategory = document.getElementById("menCollapse");
    const womenCategory = document.getElementById("womenCollapse");
    if (brand.classList.contains("show")) {
      brand.classList.remove("show");
    }
    // if (category.classList.contains("show")) {
    //   category.classList.remove("show");
    // }
    if (menCategory.classList.contains("show")) {
      menCategory.classList.remove("show");
    }
    if (womenCategory.classList.contains("show")) {
      womenCategory.classList.remove("show");
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchInput.length > 2) {
      setIsSearchBarOpen(false);
      window.location.href = `#/search/${searchInput}`;
    }
  };
  return (
    <>
      {isSearchBarOpen ? (
        <li className="nav-item mx-0 mx-md-2 d-flex align-items-center search-container pe-2">
          <form
            className={`rounded position-relative ${
              isSearchBarOpen ? "show" : ""
            }`}
            id="search-form"
            style={{
              width: "15rem",
            }}
          >
            <input
              className="form-control ps-5 bg-light"
              type="search"
              name="input"
              placeholder="Search..."
              aria-label="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="btn bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y"
              type="submit"
              onClick={(e) => {
                setIsSearchBarOpen(false);
                setSearchInput("");
                handleSearch(e);
              }}
            >
              <i className="bi bi-search fs-5"></i>
            </button>
          </form>
        </li>
      ) : (
        <>
          <li
            className="nav-item mx-2 d-md-none"
            onClick={() => handleOutsideClick()}
          >
            <div
              className="nav-link p-0 search-icon"
              onClick={() => {
                setIsSearchBarOpen(true);
              }}
            >
              <i className="bi bi-search fs-4"></i>
            </div>
          </li>
          <li className="nav-item mx-0 mx-md-2">
            <Link
              className="nav-link p-0 position-relative"
              to="/cart"
              onClick={() => handleOutsideClick()}
            >
              {cartCount > 0 && (
                <span
                  className={`d-flex justify-content-center align-items-center position-absolute translate-middle badge rounded-circle bg-danger ${
                    cartCount > 9 ? "px-1" : ""
                  }`}
                  style={{
                    top: "13%",
                    left: "99%",
                    fontSize: "10px",
                    minHeight: "7.5px",
                    minWidth: "7px",
                  }}
                >
                  {cartCount}
                </span>
              )}
              <i
                className={`bi bi-cart${
                  location.pathname.includes("/cart") ? "-fill" : ""
                } fs-4`}
              ></i>
            </Link>
          </li>
          <li className="nav-item ms-2">
            <Link
              className="btn btn-secondary-soft rounded-2"
              to="/SignIn"
              onClick={() => seturl(location?.pathname)}
            >
              <span>Log in</span>
            </Link>
          </li>
          <li className="nav-item ms-2">
            <Link
              className="btn btn-secondary-soft rounded-2"
              to="/SignUp"
              onClick={() => seturl(location?.pathname)}
            >
              <span>Sign up</span>
            </Link>
          </li>
        </>
      )}
    </>
  );
}

export default SignInMenuHeader;
