import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ProductCard5 from "../Products/ProductCard5";
import { XMasonry, XBlock } from "react-xmasonry";
import { useInView } from "react-intersection-observer";
import { UserAuth } from "../Context";
import ShareFeed from "./ShareFeed";
import RecentlyOnlineUsers from "../components/RecentlyOnlineUsers";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import useLogsStore from "../StatesStore/Logs";
import useUserDataStore from "../StatesStore/UserData";
import Skeleton from "../Products/Skeleton";
import NotificationOnFeed from "./NotificationOnFeed";
import RecentlyJoinedUsers from "../components/RecentlyJoinedUsers";
import SearchStyle from "../Cards/SearchStyle";
import ProductCard3 from "../Products/ProductCard3";
import { useQuery } from "@tanstack/react-query";
import useSocketStore from "../StatesStore/Socket";
import SideNavUserProfile from "../SideNav/SideNavUserProfile";
import GoogleAdCard2 from "./GoogleAdCard2";
import GoogleAdCard3 from "./GoogleAdCard3";
import GoogleAdCard4 from "./GoogleAdCard4";
import GoogleAdCardMobile from "./GoogleAdCardMobile";
import ProductCard6 from "../Products/ProductCard6";

export default function NewsFeed9() {
  const socket = useSocketStore((state) => state.socket);
  const { ref: loadMoreRef, inView: loadMoreView } = useInView({
    threshold: 0.1,
  });
  const { ref: loginRef, inView: modalView } = useInView({
    threshold: 1,
    // delay :5000,
    rootMargin: "-170px",
  });
  const { user } = UserAuth();
  const logs = useLogsStore((state) => state.logs);
  const userData = useUserDataStore((state) => state.userData);

  const [productIds, setProductIds] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const defaultFeed = async () => {
    const response = await fetch(`${process.env.REACT_APP_API}/api/logoutFeed`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  };

  const { data, error } = useQuery({
    queryKey: ["defaultFeed"],
    queryFn: () => defaultFeed(),
    enabled: !user, // Query runs when userId is undefined
  });

  // Function to make 20% of products advertised
  const makeProductsAdvertised = (productsList) => {
    return productsList.map((product) => ({
      ...product,
      advertised: Math.random() < 0.2, // 20% chance of being advertised
    }));
  };

  // Fetch product IDs from logs or user preferences
  const fetchProductIdsFromLogs = async () => {
    if (logs && logs.length > 0) return logs;
    const ids = [];
    try {
      const logsRef = collection(db, "users", user?.uid, "logs");
      const logsQuery = query(
        logsRef,
        where("productId", "!=", null),
        orderBy("timestamp", "desc"),
        limit(10)
      );
      const logsSnapshot = await getDocs(logsQuery);

      logsSnapshot.forEach((doc) => {
        const logData = doc.data();
        if (logData.productId) ids.push(logData.productId);
      });

      if (ids.length === 0 && userData?.favCategories) {
        return userData.favCategories;
      } else if (ids.length === 0) {
        const userRef = doc(db, "users", user?.uid);
        const userSnapshot = await getDoc(userRef);
        return userSnapshot.data()?.favCategories || [];
      }
      return ids;
    } catch (error) {
      console.error("Error fetching productIds from logs:", error);
      return [];
    }
  };

  // Fetch products based on the current page number
  const fetchProducts = async () => {
    if (!productIds || isLoading) return;
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_RECOMMENDER}/api/recommend/${user?.uid}`,
        {
          input: productIds,
          page_number: pageNumber,
          items_per_page: 25,
        }
      );

      const newProducts = response.data;

      setProducts((prev) => [...prev, ...newProducts]);

      if (newProducts.length < 25) {
        setHasNextPage(false); // No more pages
      }else{
        setHasNextPage(true);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Load product IDs on component mount
  useEffect(() => {
    const initializeProductIds = async () => {
      if (!user) return;

      const ids = await fetchProductIdsFromLogs();
      setProductIds(ids);
    };

    initializeProductIds();
  }, [user]);

  // Fetch products whenever page number changes
  useEffect(() => {
    if (productIds) {
      fetchProducts();
    }
  }, [productIds, pageNumber]);

  // Trigger next page fetch when the "load more" ref is in view
  useEffect(() => {
    if (loadMoreView && hasNextPage && !isLoading) {
      setPageNumber((prev) => prev + 1);
    }
  }, [loadMoreView]);

  function getTargetBlockWidth() {
    const screenWidth = window.innerWidth;
    return screenWidth < 440 ? 200 : 250;
  }
  const isMobileScreen = window.innerWidth <= 768;
  return (
    <>
      {/* <SideNavUserProfile /> */}
      {/* <div className="col-md-8 col-lg-6 vstack gap-4 p-0 mt-0 mt-lg-5 px-3 px-lg-0 me-2"> */}
      <div className="vstack gap-2 px-0 ms-1">
        <h4 className="mb-0 d-inline ms-1">Recommended for you</h4>
        <XMasonry
          center={true}
          responsive={true}
          maxColumns={5}
          targetBlockWidth={getTargetBlockWidth()}
        >
           {/* <XBlock>{ <GoogleAdCard2 />}</XBlock> */}
          {(user ? products : data)?.map((product, index) => {
            if (!user && index === 40) {
              return (
                <XBlock key="login-ref">
                  <div ref={loginRef}></div>
                </XBlock>
              );
            }
            if ((index + 1) % 9 === 0) {
              return (
                <XBlock key={`ad-${index}`} width={isMobileScreen ? 2 : 1}> 
                 {isMobileScreen ? <GoogleAdCardMobile /> : <GoogleAdCard4 />}
                </XBlock>
                
              );
            }
            // Default product card rendering
            return (
              <XBlock key={product.id} width={1}>
                {/* <div
                  className={`me-0 ${
                    isSmallScreen && index % 2 === 0 ? "ms-0" : "ms-2"
                  } ${index}`}
                > */}
                <div>
                  <ProductCard6 product={product} />
                </div>
              </XBlock>
            );
          })}
          {isLoading &&
            Array.from({ length: 25 }).map((_, index) => (
              <XBlock key={`skeleton-${index}`} width={1}>
                <Skeleton />
              </XBlock>
            ))}
          <XBlock>
            <div ref={loadMoreRef}></div>
          </XBlock>
        </XMasonry>

        {isLoading && (
          <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
