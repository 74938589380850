import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ProductCard5 from "../Products/ProductCard5";
import { XMasonry, XBlock } from "react-xmasonry";
import { useInView } from "react-intersection-observer";
import { UserAuth } from "../Context";
import ShareFeed from "./ShareFeed";
import RecentlyOnlineUsers from "../components/RecentlyOnlineUsers";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import useLogsStore from "../StatesStore/Logs";
import useUserDataStore from "../StatesStore/UserData";
import Skeleton from "../Products/Skeleton";
import NotificationOnFeed from "./NotificationOnFeed";
import RecentlyJoinedUsers from "../components/RecentlyJoinedUsers";
import SearchStyle from "../Cards/SearchStyle";
import ProductCard3 from "../Products/ProductCard3";
import { useQuery } from "@tanstack/react-query";
import useSocketStore from "../StatesStore/Socket";
import SideNavUserProfile from "../SideNav/SideNavUserProfile";
import GoogleAdCard2 from "./GoogleAdCard2";
import GoogleAdCard3 from "./GoogleAdCard3";
import GoogleAdCard4 from "./GoogleAdCard4";
import GoogleAdCardMobile from "./GoogleAdCardMobile";
import { recommendClient } from '@algolia/recommend';
export default function Discover() {
    const sortBySupplierDiversity = (array) => {
        const supplierMap = new Map();
      
        // Group products by supplier
        array.forEach((item) => {
          if (!supplierMap.has(item.supplier)) {
            supplierMap.set(item.supplier, []);
          }
          supplierMap.get(item.supplier).push(item);
        });
      
        // Flatten the groups while ensuring diversity
        const sortedResults = [];
        const suppliers = Array.from(supplierMap.keys());
      
        while (sortedResults.length < array.length) {
          suppliers.forEach((supplier) => {
            if (supplierMap.get(supplier).length > 0) {
              sortedResults.push(supplierMap.get(supplier).shift());
            }
          });
        }
      
        return sortedResults;
      };
      
  const { ref: loadMoreRef, inView: loadMoreView } = useInView({
    threshold: 0.1,
  });
  const { ref: loginRef, inView: modalView } = useInView({
    threshold: 1,
    // delay :5000,
    rootMargin: "-170px",
  });
  const { user } = UserAuth();
  const logs = useLogsStore((state) => state.logs);
  const userData = useUserDataStore((state) => state.userData);

  const [productIds, setProductIds] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchRecommendations = async () => {
      const client = recommendClient('EMFUSB67CG', 'b5d620d93e833bcc993165f6b00181f3');

      // Array of objectIDs
      const objectIDs = await fetchProductIdsFromLogs()
console.log('objectIDs',objectIDs);

      try {
        // Fetch recommendations for each objectID
        const recommendationPromises = objectIDs.map((objectID) =>
          client.getRecommendations([
            {
              indexName: 'products',
              objectID: objectID,
              model: 'related-products',
              threshold: 42.1,

            },
          ])
        );

        // Wait for all requests to complete
        const responses = await Promise.all(recommendationPromises);
        const combinedResults = responses.flatMap((response) => response.results[0].hits);
        // Combine the results
        const diverseResults = sortBySupplierDiversity(combinedResults);

        console.log('Combined Recommendations:', responses);
        setRecommendations(combinedResults);
      } catch (err) {
        console.error('Error fetching recommendations:', err);
        // setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, []);

//   if (loading) {
//     return <div>Loading...</div>;
//   }



  const defaultFeed = async () => {
    const response = await fetch(`${process.env.REACT_APP_API}/api/logoutFeed`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data;
  };

  const { data, error } = useQuery({
    queryKey: ["defaultFeed"],
    queryFn: () => defaultFeed(),
    enabled: !user, // Query runs when userId is undefined
  });

  // Function to make 20% of products advertised
  const makeProductsAdvertised = (productsList) => {
    return productsList.map((product) => ({
      ...product,
      advertised: Math.random() < 0.2, // 20% chance of being advertised
    }));
  };

  // Fetch product IDs from logs or user preferences
  const fetchProductIdsFromLogs = async () => {
    if (logs && logs.length > 0) return logs;
    const ids = [];
    try {
      const logsRef = collection(db, "users", user?.uid, "logs");
      const logsQuery = query(
        logsRef,
        where("productId", "!=", null),
        orderBy("timestamp", "desc"),
        limit(10)
      );
      const logsSnapshot = await getDocs(logsQuery);

      logsSnapshot.forEach((doc) => {
        const logData = doc.data();
        if (logData.productId) ids.push(logData.productId);
      });

      if (ids.length === 0 && userData?.favCategories) {
        return userData.favCategories;
      } else if (ids.length === 0) {
        const userRef = doc(db, "users", user?.uid);
        const userSnapshot = await getDoc(userRef);
        return userSnapshot.data()?.favCategories || [];
      }
      return ids;
    } catch (error) {
      console.error("Error fetching productIds from logs:", error);
      return [];
    }
  };

  // Fetch products based on the current page number
 

  // Load product IDs on component mount
  

  // Fetch products whenever page number changes


  // Trigger next page fetch when the "load more" ref is in view
//   useEffect(() => {
//     if (loadMoreView && hasNextPage && !isLoading) {
//       setPageNumber((prev) => prev + 1);
//     }
//   }, [loadMoreView]);

  function getTargetBlockWidth() {
    const screenWidth = window.innerWidth;
    return screenWidth < 440 ? 200 : 250;
  }
  const isMobileScreen = window.innerWidth <= 768;
  return (
    <>
      {/* <SideNavUserProfile /> */}
      {/* <div className="col-md-8 col-lg-6 vstack gap-4 p-0 mt-0 mt-lg-5 px-3 px-lg-0 me-2"> */}
      <div className="vstack gap-4 px-0">
        <XMasonry
          center={true}
          responsive={true}
          maxColumns={4}
          targetBlockWidth={getTargetBlockWidth()}
        >
           {/* <XBlock>{ <GoogleAdCard2 />}</XBlock> */}
          {recommendations?.map((product, index) => {
          
            // if ((index + 1) % 9 === 0) {
            //   return (
            //     <XBlock key={`ad-${index}`} width={isMobileScreen ? 2 : 1}> 
            //      {isMobileScreen ? <GoogleAdCardMobile /> : <GoogleAdCard4 />}
            //     </XBlock>
                
            //   );
            // }
            // Default product card rendering
            return (
              <XBlock key={index} width={1}>
                <div className={`me-0 ${index === 0 ? "ms-1" : "ms-2"}`}>
                  <ProductCard5 product={product} />
                </div>
              </XBlock>
            );
          })}
          {isLoading &&
            Array.from({ length: 25 }).map((_, index) => (
              <XBlock key={`skeleton-${index}`} width={1}>
                <Skeleton />
              </XBlock>
            ))}
          <XBlock>
            {/* <div ref={loadMoreRef}></div> */}
          </XBlock>
        </XMasonry>

      </div>
    </>
  );
}
